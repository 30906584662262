import React from "react"

const BannerMap = () => {
  return(
    <>
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 1180.3 900.2">
    <g id="Layer_1">
      <path className="st0" d="M899.1,891.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C902.9,893.4,901.3,891.8,899.1,891.8z"/>
      <path className="st0" d="M890,891.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C893.9,893.4,891.9,891.8,890,891.8z
        "/>
      <path className="st0" d="M884.5,895.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C882.8,899.5,884.5,897.8,884.5,895.6z"/>
      <path className="st0" d="M875.4,895.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C873.8,899.5,875.4,897.8,875.4,895.6z"/>
      <path className="st0" d="M1096.4,877.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1094.7,881.3,1096.4,879.4,1096.4,877.2z"/>
      <path className="st0" d="M1037.2,873.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1039.4,873.3,1037.2,873.3z"/>
      <path className="st0" d="M341.3,877.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C339.4,881.3,341.3,879.4,341.3,877.2z"
        />
      <path className="st0" d="M1092.5,864.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1096.4,865.9,1094.7,864.3,1092.5,864.3z"/>
      <path className="st0" d="M1059.5,868.1c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1057.9,872,1059.5,870.3,1059.5,868.1z"/>
      <path className="st0" d="M1050.4,868.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1048.8,872,1050.4,870.3,1050.4,868.1z"/>
      <path className="st0" d="M1037.2,864.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1041.1,865.9,1039.4,864.3,1037.2,864.3z"/>
      <path className="st0" d="M1028.1,864.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1032,865.9,1030.1,864.3,1028.1,864.3z"/>
      <path className="st0" d="M991.3,864.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C995.1,865.9,993.5,864.3,991.3,864.3z"/>
      <path className="st0" d="M986,868.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C984.1,872,986,870.3,986,868.1z"/>
      <path className="st0" d="M976.7,868.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C975,872,976.7,870.3,976.7,868.1z"/>
      <path className="st0" d="M337.2,864.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C341.3,865.9,339.4,864.3,337.2,864.3z"/>
      <path className="st0" d="M328.1,864.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C332,865.9,330.3,864.3,328.1,864.3z"/>
      <path className="st0" d="M1166.3,854.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,856.6,1168.5,854.9,1166.3,854.9z"/>
      <path className="st0" d="M1160.8,859c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S1160.8,861,1160.8,859z"/>
      <ellipse className="st0" cx="1147.8" cy="859" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1138.5,854.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1142.3,856.6,1140.7,854.9,1138.5,854.9z"/>
      <path className="st0" d="M1133.2,859c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1131.6,862.9,1133.2,861,1133.2,859z"/>
      <path className="st0" d="M1124.2,859c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1122.2,862.9,1124.2,861,1124.2,859z"/>
      <ellipse className="st0" cx="1111" cy="859" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1101.6,854.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1105.5,856.6,1103.8,854.9,1101.6,854.9z"/>
      <path className="st0" d="M1092.5,854.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1096.4,856.6,1094.7,854.9,1092.5,854.9z"/>
      <path className="st0" d="M1087.3,859c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1085.4,862.9,1087.3,861,1087.3,859z"/>
      <ellipse className="st0" cx="1074.1" cy="859" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1065,854.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1068.9,856.6,1066.9,854.9,1065,854.9z"/>
      <path className="st0" d="M1055.6,854.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1059.5,856.6,1057.9,854.9,1055.6,854.9z"/>
      <ellipse className="st0" cx="1046.6" cy="859" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1037.2,862.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,861.2,1035,862.9,1037.2,862.9z"/>
      <path className="st0" d="M1028.1,862.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024.3,861.2,1025.9,862.9,1028.1,862.9z"/>
      <path className="st0" d="M1022.6,859c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1021,862.9,1022.6,861,1022.6,859z"/>
      <ellipse className="st0" cx="1009.7" cy="859" rx="3.9" ry="3.9"/>
      <path className="st0" d="M991.3,854.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C995.1,856.6,993.5,854.9,991.3,854.9z"/>
      <path className="st0" d="M981.9,862.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C978.1,861.2,979.7,862.9,981.9,862.9z"/>
      <ellipse className="st0" cx="972.8" cy="859" rx="3.9" ry="3.9"/>
      <path className="st0" d="M963.5,854.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C967.3,856.6,965.7,854.9,963.5,854.9z"/>
      <path className="st0" d="M954.4,854.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C958.2,856.6,956.6,854.9,954.4,854.9z"/>
      <path className="st0" d="M853.1,854.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C857,856.6,855.3,854.9,853.1,854.9z"/>
      <ellipse className="st0" cx="834.7" cy="859" rx="3.9" ry="3.9"/>
      <path className="st0" d="M709.5,859c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C707.8,862.9,709.5,861,709.5,859z"/>
      <path className="st0" d="M392.5,854.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C396.3,856.6,394.7,854.9,392.5,854.9z"/>
      <ellipse className="st0" cx="383.4" cy="859" rx="3.9" ry="3.9"/>
      <path className="st0" d="M374.1,854.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C377.9,856.6,376.3,854.9,374.1,854.9z"/>
      <ellipse className="st0" cx="365" cy="859" rx="3.9" ry="3.9"/>
      <path className="st0" d="M355.6,854.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C359.5,856.6,357.8,854.9,355.6,854.9z"/>
      <ellipse className="st0" cx="346.5" cy="859" rx="3.9" ry="3.9"/>
      <path className="st0" d="M337.2,854.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C341,856.6,339.4,854.9,337.2,854.9z"/>
      <ellipse className="st0" cx="328.1" cy="859" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1175.3,845.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1179.2,847.5,1177.5,845.8,1175.3,845.8z"/>
      <path className="st0" d="M1166.3,845.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,847.5,1168.5,845.8,1166.3,845.8z"/>
      <path className="st0" d="M1156.9,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,851.9,1154.7,853.5,1156.9,853.5z"/>
      <path className="st0" d="M1147.8,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1144,851.9,1145.6,853.5,1147.8,853.5z"/>
      <path className="st0" d="M1138.5,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,851.9,1136.3,853.5,1138.5,853.5z"/>
      <path className="st0" d="M1129.4,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,851.9,1127.2,853.5,1129.4,853.5z"/>
      <path className="st0" d="M1120,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,851.9,1117.8,853.5,1120,853.5z"/>
      <path className="st0" d="M1111,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1107.1,851.9,1108.8,853.5,1111,853.5z"/>
      <path className="st0" d="M1101.6,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,851.9,1099.7,853.5,1101.6,853.5z"/>
      <path className="st0" d="M1092.5,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,851.9,1090.3,853.5,1092.5,853.5z"/>
      <path className="st0" d="M1083.2,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1079.3,851.9,1081.2,853.5,1083.2,853.5z"/>
      <path className="st0" d="M1074.1,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1070.2,851.9,1071.9,853.5,1074.1,853.5z"/>
      <path className="st0" d="M1065,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1060.9,851.9,1062.8,853.5,1065,853.5z"/>
      <path className="st0" d="M1059.5,849.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S1059.5,851.9,1059.5,849.7z"/>
      <path className="st0" d="M1046.6,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,851.9,1044.4,853.5,1046.6,853.5z"/>
      <path className="st0" d="M1037.2,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,851.9,1035,853.5,1037.2,853.5z"/>
      <path className="st0" d="M1028.1,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,851.9,1025.9,853.5,1028.1,853.5z"/>
      <path className="st0" d="M1018.8,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,851.9,1016.6,853.5,1018.8,853.5z"/>
      <path className="st0" d="M1009.7,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,851.9,1007.5,853.5,1009.7,853.5z"/>
      <path className="st0" d="M1000.3,845.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1004.2,847.5,1002.5,845.8,1000.3,845.8z"/>
      <path className="st0" d="M991.3,845.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C995.1,847.5,993.5,845.8,991.3,845.8z"/>
      <path className="st0" d="M981.9,845.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C986,847.5,984.1,845.8,981.9,845.8z"/>
      <path className="st0" d="M972.8,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C969,851.9,970.6,853.5,972.8,853.5z"/>
      <path className="st0" d="M963.5,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C959.6,851.9,961.5,853.5,963.5,853.5z"/>
      <path className="st0" d="M954.4,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C950.5,851.9,952.2,853.5,954.4,853.5z"/>
      <path className="st0" d="M949.2,849.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,853.5,949.2,851.9,949.2,849.7z"/>
      <path className="st0" d="M936,845.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C939.8,847.5,938.2,845.8,936,845.8z"/>
      <path className="st0" d="M847.6,849.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S847.6,851.9,847.6,849.7z"/>
      <path className="st0" d="M834.7,845.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C838.5,847.5,836.9,845.8,834.7,845.8z"/>
      <path className="st0" d="M705.6,845.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,847.5,707.8,845.8,705.6,845.8z"/>
      <path className="st0" d="M700.4,849.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C698.8,853.5,700.4,851.9,700.4,849.7z"/>
      <path className="st0" d="M687.2,845.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.3,847.5,689.4,845.8,687.2,845.8z"/>
      <path className="st0" d="M405.7,849.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S405.7,851.9,405.7,849.7z"/>
      <path className="st0" d="M392.5,845.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C396.3,847.5,394.7,845.8,392.5,845.8z"/>
      <path className="st0" d="M383.4,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C379.6,851.9,381.2,853.5,383.4,853.5z"/>
      <path className="st0" d="M374.1,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C370.2,851.9,371.9,853.5,374.1,853.5z"/>
      <path className="st0" d="M365,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C361.1,851.9,362.8,853.5,365,853.5z"/>
      <path className="st0" d="M355.6,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C351.8,851.9,353.7,853.5,355.6,853.5z"/>
      <path className="st0" d="M346.5,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C342.7,851.9,344.3,853.5,346.5,853.5z"/>
      <path className="st0" d="M341.3,849.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C339.4,853.5,341.3,851.9,341.3,849.7z"/>
      <path className="st0" d="M328.1,853.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C324.3,851.9,325.9,853.5,328.1,853.5z"/>
      <path className="st0" d="M322.9,849.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C320.9,853.5,322.9,851.9,322.9,849.7z"/>
      <path className="st0" d="M309.7,845.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C313.5,847.5,311.9,845.8,309.7,845.8z"/>
      <path className="st0" d="M304.4,849.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C302.5,853.5,304.4,851.9,304.4,849.7z"/>
      <path className="st0" d="M291.2,845.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C295.1,847.5,293.4,845.8,291.2,845.8z"/>
      <path className="st0" d="M286,849.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S286,851.9,286,849.7z"/>
      <ellipse className="st0" cx="1175.3" cy="840.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1170.1,840.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1168.5,844.5,1170.1,842.8,1170.1,840.6z"/>
      <path className="st0" d="M1156.9,844.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1154.7,844.5,1156.9,844.5z"/>
      <ellipse className="st0" cx="1147.8" cy="840.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1138.5,844.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1136.3,844.5,1138.5,844.5z"/>
      <path className="st0" d="M1129.4,844.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1127.2,844.5,1129.4,844.5z"/>
      <path className="st0" d="M1120,844.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1117.8,844.5,1120,844.5z"/>
      <ellipse className="st0" cx="1111" cy="840.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1101.6,844.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1099.7,844.5,1101.6,844.5z"/>
      <path className="st0" d="M1092.5,844.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1090.3,844.5,1092.5,844.5z"/>
      <path className="st0" d="M1083.2,844.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1081.2,844.5,1083.2,844.5z"/>
      <ellipse className="st0" cx="1074.1" cy="840.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1065,844.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1062.8,844.5,1065,844.5z"/>
      <path className="st0" d="M1059.5,840.6c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1057.9,844.5,1059.5,842.8,1059.5,840.6z"/>
      <ellipse className="st0" cx="1046.6" cy="840.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1037.2,844.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1035,844.5,1037.2,844.5z"/>
      <path className="st0" d="M1028.1,844.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1025.9,844.5,1028.1,844.5z"/>
      <path className="st0" d="M1018.8,844.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1016.6,844.5,1018.8,844.5z"/>
      <ellipse className="st0" cx="1009.7" cy="840.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1000.3,844.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S998.1,844.5,1000.3,844.5z"/>
      <path className="st0" d="M995.1,840.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C993.5,844.5,995.1,842.8,995.1,840.6z"/>
      <path className="st0" d="M986,840.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C984.1,844.5,986,842.8,986,840.6z"/>
      <ellipse className="st0" cx="972.8" cy="840.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M963.5,844.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S961.5,844.5,963.5,844.5z"/>
      <path className="st0" d="M954.4,844.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S952.2,844.5,954.4,844.5z"/>
      <path className="st0" d="M945,844.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S943.1,844.5,945,844.5z"/>
      <ellipse className="st0" cx="936" cy="840.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M853.1,836.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C857,838.4,855.3,836.7,853.1,836.7z"/>
      <path className="st0" d="M843.8,836.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C847.6,838.4,846,836.7,843.8,836.7z"/>
      <ellipse className="st0" cx="834.7" cy="840.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M825.3,836.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C829.2,838.4,827.5,836.7,825.3,836.7z"/>
      <path className="st0" d="M705.6,836.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,838.4,707.8,836.7,705.6,836.7z"/>
      <ellipse className="st0" cx="696.6" cy="840.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M683.3,840.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S683.3,838.4,683.3,840.6z"/>
      <path className="st0" d="M678.1,836.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C682,838.4,680.3,836.7,678.1,836.7z"/>
      <path className="st0" d="M668.8,836.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C672.6,838.4,671,836.7,668.8,836.7z"/>
      <ellipse className="st0" cx="420.3" cy="840.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M410.9,836.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C414.8,838.4,413.1,836.7,410.9,836.7z"/>
      <ellipse className="st0" cx="401.8" cy="840.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M396.3,840.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C394.7,844.5,396.3,842.8,396.3,840.6z"/>
      <ellipse className="st0" cx="383.4" cy="840.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M374.1,844.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S371.9,844.5,374.1,844.5z"/>
      <ellipse className="st0" cx="365" cy="840.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M355.6,844.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S353.7,844.5,355.6,844.5z"/>
      <ellipse className="st0" cx="346.5" cy="840.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M337.2,844.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S335.3,844.5,337.2,844.5z"/>
      <ellipse className="st0" cx="328.1" cy="840.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M319,844.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S316.8,844.5,319,844.5z"/>
      <ellipse className="st0" cx="309.7" cy="840.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M300.6,844.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S298.4,844.5,300.6,844.5z"/>
      <path className="st0" d="M291.2,844.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S289,844.5,291.2,844.5z"/>
      <ellipse className="st0" cx="282.1" cy="840.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1175.3,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1171.5,833.4,1173.1,835.4,1175.3,835.4z"/>
      <path className="st0" d="M1170.1,831.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1168.5,835.4,1170.1,833.4,1170.1,831.2z"/>
      <path className="st0" d="M1156.9,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,833.4,1154.7,835.4,1156.9,835.4z"/>
      <path className="st0" d="M1147.8,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1144,833.4,1145.6,835.4,1147.8,835.4z"/>
      <path className="st0" d="M1138.5,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,833.4,1136.3,835.4,1138.5,835.4z"/>
      <path className="st0" d="M1129.4,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,833.4,1127.2,835.4,1129.4,835.4z"/>
      <path className="st0" d="M1120,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,833.4,1117.8,835.4,1120,835.4z"/>
      <path className="st0" d="M1111,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1107.1,833.4,1108.8,835.4,1111,835.4z"/>
      <path className="st0" d="M1101.6,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,833.4,1099.7,835.4,1101.6,835.4z"/>
      <path className="st0" d="M1092.5,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,833.4,1090.3,835.4,1092.5,835.4z"/>
      <path className="st0" d="M1083.2,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1079.3,833.4,1081.2,835.4,1083.2,835.4z"/>
      <path className="st0" d="M1074.1,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1070.2,833.4,1071.9,835.4,1074.1,835.4z"/>
      <path className="st0" d="M1065,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1060.9,833.4,1062.8,835.4,1065,835.4z"/>
      <path className="st0" d="M1059.5,831.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1057.9,835.4,1059.5,833.4,1059.5,831.2z"/>
      <path className="st0" d="M1046.6,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,833.4,1044.4,835.4,1046.6,835.4z"/>
      <path className="st0" d="M1037.2,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,833.4,1035,835.4,1037.2,835.4z"/>
      <path className="st0" d="M1028.1,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,833.4,1025.9,835.4,1028.1,835.4z"/>
      <path className="st0" d="M1018.8,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,833.4,1016.6,835.4,1018.8,835.4z"/>
      <path className="st0" d="M1009.7,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,833.4,1007.5,835.4,1009.7,835.4z"/>
      <path className="st0" d="M1000.3,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C996.5,833.4,998.1,835.4,1000.3,835.4z"/>
      <path className="st0" d="M995.1,831.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C993.5,835.4,995.1,833.4,995.1,831.2z"/>
      <path className="st0" d="M986,831.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C984.1,835.4,986,833.4,986,831.2z"/>
      <path className="st0" d="M972.8,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C969,833.4,970.6,835.4,972.8,835.4z"/>
      <path className="st0" d="M963.5,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C959.6,833.4,961.5,835.4,963.5,835.4z"/>
      <path className="st0" d="M954.4,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C950.5,833.4,952.2,835.4,954.4,835.4z"/>
      <path className="st0" d="M945,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C941.2,833.4,943.1,835.4,945,835.4z"/>
      <path className="st0" d="M936,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C932.1,833.4,933.7,835.4,936,835.4z"/>
      <path className="st0" d="M871.6,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C867.4,833.4,869.4,835.4,871.6,835.4z"/>
      <path className="st0" d="M843.8,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C839.9,833.4,841.6,835.4,843.8,835.4z"/>
      <path className="st0" d="M834.7,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C830.8,833.4,832.5,835.4,834.7,835.4z"/>
      <path className="st0" d="M821.5,831.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C823.1,827.4,821.5,829.3,821.5,831.2z"/>
      <path className="st0" d="M724.1,827.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,829.3,726.3,827.4,724.1,827.4z"/>
      <path className="st0" d="M705.6,827.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,829.3,707.8,827.4,705.6,827.4z"/>
      <path className="st0" d="M674.3,831.2c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C675.9,827.4,674.3,829.3,674.3,831.2z"/>
      <path className="st0" d="M664.9,831.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S664.9,829.3,664.9,831.2z"/>
      <path className="st0" d="M420.3,827.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C424.1,829.3,422.5,827.4,420.3,827.4z"/>
      <path className="st0" d="M410.9,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C407.1,833.4,408.7,835.4,410.9,835.4z"/>
      <path className="st0" d="M405.7,831.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S405.7,833.4,405.7,831.2z"/>
      <path className="st0" d="M396.3,831.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C394.7,835.4,396.3,833.4,396.3,831.2z"/>
      <path className="st0" d="M383.4,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C379.6,833.4,381.2,835.4,383.4,835.4z"/>
      <path className="st0" d="M374.1,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C370.2,833.4,371.9,835.4,374.1,835.4z"/>
      <path className="st0" d="M365,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C361.1,833.4,362.8,835.4,365,835.4z"/>
      <path className="st0" d="M355.6,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C351.8,833.4,353.7,835.4,355.6,835.4z"/>
      <path className="st0" d="M346.5,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C342.7,833.4,344.3,835.4,346.5,835.4z"/>
      <path className="st0" d="M337.2,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C333.3,833.4,335.3,835.4,337.2,835.4z"/>
      <path className="st0" d="M328.1,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C324.3,833.4,325.9,835.4,328.1,835.4z"/>
      <path className="st0" d="M319,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C314.9,833.4,316.8,835.4,319,835.4z
        "/>
      <path className="st0" d="M309.7,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C305.8,833.4,307.5,835.4,309.7,835.4z"/>
      <path className="st0" d="M300.6,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C296.5,833.4,298.4,835.4,300.6,835.4z"/>
      <path className="st0" d="M291.2,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C287.4,833.4,289,835.4,291.2,835.4z"/>
      <path className="st0" d="M282.1,835.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C278,833.4,279.9,835.4,282.1,835.4z"/>
      <path className="st0" d="M1175.3,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1171.5,824.4,1173.1,826,1175.3,826z"/>
      <path className="st0" d="M1170.1,822.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1168.5,826,1170.1,824.4,1170.1,822.2z"/>
      <path className="st0" d="M1156.9,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,824.4,1154.7,826,1156.9,826z"/>
      <path className="st0" d="M1147.8,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1144,824.4,1145.6,826,1147.8,826z"/>
      <path className="st0" d="M1138.5,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,824.4,1136.3,826,1138.5,826z"/>
      <path className="st0" d="M1129.4,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,824.4,1127.2,826,1129.4,826z"/>
      <path className="st0" d="M1120,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,824.4,1117.8,826,1120,826z"/>
      <path className="st0" d="M1111,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C1107.1,824.4,1108.8,826,1111,826z"
        />
      <path className="st0" d="M1101.6,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,824.4,1099.7,826,1101.6,826z"/>
      <path className="st0" d="M1092.5,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,824.4,1090.3,826,1092.5,826z"/>
      <path className="st0" d="M1083.2,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1079.3,824.4,1081.2,826,1083.2,826z"/>
      <path className="st0" d="M1074.1,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1070.2,824.4,1071.9,826,1074.1,826z"/>
      <path className="st0" d="M1065,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C1060.9,824.4,1062.8,826,1065,826z"
        />
      <path className="st0" d="M1059.5,822.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S1059.5,824.4,1059.5,822.2z"/>
      <path className="st0" d="M1046.6,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,824.4,1044.4,826,1046.6,826z"/>
      <path className="st0" d="M1037.2,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C1033.4,824.4,1035,826,1037.2,826z
        "/>
      <path className="st0" d="M1028.1,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,824.4,1025.9,826,1028.1,826z"/>
      <path className="st0" d="M1018.8,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,824.4,1016.6,826,1018.8,826z"/>
      <path className="st0" d="M1009.7,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,824.4,1007.5,826,1009.7,826z"/>
      <path className="st0" d="M1000.3,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C996.5,824.4,998.1,826,1000.3,826z"/>
      <path className="st0" d="M995.1,822.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S995.1,824.4,995.1,822.2z"
        />
      <path className="st0" d="M986,822.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C984.1,826,986,824.4,986,822.2z"/>
      <path className="st0" d="M972.8,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C969,824.4,970.6,826,972.8,826z"/>
      <path className="st0" d="M963.5,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C959.6,824.4,961.5,826,963.5,826z"/>
      <path className="st0" d="M954.4,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C950.5,824.4,952.2,826,954.4,826z"/>
      <path className="st0" d="M945,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C941.2,824.4,943.1,826,945,826z
        "/>
      <path className="st0" d="M936,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C932.1,824.4,933.7,826,936,826z
        "/>
      <path className="st0" d="M926.6,818.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C930.7,820,928.8,818.3,926.6,818.3z"/>
      <path className="st0" d="M858.4,822.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C860,818.3,858.4,820,858.4,822.2z"/>
      <path className="st0" d="M853.1,818.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C857,820,855.3,818.3,853.1,818.3z"/>
      <path className="st0" d="M737.3,822.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C735.4,826,737.3,824.4,737.3,822.2z"/>
      <path className="st0" d="M724.1,818.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,820,726.3,818.3,724.1,818.3z"/>
      <path className="st0" d="M429.4,818.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C433.2,820,431.6,818.3,429.4,818.3z"/>
      <path className="st0" d="M420.3,818.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C424.1,820,422.5,818.3,420.3,818.3z"/>
      <path className="st0" d="M410.9,818.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C414.8,820,413.1,818.3,410.9,818.3z"/>
      <path className="st0" d="M405.7,822.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S405.7,824.4,405.7,822.2z"/>
      <path className="st0" d="M396.3,822.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C394.7,826,396.3,824.4,396.3,822.2z"/>
      <path className="st0" d="M383.4,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C379.6,824.4,381.2,826,383.4,826z"
        />
      <path className="st0" d="M374.1,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C370.2,824.4,371.9,826,374.1,826z"/>
      <path className="st0" d="M365,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C361.1,824.4,362.8,826,365,826z
        "/>
      <path className="st0" d="M355.6,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C351.8,824.4,353.7,826,355.6,826z"/>
      <path className="st0" d="M346.5,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C342.7,824.4,344.3,826,346.5,826z"
        />
      <path className="st0" d="M337.2,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C333.3,824.4,335.3,826,337.2,826z"
        />
      <path className="st0" d="M328.1,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C324.3,824.4,325.9,826,328.1,826z"/>
      <path className="st0" d="M319,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C314.9,824.4,316.8,826,319,826z"/>
      <path className="st0" d="M309.7,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C305.8,824.4,307.5,826,309.7,826z"/>
      <path className="st0" d="M300.6,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C296.5,824.4,298.4,826,300.6,826z"
        />
      <path className="st0" d="M291.2,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C287.4,824.4,289,826,291.2,826z"/>
      <path className="st0" d="M282.1,826c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C278,824.4,279.9,826,282.1,826z"/>
      <ellipse className="st0" cx="1175.3" cy="813.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1170.1,813.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1168.5,816.9,1170.1,815.3,1170.1,813.1z"/>
      <path className="st0" d="M1156.9,809c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1160.8,810.6,1159.1,809,1156.9,809z"/>
      <ellipse className="st0" cx="1147.8" cy="813.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1138.5,809c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1142.3,810.6,1140.7,809,1138.5,809z"/>
      <path className="st0" d="M1129.4,809c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1133.2,810.6,1131.6,809,1129.4,809z"/>
      <path className="st0" d="M1120,809c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1123.9,810.6,1122.2,809,1120,809z"/>
      <ellipse className="st0" cx="1111" cy="813.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1101.6,816.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,815.3,1099.7,816.9,1101.6,816.9z"/>
      <path className="st0" d="M1092.5,816.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,815.3,1090.3,816.9,1092.5,816.9z"/>
      <path className="st0" d="M1083.2,816.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1079.3,815.3,1081.2,816.9,1083.2,816.9z"/>
      <ellipse className="st0" cx="1074.1" cy="813.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1065,816.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1061.2,815.3,1062.8,816.9,1065,816.9z"/>
      <path className="st0" d="M1059.5,813.1c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1057.9,816.9,1059.5,815.3,1059.5,813.1z"/>
      <ellipse className="st0" cx="1046.6" cy="813.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1037.2,816.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,815.3,1035,816.9,1037.2,816.9z"/>
      <path className="st0" d="M1028.1,816.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024.3,815.3,1025.9,816.9,1028.1,816.9z"/>
      <path className="st0" d="M1018.8,816.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,815.3,1016.6,816.9,1018.8,816.9z"/>
      <ellipse className="st0" cx="1009.7" cy="813.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1000.3,816.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C996.5,815.3,998.1,816.9,1000.3,816.9z"/>
      <path className="st0" d="M995.1,813.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C993.5,816.9,995.1,815.3,995.1,813.1z"/>
      <path className="st0" d="M986,813.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C984.1,816.9,986,815.3,986,813.1z"/>
      <ellipse className="st0" cx="972.8" cy="813.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M959.6,813.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C961.5,809,959.6,810.9,959.6,813.1z"/>
      <path className="st0" d="M954.4,809c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C958.2,810.6,956.6,809,954.4,809z"/>
      <path className="st0" d="M945,816.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C941.2,815.3,943.1,816.9,945,816.9z"/>
      <ellipse className="st0" cx="936" cy="813.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M926.6,816.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C922.7,815.3,924.7,816.9,926.6,816.9z"/>
      <path className="st0" d="M847.6,813.1c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C846,816.9,847.6,815.3,847.6,813.1z"/>
      <ellipse className="st0" cx="834.7" cy="813.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M825.3,809c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C829.2,810.6,827.5,809,825.3,809z"/>
      <path className="st0" d="M820.1,813.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C818.5,816.9,820.1,815.3,820.1,813.1z"/>
      <path className="st0" d="M746.4,813.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C744.7,816.9,746.4,815.3,746.4,813.1z"/>
      <path className="st0" d="M733.4,809c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,810.6,735.4,809,733.4,809z"/>
      <path className="st0" d="M438.7,816.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C434.9,815.3,436.5,816.9,438.7,816.9z"/>
      <path className="st0" d="M429.4,809c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C433.2,810.6,431.6,809,429.4,809z"/>
      <ellipse className="st0" cx="420.3" cy="813.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M414.8,813.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C413.1,816.9,414.8,815.3,414.8,813.1z"/>
      <ellipse className="st0" cx="401.8" cy="813.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M396.3,813.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C394.7,816.9,396.3,815.3,396.3,813.1z"/>
      <ellipse className="st0" cx="383.4" cy="813.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M374.1,816.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C370.2,815.3,371.9,816.9,374.1,816.9z"/>
      <ellipse className="st0" cx="365" cy="813.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M355.6,816.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C351.8,815.3,353.7,816.9,355.6,816.9z"/>
      <ellipse className="st0" cx="346.5" cy="813.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M337.2,816.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C333.3,815.3,335.3,816.9,337.2,816.9z"/>
      <ellipse className="st0" cx="328.1" cy="813.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M319,816.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C315.2,815.3,316.8,816.9,319,816.9z
        "/>
      <ellipse className="st0" cx="309.7" cy="813.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M300.6,816.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C296.7,815.3,298.4,816.9,300.6,816.9z"/>
      <path className="st0" d="M291.2,816.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C287.4,815.3,289,816.9,291.2,816.9z"/>
      <ellipse className="st0" cx="282.1" cy="813.1" rx="3.9" ry="3.9"/>
      <ellipse className="st0" cx="272.8" cy="813.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1175.3,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1177.5,799.9,1175.3,799.9z"/>
      <path className="st0" d="M1166.3,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1168.5,799.9,1166.3,799.9z"/>
      <path className="st0" d="M1156.9,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1159.1,799.9,1156.9,799.9z"/>
      <path className="st0" d="M1147.8,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1151.7,801.5,1150,799.9,1147.8,799.9z"/>
      <path className="st0" d="M1138.5,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1142.6,801.5,1140.7,799.9,1138.5,799.9z"/>
      <path className="st0" d="M1129.4,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1131.6,799.9,1129.4,799.9z"/>
      <path className="st0" d="M1120,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1124.2,801.5,1122.2,799.9,1120,799.9z"/>
      <path className="st0" d="M1111,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1113.2,799.9,1111,799.9z"/>
      <path className="st0" d="M1101.6,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1105.7,801.5,1103.8,799.9,1101.6,799.9z"/>
      <path className="st0" d="M1092.5,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1094.7,799.9,1092.5,799.9z"/>
      <path className="st0" d="M1083.2,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1087.3,801.5,1085.4,799.9,1083.2,799.9z"/>
      <path className="st0" d="M1074.1,807.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1070.2,805.9,1071.9,807.6,1074.1,807.6z"/>
      <path className="st0" d="M1065,807.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C1060.9,805.9,1062.8,807.6,1065,807.6z"
        />
      <path className="st0" d="M1059.5,803.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S1059.5,805.9,1059.5,803.7z"/>
      <path className="st0" d="M1046.6,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1048.5,799.9,1046.6,799.9z"/>
      <path className="st0" d="M1033.4,803.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C1035,799.9,1033.4,801.5,1033.4,803.7z
        "/>
      <path className="st0" d="M1028.1,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1032,801.5,1030.1,799.9,1028.1,799.9z"/>
      <path className="st0" d="M1014.9,803.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C1016.6,799.9,1014.9,801.5,1014.9,803.7z"/>
      <path className="st0" d="M1005.6,803.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C1007.2,799.9,1005.6,801.5,1005.6,803.7z"/>
      <path className="st0" d="M1000.3,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1002.5,799.9,1000.3,799.9z"/>
      <path className="st0" d="M991.3,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S993.5,799.9,991.3,799.9z"/>
      <path className="st0" d="M978.1,803.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C979.7,799.9,978.1,801.5,978.1,803.7z"/>
      <path className="st0" d="M969,803.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S969,801.5,969,803.7z"/>
      <path className="st0" d="M959.6,803.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C961.5,799.9,959.6,801.5,959.6,803.7z"/>
      <path className="st0" d="M954.4,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S956.6,799.9,954.4,799.9z"/>
      <path className="st0" d="M945,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C949.2,801.5,947.2,799.9,945,799.9z
        "/>
      <path className="st0" d="M936,807.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C932.1,805.9,933.7,807.6,936,807.6
        z"/>
      <path className="st0" d="M926.6,807.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C922.7,805.9,924.7,807.6,926.6,807.6z"
        />
      <path className="st0" d="M853.1,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S855.3,799.9,853.1,799.9z"/>
      <path className="st0" d="M843.8,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C847.6,801.5,846,799.9,843.8,799.9z"/>
      <path className="st0" d="M834.7,807.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C830.8,805.9,832.5,807.6,834.7,807.6z"/>
      <path className="st0" d="M825.3,807.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,805.9,823.1,807.6,825.3,807.6z"/>
      <path className="st0" d="M816.3,807.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C812.4,805.9,814.1,807.6,816.3,807.6z"/>
      <path className="st0" d="M811,803.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C809.1,807.6,811,805.9,811,803.7z"
        />
      <path className="st0" d="M742.5,807.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C738.7,805.9,740.3,807.6,742.5,807.6z"/>
      <path className="st0" d="M733.4,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S735.4,799.9,733.4,799.9z"/>
      <path className="st0" d="M604.4,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S606.6,799.9,604.4,799.9z"/>
      <path className="st0" d="M599.1,803.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C597.2,807.6,599.1,805.9,599.1,803.7z"/>
      <path className="st0" d="M429.4,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C433.2,801.5,431.6,799.9,429.4,799.9z"/>
      <path className="st0" d="M420.3,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C424.1,801.5,422.5,799.9,420.3,799.9z"/>
      <path className="st0" d="M410.9,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S413.1,799.9,410.9,799.9z"/>
      <path className="st0" d="M401.8,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C405.7,801.5,404,799.9,401.8,799.9z"/>
      <path className="st0" d="M392.5,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S394.7,799.9,392.5,799.9z"/>
      <path className="st0" d="M383.4,799.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S385.6,799.9,383.4,799.9z"/>
      <path className="st0" d="M374.1,807.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C370.2,805.9,371.9,807.6,374.1,807.6z"/>
      <path className="st0" d="M365,807.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C361.1,805.9,362.8,807.6,365,807.6
        z"/>
      <path className="st0" d="M355.6,807.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C351.8,805.9,353.7,807.6,355.6,807.6z"/>
      <path className="st0" d="M346.5,807.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C342.7,805.9,344.3,807.6,346.5,807.6z"
        />
      <path className="st0" d="M337.2,807.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C333.3,805.9,335.3,807.6,337.2,807.6z"
        />
      <path className="st0" d="M328.1,807.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C324.3,805.9,325.9,807.6,328.1,807.6z"/>
      <path className="st0" d="M319,807.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C314.9,805.9,316.8,807.6,319,807.6z"/>
      <path className="st0" d="M309.7,807.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C305.8,805.9,307.5,807.6,309.7,807.6z"/>
      <path className="st0" d="M300.6,807.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C296.5,805.9,298.4,807.6,300.6,807.6z"
        />
      <path className="st0" d="M291.2,807.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C287.4,805.9,289,807.6,291.2,807.6z"/>
      <path className="st0" d="M268.9,803.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S268.9,801.5,268.9,803.7z"/>
      <ellipse className="st0" cx="1175.3" cy="794.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,790.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,792.4,1168.5,790.8,1166.3,790.8z"/>
      <path className="st0" d="M1156.9,790.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1160.8,792.4,1159.1,790.8,1156.9,790.8z"/>
      <ellipse className="st0" cx="1147.8" cy="794.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1138.5,790.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1142.3,792.4,1140.7,790.8,1138.5,790.8z"/>
      <path className="st0" d="M1129.4,790.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1133.2,792.4,1131.6,790.8,1129.4,790.8z"/>
      <path className="st0" d="M1120,790.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1123.9,792.4,1122.2,790.8,1120,790.8z"/>
      <ellipse className="st0" cx="1111" cy="794.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1101.6,790.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1105.5,792.4,1103.8,790.8,1101.6,790.8z"/>
      <path className="st0" d="M1092.5,790.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1096.4,792.4,1094.7,790.8,1092.5,790.8z"/>
      <path className="st0" d="M1083.2,790.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1087,792.4,1085.4,790.8,1083.2,790.8z"/>
      <ellipse className="st0" cx="1074.1" cy="794.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1065,790.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1068.9,792.4,1066.9,790.8,1065,790.8z"/>
      <path className="st0" d="M1055.6,790.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1059.5,792.4,1057.9,790.8,1055.6,790.8z"/>
      <ellipse className="st0" cx="1046.6" cy="794.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1033.4,794.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1035,790.8,1033.4,792.4,1033.4,794.6z"/>
      <path className="st0" d="M1028.1,790.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1032,792.4,1030.1,790.8,1028.1,790.8z"/>
      <path className="st0" d="M1014.9,794.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1016.6,790.8,1014.9,792.4,1014.9,794.6z"/>
      <ellipse className="st0" cx="1009.7" cy="794.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1000.3,790.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1004.2,792.4,1002.5,790.8,1000.3,790.8z"/>
      <path className="st0" d="M991.3,790.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C995.1,792.4,993.5,790.8,991.3,790.8z"/>
      <path className="st0" d="M978.1,794.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C979.7,790.8,978.1,792.4,978.1,794.6z"/>
      <ellipse className="st0" cx="972.8" cy="794.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M959.6,794.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C961.5,790.8,959.6,792.4,959.6,794.6z"/>
      <path className="st0" d="M954.4,790.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C958.2,792.4,956.6,790.8,954.4,790.8z"/>
      <path className="st0" d="M945,790.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C948.9,792.4,947.2,790.8,945,790.8z"/>
      <ellipse className="st0" cx="936" cy="794.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M926.6,798.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C922.7,796.8,924.7,798.5,926.6,798.5z"/>
      <path className="st0" d="M921.4,794.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C919.7,798.5,921.4,796.8,921.4,794.6z"/>
      <path className="st0" d="M849,794.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C850.9,790.8,849,792.4,849,794.6z"/>
      <path className="st0" d="M843.8,790.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C847.6,792.4,846,790.8,843.8,790.8z"/>
      <ellipse className="st0" cx="834.7" cy="794.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M825.3,798.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,796.8,823.1,798.5,825.3,798.5z"/>
      <path className="st0" d="M816.3,798.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C812.4,796.8,814.1,798.5,816.3,798.5z"/>
      <path className="st0" d="M806.9,798.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C803,796.8,805,798.5,806.9,798.5z
        "/>
      <path className="st0" d="M733.4,790.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,792.4,735.4,790.8,733.4,790.8z"/>
      <path className="st0" d="M617.6,794.6c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C615.7,798.5,617.6,796.8,617.6,794.6z"/>
      <ellipse className="st0" cx="604.4" cy="794.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M595.3,798.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.4,796.8,593.1,798.5,595.3,798.5z"/>
      <path className="st0" d="M429.4,790.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C433.2,792.4,431.6,790.8,429.4,790.8z"/>
      <ellipse className="st0" cx="420.3" cy="794.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M410.9,790.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C414.8,792.4,413.1,790.8,410.9,790.8z"/>
      <ellipse className="st0" cx="401.8" cy="794.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M392.5,790.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C396.3,792.4,394.7,790.8,392.5,790.8z"/>
      <ellipse className="st0" cx="383.4" cy="794.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M374.1,790.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C377.9,792.4,376.3,790.8,374.1,790.8z"/>
      <ellipse className="st0" cx="365" cy="794.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M355.6,798.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C351.8,796.8,353.7,798.5,355.6,798.5z"/>
      <ellipse className="st0" cx="346.5" cy="794.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M337.2,798.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C333.3,796.8,335.3,798.5,337.2,798.5z"/>
      <ellipse className="st0" cx="328.1" cy="794.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M319,798.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C315.2,796.8,316.8,798.5,319,798.5z
        "/>
      <ellipse className="st0" cx="309.7" cy="794.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M300.6,798.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C296.7,796.8,298.4,798.5,300.6,798.5z"/>
      <path className="st0" d="M291.2,798.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C287.4,796.8,289,798.5,291.2,798.5z"/>
      <ellipse className="st0" cx="282.1" cy="794.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1175.3,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1179.2,783.4,1177.5,781.4,1175.3,781.4z"/>
      <path className="st0" d="M1166.3,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,783.4,1168.5,781.4,1166.3,781.4z"/>
      <path className="st0" d="M1156.9,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1160.8,783.4,1159.1,781.4,1156.9,781.4z"/>
      <path className="st0" d="M1147.8,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1151.7,783.4,1150,781.4,1147.8,781.4z"/>
      <path className="st0" d="M1138.5,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1142.6,783.4,1140.7,781.4,1138.5,781.4z"/>
      <path className="st0" d="M1129.4,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1133.2,783.4,1131.6,781.4,1129.4,781.4z"/>
      <path className="st0" d="M1120,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1124.2,783.4,1122.2,781.4,1120,781.4z"/>
      <path className="st0" d="M1111,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1114.8,783.4,1113.2,781.4,1111,781.4z"/>
      <path className="st0" d="M1101.6,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1105.7,783.4,1103.8,781.4,1101.6,781.4z"/>
      <path className="st0" d="M1092.5,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1096.4,783.4,1094.7,781.4,1092.5,781.4z"/>
      <path className="st0" d="M1083.2,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1087.3,783.4,1085.4,781.4,1083.2,781.4z"/>
      <path className="st0" d="M1074.1,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1077.9,783.4,1076.3,781.4,1074.1,781.4z"/>
      <path className="st0" d="M1065,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1068.9,783.4,1066.9,781.4,1065,781.4z"/>
      <path className="st0" d="M1055.6,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1059.5,783.4,1057.9,781.4,1055.6,781.4z"/>
      <path className="st0" d="M1046.6,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1050.4,783.4,1048.5,781.4,1046.6,781.4z"/>
      <path className="st0" d="M1033.4,785.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1035,781.4,1033.4,783.4,1033.4,785.6z"/>
      <path className="st0" d="M1028.1,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1032,783.4,1030.1,781.4,1028.1,781.4z"/>
      <path className="st0" d="M1014.9,785.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1016.6,781.4,1014.9,783.4,1014.9,785.6z"/>
      <path className="st0" d="M1005.6,785.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1007.2,781.7,1005.6,783.4,1005.6,785.6z"/>
      <path className="st0" d="M1000.3,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1004.2,783.4,1002.5,781.4,1000.3,781.4z"/>
      <path className="st0" d="M991.3,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C995.1,783.4,993.5,781.4,991.3,781.4z"/>
      <path className="st0" d="M978.1,785.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C979.7,781.4,978.1,783.4,978.1,785.6z"/>
      <path className="st0" d="M969,785.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S969,783.4,969,785.6z"/>
      <path className="st0" d="M963.5,789.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C959.6,787.5,961.5,789.4,963.5,789.4z"/>
      <path className="st0" d="M950.5,785.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C952.2,781.4,950.5,783.4,950.5,785.6z"/>
      <path className="st0" d="M945,789.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C941.2,787.5,943.1,789.4,945,789.4z"/>
      <path className="st0" d="M936,789.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C932.1,787.5,933.7,789.4,936,789.4z"/>
      <path className="st0" d="M922.7,785.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C924.7,781.4,922.7,783.4,922.7,785.6z"/>
      <path className="st0" d="M917.5,789.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C913.7,787.5,915.3,789.4,917.5,789.4z"/>
      <path className="st0" d="M843.8,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C847.6,783.4,846,781.4,843.8,781.4z"/>
      <path className="st0" d="M834.7,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C838.5,783.4,836.9,781.4,834.7,781.4z"/>
      <path className="st0" d="M825.3,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C829.5,783.4,827.5,781.4,825.3,781.4z"/>
      <path className="st0" d="M816.3,789.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C812.4,787.5,814.1,789.4,816.3,789.4z"/>
      <path className="st0" d="M806.9,789.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C803,787.5,805,789.4,806.9,789.4z
        "/>
      <path className="st0" d="M797.8,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C801.7,783.4,800,781.4,797.8,781.4z"/>
      <path className="st0" d="M774.2,785.6c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C772.2,789.4,774.2,787.5,774.2,785.6z"/>
      <path className="st0" d="M733.4,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,783.4,735.4,781.4,733.4,781.4z"/>
      <path className="st0" d="M724.1,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,783.4,726.3,781.4,724.1,781.4z"/>
      <path className="st0" d="M715,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,783.4,717.2,781.4,715,781.4z"/>
      <path className="st0" d="M613.7,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C617.6,783.4,615.7,781.4,613.7,781.4z"/>
      <path className="st0" d="M608.2,785.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C606.6,789.4,608.2,787.5,608.2,785.6z"/>
      <path className="st0" d="M595.3,789.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.2,787.5,593.1,789.4,595.3,789.4z"/>
      <path className="st0" d="M442.6,785.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C440.6,789.4,442.6,787.5,442.6,785.6z"/>
      <path className="st0" d="M429.4,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C433.2,783.4,431.6,781.4,429.4,781.4z"/>
      <path className="st0" d="M420.3,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C424.1,783.4,422.5,781.4,420.3,781.4z"/>
      <path className="st0" d="M410.9,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C414.8,783.4,413.1,781.4,410.9,781.4z"/>
      <path className="st0" d="M401.8,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C405.7,783.4,404,781.4,401.8,781.4z"/>
      <path className="st0" d="M392.5,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C396.3,783.4,394.7,781.4,392.5,781.4z"/>
      <path className="st0" d="M383.4,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C387.3,783.4,385.6,781.4,383.4,781.4z"/>
      <path className="st0" d="M374.1,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C378.2,783.4,376.3,781.4,374.1,781.4z"/>
      <path className="st0" d="M365,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C368.8,783.4,367.2,781.4,365,781.4z"/>
      <path className="st0" d="M355.6,781.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C359.7,783.4,357.8,781.4,355.6,781.4z"/>
      <path className="st0" d="M346.5,789.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C342.7,787.5,344.3,789.4,346.5,789.4z"/>
      <path className="st0" d="M337.2,789.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C333.3,787.5,335.3,789.4,337.2,789.4z"/>
      <path className="st0" d="M328.1,789.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C324.3,787.5,325.9,789.4,328.1,789.4z"/>
      <path className="st0" d="M319,789.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C314.9,787.5,316.8,789.4,319,789.4z
        "/>
      <path className="st0" d="M309.7,789.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C305.8,787.5,307.5,789.4,309.7,789.4z"/>
      <path className="st0" d="M300.6,789.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C296.5,787.5,298.4,789.4,300.6,789.4z"/>
      <path className="st0" d="M291.2,789.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C287.4,787.5,289,789.4,291.2,789.4z"/>
      <path className="st0" d="M282.1,789.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C278,787.5,279.9,789.4,282.1,789.4z"/>
      <path className="st0" d="M1175.3,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1179.2,774,1177.5,772.4,1175.3,772.4z"/>
      <path className="st0" d="M1166.3,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,774,1168.5,772.4,1166.3,772.4z"/>
      <path className="st0" d="M1156.9,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1160.8,774,1159.1,772.4,1156.9,772.4z"/>
      <path className="st0" d="M1147.8,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1151.7,774,1150,772.4,1147.8,772.4z"/>
      <path className="st0" d="M1138.5,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1142.6,774,1140.7,772.4,1138.5,772.4z"/>
      <path className="st0" d="M1129.4,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1133.2,774,1131.6,772.4,1129.4,772.4z"/>
      <path className="st0" d="M1120,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1124.2,774,1122.2,772.4,1120,772.4z"/>
      <path className="st0" d="M1111,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1114.8,774,1113.2,772.4,1111,772.4z"/>
      <path className="st0" d="M1101.6,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1105.7,774,1103.8,772.4,1101.6,772.4z"/>
      <path className="st0" d="M1092.5,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1096.4,774,1094.7,772.4,1092.5,772.4z"/>
      <path className="st0" d="M1083.2,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1087.3,774,1085.4,772.4,1083.2,772.4z"/>
      <path className="st0" d="M1074.1,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1077.9,774,1076.3,772.4,1074.1,772.4z"/>
      <path className="st0" d="M1065,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1068.9,774,1066.9,772.4,1065,772.4z"/>
      <path className="st0" d="M1055.6,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1059.5,774,1057.9,772.4,1055.6,772.4z"/>
      <path className="st0" d="M1046.6,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1050.4,774,1048.5,772.4,1046.6,772.4z"/>
      <path className="st0" d="M1033.4,776.2c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1035,772.4,1033.4,774,1033.4,776.2z"/>
      <path className="st0" d="M1028.1,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1032,774,1030.1,772.4,1028.1,772.4z"/>
      <path className="st0" d="M1014.9,776.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1016.6,772.4,1014.9,774,1014.9,776.2z"/>
      <path className="st0" d="M1005.6,776.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1007.2,772.4,1005.6,774,1005.6,776.2z"/>
      <path className="st0" d="M1000.3,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1004.2,774,1002.5,772.4,1000.3,772.4z"/>
      <path className="st0" d="M991.3,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C995.1,774,993.5,772.4,991.3,772.4z"/>
      <path className="st0" d="M981.9,780.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C978.1,778.4,979.7,780.1,981.9,780.1z"/>
      <path className="st0" d="M972.8,780.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C969,778.4,970.6,780.1,972.8,780.1z"/>
      <path className="st0" d="M959.6,776.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C961.5,772.4,959.6,774,959.6,776.2z"/>
      <path className="st0" d="M932.1,776.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C933.7,772.4,932.1,774,932.1,776.2z"/>
      <path className="st0" d="M862.2,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C866.1,774,864.4,772.4,862.2,772.4z"/>
      <path className="st0" d="M843.8,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C847.6,774,846,772.4,843.8,772.4z"/>
      <path className="st0" d="M834.7,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C838.5,774,836.9,772.4,834.7,772.4z"/>
      <path className="st0" d="M825.3,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C829.5,774,827.5,772.4,825.3,772.4z"/>
      <path className="st0" d="M816.3,780.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C812.4,778.4,814.1,780.1,816.3,780.1z"/>
      <path className="st0" d="M806.9,780.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C803,778.4,805,780.1,806.9,780.1z
        "/>
      <path className="st0" d="M797.8,780.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C794,778.4,795.6,780.1,797.8,780.1z"/>
      <path className="st0" d="M770.3,780.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C766.2,778.4,768.1,780.1,770.3,780.1z"/>
      <path className="st0" d="M760.9,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C764.8,774,763.1,772.4,760.9,772.4z"/>
      <path className="st0" d="M751.9,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C755.7,774,753.8,772.4,751.9,772.4z"/>
      <path className="st0" d="M742.5,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C746.4,774,744.7,772.4,742.5,772.4z"/>
      <path className="st0" d="M746.4,785.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C744.7,789.4,746.4,787.5,746.4,785.6z"/>
      <path className="st0" d="M733.4,780.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C729.3,778.4,731.2,780.1,733.4,780.1z"/>
      <path className="st0" d="M724.1,780.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C720.2,778.4,721.9,780.1,724.1,780.1z"/>
      <path className="st0" d="M715,780.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C710.9,778.4,712.8,780.1,715,780.1z"/>
      <path className="st0" d="M613.7,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C617.6,774,615.7,772.4,613.7,772.4z"/>
      <path className="st0" d="M604.4,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C608.2,774,606.6,772.4,604.4,772.4z"/>
      <path className="st0" d="M595.3,780.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.2,778.4,593.1,780.1,595.3,780.1z"/>
      <path className="st0" d="M438.7,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C442.6,774,440.6,772.4,438.7,772.4z"/>
      <path className="st0" d="M433.2,776.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S433.2,778.4,433.2,776.2z"
        />
      <path className="st0" d="M424.1,776.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S424.1,778.4,424.1,776.2z"/>
      <path className="st0" d="M414.8,776.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C413.1,780.1,414.8,778.4,414.8,776.2z"/>
      <path className="st0" d="M405.7,776.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S405.7,778.4,405.7,776.2z"/>
      <path className="st0" d="M396.3,776.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C394.7,780.1,396.3,778.4,396.3,776.2z"/>
      <path className="st0" d="M387.3,776.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S387.3,778.4,387.3,776.2z"/>
      <path className="st0" d="M378.2,776.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C376.3,780.1,378.2,778.4,378.2,776.2z"/>
      <path className="st0" d="M361.1,776.2c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S361.1,774,361.1,776.2z"/>
      <path className="st0" d="M355.6,772.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C359.7,774,357.8,772.4,355.6,772.4z"/>
      <path className="st0" d="M346.5,780.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C342.7,778.4,344.3,780.1,346.5,780.1z"/>
      <path className="st0" d="M337.2,780.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C333.3,778.4,335.3,780.1,337.2,780.1z"/>
      <path className="st0" d="M328.1,780.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C324.3,778.4,325.9,780.1,328.1,780.1z"/>
      <path className="st0" d="M319,780.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C314.9,778.4,316.8,780.1,319,780.1z
        "/>
      <path className="st0" d="M309.7,780.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C305.8,778.4,307.5,780.1,309.7,780.1z"/>
      <path className="st0" d="M300.6,780.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C296.5,778.4,298.4,780.1,300.6,780.1z"/>
      <path className="st0" d="M291.2,780.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C287.4,778.4,289,780.1,291.2,780.1z"/>
      <path className="st0" d="M282.1,780.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C278,778.4,279.9,780.1,282.1,780.1z"/>
      <ellipse className="st0" cx="1175.3" cy="767.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1162.1,767.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C1164.1,763.3,1162.1,764.9,1162.1,767.1z"/>
      <path className="st0" d="M1156.9,771c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1154.7,771,1156.9,771z"/>
      <ellipse className="st0" cx="1147.8" cy="767.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1134.6,767.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C1136.3,763.3,1134.6,764.9,1134.6,767.1z"/>
      <path className="st0" d="M1129.4,771c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1127.2,771,1129.4,771z"/>
      <path className="st0" d="M1120,771c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1117.8,771,1120,771z"/>
      <ellipse className="st0" cx="1111" cy="767.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1101.6,763.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1103.8,763.3,1101.6,763.3z"/>
      <path className="st0" d="M1092.5,763.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1094.7,763.3,1092.5,763.3z"/>
      <path className="st0" d="M1083.2,763.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1085.4,763.3,1083.2,763.3z"/>
      <ellipse className="st0" cx="1074.1" cy="767.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1065,763.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1066.9,763.3,1065,763.3z"/>
      <path className="st0" d="M1055.6,763.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1057.9,763.3,1055.6,763.3z"/>
      <ellipse className="st0" cx="1046.6" cy="767.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1033.4,767.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C1035,763.3,1033.4,764.9,1033.4,767.1z
        "/>
      <path className="st0" d="M1028.1,763.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1030.1,763.3,1028.1,763.3z"/>
      <path className="st0" d="M1014.9,767.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C1016.6,763.3,1014.9,764.9,1014.9,767.1z"/>
      <ellipse className="st0" cx="1009.7" cy="767.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M996.5,767.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C998.1,763.3,996.5,764.9,996.5,767.1z"/>
      <path className="st0" d="M991.3,763.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S993.5,763.3,991.3,763.3z"/>
      <path className="st0" d="M981.9,763.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S984.1,763.3,981.9,763.3z"/>
      <ellipse className="st0" cx="972.8" cy="767.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M926.6,763.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S928.8,763.3,926.6,763.3z"/>
      <path className="st0" d="M921.4,767.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C919.7,771,921.4,769.3,921.4,767.1z"/>
      <ellipse className="st0" cx="908.4" cy="767.1" rx="3.9" ry="3.9"/>
      <ellipse className="st0" cx="871.6" cy="767.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M862.2,763.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S864.4,763.3,862.2,763.3z"/>
      <path className="st0" d="M853.1,763.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S855.3,763.3,853.1,763.3z"/>
      <path className="st0" d="M843.8,763.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S846,763.3,843.8,763.3z"/>
      <ellipse className="st0" cx="834.7" cy="767.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M825.3,763.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S827.5,763.3,825.3,763.3z"/>
      <path className="st0" d="M816.3,771c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S814.1,771,816.3,771z"/>
      <path className="st0" d="M806.9,771c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S805,771,806.9,771z"/>
      <ellipse className="st0" cx="797.8" cy="767.1" rx="3.9" ry="3.9"/>
      <ellipse className="st0" cx="760.9" cy="767.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M747.7,767.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C749.7,763.3,747.7,764.9,747.7,767.1z"/>
      <path className="st0" d="M742.5,763.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S744.7,763.3,742.5,763.3z"/>
      <ellipse className="st0" cx="724.1" cy="767.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M715,771c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S712.8,771,715,771z"/>
      <path className="st0" d="M709.5,767.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C707.8,771,709.5,769.3,709.5,767.1z"/>
      <ellipse className="st0" cx="696.6" cy="767.1" rx="3.9" ry="3.9"/>
      <ellipse className="st0" cx="604.4" cy="767.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M595.3,771c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S593.1,771,595.3,771z"/>
      <ellipse className="st0" cx="585.9" cy="767.1" rx="3.9" ry="3.9"/>
      <ellipse className="st0" cx="447.8" cy="767.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M438.7,763.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S440.6,763.3,438.7,763.3z"/>
      <path className="st0" d="M429.4,763.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S431.6,763.3,429.4,763.3z"/>
      <ellipse className="st0" cx="420.3" cy="767.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M410.9,763.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S413.1,763.3,410.9,763.3z"/>
      <ellipse className="st0" cx="401.8" cy="767.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M392.5,763.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S394.7,763.3,392.5,763.3z"/>
      <ellipse className="st0" cx="383.4" cy="767.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M374.1,763.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S376.3,763.3,374.1,763.3z"/>
      <ellipse className="st0" cx="365" cy="767.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M355.6,763.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S357.8,763.3,355.6,763.3z"/>
      <ellipse className="st0" cx="346.5" cy="767.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M337.2,771c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S335.3,771,337.2,771z"/>
      <ellipse className="st0" cx="328.1" cy="767.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M319,771c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S316.8,771,319,771z"/>
      <ellipse className="st0" cx="309.7" cy="767.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M300.6,771c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S298.4,771,300.6,771z"/>
      <path className="st0" d="M291.2,771c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S289,771,291.2,771z"/>
      <ellipse className="st0" cx="282.1" cy="767.1" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1111,761.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1107.1,760,1108.8,761.9,1111,761.9z"/>
      <path className="st0" d="M1097.8,757.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1099.7,753.9,1097.8,755.8,1097.8,757.8z"/>
      <path className="st0" d="M1092.5,753.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1096.4,755.8,1094.7,753.9,1092.5,753.9z"/>
      <path className="st0" d="M1083.2,753.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1087.3,755.8,1085.4,753.9,1083.2,753.9z"/>
      <path className="st0" d="M1074.1,753.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1077.9,755.8,1076.3,753.9,1074.1,753.9z"/>
      <path className="st0" d="M1065,753.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1068.9,755.8,1066.9,753.9,1065,753.9z"/>
      <path className="st0" d="M1055.6,753.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1059.5,755.8,1057.9,753.9,1055.6,753.9z"/>
      <path className="st0" d="M1046.6,753.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1050.4,755.8,1048.5,753.9,1046.6,753.9z"/>
      <path className="st0" d="M1033.4,757.8c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1035,753.9,1033.4,755.8,1033.4,757.8z"/>
      <path className="st0" d="M1028.1,753.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1032,755.8,1030.1,753.9,1028.1,753.9z"/>
      <path className="st0" d="M1018.8,761.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,760,1016.6,761.9,1018.8,761.9z"/>
      <path className="st0" d="M1009.7,761.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,760,1007.5,761.9,1009.7,761.9z"/>
      <path className="st0" d="M987.4,757.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C989.1,753.9,987.4,755.8,987.4,757.8z"/>
      <path className="st0" d="M981.9,761.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C978.1,760,979.7,761.9,981.9,761.9z"/>
      <path className="st0" d="M972.8,761.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C969,760,970.6,761.9,972.8,761.9z"/>
      <path className="st0" d="M950.5,757.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C952.2,753.9,950.5,755.8,950.5,757.8z"/>
      <path className="st0" d="M949.2,757.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,761.9,949.2,760,949.2,757.8z"/>
      <path className="st0" d="M936,753.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C939.8,755.8,938.2,753.9,936,753.9z"/>
      <path className="st0" d="M926.6,753.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C930.7,755.8,928.8,753.9,926.6,753.9z"/>
      <path className="st0" d="M917.5,761.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C913.7,760,915.3,761.9,917.5,761.9z"/>
      <path className="st0" d="M908.4,761.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C904.3,760,906.2,761.9,908.4,761.9z"/>
      <path className="st0" d="M899.1,753.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C902.9,755.8,901.3,753.9,899.1,753.9z"/>
      <path className="st0" d="M890,753.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C893.9,755.8,891.9,753.9,890,753.9z
        "/>
      <path className="st0" d="M884.5,757.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C882.8,761.9,884.5,760,884.5,757.8z"/>
      <path className="st0" d="M871.6,753.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C875.4,755.8,873.8,753.9,871.6,753.9z"/>
      <path className="st0" d="M862.2,761.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C858.4,760,860,761.9,862.2,761.9z"/>
      <path className="st0" d="M853.1,761.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C849,760,850.9,761.9,853.1,761.9z"/>
      <path className="st0" d="M847.6,757.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C846,761.9,847.6,760,847.6,757.8z
        "/>
      <path className="st0" d="M838.5,757.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C836.9,761.6,838.5,760,838.5,757.8z"/>
      <path className="st0" d="M829.5,757.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C827.5,761.9,829.5,760,829.5,757.8z"/>
      <path className="st0" d="M816.3,761.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C812.4,760,814.1,761.9,816.3,761.9z"/>
      <path className="st0" d="M806.9,761.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C803,760,805,761.9,806.9,761.9z"
        />
      <path className="st0" d="M797.8,761.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C794,760,795.6,761.9,797.8,761.9z"/>
      <path className="st0" d="M742.5,761.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C738.7,760,740.3,761.9,742.5,761.9z"/>
      <path className="st0" d="M733.4,761.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C729.3,760,731.2,761.9,733.4,761.9z"/>
      <path className="st0" d="M724.1,753.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,755.8,726.3,753.9,724.1,753.9z"/>
      <path className="st0" d="M715,753.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,755.8,717.2,753.9,715,753.9z"/>
      <path className="st0" d="M705.6,761.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C701.8,760,703.4,761.9,705.6,761.9z"/>
      <path className="st0" d="M696.6,761.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C692.7,760,694.4,761.9,696.6,761.9z"/>
      <path className="st0" d="M461,757.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C459.1,761.9,461,760,461,757.8z"/>
      <path className="st0" d="M447.8,753.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C451.7,755.8,450,753.9,447.8,753.9z"/>
      <path className="st0" d="M442.6,757.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C440.6,761.9,442.6,760,442.6,757.8z"/>
      <path className="st0" d="M433.2,757.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C431.6,761.9,433.2,760,433.2,757.8z"/>
      <path className="st0" d="M424.1,757.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S424.1,760,424.1,757.8z"/>
      <path className="st0" d="M414.8,757.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C413.1,761.9,414.8,760,414.8,757.8z"/>
      <path className="st0" d="M405.7,757.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S405.7,760,405.7,757.8z"/>
      <path className="st0" d="M383.4,753.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C387.3,755.8,385.6,753.9,383.4,753.9z"/>
      <path className="st0" d="M361.1,757.8c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S361.1,755.8,361.1,757.8z"/>
      <path className="st0" d="M355.6,753.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C359.7,755.8,357.8,753.9,355.6,753.9z"/>
      <path className="st0" d="M346.5,753.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C350.4,755.8,348.7,753.9,346.5,753.9z"/>
      <path className="st0" d="M337.2,753.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C341.3,755.8,339.4,753.9,337.2,753.9z"/>
      <path className="st0" d="M328.1,761.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C324.3,760,325.9,761.9,328.1,761.9z"/>
      <path className="st0" d="M319,761.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C314.9,760,316.8,761.9,319,761.9z"
        />
      <path className="st0" d="M309.7,761.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C305.8,760,307.5,761.9,309.7,761.9z"/>
      <path className="st0" d="M300.6,761.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C296.5,760,298.4,761.9,300.6,761.9z"/>
      <path className="st0" d="M291.2,761.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C287.4,760,289,761.9,291.2,761.9z
        "/>
      <path className="st0" d="M282.1,761.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C278,760,279.9,761.9,282.1,761.9z
        "/>
      <path className="st0" d="M1092.5,752.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,750.9,1090.3,752.5,1092.5,752.5z"/>
      <path className="st0" d="M1083.2,752.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1079.3,750.9,1081.2,752.5,1083.2,752.5z"/>
      <ellipse className="st0" cx="1074.1" cy="748.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1065,744.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1068.9,746.5,1066.9,744.8,1065,744.8z"/>
      <path className="st0" d="M1055.6,744.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1059.5,746.5,1057.9,744.8,1055.6,744.8z"/>
      <ellipse className="st0" cx="1046.6" cy="748.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1037.2,752.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,750.9,1035,752.5,1037.2,752.5z"/>
      <path className="st0" d="M1024,748.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C1025.9,744.8,1024,746.5,1024,748.7
        z"/>
      <path className="st0" d="M1018.8,752.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,750.9,1016.6,752.5,1018.8,752.5z"/>
      <path className="st0" d="M945,744.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C948.9,746.5,947.2,744.8,945,744.8z"/>
      <ellipse className="st0" cx="936" cy="748.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M930.7,748.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C928.8,752.5,930.7,750.9,930.7,748.7z"/>
      <path className="st0" d="M917.5,752.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C913.7,750.9,915.3,752.5,917.5,752.5z"/>
      <ellipse className="st0" cx="908.4" cy="748.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M899.1,752.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C895.2,750.9,896.9,752.5,899.1,752.5z"/>
      <path className="st0" d="M890,752.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C886.1,750.9,887.8,752.5,890,752.5z
        "/>
      <path className="st0" d="M880.6,752.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C876.8,750.9,878.4,752.5,880.6,752.5z"/>
      <ellipse className="st0" cx="871.6" cy="748.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M862.2,752.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C858.4,750.9,860,752.5,862.2,752.5z"/>
      <path className="st0" d="M853.1,752.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C849.3,750.9,850.9,752.5,853.1,752.5z"/>
      <path className="st0" d="M847.6,748.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S847.6,750.9,847.6,748.7z"/>
      <ellipse className="st0" cx="834.7" cy="748.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M825.3,752.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,750.9,823.1,752.5,825.3,752.5z"/>
      <path className="st0" d="M816.3,752.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C812.4,750.9,814.1,752.5,816.3,752.5z"/>
      <path className="st0" d="M806.9,752.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C803,750.9,805,752.5,806.9,752.5z
        "/>
      <ellipse className="st0" cx="797.8" cy="748.7" rx="3.9" ry="3.9"/>
      <ellipse className="st0" cx="724.1" cy="748.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M715,744.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,746.5,717.2,744.8,715,744.8z"/>
      <path className="st0" d="M705.6,744.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,746.5,707.8,744.8,705.6,744.8z"/>
      <ellipse className="st0" cx="696.6" cy="748.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M687.2,744.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.1,746.5,689.4,744.8,687.2,744.8z"/>
      <path className="st0" d="M678.1,744.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C682,746.5,680.3,744.8,678.1,744.8z"/>
      <path className="st0" d="M668.8,744.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C672.6,746.5,671,744.8,668.8,744.8z"/>
      <ellipse className="st0" cx="604.4" cy="748.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M479.4,748.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C477.5,752.5,479.4,750.9,479.4,748.7z"/>
      <ellipse className="st0" cx="466.2" cy="748.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M457.2,744.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C461,746.5,459.1,744.8,457.2,744.8z"/>
      <ellipse className="st0" cx="447.8" cy="748.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M442.6,748.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C440.6,752.5,442.6,750.9,442.6,748.7z"/>
      <path className="st0" d="M433.2,748.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S433.2,750.9,433.2,748.7z"
        />
      <ellipse className="st0" cx="420.3" cy="748.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M407.1,748.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C408.7,744.8,407.1,746.5,407.1,748.7z"/>
      <ellipse className="st0" cx="401.8" cy="748.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M392.5,744.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C396.3,746.5,394.7,744.8,392.5,744.8z"/>
      <ellipse className="st0" cx="383.4" cy="748.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M374.1,744.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C377.9,746.5,376.3,744.8,374.1,744.8z"/>
      <ellipse className="st0" cx="365" cy="748.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M355.6,744.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C359.5,746.5,357.8,744.8,355.6,744.8z"/>
      <ellipse className="st0" cx="346.5" cy="748.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M337.2,744.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C341,746.5,339.4,744.8,337.2,744.8z"/>
      <ellipse className="st0" cx="328.1" cy="748.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M319,752.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C315.2,750.9,316.8,752.5,319,752.5z
        "/>
      <ellipse className="st0" cx="309.7" cy="748.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M300.6,752.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C296.7,750.9,298.4,752.5,300.6,752.5z"/>
      <path className="st0" d="M291.2,752.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C287.4,750.9,289,752.5,291.2,752.5z"/>
      <ellipse className="st0" cx="282.1" cy="748.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1070.2,739.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1070.2,737.4,1070.2,739.6z"/>
      <path className="st0" d="M1060.9,739.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1062.8,735.5,1060.9,737.4,1060.9,739.6z"/>
      <path className="st0" d="M1055.6,743.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,741.5,1053.4,743.5,1055.6,743.5z"/>
      <path className="st0" d="M1046.6,743.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,741.5,1044.4,743.5,1046.6,743.5z"/>
      <path className="st0" d="M1033.4,739.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1035,735.5,1033.4,737.4,1033.4,739.6z"/>
      <path className="st0" d="M945,743.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C941.2,741.5,943.1,743.5,945,743.5z"/>
      <path className="st0" d="M936,735.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C939.8,737.4,938.2,735.5,936,735.5z"/>
      <path className="st0" d="M926.6,735.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C930.7,737.4,928.8,735.5,926.6,735.5z"/>
      <path className="st0" d="M917.5,735.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C921.4,737.4,919.7,735.5,917.5,735.5z"/>
      <path className="st0" d="M908.4,743.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C904.3,741.5,906.2,743.5,908.4,743.5z"/>
      <path className="st0" d="M899.1,743.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C895.2,741.5,896.9,743.5,899.1,743.5z"/>
      <path className="st0" d="M890,743.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C885.9,741.5,887.8,743.5,890,743.5z
        "/>
      <path className="st0" d="M880.6,743.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C876.8,741.5,878.4,743.5,880.6,743.5z"/>
      <path className="st0" d="M875.4,739.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C873.8,743.5,875.4,741.5,875.4,739.6z"/>
      <path className="st0" d="M862.2,743.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C858.4,741.5,860,743.5,862.2,743.5z"/>
      <path className="st0" d="M853.1,743.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C849,741.5,850.9,743.5,853.1,743.5z"/>
      <path className="st0" d="M847.6,739.6c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S847.6,741.5,847.6,739.6z"/>
      <path className="st0" d="M838.5,739.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C836.9,743.5,838.5,741.5,838.5,739.6z"/>
      <path className="st0" d="M825.3,743.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,741.5,823.1,743.5,825.3,743.5z"/>
      <path className="st0" d="M816.3,743.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C812.4,741.5,814.1,743.5,816.3,743.5z"/>
      <path className="st0" d="M806.9,743.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C803,741.5,805,743.5,806.9,743.5z
        "/>
      <path className="st0" d="M797.8,743.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C794,741.5,795.6,743.5,797.8,743.5z"/>
      <path className="st0" d="M720.2,739.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C721.9,735.8,720.2,737.4,720.2,739.6z"/>
      <path className="st0" d="M710.9,739.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C712.8,735.5,710.9,737.4,710.9,739.6z"/>
      <path className="st0" d="M705.6,743.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C701.8,741.5,703.4,743.5,705.6,743.5z"/>
      <path className="st0" d="M696.6,735.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,737.4,698.8,735.5,696.6,735.5z"/>
      <path className="st0" d="M687.2,743.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C683.3,741.5,685,743.5,687.2,743.5z"/>
      <path className="st0" d="M678.1,743.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,741.5,675.9,743.5,678.1,743.5z"/>
      <path className="st0" d="M668.8,743.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C664.9,741.5,666.6,743.5,668.8,743.5z"/>
      <path className="st0" d="M663.5,739.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C661.9,743.5,663.5,741.5,663.5,739.6z"/>
      <path className="st0" d="M604.4,735.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C608.2,737.4,606.6,735.5,604.4,735.5z"/>
      <path className="st0" d="M595.3,743.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.2,741.5,593.1,743.5,595.3,743.5z"/>
      <path className="st0" d="M493.8,735.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C497.9,737.4,496,735.5,493.8,735.5z"/>
      <path className="st0" d="M484.7,735.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C488.5,737.4,486.9,735.5,484.7,735.5z"/>
      <path className="st0" d="M475.3,735.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C479.4,737.4,477.5,735.5,475.3,735.5z"/>
      <path className="st0" d="M466.2,743.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C462.4,741.5,464,743.5,466.2,743.5z"/>
      <path className="st0" d="M461,739.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C459.1,743.5,461,741.5,461,739.6z"/>
      <path className="st0" d="M451.7,739.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C450,743.5,451.7,741.5,451.7,739.6z"/>
      <path className="st0" d="M442.6,739.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C440.6,743.5,442.6,741.5,442.6,739.6z"/>
      <path className="st0" d="M433.2,739.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S433.2,741.5,433.2,739.6z"
        />
      <path className="st0" d="M416.2,739.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S416.2,737.4,416.2,739.6z"/>
      <path className="st0" d="M407.1,739.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C408.7,735.5,407.1,737.4,407.1,739.6z"/>
      <path className="st0" d="M401.8,735.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C405.7,737.4,404,735.5,401.8,735.5z"/>
      <path className="st0" d="M392.5,735.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C396.3,737.4,394.7,735.5,392.5,735.5z"/>
      <path className="st0" d="M383.4,735.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C387.3,737.4,385.6,735.5,383.4,735.5z"/>
      <path className="st0" d="M374.1,735.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C378.2,737.4,376.3,735.5,374.1,735.5z"/>
      <path className="st0" d="M361.1,739.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S361.1,737.4,361.1,739.6z"/>
      <path className="st0" d="M355.6,735.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C359.7,737.4,357.8,735.5,355.6,735.5z"/>
      <path className="st0" d="M346.5,735.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C350.4,737.4,348.7,735.5,346.5,735.5z"/>
      <path className="st0" d="M337.2,735.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C341.3,737.4,339.4,735.5,337.2,735.5z"/>
      <path className="st0" d="M328.1,735.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C332,737.4,330.3,735.5,328.1,735.5z"/>
      <path className="st0" d="M319,735.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C322.9,737.4,320.9,735.5,319,735.5z
        "/>
      <path className="st0" d="M309.7,743.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C305.8,741.5,307.5,743.5,309.7,743.5z"/>
      <path className="st0" d="M300.6,743.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C296.5,741.5,298.4,743.5,300.6,743.5z"/>
      <path className="st0" d="M291.2,743.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C287.4,741.5,289,743.5,291.2,743.5z"/>
      <path className="st0" d="M282.1,743.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C278,741.5,279.9,743.5,282.1,743.5z"/>
      <ellipse className="st0" cx="936" cy="730.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M926.6,726.4c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S928.8,726.4,926.6,726.4z"/>
      <path className="st0" d="M917.5,726.4c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S919.7,726.4,917.5,726.4z"/>
      <ellipse className="st0" cx="908.4" cy="730.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M899.1,734.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C895.2,732.5,896.9,734.1,899.1,734.1z"/>
      <path className="st0" d="M890,734.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C886.1,732.5,887.8,734.1,890,734.1z
        "/>
      <path className="st0" d="M880.6,734.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C876.8,732.5,878.4,734.1,880.6,734.1z"/>
      <ellipse className="st0" cx="871.6" cy="730.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M862.2,734.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C858.4,732.5,860,734.1,862.2,734.1z"/>
      <path className="st0" d="M853.1,734.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C849.3,732.5,850.9,734.1,853.1,734.1z"/>
      <path className="st0" d="M847.6,730.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S847.6,732.5,847.6,730.3z"/>
      <ellipse className="st0" cx="834.7" cy="730.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M825.3,734.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,732.5,823.1,734.1,825.3,734.1z"/>
      <path className="st0" d="M816.3,734.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C812.4,732.5,814.1,734.1,816.3,734.1z"/>
      <path className="st0" d="M806.9,734.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C803,732.5,805,734.1,806.9,734.1z
        "/>
      <ellipse className="st0" cx="797.8" cy="730.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M788.5,734.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C784.6,732.5,786.5,734.1,788.5,734.1z"/>
      <path className="st0" d="M783.2,730.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C781.6,734.1,783.2,732.5,783.2,730.3z"/>
      <ellipse className="st0" cx="696.6" cy="730.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M687.2,726.4c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S689.4,726.4,687.2,726.4z"/>
      <path className="st0" d="M678.1,726.4c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S680.3,726.4,678.1,726.4z"/>
      <path className="st0" d="M668.8,734.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C664.9,732.5,666.6,734.1,668.8,734.1z"/>
      <ellipse className="st0" cx="659.7" cy="730.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M654.5,730.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C652.5,734.1,654.5,732.5,654.5,730.3z"/>
      <ellipse className="st0" cx="604.4" cy="730.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M595.3,734.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.4,732.5,593.1,734.1,595.3,734.1z"/>
      <path className="st0" d="M493.8,726.4c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S496,726.4,493.8,726.4z"/>
      <ellipse className="st0" cx="484.7" cy="730.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M479.4,730.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C477.5,734.1,479.4,732.5,479.4,730.3z"/>
      <ellipse className="st0" cx="466.2" cy="730.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M461,730.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C459.1,734.1,461,732.5,461,730.3z"/>
      <ellipse className="st0" cx="447.8" cy="730.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M442.6,730.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C440.6,734.1,442.6,732.5,442.6,730.3z"/>
      <path className="st0" d="M425.5,730.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C427.2,726.4,425.5,728.1,425.5,730.3z"/>
      <ellipse className="st0" cx="420.3" cy="730.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M407.1,730.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C408.7,726.4,407.1,728.1,407.1,730.3z"/>
      <ellipse className="st0" cx="401.8" cy="730.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M392.5,726.4c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S394.7,726.4,392.5,726.4z"/>
      <ellipse className="st0" cx="383.4" cy="730.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M374.1,726.4c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S376.3,726.4,374.1,726.4z"/>
      <ellipse className="st0" cx="365" cy="730.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M355.6,726.4c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S357.8,726.4,355.6,726.4z"/>
      <ellipse className="st0" cx="346.5" cy="730.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M337.2,726.4c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S339.4,726.4,337.2,726.4z"/>
      <ellipse className="st0" cx="328.1" cy="730.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M319,726.4c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S320.9,726.4,319,726.4z"/>
      <ellipse className="st0" cx="309.7" cy="730.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M300.6,734.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C296.7,732.5,298.4,734.1,300.6,734.1z"/>
      <path className="st0" d="M291.2,734.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C287.4,732.5,289,734.1,291.2,734.1z"/>
      <ellipse className="st0" cx="282.1" cy="730.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M936,717.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C939.8,719,938.2,717.3,936,717.3z"/>
      <path className="st0" d="M926.6,717.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C930.7,719,928.8,717.3,926.6,717.3z"/>
      <path className="st0" d="M917.5,717.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C921.4,719,919.7,717.3,917.5,717.3z"/>
      <path className="st0" d="M908.4,717.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C912.3,719,910.4,717.3,908.4,717.3z"/>
      <path className="st0" d="M899.1,725c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C895.2,723.4,896.9,725,899.1,725z"/>
      <path className="st0" d="M890,725c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C885.9,723.4,887.8,725,890,725z"/>
      <path className="st0" d="M880.6,725c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C876.8,723.4,878.4,725,880.6,725z"/>
      <path className="st0" d="M875.4,721.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C873.8,725,875.4,723.4,875.4,721.2z"/>
      <path className="st0" d="M862.2,725c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C858.4,723.4,860,725,862.2,725z"/>
      <path className="st0" d="M853.1,725c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C849,723.4,850.9,725,853.1,725z"/>
      <path className="st0" d="M847.6,721.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S847.6,723.4,847.6,721.2z"/>
      <path className="st0" d="M834.7,725c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C830.8,723.4,832.5,725,834.7,725z"/>
      <path className="st0" d="M825.3,725c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,723.4,823.1,725,825.3,725z"/>
      <path className="st0" d="M816.3,725c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C812.4,723.4,814.1,725,816.3,725z"/>
      <path className="st0" d="M806.9,725c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C803,723.4,805,725,806.9,725z"/>
      <path className="st0" d="M797.8,725c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C794,723.4,795.6,725,797.8,725z"/>
      <path className="st0" d="M788.5,725c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C784.6,723.4,786.5,725,788.5,725z"/>
      <path className="st0" d="M779.4,725c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C775.5,723.4,777.2,725,779.4,725z"/>
      <path className="st0" d="M774.2,721.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C772.2,725,774.2,723.4,774.2,721.2z"/>
      <path className="st0" d="M687.2,717.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.3,719,689.4,717.3,687.2,717.3z"/>
      <path className="st0" d="M678.1,717.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C682,719,680.3,717.3,678.1,717.3z"/>
      <path className="st0" d="M668.8,717.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C672.9,719,671,717.3,668.8,717.3z"/>
      <path className="st0" d="M659.7,725c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C655.8,723.4,657.5,725,659.7,725z"/>
      <path className="st0" d="M641.2,717.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C645.1,719,643.4,717.3,641.2,717.3z"/>
      <path className="st0" d="M600.5,721.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C602.2,717.3,600.5,719,600.5,721.2z"/>
      <path className="st0" d="M489.9,721.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C491.8,717.3,489.9,719,489.9,721.2z"/>
      <path className="st0" d="M484.7,717.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C488.5,719,486.9,717.3,484.7,717.3z"/>
      <path className="st0" d="M475.3,717.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C479.4,719,477.5,717.3,475.3,717.3z"/>
      <path className="st0" d="M466.2,717.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C470.1,719,468.4,717.3,466.2,717.3z"/>
      <path className="st0" d="M461,721.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C459.1,725,461,723.4,461,721.2z"/>
      <path className="st0" d="M451.7,721.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C450,725,451.7,723.4,451.7,721.2z"/>
      <path className="st0" d="M434.6,721.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C436.5,717.3,434.6,719,434.6,721.2z"/>
      <path className="st0" d="M425.5,721.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C427.2,717.3,425.5,719,425.5,721.2z"/>
      <path className="st0" d="M416.2,721.2c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S416.2,719,416.2,721.2z"/>
      <path className="st0" d="M407.1,721.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C408.7,717.3,407.1,719,407.1,721.2z"/>
      <path className="st0" d="M401.8,717.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C405.7,719,404,717.3,401.8,717.3z
        "/>
      <path className="st0" d="M392.5,717.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C396.3,719,394.7,717.3,392.5,717.3z"/>
      <path className="st0" d="M383.4,717.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C387.3,719,385.6,717.3,383.4,717.3z"/>
      <path className="st0" d="M374.1,717.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C378.2,719,376.3,717.3,374.1,717.3z"/>
      <path className="st0" d="M361.1,721.2c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S361.1,719,361.1,721.2z"/>
      <path className="st0" d="M355.6,717.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C359.7,719,357.8,717.3,355.6,717.3z"/>
      <path className="st0" d="M346.5,717.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C350.4,719,348.7,717.3,346.5,717.3z"/>
      <path className="st0" d="M337.2,717.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C341.3,719,339.4,717.3,337.2,717.3z"/>
      <path className="st0" d="M328.1,717.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C332,719,330.3,717.3,328.1,717.3z"/>
      <path className="st0" d="M319,717.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C322.9,719,320.9,717.3,319,717.3z"
        />
      <path className="st0" d="M309.7,717.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C313.5,719,311.9,717.3,309.7,717.3z"/>
      <path className="st0" d="M300.6,717.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C304.4,719,302.5,717.3,300.6,717.3z"/>
      <path className="st0" d="M287.4,721.2c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S287.4,719,287.4,721.2z"/>
      <path className="st0" d="M282.1,725c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C278,723.4,279.9,725,282.1,725z"/>
      <path className="st0" d="M936,708c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C939.8,709.9,938.2,708,936,708z
        "/>
      <path className="st0" d="M926.6,708c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C930.7,709.9,928.8,708,926.6,708z"
        />
      <path className="st0" d="M917.5,708c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C921.4,709.9,919.7,708,917.5,708z"/>
      <path className="st0" d="M908.4,708c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C912.3,709.9,910.4,708,908.4,708z"/>
      <path className="st0" d="M899.1,708c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C902.9,709.9,901.3,708,899.1,708z"/>
      <path className="st0" d="M890,715.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C885.9,714,887.8,715.9,890,715.9z"
        />
      <path className="st0" d="M880.6,715.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C876.8,714,878.4,715.9,880.6,715.9z"/>
      <path className="st0" d="M875.4,711.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C873.8,715.7,875.4,714,875.4,711.8z"/>
      <path className="st0" d="M853.1,715.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C849,714,850.9,715.9,853.1,715.9z"/>
      <path className="st0" d="M847.6,711.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C846,715.9,847.6,714,847.6,711.8z
        "/>
      <path className="st0" d="M834.7,715.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C830.8,714,832.5,715.9,834.7,715.9z"/>
      <path className="st0" d="M825.3,715.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,714,823.1,715.9,825.3,715.9z"/>
      <path className="st0" d="M816.3,715.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C812.4,714,814.1,715.9,816.3,715.9z"/>
      <path className="st0" d="M806.9,715.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C803,714,805,715.9,806.9,715.9z"
        />
      <path className="st0" d="M797.8,715.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C794,714,795.6,715.9,797.8,715.9z"/>
      <path className="st0" d="M788.5,715.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C784.6,714,786.5,715.9,788.5,715.9z"/>
      <path className="st0" d="M779.4,715.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C775.5,714,777.2,715.9,779.4,715.9z"/>
      <path className="st0" d="M770.3,715.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C766.2,714,768.1,715.9,770.3,715.9z"/>
      <path className="st0" d="M760.9,708c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C764.8,709.9,763.1,708,760.9,708z"/>
      <path className="st0" d="M683.3,711.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S683.3,709.9,683.3,711.8z"/>
      <path className="st0" d="M678.1,708c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C682,709.9,680.3,708,678.1,708z"/>
      <path className="st0" d="M668.8,708c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C672.9,709.9,671,708,668.8,708z"/>
      <path className="st0" d="M659.7,708c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C663.5,709.9,661.9,708,659.7,708z"/>
      <path className="st0" d="M650.3,715.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C646.5,714,648.4,715.9,650.3,715.9z"/>
      <path className="st0" d="M641.2,715.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C637.4,714,639,715.9,641.2,715.9z
        "/>
      <path className="st0" d="M636,711.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C634.1,715.9,636,714,636,711.8z"/>
      <path className="st0" d="M484.7,708c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C488.5,709.9,486.9,708,484.7,708z"
        />
      <path className="st0" d="M475.3,708c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C479.4,709.9,477.5,708,475.3,708z"/>
      <path className="st0" d="M466.2,708c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C470.1,709.9,468.4,708,466.2,708z"
        />
      <path className="st0" d="M457.2,708c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C461,709.9,459.1,708,457.2,708z"/>
      <path className="st0" d="M447.8,708c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C451.7,709.9,450,708,447.8,708z"/>
      <path className="st0" d="M434.6,711.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C436.5,708,434.6,709.9,434.6,711.8z"/>
      <path className="st0" d="M425.5,711.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C427.2,708,425.5,709.9,425.5,711.8z"/>
      <path className="st0" d="M416.2,711.8c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S416.2,709.9,416.2,711.8z"/>
      <path className="st0" d="M410.9,715.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C407.1,714,408.7,715.9,410.9,715.9z"/>
      <path className="st0" d="M401.8,715.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C397.7,714,399.6,715.9,401.8,715.9z"/>
      <path className="st0" d="M388.6,711.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S388.6,709.9,388.6,711.8z"/>
      <path className="st0" d="M383.4,708c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C387.3,709.9,385.6,708,383.4,708z"
        />
      <path className="st0" d="M374.1,708c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C378.2,709.9,376.3,708,374.1,708z"/>
      <path className="st0" d="M365,715.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C361.1,714,362.8,715.9,365,715.9z"/>
      <path className="st0" d="M351.8,711.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C353.7,708,351.8,709.9,351.8,711.8z"/>
      <path className="st0" d="M346.5,708c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C350.4,709.9,348.7,708,346.5,708z"
        />
      <path className="st0" d="M337.2,708c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C341.3,709.9,339.4,708,337.2,708z"
        />
      <path className="st0" d="M328.1,708c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C332,709.9,330.3,708,328.1,708z"/>
      <path className="st0" d="M319,708c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C322.9,709.9,320.9,708,319,708z"/>
      <path className="st0" d="M309.7,708c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C313.5,709.9,311.9,708,309.7,708z"/>
      <path className="st0" d="M300.6,708c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C304.4,709.9,302.5,708,300.6,708z"
        />
      <path className="st0" d="M291.2,715.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C287.4,714,289,715.9,291.2,715.9z
        "/>
      <path className="st0" d="M282.1,715.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C278,714,279.9,715.9,282.1,715.9z
        "/>
      <path className="st0" d="M272.8,708c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C276.6,709.9,275,708,272.8,708z"/>
      <ellipse className="st0" cx="1175.3" cy="702.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,698.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,700.5,1168.5,698.9,1166.3,698.9z"/>
      <path className="st0" d="M949.2,702.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,706.6,949.2,704.9,949.2,702.7z"/>
      <ellipse className="st0" cx="936" cy="702.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M926.6,698.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C930.4,700.5,928.8,698.9,926.6,698.9z"/>
      <path className="st0" d="M917.5,698.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C921.4,700.5,919.7,698.9,917.5,698.9z"/>
      <ellipse className="st0" cx="908.4" cy="702.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M899.1,698.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C902.9,700.5,901.3,698.9,899.1,698.9z"/>
      <path className="st0" d="M890,698.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C893.9,700.5,891.9,698.9,890,698.9z
        "/>
      <path className="st0" d="M880.6,706.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C876.8,704.9,878.4,706.6,880.6,706.6z"/>
      <ellipse className="st0" cx="871.6" cy="702.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M862.2,706.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C858.4,704.9,860,706.6,862.2,706.6z"/>
      <path className="st0" d="M853.1,706.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C849.3,704.9,850.9,706.6,853.1,706.6z"/>
      <path className="st0" d="M847.6,702.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S847.6,704.9,847.6,702.7z"/>
      <ellipse className="st0" cx="834.7" cy="702.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M825.3,706.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,704.9,823.1,706.6,825.3,706.6z"/>
      <path className="st0" d="M816.3,706.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C812.4,704.9,814.1,706.6,816.3,706.6z"/>
      <path className="st0" d="M806.9,706.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C803,704.9,805,706.6,806.9,706.6z
        "/>
      <ellipse className="st0" cx="797.8" cy="702.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M788.5,706.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C784.6,704.9,786.5,706.6,788.5,706.6z"/>
      <path className="st0" d="M779.4,706.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C775.5,704.9,777.2,706.6,779.4,706.6z"/>
      <path className="st0" d="M770.3,706.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C766.4,704.9,768.1,706.6,770.3,706.6z"/>
      <ellipse className="st0" cx="760.9" cy="702.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M751.9,698.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C755.7,700.5,753.8,698.9,751.9,698.9z"/>
      <path className="st0" d="M746.4,702.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C744.7,706.6,746.4,704.9,746.4,702.7z"/>
      <path className="st0" d="M674.3,702.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C675.9,698.9,674.3,700.5,674.3,702.7z"/>
      <path className="st0" d="M668.8,698.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C672.6,700.5,671,698.9,668.8,698.9z"/>
      <ellipse className="st0" cx="659.7" cy="702.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M650.3,698.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C654.2,700.5,652.5,698.9,650.3,698.9z"/>
      <ellipse className="st0" cx="641.2" cy="702.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M631.9,706.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C628,704.9,630,706.6,631.9,706.6z"/>
      <ellipse className="st0" cx="558.4" cy="702.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M549.1,698.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C552.9,700.5,551.3,698.9,549.1,698.9z"/>
      <ellipse className="st0" cx="540" cy="702.7" rx="3.9" ry="3.9"/>
      <ellipse className="st0" cx="503.1" cy="702.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M493.8,698.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C497.6,700.5,496,698.9,493.8,698.9z"/>
      <ellipse className="st0" cx="484.7" cy="702.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M475.3,698.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C479.2,700.5,477.5,698.9,475.3,698.9z"/>
      <ellipse className="st0" cx="466.2" cy="702.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M457.2,698.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C461,700.5,459.1,698.9,457.2,698.9z"/>
      <ellipse className="st0" cx="447.8" cy="702.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M438.7,706.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C434.9,704.9,436.5,706.6,438.7,706.6z"/>
      <path className="st0" d="M429.4,706.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C425.5,704.9,427.2,706.6,429.4,706.6z"/>
      <ellipse className="st0" cx="420.3" cy="702.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M407.1,702.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C408.7,698.9,407.1,700.5,407.1,702.7z"/>
      <ellipse className="st0" cx="383.4" cy="702.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M370.2,702.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C371.9,698.9,370.2,700.5,370.2,702.7z"/>
      <ellipse className="st0" cx="365" cy="702.7" rx="3.9" ry="3.9"/>
      <ellipse className="st0" cx="346.5" cy="702.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M337.2,706.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C333.3,704.9,335.3,706.6,337.2,706.6z"/>
      <ellipse className="st0" cx="328.1" cy="702.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M319,706.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C315.2,704.9,316.8,706.6,319,706.6z
        "/>
      <ellipse className="st0" cx="309.7" cy="702.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M300.6,706.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C296.7,704.9,298.4,706.6,300.6,706.6z"/>
      <path className="st0" d="M287.4,702.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C289,698.9,287.4,700.5,287.4,702.7
        z"/>
      <path className="st0" d="M1175.3,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1171.5,695.9,1173.1,697.5,1175.3,697.5z"/>
      <path className="st0" d="M1166.3,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.1,695.9,1164.1,697.5,1166.3,697.5z"/>
      <path className="st0" d="M1160.8,693.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S1160.8,695.9,1160.8,693.7z"/>
      <path className="st0" d="M954.4,689.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C958.2,691.5,956.6,689.5,954.4,689.5z"/>
      <path className="st0" d="M945,689.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C949.2,691.5,947.2,689.5,945,689.5z"/>
      <path className="st0" d="M939.8,693.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C938.2,697.5,939.8,695.9,939.8,693.7z"/>
      <path className="st0" d="M930.7,693.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C928.8,697.5,930.7,695.9,930.7,693.7z"/>
      <path className="st0" d="M921.4,693.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C919.7,697.5,921.4,695.9,921.4,693.7z"/>
      <path className="st0" d="M912.3,693.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C910.6,697.5,912.3,695.9,912.3,693.7z"/>
      <path className="st0" d="M902.9,693.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C901.3,697.5,902.9,695.9,902.9,693.7z"/>
      <path className="st0" d="M893.9,693.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C891.9,697.5,893.9,695.9,893.9,693.7z"
        />
      <path className="st0" d="M880.6,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C876.8,695.9,878.4,697.5,880.6,697.5z"/>
      <path className="st0" d="M875.4,693.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C873.8,697.5,875.4,695.9,875.4,693.7z"/>
      <path className="st0" d="M862.2,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C858.4,695.9,860,697.5,862.2,697.5z"/>
      <path className="st0" d="M853.1,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C849,695.9,850.9,697.5,853.1,697.5z"/>
      <path className="st0" d="M847.6,693.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S847.6,695.9,847.6,693.7z"/>
      <path className="st0" d="M834.7,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C830.8,695.9,832.5,697.5,834.7,697.5z"/>
      <path className="st0" d="M825.3,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,695.9,823.1,697.5,825.3,697.5z"/>
      <path className="st0" d="M816.3,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C812.4,695.9,814.1,697.5,816.3,697.5z"/>
      <path className="st0" d="M806.9,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C803,695.9,805,697.5,806.9,697.5z"/>
      <path className="st0" d="M797.8,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C794,695.9,795.6,697.5,797.8,697.5z"/>
      <path className="st0" d="M788.5,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C784.6,695.9,786.5,697.5,788.5,697.5z"/>
      <path className="st0" d="M779.4,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C775.5,695.9,777.2,697.5,779.4,697.5z"/>
      <path className="st0" d="M770.3,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C766.2,695.9,768.1,697.5,770.3,697.5z"
        />
      <path className="st0" d="M760.9,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C757.1,695.9,758.7,697.5,760.9,697.5z"/>
      <path className="st0" d="M751.9,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C747.7,695.9,749.7,697.5,751.9,697.5z"/>
      <path className="st0" d="M742.5,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C738.7,695.9,740.3,697.5,742.5,697.5z"/>
      <path className="st0" d="M737.3,693.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C735.4,697.5,737.3,695.9,737.3,693.7z"/>
      <path className="st0" d="M724.1,689.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,691.5,726.3,689.5,724.1,689.5z"/>
      <path className="st0" d="M664.9,693.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C666.6,689.5,664.9,691.5,664.9,693.7z"/>
      <path className="st0" d="M659.7,689.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C663.5,691.5,661.9,689.5,659.7,689.5z"/>
      <path className="st0" d="M650.3,689.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C654.5,691.5,652.5,689.5,650.3,689.5z"/>
      <path className="st0" d="M641.2,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C637.4,695.9,639,697.5,641.2,697.5z"/>
      <path className="st0" d="M631.9,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C628,695.9,630,697.5,631.9,697.5
        z"/>
      <path className="st0" d="M622.8,689.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C626.7,691.5,625,689.5,622.8,689.5z"/>
      <path className="st0" d="M567.5,689.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C571.4,691.5,569.7,689.5,567.5,689.5z"/>
      <path className="st0" d="M558.4,689.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C562.3,691.5,560.6,689.5,558.4,689.5z"/>
      <path className="st0" d="M549.1,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C545.2,695.9,546.9,697.5,549.1,697.5z"
        />
      <path className="st0" d="M540,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C536.1,695.9,537.8,697.5,540,697.5
        z"/>
      <path className="st0" d="M530.6,689.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C534.8,691.5,532.8,689.5,530.6,689.5z"/>
      <path className="st0" d="M525.4,693.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C523.7,697.5,525.4,695.9,525.4,693.7z"/>
      <path className="st0" d="M516.3,693.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C514.4,697.5,516.3,695.9,516.3,693.7z"
        />
      <path className="st0" d="M503.1,689.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C507,691.5,505.3,689.5,503.1,689.5z"/>
      <path className="st0" d="M493.8,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C489.9,695.9,491.8,697.5,493.8,697.5z"/>
      <path className="st0" d="M488.5,693.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S488.5,695.9,488.5,693.7z"/>
      <path className="st0" d="M479.4,693.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C477.5,697.5,479.4,695.9,479.4,693.7z"/>
      <path className="st0" d="M470.1,693.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S470.1,695.9,470.1,693.7z"/>
      <path className="st0" d="M461,693.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C459.1,697.5,461,695.9,461,693.7z"
        />
      <path className="st0" d="M447.8,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C443.9,695.9,445.6,697.5,447.8,697.5z"
        />
      <path className="st0" d="M438.7,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C434.6,695.9,436.5,697.5,438.7,697.5z"/>
      <path className="st0" d="M429.4,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C425.5,695.9,427.2,697.5,429.4,697.5z"/>
      <path className="st0" d="M420.3,697.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C416.2,695.9,418.1,697.5,420.3,697.5z"/>
      <ellipse className="st0" cx="1175.3" cy="684.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,688.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.4,686.5,1164.1,688.2,1166.3,688.2z"/>
      <path className="st0" d="M1156.9,688.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,686.5,1154.7,688.2,1156.9,688.2z"/>
      <ellipse className="st0" cx="1147.8" cy="684.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M954.4,680.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C958.2,682.1,956.6,680.5,954.4,680.5z"/>
      <path className="st0" d="M949.2,684.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,688.2,949.2,686.5,949.2,684.3z"/>
      <ellipse className="st0" cx="936" cy="684.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M930.7,684.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C928.8,688.2,930.7,686.5,930.7,684.3z"/>
      <path className="st0" d="M921.4,684.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C919.7,688.2,921.4,686.5,921.4,684.3z"/>
      <ellipse className="st0" cx="908.4" cy="684.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M902.9,684.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C901.3,688.2,902.9,686.5,902.9,684.3z"/>
      <path className="st0" d="M893.9,684.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C891.9,688.2,893.9,686.5,893.9,684.3z"/>
      <path className="st0" d="M880.6,688.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C876.8,686.5,878.4,688.2,880.6,688.2z"/>
      <ellipse className="st0" cx="871.6" cy="684.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M862.2,688.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C858.4,686.5,860,688.2,862.2,688.2z"/>
      <path className="st0" d="M853.1,688.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C849.3,686.5,850.9,688.2,853.1,688.2z"/>
      <path className="st0" d="M847.6,684.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S847.6,686.5,847.6,684.3z"/>
      <ellipse className="st0" cx="834.7" cy="684.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M825.3,688.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,686.5,823.1,688.2,825.3,688.2z"/>
      <path className="st0" d="M816.3,688.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C812.4,686.5,814.1,688.2,816.3,688.2z"/>
      <path className="st0" d="M806.9,688.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C803,686.5,805,688.2,806.9,688.2z
        "/>
      <ellipse className="st0" cx="797.8" cy="684.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M788.5,688.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C784.6,686.5,786.5,688.2,788.5,688.2z"/>
      <path className="st0" d="M770.3,688.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C766.4,686.5,768.1,688.2,770.3,688.2z"/>
      <ellipse className="st0" cx="760.9" cy="684.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M751.9,688.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C748,686.5,749.7,688.2,751.9,688.2z"/>
      <path className="st0" d="M742.5,688.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C738.7,686.5,740.3,688.2,742.5,688.2z"/>
      <path className="st0" d="M733.4,688.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C729.6,686.5,731.2,688.2,733.4,688.2z"/>
      <ellipse className="st0" cx="724.1" cy="684.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M715,680.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,682.1,717.2,680.5,715,680.5z"/>
      <ellipse className="st0" cx="659.7" cy="684.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M650.3,680.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C654.2,682.1,652.5,680.5,650.3,680.5z"/>
      <ellipse className="st0" cx="641.2" cy="684.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M631.9,688.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C628,686.5,630,688.2,631.9,688.2z"/>
      <ellipse className="st0" cx="622.8" cy="684.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M617.6,684.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C615.7,688.2,617.6,686.5,617.6,684.3z"/>
      <ellipse className="st0" cx="585.9" cy="684.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M580.7,684.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C578.8,688.2,580.7,686.5,580.7,684.3z"/>
      <path className="st0" d="M567.5,680.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C571.4,682.1,569.7,680.5,567.5,680.5z"/>
      <ellipse className="st0" cx="558.4" cy="684.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M549.1,688.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C545.2,686.5,546.9,688.2,549.1,688.2z"/>
      <ellipse className="st0" cx="540" cy="684.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M530.6,688.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C526.8,686.5,528.4,688.2,530.6,688.2z"/>
      <ellipse className="st0" cx="521.5" cy="684.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M512.2,688.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C508.3,686.5,510.3,688.2,512.2,688.2z"/>
      <ellipse className="st0" cx="503.1" cy="684.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M493.8,688.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C489.9,686.5,491.8,688.2,493.8,688.2z"/>
      <ellipse className="st0" cx="484.7" cy="684.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M479.4,684.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C477.5,688.2,479.4,686.5,479.4,684.3z"/>
      <ellipse className="st0" cx="466.2" cy="684.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M457.2,688.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C453.3,686.5,455,688.2,457.2,688.2z"/>
      <ellipse className="st0" cx="447.8" cy="684.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M438.7,688.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C434.9,686.5,436.5,688.2,438.7,688.2z"/>
      <path className="st0" d="M429.4,688.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C425.5,686.5,427.2,688.2,429.4,688.2z"/>
      <ellipse className="st0" cx="420.3" cy="684.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1175.3,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1173.1,679.1,1175.3,679.1z"/>
      <path className="st0" d="M1166.3,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.1,677.4,1164.1,679.1,1166.3,679.1z"/>
      <path className="st0" d="M1156.9,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1154.7,679.1,1156.9,679.1z"/>
      <path className="st0" d="M1147.8,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1145.6,679.1,1147.8,679.1z"/>
      <path className="st0" d="M1138.5,671.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1142.6,673,1140.7,671.4,1138.5,671.4z"/>
      <path className="st0" d="M1133.2,675.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1131.6,679.1,1133.2,677.4,1133.2,675.2z"/>
      <path className="st0" d="M1059.5,675.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S1059.5,677.4,1059.5,675.2z"/>
      <path className="st0" d="M1050.4,675.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1048.8,679.1,1050.4,677.4,1050.4,675.2z"/>
      <path className="st0" d="M954.4,671.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C958.2,673,956.6,671.4,954.4,671.4z"/>
      <path className="st0" d="M945,671.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C949.2,673,947.2,671.4,945,671.4z"/>
      <path className="st0" d="M936,671.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C939.8,673,938.2,671.4,936,671.4z"/>
      <path className="st0" d="M926.6,671.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C930.7,673,928.8,671.4,926.6,671.4z"/>
      <path className="st0" d="M917.5,671.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C921.4,673,919.7,671.4,917.5,671.4z"/>
      <path className="st0" d="M908.4,671.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C912.3,673,910.4,671.4,908.4,671.4z"/>
      <path className="st0" d="M899.1,671.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C902.9,673,901.3,671.4,899.1,671.4z"/>
      <path className="st0" d="M890,671.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C893.9,673,891.9,671.4,890,671.4z"
        />
      <path className="st0" d="M880.6,671.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C884.5,673,882.8,671.4,880.6,671.4z"/>
      <path className="st0" d="M875.4,675.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C873.8,679.1,875.4,677.4,875.4,675.2z"/>
      <path className="st0" d="M862.2,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S860,679.1,862.2,679.1z"/>
      <path className="st0" d="M853.1,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C849,677.4,850.9,679.1,853.1,679.1z"/>
      <path className="st0" d="M847.6,675.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S847.6,677.4,847.6,675.2z"/>
      <path className="st0" d="M834.7,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S832.5,679.1,834.7,679.1z"/>
      <path className="st0" d="M825.3,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S823.1,679.1,825.3,679.1z"/>
      <path className="st0" d="M816.3,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S814.1,679.1,816.3,679.1z"/>
      <path className="st0" d="M806.9,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S805,679.1,806.9,679.1z"/>
      <path className="st0" d="M797.8,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S795.6,679.1,797.8,679.1z"/>
      <path className="st0" d="M788.5,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C784.6,677.4,786.5,679.1,788.5,679.1z"/>
      <path className="st0" d="M779.4,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S777.2,679.1,779.4,679.1z"/>
      <path className="st0" d="M770.3,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C766.2,677.4,768.1,679.1,770.3,679.1z"
        />
      <path className="st0" d="M760.9,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C757.1,677.4,758.7,679.1,760.9,679.1z"/>
      <path className="st0" d="M751.9,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C747.7,677.4,749.7,679.1,751.9,679.1z"/>
      <path className="st0" d="M742.5,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S740.3,679.1,742.5,679.1z"/>
      <path className="st0" d="M733.4,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C729.3,677.4,731.2,679.1,733.4,679.1z"/>
      <path className="st0" d="M724.1,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S721.9,679.1,724.1,679.1z"/>
      <path className="st0" d="M715,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C710.9,677.4,712.8,679.1,715,679.1
        z"/>
      <path className="st0" d="M659.7,671.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C663.5,673,661.9,671.4,659.7,671.4z"/>
      <path className="st0" d="M650.3,671.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C654.5,673,652.5,671.4,650.3,671.4z"/>
      <path className="st0" d="M641.2,671.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C645.1,673,643.4,671.4,641.2,671.4z"/>
      <path className="st0" d="M631.9,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C628,677.4,630,679.1,631.9,679.1
        z"/>
      <path className="st0" d="M622.8,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S620.6,679.1,622.8,679.1z"/>
      <path className="st0" d="M613.7,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C609.6,677.4,611.5,679.1,613.7,679.1z"/>
      <path className="st0" d="M604.4,671.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C608.2,673,606.6,671.4,604.4,671.4z"/>
      <path className="st0" d="M599.1,675.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C597.2,679.1,599.1,677.4,599.1,675.2z"/>
      <path className="st0" d="M585.9,671.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C589.8,673,588.1,671.4,585.9,671.4z"/>
      <path className="st0" d="M576.9,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C572.7,677.4,574.7,679.1,576.9,679.1z"
        />
      <path className="st0" d="M571.4,675.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C569.7,679.1,571.4,677.4,571.4,675.2z"/>
      <path className="st0" d="M562.3,675.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C560.6,679.1,562.3,677.4,562.3,675.2z"/>
      <path className="st0" d="M549.1,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S546.9,679.1,549.1,679.1z"/>
      <path className="st0" d="M540,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S537.8,679.1,540,679.1z"/>
      <path className="st0" d="M530.6,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S528.4,679.1,530.6,679.1z"/>
      <path className="st0" d="M521.5,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S519.3,679.1,521.5,679.1z"/>
      <path className="st0" d="M512.2,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C508.3,677.4,510.3,679.1,512.2,679.1z"/>
      <path className="st0" d="M507,675.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S507,677.4,507,675.2z"/>
      <path className="st0" d="M493.8,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C489.9,677.4,491.8,679.1,493.8,679.1z"/>
      <path className="st0" d="M488.5,675.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S488.5,677.4,488.5,675.2z"/>
      <path className="st0" d="M479.4,675.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C477.5,679.1,479.4,677.4,479.4,675.2z"/>
      <path className="st0" d="M470.1,675.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S470.1,677.4,470.1,675.2z"/>
      <path className="st0" d="M457.2,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C453,677.4,455,679.1,457.2,679.1
        z"/>
      <path className="st0" d="M447.8,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S445.6,679.1,447.8,679.1z"/>
      <path className="st0" d="M438.7,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C434.6,677.4,436.5,679.1,438.7,679.1z"/>
      <path className="st0" d="M429.4,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S427.2,679.1,429.4,679.1z"/>
      <path className="st0" d="M420.3,679.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C416.2,677.4,418.1,679.1,420.3,679.1z"/>
      <path className="st0" d="M1175.3,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1171.5,668.1,1173.1,670,1175.3,670z"/>
      <path className="st0" d="M1166.3,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.1,668.1,1164.1,670,1166.3,670z"/>
      <path className="st0" d="M1156.9,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,668.1,1154.7,670,1156.9,670z"/>
      <path className="st0" d="M1147.8,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1144,668.1,1145.6,670,1147.8,670z"/>
      <path className="st0" d="M1138.5,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,668.1,1136.3,670,1138.5,670z"/>
      <path className="st0" d="M1129.4,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,668.1,1127.2,670,1129.4,670z"/>
      <path className="st0" d="M1124.2,666.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1122.2,670,1124.2,668.1,1124.2,666.1z"/>
      <path className="st0" d="M1074.1,662c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1077.9,663.9,1076.3,662,1074.1,662z"/>
      <path className="st0" d="M1065,662c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C1068.9,663.9,1066.9,662,1065,662z"
        />
      <path className="st0" d="M1055.6,662c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1059.5,663.9,1057.9,662,1055.6,662z"/>
      <path className="st0" d="M976.7,666.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C975,670,976.7,668.1,976.7,666.1z"/>
      <path className="st0" d="M954.4,662c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C958.2,663.9,956.6,662,954.4,662z"/>
      <path className="st0" d="M945,662c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C949.2,663.9,947.2,662,945,662z
        "/>
      <path className="st0" d="M936,662c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C939.8,663.9,938.2,662,936,662z
        "/>
      <path className="st0" d="M926.6,662c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C930.7,663.9,928.8,662,926.6,662z"
        />
      <path className="st0" d="M917.5,662c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C921.4,663.9,919.7,662,917.5,662z"/>
      <path className="st0" d="M908.4,662c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C912.3,663.9,910.4,662,908.4,662z"/>
      <path className="st0" d="M899.1,662c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C902.9,663.9,901.3,662,899.1,662z"/>
      <path className="st0" d="M890,662c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C893.9,663.9,891.9,662,890,662z"/>
      <path className="st0" d="M880.6,662c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C884.5,663.9,882.8,662,880.6,662z"/>
      <path className="st0" d="M871.6,662c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C875.4,663.9,873.8,662,871.6,662z"/>
      <path className="st0" d="M862.2,662c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C866.1,663.9,864.4,662,862.2,662z"/>
      <path className="st0" d="M853.1,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C849,668.1,850.9,670,853.1,670z"/>
      <path className="st0" d="M847.6,666.1c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S847.6,668.1,847.6,666.1z"/>
      <path className="st0" d="M834.7,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C830.8,668.1,832.5,670,834.7,670z"/>
      <path className="st0" d="M825.3,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,668.1,823.1,670,825.3,670z"/>
      <path className="st0" d="M816.3,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C812.4,668.1,814.1,670,816.3,670z"/>
      <path className="st0" d="M806.9,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C803,668.1,805,670,806.9,670z"/>
      <path className="st0" d="M797.8,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C794,668.1,795.6,670,797.8,670z"/>
      <path className="st0" d="M788.5,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C784.6,668.1,786.5,670,788.5,670z"/>
      <path className="st0" d="M779.4,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C775.5,668.1,777.2,670,779.4,670z"/>
      <path className="st0" d="M770.3,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C766.2,668.1,768.1,670,770.3,670z"
        />
      <path className="st0" d="M760.9,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C757.1,668.1,758.7,670,760.9,670z"/>
      <path className="st0" d="M751.9,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C747.7,668.1,749.7,670,751.9,670z"/>
      <path className="st0" d="M742.5,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C738.7,668.1,740.3,670,742.5,670z"/>
      <path className="st0" d="M733.4,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C729.3,668.1,731.2,670,733.4,670z"/>
      <path className="st0" d="M724.1,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C720.2,668.1,721.9,670,724.1,670z"/>
      <path className="st0" d="M715,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C710.9,668.1,712.8,670,715,670z
        "/>
      <path className="st0" d="M687.2,662c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.3,663.9,689.4,662,687.2,662z"/>
      <path className="st0" d="M659.7,662c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C663.5,663.9,661.9,662,659.7,662z"/>
      <path className="st0" d="M650.3,662c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C654.5,663.9,652.5,662,650.3,662z"/>
      <path className="st0" d="M641.2,662c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C645.1,663.9,643.4,662,641.2,662z"
        />
      <path className="st0" d="M631.9,662c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C636,663.9,634.1,662,631.9,662z"/>
      <path className="st0" d="M622.8,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C619,668.1,620.6,670,622.8,670z"/>
      <path className="st0" d="M613.7,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C609.6,668.1,611.5,670,613.7,670z"/>
      <path className="st0" d="M604.4,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C600.5,668.1,602.2,670,604.4,670z"/>
      <path className="st0" d="M595.3,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.2,668.1,593.1,670,595.3,670z"/>
      <path className="st0" d="M589.8,666.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C588.1,670,589.8,668.1,589.8,666.1z"/>
      <path className="st0" d="M576.9,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C572.7,668.1,574.7,670,576.9,670z"
        />
      <path className="st0" d="M571.4,666.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C569.7,670,571.4,668.1,571.4,666.1z"/>
      <path className="st0" d="M562.3,666.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C560.6,670,562.3,668.1,562.3,666.1z"/>
      <path className="st0" d="M549.1,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C545.2,668.1,546.9,670,549.1,670z"
        />
      <path className="st0" d="M540,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C536.1,668.1,537.8,670,540,670z
        "/>
      <path className="st0" d="M530.6,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C526.8,668.1,528.4,670,530.6,670z"
        />
      <path className="st0" d="M521.5,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C517.7,668.1,519.3,670,521.5,670z"
        />
      <path className="st0" d="M512.2,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C508.3,668.1,510.3,670,512.2,670z"/>
      <path className="st0" d="M507,666.1c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S507,668.1,507,666.1z"/>
      <path className="st0" d="M493.8,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C489.9,668.1,491.8,670,493.8,670z"/>
      <path className="st0" d="M488.5,666.1c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S488.5,668.1,488.5,666.1z"/>
      <path className="st0" d="M479.4,666.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C477.5,670,479.4,668.1,479.4,666.1z"/>
      <path className="st0" d="M466.2,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C462.4,668.1,464,670,466.2,670z"/>
      <path className="st0" d="M457.2,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C453,668.1,455,670,457.2,670z
        "/>
      <path className="st0" d="M447.8,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C443.9,668.1,445.6,670,447.8,670z"
        />
      <path className="st0" d="M438.7,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C434.6,668.1,436.5,670,438.7,670z"/>
      <path className="st0" d="M429.4,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C425.5,668.1,427.2,670,429.4,670z"/>
      <path className="st0" d="M420.3,670c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C416.2,668.1,418.1,670,420.3,670z"/>
      <ellipse className="st0" cx="1175.3" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.4,659,1164.1,660.6,1166.3,660.6z"/>
      <path className="st0" d="M1156.9,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,659,1154.7,660.6,1156.9,660.6z"/>
      <ellipse className="st0" cx="1147.8" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1138.5,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,659,1136.3,660.6,1138.5,660.6z"/>
      <path className="st0" d="M1129.4,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,659,1127.2,660.6,1129.4,660.6z"/>
      <path className="st0" d="M1120,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,659,1117.8,660.6,1120,660.6z"/>
      <ellipse className="st0" cx="1111" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1092.5,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,659,1090.3,660.6,1092.5,660.6z"/>
      <path className="st0" d="M1083.2,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1079.3,659,1081.2,660.6,1083.2,660.6z"/>
      <ellipse className="st0" cx="1074.1" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1065,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1061.2,659,1062.8,660.6,1065,660.6z"/>
      <path className="st0" d="M1055.6,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,659,1053.4,660.6,1055.6,660.6z"/>
      <ellipse className="st0" cx="1046.6" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1037.2,652.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1041.1,654.6,1039.4,652.9,1037.2,652.9z"/>
      <ellipse className="st0" cx="972.8" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M963.5,652.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C967.3,654.6,965.7,652.9,963.5,652.9z"/>
      <path className="st0" d="M954.4,652.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C958.2,654.6,956.6,652.9,954.4,652.9z"/>
      <path className="st0" d="M945,652.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C948.9,654.6,947.2,652.9,945,652.9z"/>
      <ellipse className="st0" cx="936" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M926.6,652.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C930.4,654.6,928.8,652.9,926.6,652.9z"/>
      <path className="st0" d="M917.5,652.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C921.4,654.6,919.7,652.9,917.5,652.9z"/>
      <ellipse className="st0" cx="908.4" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M890,652.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C893.9,654.6,891.9,652.9,890,652.9z
        "/>
      <path className="st0" d="M880.6,652.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C884.5,654.6,882.8,652.9,880.6,652.9z"/>
      <ellipse className="st0" cx="871.6" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M862.2,652.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C866.1,654.6,864.4,652.9,862.2,652.9z"/>
      <path className="st0" d="M853.1,652.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C857,654.6,855.3,652.9,853.1,652.9z"/>
      <path className="st0" d="M847.6,656.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S847.6,659,847.6,656.8z"/>
      <ellipse className="st0" cx="834.7" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M816.3,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C812.4,659,814.1,660.6,816.3,660.6z"/>
      <path className="st0" d="M806.9,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C803,659,805,660.6,806.9,660.6z"
        />
      <ellipse className="st0" cx="797.8" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M788.5,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C784.6,659,786.5,660.6,788.5,660.6z"/>
      <path className="st0" d="M779.4,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C775.5,659,777.2,660.6,779.4,660.6z"/>
      <path className="st0" d="M770.3,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C766.4,659,768.1,660.6,770.3,660.6z"/>
      <ellipse className="st0" cx="760.9" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M751.9,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C748,659,749.7,660.6,751.9,660.6z"/>
      <path className="st0" d="M742.5,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C738.7,659,740.3,660.6,742.5,660.6z"/>
      <path className="st0" d="M733.4,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C729.6,659,731.2,660.6,733.4,660.6z"/>
      <ellipse className="st0" cx="724.1" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M715,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C711.1,659,712.8,660.6,715,660.6z"/>
      <path className="st0" d="M709.5,656.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C707.8,660.6,709.5,659,709.5,656.8z"/>
      <ellipse className="st0" cx="696.6" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M687.2,652.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.1,654.6,689.4,652.9,687.2,652.9z"/>
      <ellipse className="st0" cx="659.7" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M650.3,652.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C654.2,654.6,652.5,652.9,650.3,652.9z"/>
      <ellipse className="st0" cx="641.2" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M631.9,652.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C635.7,654.6,634.1,652.9,631.9,652.9z"/>
      <ellipse className="st0" cx="622.8" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M613.7,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C609.9,659,611.5,660.6,613.7,660.6z"/>
      <ellipse className="st0" cx="604.4" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M595.3,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.4,659,593.1,660.6,595.3,660.6z"/>
      <ellipse className="st0" cx="585.9" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M576.9,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C573,659,574.7,660.6,576.9,660.6z
        "/>
      <path className="st0" d="M571.4,656.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C569.7,660.6,571.4,659,571.4,656.8z"/>
      <ellipse className="st0" cx="558.4" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M549.1,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C545.2,659,546.9,660.6,549.1,660.6z"/>
      <ellipse className="st0" cx="540" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M530.6,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C526.8,659,528.4,660.6,530.6,660.6z"/>
      <ellipse className="st0" cx="521.5" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M512.2,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C508.3,659,510.3,660.6,512.2,660.6z"/>
      <ellipse className="st0" cx="503.1" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M493.8,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C489.9,659,491.8,660.6,493.8,660.6z"/>
      <ellipse className="st0" cx="484.7" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M479.4,656.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C477.5,660.6,479.4,659,479.4,656.8z"/>
      <ellipse className="st0" cx="466.2" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M457.2,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C453.3,659,455,660.6,457.2,660.6z"/>
      <ellipse className="st0" cx="447.8" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M438.7,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C434.9,659,436.5,660.6,438.7,660.6z"/>
      <path className="st0" d="M429.4,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C425.5,659,427.2,660.6,429.4,660.6z"/>
      <ellipse className="st0" cx="420.3" cy="656.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1171.5,647.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1171.5,645.5,1171.5,647.7z"/>
      <path className="st0" d="M1162.1,647.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1164.1,643.9,1162.1,645.5,1162.1,647.7z"/>
      <path className="st0" d="M1153.1,647.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1154.7,643.9,1153.1,645.5,1153.1,647.7z"/>
      <path className="st0" d="M1144,647.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1145.6,643.9,1144,645.5,1144,647.7z"/>
      <path className="st0" d="M1134.6,647.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1136.3,643.9,1134.6,645.5,1134.6,647.7z"/>
      <path className="st0" d="M1129.4,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,649.9,1127.2,651.6,1129.4,651.6z"/>
      <path className="st0" d="M1070.2,647.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1070.2,645.5,1070.2,647.7z"/>
      <path className="st0" d="M1065,643.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1068.9,645.5,1066.9,643.9,1065,643.9z"/>
      <path className="st0" d="M1055.6,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,649.9,1053.4,651.6,1055.6,651.6z"/>
      <path className="st0" d="M1046.6,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,649.9,1044.4,651.6,1046.6,651.6z"/>
      <path className="st0" d="M1033.4,647.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1035,643.9,1033.4,645.5,1033.4,647.7z"/>
      <path className="st0" d="M1028.1,643.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1032,645.5,1030.1,643.9,1028.1,643.9z"/>
      <path className="st0" d="M972.8,643.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C976.7,645.5,975,643.9,972.8,643.9z"/>
      <path className="st0" d="M963.5,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C959.6,649.9,961.5,651.6,963.5,651.6z"/>
      <path className="st0" d="M958.2,647.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C956.6,651.6,958.2,649.9,958.2,647.7z"/>
      <path className="st0" d="M949.2,647.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,651.6,949.2,649.9,949.2,647.7z"/>
      <path className="st0" d="M939.8,647.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C938.2,651.6,939.8,649.9,939.8,647.7z"/>
      <path className="st0" d="M921.4,647.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C919.7,651.6,921.4,649.9,921.4,647.7z"/>
      <path className="st0" d="M912.3,647.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C910.6,651.6,912.3,649.9,912.3,647.7z"/>
      <path className="st0" d="M902.9,647.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C901.3,651.6,902.9,649.9,902.9,647.7z"/>
      <path className="st0" d="M893.9,647.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C891.9,651.6,893.9,649.9,893.9,647.7z"/>
      <path className="st0" d="M884.5,647.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C882.8,651.6,884.5,649.9,884.5,647.7z"/>
      <path className="st0" d="M875.4,647.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C873.8,651.6,875.4,649.9,875.4,647.7z"/>
      <path className="st0" d="M866.1,647.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C864.4,651.6,866.1,649.9,866.1,647.7z"/>
      <path className="st0" d="M857,647.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C855.3,651.6,857,649.9,857,647.7z"/>
      <path className="st0" d="M847.6,647.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S847.6,649.9,847.6,647.7z"/>
      <path className="st0" d="M834.7,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C830.8,649.9,832.5,651.6,834.7,651.6z"/>
      <path className="st0" d="M825.3,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,649.9,823.1,651.6,825.3,651.6z"/>
      <path className="st0" d="M816.3,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C812.4,649.9,814.1,651.6,816.3,651.6z"/>
      <path className="st0" d="M806.9,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C803,649.9,805,651.6,806.9,651.6z
        "/>
      <path className="st0" d="M797.8,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C794,649.9,795.6,651.6,797.8,651.6z"/>
      <path className="st0" d="M788.5,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C784.6,649.9,786.5,651.6,788.5,651.6z"/>
      <path className="st0" d="M779.4,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C775.5,649.9,777.2,651.6,779.4,651.6z"/>
      <path className="st0" d="M770.3,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C766.2,649.9,768.1,651.6,770.3,651.6z"/>
      <path className="st0" d="M760.9,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C757.1,649.9,758.7,651.6,760.9,651.6z"/>
      <path className="st0" d="M751.9,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C747.7,649.9,749.7,651.6,751.9,651.6z"/>
      <path className="st0" d="M742.5,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C738.7,649.9,740.3,651.6,742.5,651.6z"/>
      <path className="st0" d="M733.4,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C729.3,649.9,731.2,651.6,733.4,651.6z"/>
      <path className="st0" d="M724.1,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C720.2,649.9,721.9,651.6,724.1,651.6z"/>
      <path className="st0" d="M715,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C710.9,649.9,712.8,651.6,715,651.6z"/>
      <path className="st0" d="M705.6,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C701.8,649.9,703.4,651.6,705.6,651.6z"/>
      <path className="st0" d="M696.6,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C692.7,649.9,694.4,651.6,696.6,651.6z"/>
      <path className="st0" d="M668.8,643.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C672.9,645.5,671,643.9,668.8,643.9z"/>
      <path className="st0" d="M659.7,643.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C663.5,645.5,661.9,643.9,659.7,643.9z"/>
      <path className="st0" d="M650.3,643.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C654.5,645.5,652.5,643.9,650.3,643.9z"/>
      <path className="st0" d="M641.2,643.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C645.1,645.5,643.4,643.9,641.2,643.9z"/>
      <path className="st0" d="M631.9,643.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C636,645.5,634.1,643.9,631.9,643.9z"/>
      <path className="st0" d="M622.8,643.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C626.7,645.5,625,643.9,622.8,643.9z"/>
      <path className="st0" d="M613.7,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C609.6,649.9,611.5,651.6,613.7,651.6z"/>
      <path className="st0" d="M604.4,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C600.5,649.9,602.2,651.6,604.4,651.6z"/>
      <path className="st0" d="M595.3,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.2,649.9,593.1,651.6,595.3,651.6z"/>
      <path className="st0" d="M589.8,647.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C588.1,651.6,589.8,649.9,589.8,647.7z"/>
      <path className="st0" d="M576.9,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C572.7,649.9,574.7,651.6,576.9,651.6z"/>
      <path className="st0" d="M571.4,647.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C569.7,651.6,571.4,649.9,571.4,647.7z"/>
      <path className="st0" d="M562.3,647.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C560.6,651.6,562.3,649.9,562.3,647.7z"/>
      <path className="st0" d="M549.1,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C545.2,649.9,546.9,651.6,549.1,651.6z"/>
      <path className="st0" d="M540,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C536.1,649.9,537.8,651.6,540,651.6z"/>
      <path className="st0" d="M530.6,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C526.8,649.9,528.4,651.6,530.6,651.6z"/>
      <path className="st0" d="M521.5,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C517.7,649.9,519.3,651.6,521.5,651.6z"/>
      <path className="st0" d="M512.2,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C508.3,649.9,510.3,651.6,512.2,651.6z"/>
      <path className="st0" d="M507,647.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S507,649.9,507,647.7z"/>
      <path className="st0" d="M493.8,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C489.9,649.9,491.8,651.6,493.8,651.6z"/>
      <path className="st0" d="M488.5,647.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S488.5,649.9,488.5,647.7z"/>
      <path className="st0" d="M475.3,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C471.5,649.9,473.4,651.6,475.3,651.6z"/>
      <path className="st0" d="M466.2,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C462.4,649.9,464,651.6,466.2,651.6z"/>
      <path className="st0" d="M457.2,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C453,649.9,455,651.6,457.2,651.6z"/>
      <path className="st0" d="M447.8,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C443.9,649.9,445.6,651.6,447.8,651.6z"/>
      <path className="st0" d="M438.7,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C434.6,649.9,436.5,651.6,438.7,651.6z"/>
      <path className="st0" d="M429.4,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C425.5,649.9,427.2,651.6,429.4,651.6z"/>
      <path className="st0" d="M420.3,651.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C416.2,649.9,418.1,651.6,420.3,651.6z"/>
      <ellipse className="st0" cx="1175.3" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.4,640.8,1164.1,642.5,1166.3,642.5z"/>
      <path className="st0" d="M1156.9,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,640.8,1154.7,642.5,1156.9,642.5z"/>
      <ellipse className="st0" cx="1147.8" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1138.5,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,640.8,1136.3,642.5,1138.5,642.5z"/>
      <path className="st0" d="M1129.4,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,640.8,1127.2,642.5,1129.4,642.5z"/>
      <path className="st0" d="M1065,634.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1068.9,636.1,1066.9,634.5,1065,634.5z"/>
      <path className="st0" d="M1055.6,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,640.8,1053.4,642.5,1055.6,642.5z"/>
      <path className="st0" d="M986,638.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C984.1,642.5,986,640.6,986,638.3z"/>
      <ellipse className="st0" cx="972.8" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M963.5,634.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C967.3,636.1,965.7,634.5,963.5,634.5z"/>
      <path className="st0" d="M958.2,638.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C956.6,642.5,958.2,640.6,958.2,638.3z"/>
      <path className="st0" d="M949.2,638.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,642.5,949.2,640.6,949.2,638.3z"/>
      <ellipse className="st0" cx="936" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M930.7,638.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C928.8,642.5,930.7,640.6,930.7,638.3z"/>
      <path className="st0" d="M921.4,638.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C919.7,642.5,921.4,640.6,921.4,638.3z"/>
      <ellipse className="st0" cx="908.4" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M902.9,638.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C901.3,642.5,902.9,640.6,902.9,638.3z"/>
      <path className="st0" d="M893.9,638.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C891.9,642.5,893.9,640.6,893.9,638.3z"/>
      <path className="st0" d="M884.5,638.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C882.8,642.5,884.5,640.6,884.5,638.3z"/>
      <ellipse className="st0" cx="871.6" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M866.1,638.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C864.4,642.5,866.1,640.6,866.1,638.3z"/>
      <path className="st0" d="M857,638.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C855.3,642.5,857,640.6,857,638.3z"/>
      <path className="st0" d="M847.6,638.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C846,642.5,847.6,640.6,847.6,638.3z"/>
      <ellipse className="st0" cx="834.7" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M825.3,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,640.8,823.1,642.5,825.3,642.5z"/>
      <path className="st0" d="M816.3,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C812.4,640.8,814.1,642.5,816.3,642.5z"/>
      <path className="st0" d="M806.9,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C803,640.8,805,642.5,806.9,642.5z
        "/>
      <ellipse className="st0" cx="797.8" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M788.5,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C784.6,640.8,786.5,642.5,788.5,642.5z"/>
      <path className="st0" d="M779.4,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C775.5,640.8,777.2,642.5,779.4,642.5z"/>
      <path className="st0" d="M770.3,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C766.4,640.8,768.1,642.5,770.3,642.5z"/>
      <ellipse className="st0" cx="760.9" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M751.9,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C748,640.8,749.7,642.5,751.9,642.5z"/>
      <path className="st0" d="M742.5,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C738.7,640.8,740.3,642.5,742.5,642.5z"/>
      <path className="st0" d="M733.4,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C729.6,640.8,731.2,642.5,733.4,642.5z"/>
      <ellipse className="st0" cx="724.1" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M715,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C711.1,640.8,712.8,642.5,715,642.5z"/>
      <path className="st0" d="M705.6,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C701.8,640.8,703.4,642.5,705.6,642.5z"/>
      <ellipse className="st0" cx="696.6" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M687.2,634.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.1,636.1,689.4,634.5,687.2,634.5z"/>
      <path className="st0" d="M678.1,634.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C682,636.1,680.3,634.5,678.1,634.5z"/>
      <path className="st0" d="M668.8,634.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C672.6,636.1,671,634.5,668.8,634.5z"/>
      <ellipse className="st0" cx="659.7" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M654.5,638.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C652.5,642.5,654.5,640.6,654.5,638.3z"/>
      <ellipse className="st0" cx="641.2" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M636,638.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C634.1,642.5,636,640.6,636,638.3z"
        />
      <ellipse className="st0" cx="622.8" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M613.7,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C609.9,640.8,611.5,642.5,613.7,642.5z"/>
      <ellipse className="st0" cx="604.4" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M595.3,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.4,640.8,593.1,642.5,595.3,642.5z"/>
      <ellipse className="st0" cx="585.9" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M576.9,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C573,640.8,574.7,642.5,576.9,642.5z"/>
      <path className="st0" d="M571.4,638.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C569.7,642.5,571.4,640.6,571.4,638.3z"/>
      <ellipse className="st0" cx="558.4" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M549.1,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C545.2,640.8,546.9,642.5,549.1,642.5z"/>
      <ellipse className="st0" cx="540" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M530.6,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C526.8,640.8,528.4,642.5,530.6,642.5z"/>
      <ellipse className="st0" cx="521.5" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M512.2,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C508.3,640.8,510.3,642.5,512.2,642.5z"/>
      <ellipse className="st0" cx="503.1" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M493.8,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C489.9,640.8,491.8,642.5,493.8,642.5z"/>
      <ellipse className="st0" cx="484.7" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M475.3,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C471.5,640.8,473.4,642.5,475.3,642.5z"/>
      <ellipse className="st0" cx="466.2" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M457.2,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C453.3,640.8,455,642.5,457.2,642.5z"/>
      <ellipse className="st0" cx="447.8" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M438.7,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C434.9,640.8,436.5,642.5,438.7,642.5z"/>
      <path className="st0" d="M429.4,642.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C425.5,640.8,427.2,642.5,429.4,642.5z"/>
      <ellipse className="st0" cx="420.3" cy="638.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1171.5,629.3c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1171.5,627.1,1171.5,629.3z"/>
      <path className="st0" d="M1162.1,629.3c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1164.1,625.4,1162.1,627.1,1162.1,629.3z"/>
      <path className="st0" d="M1153.1,629.3c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1154.7,625.4,1153.1,627.1,1153.1,629.3z"/>
      <path className="st0" d="M1144,629.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1145.6,625.4,1144,627.1,1144,629.3z"/>
      <path className="st0" d="M1138.5,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,631.5,1136.3,633.1,1138.5,633.1z"/>
      <path className="st0" d="M1065,625.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1068.9,627.1,1066.9,625.4,1065,625.4z"/>
      <path className="st0" d="M1055.6,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,631.5,1053.4,633.1,1055.6,633.1z"/>
      <path className="st0" d="M1050.4,629.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1048.8,633.1,1050.4,631.5,1050.4,629.3z"/>
      <path className="st0" d="M1037.2,625.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1041.1,627.1,1039.4,625.4,1037.2,625.4z"/>
      <path className="st0" d="M1028.1,625.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1032,627.1,1030.1,625.4,1028.1,625.4z"/>
      <path className="st0" d="M1022.6,629.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1021,633.1,1022.6,631.5,1022.6,629.3z"/>
      <path className="st0" d="M991.3,625.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C995.1,627.1,993.5,625.4,991.3,625.4z"/>
      <path className="st0" d="M981.9,625.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C986,627.1,984.1,625.4,981.9,625.4z"/>
      <path className="st0" d="M976.7,629.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C975,633.1,976.7,631.5,976.7,629.3z"/>
      <path className="st0" d="M967.6,629.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C965.7,633.1,967.6,631.5,967.6,629.3z"/>
      <path className="st0" d="M958.2,629.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C956.6,633.1,958.2,631.5,958.2,629.3z"/>
      <path className="st0" d="M949.2,629.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,633.1,949.2,631.5,949.2,629.3z"/>
      <path className="st0" d="M939.8,629.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C938.2,633.1,939.8,631.5,939.8,629.3z"/>
      <path className="st0" d="M930.7,629.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C928.8,633.1,930.7,631.5,930.7,629.3z"/>
      <path className="st0" d="M921.4,629.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C919.7,633.1,921.4,631.5,921.4,629.3z"/>
      <path className="st0" d="M912.3,629.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C910.6,633.1,912.3,631.5,912.3,629.3z"/>
      <path className="st0" d="M902.9,629.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C901.3,633.1,902.9,631.5,902.9,629.3z"/>
      <path className="st0" d="M893.9,629.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C891.9,633.1,893.9,631.5,893.9,629.3z"/>
      <path className="st0" d="M884.5,629.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C882.8,633.1,884.5,631.5,884.5,629.3z"/>
      <path className="st0" d="M875.4,629.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C873.8,633.1,875.4,631.5,875.4,629.3z"/>
      <path className="st0" d="M866.1,629.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C864.4,633.1,866.1,631.5,866.1,629.3z"/>
      <path className="st0" d="M857,629.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C855.3,633.1,857,631.5,857,629.3z"/>
      <path className="st0" d="M847.6,629.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S847.6,631.5,847.6,629.3z"/>
      <path className="st0" d="M834.7,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C830.8,631.5,832.5,633.1,834.7,633.1z"/>
      <path className="st0" d="M825.3,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,631.5,823.1,633.1,825.3,633.1z"/>
      <path className="st0" d="M816.3,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C812.4,631.5,814.1,633.1,816.3,633.1z"/>
      <path className="st0" d="M806.9,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C803,631.5,805,633.1,806.9,633.1z
        "/>
      <path className="st0" d="M797.8,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C794,631.5,795.6,633.1,797.8,633.1z"/>
      <path className="st0" d="M788.5,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C784.6,631.5,786.5,633.1,788.5,633.1z"/>
      <path className="st0" d="M779.4,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C775.5,631.5,777.2,633.1,779.4,633.1z"/>
      <path className="st0" d="M770.3,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C766.2,631.5,768.1,633.1,770.3,633.1z"/>
      <path className="st0" d="M760.9,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C757.1,631.5,758.7,633.1,760.9,633.1z"/>
      <path className="st0" d="M751.9,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C747.7,631.5,749.7,633.1,751.9,633.1z"/>
      <path className="st0" d="M742.5,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C738.7,631.5,740.3,633.1,742.5,633.1z"/>
      <path className="st0" d="M733.4,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C729.3,631.5,731.2,633.1,733.4,633.1z"/>
      <path className="st0" d="M724.1,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C720.2,631.5,721.9,633.1,724.1,633.1z"/>
      <path className="st0" d="M715,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C710.9,631.5,712.8,633.1,715,633.1z"/>
      <path className="st0" d="M705.6,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C701.8,631.5,703.4,633.1,705.6,633.1z"/>
      <path className="st0" d="M696.6,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C692.7,631.5,694.4,633.1,696.6,633.1z"/>
      <path className="st0" d="M687.2,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C683.3,631.5,685,633.1,687.2,633.1z"/>
      <path className="st0" d="M678.1,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,631.5,675.9,633.1,678.1,633.1z"/>
      <path className="st0" d="M672.9,629.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C671,633.1,672.9,631.5,672.9,629.3z"/>
      <path className="st0" d="M663.5,629.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C661.9,633.1,663.5,631.5,663.5,629.3z"/>
      <path className="st0" d="M654.5,629.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C652.5,633.1,654.5,631.5,654.5,629.3z"/>
      <path className="st0" d="M645.1,629.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C643.4,633.1,645.1,631.5,645.1,629.3z"/>
      <path className="st0" d="M636,629.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C634.1,633.1,636,631.5,636,629.3z"
        />
      <path className="st0" d="M622.8,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C619,631.5,620.6,633.1,622.8,633.1z"/>
      <path className="st0" d="M613.7,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C609.6,631.5,611.5,633.1,613.7,633.1z"/>
      <path className="st0" d="M604.4,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C600.5,631.5,602.2,633.1,604.4,633.1z"/>
      <path className="st0" d="M595.3,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.2,631.5,593.1,633.1,595.3,633.1z"/>
      <path className="st0" d="M589.8,629.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C588.1,633.1,589.8,631.5,589.8,629.3z"/>
      <path className="st0" d="M576.9,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C572.7,631.5,574.7,633.1,576.9,633.1z"/>
      <path className="st0" d="M571.4,629.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C569.7,633.1,571.4,631.5,571.4,629.3z"/>
      <path className="st0" d="M562.3,629.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C560.6,633.1,562.3,631.5,562.3,629.3z"/>
      <path className="st0" d="M549.1,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C545.2,631.5,546.9,633.1,549.1,633.1z"/>
      <path className="st0" d="M540,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C536.1,631.5,537.8,633.1,540,633.1z"/>
      <path className="st0" d="M530.6,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C526.8,631.5,528.4,633.1,530.6,633.1z"/>
      <path className="st0" d="M521.5,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C517.7,631.5,519.3,633.1,521.5,633.1z"/>
      <path className="st0" d="M512.2,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C508.3,631.5,510.3,633.1,512.2,633.1z"/>
      <path className="st0" d="M507,629.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S507,631.5,507,629.3z"/>
      <path className="st0" d="M493.8,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C489.9,631.5,491.8,633.1,493.8,633.1z"/>
      <path className="st0" d="M484.7,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C480.8,631.5,482.5,633.1,484.7,633.1z"/>
      <path className="st0" d="M475.3,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C471.5,631.5,473.4,633.1,475.3,633.1z"/>
      <path className="st0" d="M466.2,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C462.4,631.5,464,633.1,466.2,633.1z"/>
      <path className="st0" d="M457.2,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C453,631.5,455,633.1,457.2,633.1z"/>
      <path className="st0" d="M447.8,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C443.9,631.5,445.6,633.1,447.8,633.1z"/>
      <path className="st0" d="M438.7,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C434.6,631.5,436.5,633.1,438.7,633.1z"/>
      <path className="st0" d="M429.4,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C425.5,631.5,427.2,633.1,429.4,633.1z"/>
      <path className="st0" d="M420.3,633.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C416.2,631.5,418.1,633.1,420.3,633.1z"/>
      <path className="st0" d="M1175.3,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1171.5,622.1,1173.1,624,1175.3,624z"/>
      <path className="st0" d="M1166.3,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.1,622.1,1164.1,624,1166.3,624z"/>
      <path className="st0" d="M1156.9,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,622.1,1154.7,624,1156.9,624z"/>
      <path className="st0" d="M1147.8,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1144,622.1,1145.6,624,1147.8,624z"/>
      <path className="st0" d="M1134.6,620.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1136.3,616.1,1134.6,618,1134.6,620.2z"/>
      <path className="st0" d="M1133.2,620.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1131.6,624,1133.2,622.1,1133.2,620.2z"/>
      <path className="st0" d="M1087.3,620.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1085.4,624,1087.3,622.1,1087.3,620.2z"/>
      <path className="st0" d="M1074.1,616.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1077.9,618,1076.3,616.1,1074.1,616.1z"/>
      <path className="st0" d="M1065,616.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1068.9,618,1066.9,616.1,1065,616.1z"/>
      <path className="st0" d="M1055.6,616.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1059.5,618,1057.9,616.1,1055.6,616.1z"/>
      <path className="st0" d="M1046.6,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,622.1,1044.4,624,1046.6,624z"/>
      <path className="st0" d="M1037.2,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C1033.4,622.1,1035,624,1037.2,624z
        "/>
      <path className="st0" d="M1028.1,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,622.1,1025.9,624,1028.1,624z"/>
      <path className="st0" d="M1000.3,616.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1004.2,618,1002.5,616.1,1000.3,616.1z"/>
      <path className="st0" d="M991.3,616.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C995.1,618,993.5,616.1,991.3,616.1z"/>
      <path className="st0" d="M986,620.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C984.1,624,986,622.1,986,620.2z"/>
      <path className="st0" d="M976.7,620.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C975,624,976.7,622.1,976.7,620.2z"/>
      <path className="st0" d="M967.6,620.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C965.7,624,967.6,622.1,967.6,620.2z"/>
      <path className="st0" d="M958.2,620.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C956.6,624,958.2,622.1,958.2,620.2z"/>
      <path className="st0" d="M949.2,620.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,624,949.2,622.1,949.2,620.2z"/>
      <path className="st0" d="M939.8,620.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C938.2,624,939.8,622.1,939.8,620.2z"/>
      <path className="st0" d="M930.7,620.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C928.8,624,930.7,622.1,930.7,620.2z"/>
      <path className="st0" d="M921.4,620.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C919.7,624,921.4,622.1,921.4,620.2z"/>
      <path className="st0" d="M912.3,620.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C910.6,624,912.3,622.1,912.3,620.2z"/>
      <path className="st0" d="M902.9,620.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C901.3,624,902.9,622.1,902.9,620.2z"/>
      <path className="st0" d="M893.9,620.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C891.9,624,893.9,622.1,893.9,620.2z"/>
      <path className="st0" d="M884.5,620.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C882.8,624,884.5,622.1,884.5,620.2z"/>
      <path className="st0" d="M875.4,620.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C873.8,624,875.4,622.1,875.4,620.2z"/>
      <path className="st0" d="M866.1,620.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C864.4,624,866.1,622.1,866.1,620.2z"/>
      <path className="st0" d="M857,620.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C855.3,624,857,622.1,857,620.2z"/>
      <path className="st0" d="M847.6,620.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S847.6,622.1,847.6,620.2z"/>
      <path className="st0" d="M834.7,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C830.8,622.1,832.5,624,834.7,624z"/>
      <path className="st0" d="M825.3,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,622.1,823.1,624,825.3,624z"/>
      <path className="st0" d="M816.3,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C812.4,622.1,814.1,624,816.3,624z"/>
      <path className="st0" d="M806.9,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C803,622.1,805,624,806.9,624z"/>
      <path className="st0" d="M797.8,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C794,622.1,795.6,624,797.8,624z"/>
      <path className="st0" d="M788.5,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C784.6,622.1,786.5,624,788.5,624z"/>
      <path className="st0" d="M779.4,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C775.5,622.1,777.2,624,779.4,624z"/>
      <path className="st0" d="M760.9,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C757.1,622.1,758.7,624,760.9,624z"/>
      <path className="st0" d="M751.9,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C747.7,622.1,749.7,624,751.9,624z"/>
      <path className="st0" d="M742.5,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C738.7,622.1,740.3,624,742.5,624z"/>
      <path className="st0" d="M733.4,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C729.3,622.1,731.2,624,733.4,624z"/>
      <path className="st0" d="M724.1,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C720.2,622.1,721.9,624,724.1,624z"/>
      <path className="st0" d="M715,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C710.9,622.1,712.8,624,715,624z
        "/>
      <path className="st0" d="M705.6,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C701.8,622.1,703.4,624,705.6,624z"/>
      <path className="st0" d="M696.6,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C692.7,622.1,694.4,624,696.6,624z"/>
      <path className="st0" d="M687.2,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C683.3,622.1,685,624,687.2,624z"/>
      <path className="st0" d="M678.1,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C674.3,622.1,675.9,624,678.1,624z"
        />
      <path className="st0" d="M672.9,620.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C671,624,672.9,622.1,672.9,620.2z"/>
      <path className="st0" d="M663.5,620.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C661.9,624,663.5,622.1,663.5,620.2z"/>
      <path className="st0" d="M654.5,620.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C652.5,624,654.5,622.1,654.5,620.2z"/>
      <path className="st0" d="M645.1,620.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C643.4,624,645.1,622.1,645.1,620.2z"/>
      <path className="st0" d="M636,620.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C634.1,624,636,622.1,636,620.2z"/>
      <path className="st0" d="M622.8,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C619,622.1,620.6,624,622.8,624z"/>
      <path className="st0" d="M613.7,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C609.6,622.1,611.5,624,613.7,624z"/>
      <path className="st0" d="M604.4,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C600.5,622.1,602.2,624,604.4,624z"/>
      <path className="st0" d="M595.3,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.2,622.1,593.1,624,595.3,624z"/>
      <path className="st0" d="M589.8,620.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C588.1,624,589.8,622.1,589.8,620.2z"/>
      <path className="st0" d="M576.9,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C572.7,622.1,574.7,624,576.9,624z"
        />
      <path className="st0" d="M571.4,620.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C569.7,624,571.4,622.1,571.4,620.2z"/>
      <path className="st0" d="M562.3,620.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C560.6,624,562.3,622.1,562.3,620.2z"/>
      <path className="st0" d="M549.1,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C545.2,622.1,546.9,624,549.1,624z"
        />
      <path className="st0" d="M540,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C536.1,622.1,537.8,624,540,624z
        "/>
      <path className="st0" d="M530.6,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C526.8,622.1,528.4,624,530.6,624z"
        />
      <path className="st0" d="M521.5,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C517.7,622.1,519.3,624,521.5,624z"
        />
      <path className="st0" d="M512.2,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C508.3,622.1,510.3,624,512.2,624z"/>
      <path className="st0" d="M507,620.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S507,622.1,507,620.2z"/>
      <path className="st0" d="M493.8,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C489.9,622.1,491.8,624,493.8,624z"/>
      <path className="st0" d="M484.7,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C480.8,622.1,482.5,624,484.7,624z"
        />
      <path className="st0" d="M475.3,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C471.5,622.1,473.4,624,475.3,624z"/>
      <path className="st0" d="M466.2,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C462.4,622.1,464,624,466.2,624z"/>
      <path className="st0" d="M457.2,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C453,622.1,455,624,457.2,624z
        "/>
      <path className="st0" d="M447.8,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C443.9,622.1,445.6,624,447.8,624z"
        />
      <path className="st0" d="M438.7,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C434.6,622.1,436.5,624,438.7,624z"/>
      <path className="st0" d="M429.4,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C425.5,622.1,427.2,624,429.4,624z"/>
      <path className="st0" d="M420.3,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C416.2,622.1,418.1,624,420.3,624z"/>
      <path className="st0" d="M410.9,616.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C414.8,618,413.1,616.1,410.9,616.1z"/>
      <ellipse className="st0" cx="1175.3" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1164.1,614.7,1166.3,614.7z"/>
      <path className="st0" d="M1156.9,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1154.7,614.7,1156.9,614.7z"/>
      <ellipse className="st0" cx="1147.8" cy="610.8" rx="3.9" ry="3.9"/>
      <ellipse className="st0" cx="1111" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1101.6,607c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1105.5,608.6,1103.8,607,1101.6,607z"/>
      <path className="st0" d="M1096.4,610.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1094.7,614.7,1096.4,613,1096.4,610.8z"/>
      <path className="st0" d="M1083.2,607c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C1087,608.6,1085.4,607,1083.2,607z
        "/>
      <ellipse className="st0" cx="1074.1" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1068.9,610.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1066.9,614.7,1068.9,613,1068.9,610.8z"/>
      <path className="st0" d="M1055.6,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1053.4,614.7,1055.6,614.7z"/>
      <ellipse className="st0" cx="1046.6" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1037.2,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1035,614.7,1037.2,614.7z"/>
      <path className="st0" d="M1028.1,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1025.9,614.7,1028.1,614.7z"/>
      <path className="st0" d="M1022.6,610.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1021,614.7,1022.6,613,1022.6,610.8z"/>
      <ellipse className="st0" cx="1009.7" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1000.3,607c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1004.2,608.6,1002.5,607,1000.3,607z"/>
      <path className="st0" d="M995.1,610.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C993.5,614.7,995.1,613,995.1,610.8z"/>
      <path className="st0" d="M986,610.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C984.1,614.7,986,613,986,610.8z"/>
      <ellipse className="st0" cx="972.8" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M967.6,610.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C965.7,614.7,967.6,613,967.6,610.8z"/>
      <path className="st0" d="M958.2,610.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C956.6,614.7,958.2,613,958.2,610.8z"/>
      <path className="st0" d="M949.2,610.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,614.7,949.2,613,949.2,610.8z"/>
      <ellipse className="st0" cx="936" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M930.7,610.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C928.8,614.7,930.7,613,930.7,610.8z"/>
      <path className="st0" d="M921.4,610.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C919.7,614.7,921.4,613,921.4,610.8z"/>
      <ellipse className="st0" cx="908.4" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M902.9,610.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C901.3,614.7,902.9,613,902.9,610.8z"/>
      <path className="st0" d="M893.9,610.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C891.9,614.7,893.9,613,893.9,610.8z"/>
      <path className="st0" d="M884.5,610.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C882.8,614.7,884.5,613,884.5,610.8z"/>
      <ellipse className="st0" cx="871.6" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M866.1,610.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C864.4,614.7,866.1,613,866.1,610.8z"/>
      <path className="st0" d="M857,610.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C855.3,614.7,857,613,857,610.8z"/>
      <path className="st0" d="M847.6,610.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C846,614.7,847.6,613,847.6,610.8z
        "/>
      <ellipse className="st0" cx="834.7" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M821.5,610.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C823.1,607,821.5,608.6,821.5,610.8z"/>
      <path className="st0" d="M816.3,607c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C820.1,608.6,818.5,607,816.3,607z"/>
      <path className="st0" d="M806.9,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S805,614.7,806.9,614.7z"/>
      <ellipse className="st0" cx="797.8" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M788.5,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S786.5,614.7,788.5,614.7z"/>
      <path className="st0" d="M779.4,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S777.2,614.7,779.4,614.7z"/>
      <path className="st0" d="M770.3,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S768.1,614.7,770.3,614.7z"/>
      <ellipse className="st0" cx="760.9" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M751.9,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S749.7,614.7,751.9,614.7z"/>
      <path className="st0" d="M733.4,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S731.2,614.7,733.4,614.7z"/>
      <ellipse className="st0" cx="724.1" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M715,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S712.8,614.7,715,614.7z"/>
      <path className="st0" d="M705.6,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S703.4,614.7,705.6,614.7z"/>
      <ellipse className="st0" cx="696.6" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M687.2,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S685,614.7,687.2,614.7z"/>
      <path className="st0" d="M678.1,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S675.9,614.7,678.1,614.7z"/>
      <path className="st0" d="M672.9,610.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C671,614.7,672.9,613,672.9,610.8z"/>
      <ellipse className="st0" cx="659.7" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M654.5,610.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C652.5,614.7,654.5,613,654.5,610.8z"/>
      <ellipse className="st0" cx="641.2" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M636,610.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C634.1,614.7,636,613,636,610.8z"/>
      <ellipse className="st0" cx="622.8" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M613.7,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S611.5,614.7,613.7,614.7z"/>
      <ellipse className="st0" cx="604.4" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M595.3,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S593.1,614.7,595.3,614.7z"/>
      <ellipse className="st0" cx="585.9" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M576.9,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S574.7,614.7,576.9,614.7z"/>
      <path className="st0" d="M571.4,610.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C569.7,614.7,571.4,613,571.4,610.8z"/>
      <ellipse className="st0" cx="558.4" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M549.1,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S546.9,614.7,549.1,614.7z"/>
      <ellipse className="st0" cx="540" cy="610.8" rx="3.9" ry="3.9"/>
      <ellipse className="st0" cx="521.5" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M512.2,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S510.3,614.7,512.2,614.7z"/>
      <ellipse className="st0" cx="503.1" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M493.8,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S491.8,614.7,493.8,614.7z"/>
      <ellipse className="st0" cx="484.7" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M475.3,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S473.4,614.7,475.3,614.7z"/>
      <ellipse className="st0" cx="466.2" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M457.2,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S455,614.7,457.2,614.7z"/>
      <ellipse className="st0" cx="447.8" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M438.7,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S436.5,614.7,438.7,614.7z"/>
      <path className="st0" d="M429.4,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S427.2,614.7,429.4,614.7z"/>
      <ellipse className="st0" cx="420.3" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M410.9,614.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S408.7,614.7,410.9,614.7z"/>
      <ellipse className="st0" cx="401.8" cy="610.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1175.3,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1173.1,605.6,1175.3,605.6z"/>
      <path className="st0" d="M1166.3,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.1,604,1164.1,605.6,1166.3,605.6z"/>
      <path className="st0" d="M1156.9,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1154.7,605.6,1156.9,605.6z"/>
      <path className="st0" d="M1147.8,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1145.6,605.6,1147.8,605.6z"/>
      <path className="st0" d="M1138.5,597.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1142.6,599.6,1140.7,597.9,1138.5,597.9z"/>
      <path className="st0" d="M1133.2,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1131.6,605.6,1133.2,604,1133.2,601.8z"/>
      <path className="st0" d="M1124.2,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1122.2,605.6,1124.2,604,1124.2,601.8z"/>
      <path className="st0" d="M1111,597.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1113.2,597.9,1111,597.9z"/>
      <path className="st0" d="M1101.6,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1099.7,605.6,1101.6,605.6z"/>
      <path className="st0" d="M1092.5,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1090.3,605.6,1092.5,605.6z"/>
      <path className="st0" d="M1087.3,601.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C1085.4,605.6,1087.3,604,1087.3,601.8z
        "/>
      <path className="st0" d="M1074.1,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1071.9,605.6,1074.1,605.6z"/>
      <path className="st0" d="M1068.9,601.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C1066.9,605.6,1068.9,604,1068.9,601.8z
        "/>
      <path className="st0" d="M1055.6,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1053.4,605.6,1055.6,605.6z"/>
      <path className="st0" d="M1046.6,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,604,1044.4,605.6,1046.6,605.6z"/>
      <path className="st0" d="M1037.2,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1035,605.6,1037.2,605.6z"/>
      <path className="st0" d="M1028.1,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,604,1025.9,605.6,1028.1,605.6z"/>
      <path className="st0" d="M1018.8,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1016.6,605.6,1018.8,605.6z"/>
      <path className="st0" d="M1009.7,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C1005.6,604,1007.5,605.6,1009.7,605.6
        z"/>
      <path className="st0" d="M1004.2,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1002.5,605.6,1004.2,604,1004.2,601.8z"/>
      <path className="st0" d="M995.1,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S995.1,604,995.1,601.8z"/>
      <path className="st0" d="M986,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C984.1,605.6,986,604,986,601.8z"/>
      <path className="st0" d="M976.7,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C975,605.6,976.7,604,976.7,601.8z
        "/>
      <path className="st0" d="M967.6,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C965.7,605.6,967.6,604,967.6,601.8z"/>
      <path className="st0" d="M958.2,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C956.6,605.6,958.2,604,958.2,601.8z"/>
      <path className="st0" d="M949.2,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C947.2,605.6,949.2,604,949.2,601.8z"/>
      <path className="st0" d="M939.8,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C938.2,605.6,939.8,604,939.8,601.8z"/>
      <path className="st0" d="M930.7,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C928.8,605.6,930.7,604,930.7,601.8z"/>
      <path className="st0" d="M921.4,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C919.7,605.6,921.4,604,921.4,601.8z"/>
      <path className="st0" d="M912.3,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C910.6,605.6,912.3,604,912.3,601.8z"/>
      <path className="st0" d="M902.9,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C901.3,605.6,902.9,604,902.9,601.8z"/>
      <path className="st0" d="M893.9,601.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C891.9,605.6,893.9,604,893.9,601.8z"/>
      <path className="st0" d="M884.5,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C882.8,605.6,884.5,604,884.5,601.8z"/>
      <path className="st0" d="M875.4,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C873.8,605.6,875.4,604,875.4,601.8z"/>
      <path className="st0" d="M866.1,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C864.4,605.6,866.1,604,866.1,601.8z"/>
      <path className="st0" d="M849,601.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C850.9,597.9,849,599.6,849,601.8z"
        />
      <path className="st0" d="M839.9,601.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C841.6,597.9,839.9,599.6,839.9,601.8z"/>
      <path className="st0" d="M830.8,601.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C832.5,597.9,830.8,599.6,830.8,601.8z"/>
      <path className="st0" d="M821.5,601.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C823.1,597.9,821.5,599.6,821.5,601.8z"/>
      <path className="st0" d="M816.3,597.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C820.1,599.6,818.5,597.9,816.3,597.9z"/>
      <path className="st0" d="M803,601.8c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C805,597.9,803,599.6,803,601.8z"/>
      <path className="st0" d="M794,601.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C795.6,597.9,794,599.6,794,601.8z"
        />
      <path className="st0" d="M788.5,597.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C792.6,599.6,790.7,597.9,788.5,597.9z"/>
      <path className="st0" d="M779.4,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S777.2,605.6,779.4,605.6z"/>
      <path className="st0" d="M770.3,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C766.2,604,768.1,605.6,770.3,605.6z"/>
      <path className="st0" d="M760.9,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C757.1,604,758.7,605.6,760.9,605.6z"/>
      <path className="st0" d="M751.9,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C747.7,604,749.7,605.6,751.9,605.6z"/>
      <path className="st0" d="M742.5,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S740.3,605.6,742.5,605.6z"/>
      <path className="st0" d="M733.4,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C729.3,604,731.2,605.6,733.4,605.6z"/>
      <path className="st0" d="M724.1,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S721.9,605.6,724.1,605.6z"/>
      <path className="st0" d="M715,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C710.9,604,712.8,605.6,715,605.6z"
        />
      <path className="st0" d="M705.6,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S703.4,605.6,705.6,605.6z"/>
      <path className="st0" d="M696.6,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S694.4,605.6,696.6,605.6z"/>
      <path className="st0" d="M687.2,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S685,605.6,687.2,605.6z"/>
      <path className="st0" d="M678.1,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S675.9,605.6,678.1,605.6z"/>
      <path className="st0" d="M672.9,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C671,605.6,672.9,604,672.9,601.8z
        "/>
      <path className="st0" d="M663.5,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C661.9,605.6,663.5,604,663.5,601.8z"/>
      <path className="st0" d="M654.5,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C652.5,605.6,654.5,604,654.5,601.8z"/>
      <path className="st0" d="M645.1,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C643.4,605.6,645.1,604,645.1,601.8z"/>
      <path className="st0" d="M636,601.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C634.1,605.6,636,604,636,601.8z"/>
      <path className="st0" d="M622.8,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S620.6,605.6,622.8,605.6z"/>
      <path className="st0" d="M613.7,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C609.6,604,611.5,605.6,613.7,605.6z"/>
      <path className="st0" d="M604.4,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S602.2,605.6,604.4,605.6z"/>
      <path className="st0" d="M595.3,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.2,604,593.1,605.6,595.3,605.6z"/>
      <path className="st0" d="M589.8,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C588.1,605.6,589.8,604,589.8,601.8z"/>
      <path className="st0" d="M576.9,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C572.7,604,574.7,605.6,576.9,605.6z"/>
      <path className="st0" d="M571.4,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C569.7,605.6,571.4,604,571.4,601.8z"/>
      <path className="st0" d="M562.3,601.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C560.6,605.6,562.3,604,562.3,601.8z"/>
      <path className="st0" d="M540,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S537.8,605.6,540,605.6z"/>
      <path className="st0" d="M530.6,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S528.4,605.6,530.6,605.6z"/>
      <path className="st0" d="M521.5,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S519.3,605.6,521.5,605.6z"/>
      <path className="st0" d="M512.2,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C508.3,604,510.3,605.6,512.2,605.6z"/>
      <path className="st0" d="M507,601.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S507,604,507,601.8z"/>
      <path className="st0" d="M489.9,601.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C491.8,597.9,489.9,599.6,489.9,601.8z"/>
      <path className="st0" d="M480.8,601.8c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S480.8,599.6,480.8,601.8z"/>
      <path className="st0" d="M471.5,601.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C473.4,597.9,471.5,599.6,471.5,601.8z"/>
      <path className="st0" d="M462.4,601.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C464,597.9,462.4,599.6,462.4,601.8z"/>
      <path className="st0" d="M457.2,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C453,604,455,605.6,457.2,605.6z"
        />
      <path className="st0" d="M447.8,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S445.6,605.6,447.8,605.6z"/>
      <path className="st0" d="M438.7,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C434.6,604,436.5,605.6,438.7,605.6z"/>
      <path className="st0" d="M429.4,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S427.2,605.6,429.4,605.6z"/>
      <path className="st0" d="M420.3,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C416.2,604,418.1,605.6,420.3,605.6z"/>
      <path className="st0" d="M410.9,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S408.7,605.6,410.9,605.6z"/>
      <path className="st0" d="M401.8,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C397.7,604,399.6,605.6,401.8,605.6z"/>
      <ellipse className="st0" cx="1175.3" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.4,594.9,1164.1,596.5,1166.3,596.5z"/>
      <path className="st0" d="M1156.9,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,594.9,1154.7,596.5,1156.9,596.5z"/>
      <ellipse className="st0" cx="1147.8" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1138.5,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,594.9,1136.3,596.5,1138.5,596.5z"/>
      <path className="st0" d="M1129.4,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,594.9,1127.2,596.5,1129.4,596.5z"/>
      <path className="st0" d="M1120,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,594.9,1117.8,596.5,1120,596.5z"/>
      <ellipse className="st0" cx="1111" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1101.6,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,594.9,1099.7,596.5,1101.6,596.5z"/>
      <path className="st0" d="M1092.5,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,594.9,1090.3,596.5,1092.5,596.5z"/>
      <path className="st0" d="M1087.3,592.4c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1085.4,596.5,1087.3,594.6,1087.3,592.4z"/>
      <ellipse className="st0" cx="1074.1" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1068.9,592.4c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1066.9,596.5,1068.9,594.6,1068.9,592.4z"/>
      <path className="st0" d="M1055.6,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,594.9,1053.4,596.5,1055.6,596.5z"/>
      <ellipse className="st0" cx="1046.6" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1037.2,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,594.9,1035,596.5,1037.2,596.5z"/>
      <path className="st0" d="M1028.1,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024.3,594.9,1025.9,596.5,1028.1,596.5z"/>
      <path className="st0" d="M1018.8,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,594.9,1016.6,596.5,1018.8,596.5z"/>
      <ellipse className="st0" cx="1009.7" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1004.2,592.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1002.5,596.5,1004.2,594.6,1004.2,592.4z"/>
      <path className="st0" d="M995.1,592.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C993.5,596.5,995.1,594.6,995.1,592.4z"/>
      <path className="st0" d="M986,592.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C984.1,596.5,986,594.6,986,592.4z"/>
      <ellipse className="st0" cx="972.8" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M967.6,592.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C965.7,596.5,967.6,594.6,967.6,592.4z"/>
      <path className="st0" d="M958.2,592.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C956.6,596.5,958.2,594.6,958.2,592.4z"/>
      <path className="st0" d="M949.2,592.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,596.5,949.2,594.6,949.2,592.4z"/>
      <ellipse className="st0" cx="936" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M930.7,592.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C928.8,596.5,930.7,594.6,930.7,592.4z"/>
      <path className="st0" d="M921.4,592.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C919.7,596.5,921.4,594.6,921.4,592.4z"/>
      <ellipse className="st0" cx="908.4" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M902.9,592.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C901.3,596.5,902.9,594.6,902.9,592.4z"/>
      <path className="st0" d="M893.9,592.4c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C891.9,596.5,893.9,594.6,893.9,592.4z"/>
      <path className="st0" d="M884.5,592.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C882.8,596.5,884.5,594.6,884.5,592.4z"/>
      <ellipse className="st0" cx="871.6" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M858.4,592.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C860,588.5,858.4,590.5,858.4,592.4z"/>
      <path className="st0" d="M849,592.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C850.9,588.5,849,590.5,849,592.4z"/>
      <path className="st0" d="M839.9,592.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C841.6,588.5,839.9,590.5,839.9,592.4z"/>
      <ellipse className="st0" cx="834.7" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M821.5,592.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C823.1,588.5,821.5,590.5,821.5,592.4z"/>
      <path className="st0" d="M816.3,588.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C820.1,590.2,818.5,588.5,816.3,588.5z"/>
      <path className="st0" d="M803,592.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C805,588.5,803,590.5,803,592.4z"/>
      <ellipse className="st0" cx="797.8" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M788.5,588.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C792.3,590.2,790.7,588.5,788.5,588.5z"/>
      <path className="st0" d="M779.4,588.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C783.2,590.2,781.6,588.5,779.4,588.5z"/>
      <path className="st0" d="M770.3,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C766.4,594.9,768.1,596.5,770.3,596.5z"/>
      <ellipse className="st0" cx="760.9" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M751.9,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C748,594.9,749.7,596.5,751.9,596.5z"/>
      <path className="st0" d="M733.4,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C729.6,594.9,731.2,596.5,733.4,596.5z"/>
      <ellipse className="st0" cx="724.1" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M715,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C711.1,594.9,712.8,596.5,715,596.5z"/>
      <path className="st0" d="M705.6,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C701.8,594.9,703.4,596.5,705.6,596.5z"/>
      <ellipse className="st0" cx="696.6" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M687.2,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C683.3,594.9,685,596.5,687.2,596.5z"/>
      <path className="st0" d="M678.1,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,594.9,675.9,596.5,678.1,596.5z"/>
      <path className="st0" d="M672.9,592.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C671,596.5,672.9,594.6,672.9,592.4z"/>
      <ellipse className="st0" cx="659.7" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M654.5,592.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C652.5,596.5,654.5,594.6,654.5,592.4z"/>
      <ellipse className="st0" cx="641.2" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M636,592.4c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C634.1,596.5,636,594.6,636,592.4z"
        />
      <ellipse className="st0" cx="622.8" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M613.7,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C609.9,594.9,611.5,596.5,613.7,596.5z"/>
      <ellipse className="st0" cx="604.4" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M595.3,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.4,594.9,593.1,596.5,595.3,596.5z"/>
      <ellipse className="st0" cx="585.9" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M576.9,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C573,594.9,574.7,596.5,576.9,596.5z"/>
      <path className="st0" d="M571.4,592.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C569.7,596.5,571.4,594.6,571.4,592.4z"/>
      <ellipse className="st0" cx="558.4" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M549.1,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C545.2,594.9,546.9,596.5,549.1,596.5z"/>
      <ellipse className="st0" cx="540" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M530.6,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C526.8,594.9,528.4,596.5,530.6,596.5z"/>
      <ellipse className="st0" cx="521.5" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M512.2,588.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C516,590.2,514.4,588.5,512.2,588.5z"/>
      <ellipse className="st0" cx="503.1" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M489.9,592.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C491.8,588.5,489.9,590.5,489.9,592.4z"/>
      <ellipse className="st0" cx="484.7" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M471.5,592.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C473.4,588.5,471.5,590.5,471.5,592.4z"/>
      <ellipse className="st0" cx="466.2" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M457.2,588.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C461,590.2,459.1,588.5,457.2,588.5z"/>
      <ellipse className="st0" cx="447.8" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M438.7,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C434.9,594.9,436.5,596.5,438.7,596.5z"/>
      <path className="st0" d="M429.4,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C425.5,594.9,427.2,596.5,429.4,596.5z"/>
      <ellipse className="st0" cx="420.3" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M410.9,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C407.1,594.9,408.7,596.5,410.9,596.5z"/>
      <ellipse className="st0" cx="401.8" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M392.5,588.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C396.3,590.2,394.7,588.5,392.5,588.5z"/>
      <ellipse className="st0" cx="383.4" cy="592.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1175.3,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1171.5,585.5,1173.1,587.2,1175.3,587.2z"/>
      <path className="st0" d="M1166.3,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.1,585.5,1164.1,587.2,1166.3,587.2z"/>
      <path className="st0" d="M1156.9,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,585.5,1154.7,587.2,1156.9,587.2z"/>
      <path className="st0" d="M1147.8,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1144,585.5,1145.6,587.2,1147.8,587.2z"/>
      <path className="st0" d="M1138.5,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,585.5,1136.3,587.2,1138.5,587.2z"/>
      <path className="st0" d="M1129.4,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,585.5,1127.2,587.2,1129.4,587.2z"/>
      <path className="st0" d="M1120,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,585.5,1117.8,587.2,1120,587.2z"/>
      <path className="st0" d="M1114.8,583.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S1114.8,585.5,1114.8,583.3z"/>
      <path className="st0" d="M1101.6,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,585.5,1099.7,587.2,1101.6,587.2z"/>
      <path className="st0" d="M1092.5,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,585.5,1090.3,587.2,1092.5,587.2z"/>
      <path className="st0" d="M1087.3,583.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1085.4,587.2,1087.3,585.5,1087.3,583.3z"/>
      <path className="st0" d="M1074.1,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1070.2,585.5,1071.9,587.2,1074.1,587.2z"/>
      <path className="st0" d="M1068.9,583.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1066.9,587.2,1068.9,585.5,1068.9,583.3z"/>
      <path className="st0" d="M1055.6,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,585.5,1053.4,587.2,1055.6,587.2z"/>
      <path className="st0" d="M1046.6,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,585.5,1044.4,587.2,1046.6,587.2z"/>
      <path className="st0" d="M1037.2,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,585.5,1035,587.2,1037.2,587.2z"/>
      <path className="st0" d="M1028.1,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,585.5,1025.9,587.2,1028.1,587.2z"/>
      <path className="st0" d="M1018.8,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,585.5,1016.6,587.2,1018.8,587.2z"/>
      <path className="st0" d="M1009.7,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,585.5,1007.5,587.2,1009.7,587.2z"/>
      <path className="st0" d="M1004.2,583.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1002.5,587.2,1004.2,585.5,1004.2,583.3z"/>
      <path className="st0" d="M995.1,583.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C993.5,587.2,995.1,585.5,995.1,583.3z"/>
      <path className="st0" d="M986,583.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C984.1,587.2,986,585.5,986,583.3z"/>
      <path className="st0" d="M976.7,583.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C975,587.2,976.7,585.5,976.7,583.3z"/>
      <path className="st0" d="M967.6,583.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C965.7,587.2,967.6,585.5,967.6,583.3z"/>
      <path className="st0" d="M958.2,583.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C956.6,587.2,958.2,585.5,958.2,583.3z"/>
      <path className="st0" d="M949.2,583.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,587.2,949.2,585.5,949.2,583.3z"/>
      <path className="st0" d="M939.8,583.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C938.2,587.2,939.8,585.5,939.8,583.3z"/>
      <path className="st0" d="M930.7,583.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C928.8,587.2,930.7,585.5,930.7,583.3z"/>
      <path className="st0" d="M921.4,583.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C919.7,587.2,921.4,585.5,921.4,583.3z"/>
      <path className="st0" d="M912.3,583.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C910.6,587.2,912.3,585.5,912.3,583.3z"/>
      <path className="st0" d="M902.9,583.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C901.3,587.2,902.9,585.5,902.9,583.3z"/>
      <path className="st0" d="M893.9,583.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C891.9,587.2,893.9,585.5,893.9,583.3z"/>
      <path className="st0" d="M884.5,583.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C882.8,587.2,884.5,585.5,884.5,583.3z"/>
      <path className="st0" d="M867.4,583.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C869.1,579.5,867.4,581.1,867.4,583.3z"/>
      <path className="st0" d="M858.4,583.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C860,579.5,858.4,581.1,858.4,583.3z"/>
      <path className="st0" d="M849,583.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C850.9,579.5,849,581.1,849,583.3z"/>
      <path className="st0" d="M839.9,583.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C841.6,579.5,839.9,581.1,839.9,583.3z"/>
      <path className="st0" d="M830.8,583.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C832.5,579.5,830.8,581.1,830.8,583.3z"/>
      <path className="st0" d="M821.5,583.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C823.1,579.5,821.5,581.1,821.5,583.3z"/>
      <path className="st0" d="M816.3,579.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C820.1,581.1,818.5,579.5,816.3,579.5z"/>
      <path className="st0" d="M803,583.3c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C805,579.5,803,581.1,803,583.3z"/>
      <path className="st0" d="M794,583.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C795.6,579.5,794,581.1,794,583.3z"/>
      <path className="st0" d="M788.5,579.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C792.6,581.1,790.7,579.5,788.5,579.5z"/>
      <path className="st0" d="M779.4,579.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C783.2,581.1,781.6,579.5,779.4,579.5z"/>
      <path className="st0" d="M770.3,579.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C774.2,581.1,772.2,579.5,770.3,579.5z"/>
      <path className="st0" d="M760.9,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C757.1,585.5,758.7,587.2,760.9,587.2z"/>
      <path className="st0" d="M751.9,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C747.7,585.5,749.7,587.2,751.9,587.2z"/>
      <path className="st0" d="M742.5,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C738.7,585.5,740.3,587.2,742.5,587.2z"/>
      <path className="st0" d="M733.4,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C729.3,585.5,731.2,587.2,733.4,587.2z"/>
      <path className="st0" d="M715,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C710.9,585.5,712.8,587.2,715,587.2z"/>
      <path className="st0" d="M705.6,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C701.8,585.5,703.4,587.2,705.6,587.2z"/>
      <path className="st0" d="M696.6,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C692.7,585.5,694.4,587.2,696.6,587.2z"/>
      <path className="st0" d="M687.2,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C683.3,585.5,685,587.2,687.2,587.2z"/>
      <path className="st0" d="M678.1,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,585.5,675.9,587.2,678.1,587.2z"/>
      <path className="st0" d="M672.9,583.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C671,587.2,672.9,585.5,672.9,583.3z"/>
      <path className="st0" d="M663.5,583.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C661.9,587.2,663.5,585.5,663.5,583.3z"/>
      <path className="st0" d="M654.5,583.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C652.5,587.2,654.5,585.5,654.5,583.3z"/>
      <path className="st0" d="M645.1,583.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C643.4,587.2,645.1,585.5,645.1,583.3z"/>
      <path className="st0" d="M636,583.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C634.1,587.2,636,585.5,636,583.3z"
        />
      <path className="st0" d="M622.8,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C619,585.5,620.6,587.2,622.8,587.2z"/>
      <path className="st0" d="M613.7,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C609.6,585.5,611.5,587.2,613.7,587.2z"/>
      <path className="st0" d="M604.4,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C600.5,585.5,602.2,587.2,604.4,587.2z"/>
      <path className="st0" d="M595.3,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.2,585.5,593.1,587.2,595.3,587.2z"/>
      <path className="st0" d="M589.8,583.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C588.1,587.2,589.8,585.5,589.8,583.3z"/>
      <path className="st0" d="M576.9,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C572.7,585.5,574.7,587.2,576.9,587.2z"/>
      <path className="st0" d="M571.4,583.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C569.7,587.2,571.4,585.5,571.4,583.3z"/>
      <path className="st0" d="M562.3,583.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C560.6,587.2,562.3,585.5,562.3,583.3z"/>
      <path className="st0" d="M545.2,583.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S545.2,581.1,545.2,583.3z"/>
      <path className="st0" d="M536.1,583.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C537.8,579.5,536.1,581.1,536.1,583.3z"/>
      <path className="st0" d="M526.8,583.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S526.8,581.1,526.8,583.3z"/>
      <path className="st0" d="M517.7,583.3c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S517.7,581.1,517.7,583.3z"/>
      <path className="st0" d="M512.2,579.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C516.3,581.1,514.4,579.5,512.2,579.5z"/>
      <path className="st0" d="M499.3,583.3c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S499.3,581.1,499.3,583.3z"/>
      <path className="st0" d="M489.9,583.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C491.8,579.5,489.9,581.1,489.9,583.3z"/>
      <path className="st0" d="M480.8,583.3c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S480.8,581.1,480.8,583.3z"/>
      <path className="st0" d="M471.5,583.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C473.4,579.5,471.5,581.1,471.5,583.3z"/>
      <path className="st0" d="M462.4,583.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C464,579.5,462.4,581.1,462.4,583.3z"/>
      <path className="st0" d="M457.2,579.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C461,581.1,459.1,579.5,457.2,579.5z"/>
      <path className="st0" d="M443.9,583.3c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S443.9,581.1,443.9,583.3z"/>
      <path className="st0" d="M438.7,579.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C442.6,581.1,440.6,579.5,438.7,579.5z"/>
      <path className="st0" d="M425.5,583.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C427.2,579.5,425.5,581.1,425.5,583.3z"/>
      <path className="st0" d="M416.2,583.3c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S416.2,581.1,416.2,583.3z"/>
      <path className="st0" d="M407.1,583.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C408.7,579.5,407.1,581.1,407.1,583.3z"/>
      <path className="st0" d="M401.8,579.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C405.7,581.1,404,579.5,401.8,579.5z"/>
      <path className="st0" d="M392.5,579.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C396.3,581.1,394.7,579.5,392.5,579.5z"/>
      <path className="st0" d="M383.4,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C379.6,585.5,381.2,587.2,383.4,587.2z"/>
      <path className="st0" d="M374.1,579.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C378.2,581.1,376.3,579.5,374.1,579.5z"/>
      <path className="st0" d="M365,579.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C368.8,581.1,367.2,579.5,365,579.5z"/>
      <path className="st0" d="M355.6,579.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C359.7,581.1,357.8,579.5,355.6,579.5z"/>
      <ellipse className="st0" cx="1175.3" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,578.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.4,576.4,1164.1,578.1,1166.3,578.1z"/>
      <path className="st0" d="M1156.9,578.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,576.4,1154.7,578.1,1156.9,578.1z"/>
      <ellipse className="st0" cx="1147.8" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1138.5,578.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,576.4,1136.3,578.1,1138.5,578.1z"/>
      <path className="st0" d="M1129.4,578.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,576.4,1127.2,578.1,1129.4,578.1z"/>
      <path className="st0" d="M1120,578.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,576.4,1117.8,578.1,1120,578.1z"/>
      <ellipse className="st0" cx="1111" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1101.6,578.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,576.4,1099.7,578.1,1101.6,578.1z"/>
      <path className="st0" d="M1092.5,578.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,576.4,1090.3,578.1,1092.5,578.1z"/>
      <path className="st0" d="M1087.3,574.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1085.4,578.1,1087.3,576.4,1087.3,574.2z"/>
      <ellipse className="st0" cx="1074.1" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1068.9,574.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1066.9,578.1,1068.9,576.4,1068.9,574.2z"/>
      <path className="st0" d="M1055.6,578.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,576.4,1053.4,578.1,1055.6,578.1z"/>
      <ellipse className="st0" cx="1046.6" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1037.2,578.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,576.4,1035,578.1,1037.2,578.1z"/>
      <path className="st0" d="M1028.1,578.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024.3,576.4,1025.9,578.1,1028.1,578.1z"/>
      <path className="st0" d="M1018.8,578.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,576.4,1016.6,578.1,1018.8,578.1z"/>
      <ellipse className="st0" cx="1009.7" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1004.2,574.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1002.5,578.1,1004.2,576.4,1004.2,574.2z"/>
      <path className="st0" d="M995.1,574.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S995.1,576.4,995.1,574.2z"
        />
      <path className="st0" d="M986,574.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C984.1,578.1,986,576.4,986,574.2z"/>
      <ellipse className="st0" cx="972.8" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M967.6,574.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C965.7,578.1,967.6,576.4,967.6,574.2z"/>
      <path className="st0" d="M958.2,574.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C956.6,578.1,958.2,576.4,958.2,574.2z"/>
      <path className="st0" d="M949.2,574.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,578.1,949.2,576.4,949.2,574.2z"/>
      <ellipse className="st0" cx="936" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M930.7,574.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C928.8,578.1,930.7,576.4,930.7,574.2z"/>
      <path className="st0" d="M921.4,574.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C919.7,578.1,921.4,576.4,921.4,574.2z"/>
      <ellipse className="st0" cx="908.4" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M902.9,574.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C901.3,578.1,902.9,576.4,902.9,574.2z"/>
      <path className="st0" d="M893.9,574.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C891.9,578.1,893.9,576.4,893.9,574.2z"/>
      <path className="st0" d="M876.8,574.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C878.4,570.1,876.8,572,876.8,574.2z"/>
      <ellipse className="st0" cx="871.6" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M858.4,574.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C860,570.1,858.4,572,858.4,574.2z"/>
      <path className="st0" d="M849,574.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C850.9,570.1,849,572,849,574.2
        z"/>
      <path className="st0" d="M839.9,574.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C841.6,570.1,839.9,572,839.9,574.2z"/>
      <ellipse className="st0" cx="834.7" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M821.5,574.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C823.1,570.1,821.5,572,821.5,574.2z"/>
      <path className="st0" d="M816.3,570.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S818.5,570.1,816.3,570.1z"/>
      <path className="st0" d="M803,574.2c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C805,570.1,803,572,803,574.2z"/>
      <ellipse className="st0" cx="797.8" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M788.5,570.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S790.7,570.1,788.5,570.1z"/>
      <path className="st0" d="M779.4,570.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S781.6,570.1,779.4,570.1z"/>
      <path className="st0" d="M770.3,570.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S772.2,570.1,770.3,570.1z"/>
      <ellipse className="st0" cx="760.9" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M747.7,574.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C749.7,570.1,747.7,572,747.7,574.2z"/>
      <path className="st0" d="M742.5,570.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S744.7,570.1,742.5,570.1z"/>
      <path className="st0" d="M733.4,578.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C729.6,576.4,731.2,578.1,733.4,578.1z"/>
      <ellipse className="st0" cx="724.1" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M715,578.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C711.1,576.4,712.8,578.1,715,578.1z"/>
      <path className="st0" d="M705.6,578.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C701.8,576.4,703.4,578.1,705.6,578.1z"/>
      <ellipse className="st0" cx="696.6" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M687.2,578.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C683.3,576.4,685,578.1,687.2,578.1z"/>
      <path className="st0" d="M678.1,578.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,576.4,675.9,578.1,678.1,578.1z"/>
      <path className="st0" d="M672.9,574.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C671,578.1,672.9,576.4,672.9,574.2z"/>
      <ellipse className="st0" cx="659.7" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M654.5,574.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C652.5,578.1,654.5,576.4,654.5,574.2z"/>
      <ellipse className="st0" cx="641.2" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M636,574.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C634.1,578.1,636,576.4,636,574.2z"
        />
      <ellipse className="st0" cx="622.8" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M613.7,578.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C609.9,576.4,611.5,578.1,613.7,578.1z"/>
      <ellipse className="st0" cx="604.4" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M595.3,578.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.4,576.4,593.1,578.1,595.3,578.1z"/>
      <ellipse className="st0" cx="585.9" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M576.9,578.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C573,576.4,574.7,578.1,576.9,578.1z"/>
      <path className="st0" d="M571.4,574.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C569.7,578.1,571.4,576.4,571.4,574.2z"/>
      <ellipse className="st0" cx="558.4" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M545.2,574.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C546.9,570.1,545.2,572,545.2,574.2z"/>
      <ellipse className="st0" cx="540" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M526.8,574.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C528.4,570.1,526.8,572,526.8,574.2z"/>
      <ellipse className="st0" cx="521.5" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M512.2,570.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S514.4,570.1,512.2,570.1z"/>
      <ellipse className="st0" cx="503.1" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M489.9,574.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C491.8,570.1,489.9,572,489.9,574.2z"/>
      <ellipse className="st0" cx="484.7" cy="574.2" rx="3.9" ry="3.9"/>
      <ellipse className="st0" cx="466.2" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M457.2,570.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S459.1,570.1,457.2,570.1z"/>
      <ellipse className="st0" cx="447.8" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M438.7,570.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S440.6,570.1,438.7,570.1z"/>
      <path className="st0" d="M429.4,578.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C425.5,576.4,427.2,578.1,429.4,578.1z"/>
      <ellipse className="st0" cx="420.3" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M410.9,578.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C407.1,576.4,408.7,578.1,410.9,578.1z"/>
      <ellipse className="st0" cx="401.8" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M388.6,574.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C390.3,570.1,388.6,572,388.6,574.2z"/>
      <ellipse className="st0" cx="383.4" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M374.1,570.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S376.3,570.1,374.1,570.1z"/>
      <ellipse className="st0" cx="365" cy="574.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1175.3,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1171.5,567.1,1173.1,568.7,1175.3,568.7z"/>
      <path className="st0" d="M1166.3,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.1,567.1,1164.1,568.7,1166.3,568.7z"/>
      <path className="st0" d="M1156.9,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,567.1,1154.7,568.7,1156.9,568.7z"/>
      <path className="st0" d="M1147.8,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1144,567.1,1145.6,568.7,1147.8,568.7z"/>
      <path className="st0" d="M1138.5,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,567.1,1136.3,568.7,1138.5,568.7z"/>
      <path className="st0" d="M1129.4,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,567.1,1127.2,568.7,1129.4,568.7z"/>
      <path className="st0" d="M1120,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,567.1,1117.8,568.7,1120,568.7z"/>
      <path className="st0" d="M1114.8,564.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S1114.8,567.1,1114.8,564.9z"/>
      <path className="st0" d="M1101.6,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,567.1,1099.7,568.7,1101.6,568.7z"/>
      <path className="st0" d="M1092.5,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,567.1,1090.3,568.7,1092.5,568.7z"/>
      <path className="st0" d="M1087.3,564.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1085.4,568.7,1087.3,567.1,1087.3,564.9z"/>
      <path className="st0" d="M1074.1,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1070.2,567.1,1071.9,568.7,1074.1,568.7z"/>
      <path className="st0" d="M1068.9,564.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1066.9,568.7,1068.9,567.1,1068.9,564.9z"/>
      <path className="st0" d="M1055.6,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,567.1,1053.4,568.7,1055.6,568.7z"/>
      <path className="st0" d="M1046.6,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,567.1,1044.4,568.7,1046.6,568.7z"/>
      <path className="st0" d="M1037.2,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,567.1,1035,568.7,1037.2,568.7z"/>
      <path className="st0" d="M1028.1,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,567.1,1025.9,568.7,1028.1,568.7z"/>
      <path className="st0" d="M1018.8,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,567.1,1016.6,568.7,1018.8,568.7z"/>
      <path className="st0" d="M1009.7,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,567.1,1007.5,568.7,1009.7,568.7z"/>
      <path className="st0" d="M1004.2,564.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1002.5,568.7,1004.2,567.1,1004.2,564.9z"/>
      <path className="st0" d="M995.1,564.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S995.1,567.1,995.1,564.9z"
        />
      <path className="st0" d="M986,564.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C984.1,568.7,986,567.1,986,564.9z"/>
      <path className="st0" d="M976.7,564.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C975,568.7,976.7,567.1,976.7,564.9z"/>
      <path className="st0" d="M967.6,564.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C965.7,568.7,967.6,567.1,967.6,564.9z"/>
      <path className="st0" d="M958.2,564.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C956.6,568.7,958.2,567.1,958.2,564.9z"/>
      <path className="st0" d="M949.2,564.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,568.7,949.2,567.1,949.2,564.9z"/>
      <path className="st0" d="M939.8,564.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C938.2,568.7,939.8,567.1,939.8,564.9z"/>
      <path className="st0" d="M930.7,564.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C928.8,568.7,930.7,567.1,930.7,564.9z"/>
      <path className="st0" d="M921.4,564.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C919.7,568.7,921.4,567.1,921.4,564.9z"/>
      <path className="st0" d="M912.3,564.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C910.6,568.7,912.3,567.1,912.3,564.9z"/>
      <path className="st0" d="M902.9,564.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C901.3,568.7,902.9,567.1,902.9,564.9z"/>
      <path className="st0" d="M885.9,564.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C887.8,561,885.9,562.7,885.9,564.9z"/>
      <path className="st0" d="M876.8,564.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C878.4,561,876.8,562.7,876.8,564.9z"/>
      <path className="st0" d="M867.4,564.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C869.1,561,867.4,562.7,867.4,564.9z"/>
      <path className="st0" d="M858.4,564.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C860,561,858.4,562.7,858.4,564.9z"/>
      <path className="st0" d="M849,564.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C850.9,561,849,562.7,849,564.9
        z"/>
      <path className="st0" d="M839.9,564.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C841.6,561,839.9,562.7,839.9,564.9z"/>
      <path className="st0" d="M830.8,564.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C832.5,561,830.8,562.7,830.8,564.9z"/>
      <path className="st0" d="M821.5,564.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C823.1,561,821.5,562.7,821.5,564.9z"/>
      <path className="st0" d="M816.3,561c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C820.1,562.7,818.5,561,816.3,561z"/>
      <path className="st0" d="M803,564.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C805,561,803,562.7,803,564.9z"/>
      <path className="st0" d="M794,564.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C795.6,561,794,562.7,794,564.9
        z"/>
      <path className="st0" d="M788.5,561c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C792.6,562.7,790.7,561,788.5,561z"/>
      <path className="st0" d="M779.4,561c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S781.6,561,779.4,561z"/>
      <path className="st0" d="M770.3,561c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S772.2,561,770.3,561z"/>
      <path className="st0" d="M760.9,561c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S763.1,561,760.9,561z"/>
      <path className="st0" d="M747.7,564.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C749.7,561,747.7,562.7,747.7,564.9z"/>
      <path className="st0" d="M742.5,561c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S744.7,561,742.5,561z"/>
      <path className="st0" d="M733.4,561c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S735.4,561,733.4,561z"/>
      <path className="st0" d="M724.1,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C720.2,567.1,721.9,568.7,724.1,568.7z"/>
      <path className="st0" d="M715,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C710.9,567.1,712.8,568.7,715,568.7z"/>
      <path className="st0" d="M705.6,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C701.8,567.1,703.4,568.7,705.6,568.7z"/>
      <path className="st0" d="M696.6,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C692.7,567.1,694.4,568.7,696.6,568.7z"/>
      <path className="st0" d="M687.2,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C683.3,567.1,685,568.7,687.2,568.7z"/>
      <path className="st0" d="M678.1,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,567.1,675.9,568.7,678.1,568.7z"/>
      <path className="st0" d="M672.9,564.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C671,568.7,672.9,567.1,672.9,564.9z"/>
      <path className="st0" d="M663.5,564.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C661.9,568.7,663.5,567.1,663.5,564.9z"/>
      <path className="st0" d="M654.5,564.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C652.5,568.7,654.5,567.1,654.5,564.9z"/>
      <path className="st0" d="M645.1,564.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C643.4,568.7,645.1,567.1,645.1,564.9z"/>
      <path className="st0" d="M636,564.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C634.1,568.7,636,567.1,636,564.9z"
        />
      <path className="st0" d="M622.8,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C619,567.1,620.6,568.7,622.8,568.7z"/>
      <path className="st0" d="M613.7,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C609.6,567.1,611.5,568.7,613.7,568.7z"/>
      <path className="st0" d="M604.4,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C600.5,567.1,602.2,568.7,604.4,568.7z"/>
      <path className="st0" d="M595.3,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.2,567.1,593.1,568.7,595.3,568.7z"/>
      <path className="st0" d="M589.8,564.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C588.1,568.7,589.8,567.1,589.8,564.9z"/>
      <path className="st0" d="M576.9,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C572.7,567.1,574.7,568.7,576.9,568.7z"/>
      <path className="st0" d="M571.4,564.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C569.7,568.7,571.4,567.1,571.4,564.9z"/>
      <path className="st0" d="M554.3,564.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C555.9,561,554.3,562.7,554.3,564.9z"/>
      <path className="st0" d="M545.2,564.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S545.2,562.7,545.2,564.9z"/>
      <path className="st0" d="M536.1,564.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C537.8,561,536.1,562.7,536.1,564.9z"/>
      <path className="st0" d="M526.8,564.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S526.8,562.7,526.8,564.9z"/>
      <path className="st0" d="M517.7,564.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S517.7,562.7,517.7,564.9z"/>
      <path className="st0" d="M512.2,561c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C516.3,562.7,514.4,561,512.2,561z"
        />
      <path className="st0" d="M499.3,564.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S499.3,562.7,499.3,564.9z"/>
      <path className="st0" d="M489.9,564.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C491.8,561,489.9,562.7,489.9,564.9z"/>
      <path className="st0" d="M480.8,564.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S480.8,562.7,480.8,564.9z"/>
      <path className="st0" d="M471.5,564.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C473.4,561,471.5,562.7,471.5,564.9z"/>
      <path className="st0" d="M462.4,564.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C464,561,462.4,562.7,462.4,564.9z"/>
      <path className="st0" d="M457.2,561c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S459.1,561,457.2,561z"/>
      <path className="st0" d="M443.9,564.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S443.9,562.7,443.9,564.9z"/>
      <path className="st0" d="M438.7,561c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S440.6,561,438.7,561z"/>
      <path className="st0" d="M429.4,561c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C433.2,562.7,431.6,561,429.4,561z"/>
      <path className="st0" d="M420.3,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C416.2,567.1,418.1,568.7,420.3,568.7z"/>
      <path className="st0" d="M410.9,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C407.1,567.1,408.7,568.7,410.9,568.7z"/>
      <path className="st0" d="M383.4,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C379.6,567.1,381.2,568.7,383.4,568.7z"/>
      <path className="st0" d="M370.2,564.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S370.2,562.7,370.2,564.9z"/>
      <path className="st0" d="M365,568.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C361.1,567.1,362.8,568.7,365,568.7z"/>
      <path className="st0" d="M355.6,561c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C359.7,562.7,357.8,561,355.6,561z"/>
      <path className="st0" d="M1175.3,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1171.5,558,1173.1,559.7,1175.3,559.7z"/>
      <path className="st0" d="M1166.3,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.1,558,1164.1,559.7,1166.3,559.7z"/>
      <path className="st0" d="M1156.9,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,558,1154.7,559.7,1156.9,559.7z"/>
      <path className="st0" d="M1147.8,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1144,558,1145.6,559.7,1147.8,559.7z"/>
      <path className="st0" d="M1138.5,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,558,1136.3,559.7,1138.5,559.7z"/>
      <path className="st0" d="M1129.4,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,558,1127.2,559.7,1129.4,559.7z"/>
      <path className="st0" d="M1120,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,558,1117.8,559.7,1120,559.7z"/>
      <path className="st0" d="M1114.8,555.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S1114.8,558,1114.8,555.8z"/>
      <path className="st0" d="M1101.6,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,558,1099.7,559.7,1101.6,559.7z"/>
      <path className="st0" d="M1092.5,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,558,1090.3,559.7,1092.5,559.7z"/>
      <path className="st0" d="M1087.3,555.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1085.4,559.7,1087.3,558,1087.3,555.8z"/>
      <path className="st0" d="M1074.1,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1070.2,558,1071.9,559.7,1074.1,559.7z"/>
      <path className="st0" d="M1068.9,555.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1066.9,559.7,1068.9,558,1068.9,555.8z"/>
      <path className="st0" d="M1055.6,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,558,1053.4,559.7,1055.6,559.7z"/>
      <path className="st0" d="M1046.6,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,558,1044.4,559.7,1046.6,559.7z"/>
      <path className="st0" d="M1037.2,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,558,1035,559.7,1037.2,559.7z"/>
      <path className="st0" d="M1028.1,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,558,1025.9,559.7,1028.1,559.7z"/>
      <path className="st0" d="M1018.8,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,558,1016.6,559.7,1018.8,559.7z"/>
      <path className="st0" d="M1009.7,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,558,1007.5,559.7,1009.7,559.7z"/>
      <path className="st0" d="M1004.2,555.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1002.5,559.7,1004.2,558,1004.2,555.8z"/>
      <path className="st0" d="M995.1,555.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C993.5,559.7,995.1,558,995.1,555.8z"/>
      <path className="st0" d="M986,555.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C984.1,559.7,986,558,986,555.8z"/>
      <path className="st0" d="M976.7,555.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C975,559.7,976.7,558,976.7,555.8z"/>
      <path className="st0" d="M967.6,555.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C965.7,559.7,967.6,558,967.6,555.8z"/>
      <path className="st0" d="M958.2,555.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C956.6,559.7,958.2,558,958.2,555.8z"/>
      <path className="st0" d="M949.2,555.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,559.7,949.2,558,949.2,555.8z"/>
      <path className="st0" d="M939.8,555.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C938.2,559.7,939.8,558,939.8,555.8z"/>
      <path className="st0" d="M930.7,555.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C928.8,559.7,930.7,558,930.7,555.8z"/>
      <path className="st0" d="M921.4,555.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C919.7,559.7,921.4,558,921.4,555.8z"/>
      <path className="st0" d="M912.3,555.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C910.6,559.7,912.3,558,912.3,555.8z"/>
      <path className="st0" d="M895.2,555.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C896.9,551.9,895.2,553.6,895.2,555.8z"/>
      <path className="st0" d="M885.9,555.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C887.8,551.9,885.9,553.6,885.9,555.8z"/>
      <path className="st0" d="M876.8,555.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C878.4,551.9,876.8,553.6,876.8,555.8z"/>
      <path className="st0" d="M867.4,555.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C869.1,551.9,867.4,553.6,867.4,555.8z"/>
      <path className="st0" d="M858.4,555.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C860,551.9,858.4,553.6,858.4,555.8z"/>
      <path className="st0" d="M849,555.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C850.9,551.9,849,553.6,849,555.8z"/>
      <path className="st0" d="M843.8,551.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C847.6,553.6,846,551.9,843.8,551.9z"/>
      <path className="st0" d="M834.7,551.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C838.5,553.6,836.9,551.9,834.7,551.9z"/>
      <path className="st0" d="M825.3,551.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C829.5,553.6,827.5,551.9,825.3,551.9z"/>
      <path className="st0" d="M816.3,551.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C820.1,553.6,818.5,551.9,816.3,551.9z"/>
      <path className="st0" d="M803,555.8c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C805,551.9,803,553.6,803,555.8z"/>
      <path className="st0" d="M794,555.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C795.6,551.9,794,553.6,794,555.8z"/>
      <path className="st0" d="M788.5,551.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C792.6,553.6,790.7,551.9,788.5,551.9z"/>
      <path className="st0" d="M779.4,551.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C783.2,553.6,781.6,551.9,779.4,551.9z"/>
      <path className="st0" d="M770.3,551.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C774.2,553.6,772.2,551.9,770.3,551.9z"/>
      <path className="st0" d="M760.9,551.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C764.8,553.6,763.1,551.9,760.9,551.9z"/>
      <path className="st0" d="M747.7,555.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C749.7,551.9,747.7,553.6,747.7,555.8z"/>
      <path className="st0" d="M742.5,551.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C746.4,553.6,744.7,551.9,742.5,551.9z"/>
      <path className="st0" d="M733.4,551.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,553.6,735.4,551.9,733.4,551.9z"/>
      <path className="st0" d="M724.1,551.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,553.6,726.3,551.9,724.1,551.9z"/>
      <path className="st0" d="M715,551.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,553.6,717.2,551.9,715,551.9z"/>
      <path className="st0" d="M705.6,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C701.8,558,703.4,559.7,705.6,559.7z"/>
      <path className="st0" d="M696.6,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C692.7,558,694.4,559.7,696.6,559.7z"/>
      <path className="st0" d="M687.2,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C683.3,558,685,559.7,687.2,559.7z"/>
      <path className="st0" d="M678.1,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,558,675.9,559.7,678.1,559.7z"/>
      <path className="st0" d="M672.9,555.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C671,559.7,672.9,558,672.9,555.8z"/>
      <path className="st0" d="M663.5,555.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C661.9,559.7,663.5,558,663.5,555.8z"/>
      <path className="st0" d="M654.5,555.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C652.5,559.7,654.5,558,654.5,555.8z"/>
      <path className="st0" d="M645.1,555.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C643.4,559.7,645.1,558,645.1,555.8z"/>
      <path className="st0" d="M636,555.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C634.1,559.7,636,558,636,555.8z"/>
      <path className="st0" d="M622.8,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C619,558,620.6,559.7,622.8,559.7z"/>
      <path className="st0" d="M613.7,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C609.6,558,611.5,559.7,613.7,559.7z"/>
      <path className="st0" d="M604.4,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C600.5,558,602.2,559.7,604.4,559.7z"/>
      <path className="st0" d="M595.3,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.2,558,593.1,559.7,595.3,559.7z"/>
      <path className="st0" d="M589.8,555.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C588.1,559.7,589.8,558,589.8,555.8z"/>
      <path className="st0" d="M572.7,555.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C574.7,551.9,572.7,553.6,572.7,555.8z"/>
      <path className="st0" d="M563.6,555.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S563.6,553.6,563.6,555.8z"/>
      <path className="st0" d="M554.3,555.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C555.9,551.9,554.3,553.6,554.3,555.8z"/>
      <path className="st0" d="M545.2,555.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S545.2,553.6,545.2,555.8z"/>
      <path className="st0" d="M536.1,555.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C537.8,551.9,536.1,553.6,536.1,555.8z"/>
      <path className="st0" d="M526.8,555.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S526.8,553.6,526.8,555.8z"/>
      <path className="st0" d="M517.7,555.8c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S517.7,553.6,517.7,555.8z"/>
      <path className="st0" d="M512.2,551.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C516.3,553.6,514.4,551.9,512.2,551.9z"/>
      <path className="st0" d="M499.3,555.8c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S499.3,553.6,499.3,555.8z"/>
      <path className="st0" d="M489.9,555.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C491.8,551.9,489.9,553.6,489.9,555.8z"/>
      <path className="st0" d="M480.8,555.8c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S480.8,553.6,480.8,555.8z"/>
      <path className="st0" d="M471.5,555.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C473.4,551.9,471.5,553.6,471.5,555.8z"/>
      <path className="st0" d="M462.4,555.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C464,551.9,462.4,553.6,462.4,555.8z"/>
      <path className="st0" d="M457.2,551.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C461,553.6,459.1,551.9,457.2,551.9z"/>
      <path className="st0" d="M443.9,555.8c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S443.9,553.6,443.9,555.8z"/>
      <path className="st0" d="M438.7,551.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C442.6,553.6,440.6,551.9,438.7,551.9z"/>
      <path className="st0" d="M429.4,551.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C433.2,553.6,431.6,551.9,429.4,551.9z"/>
      <path className="st0" d="M420.3,551.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C424.1,553.6,422.5,551.9,420.3,551.9z"/>
      <path className="st0" d="M410.9,559.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C407.1,558,408.7,559.7,410.9,559.7z"/>
      <ellipse className="st0" cx="1175.3" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,550.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.4,548.9,1164.1,550.6,1166.3,550.6z"/>
      <path className="st0" d="M1156.9,550.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,548.9,1154.7,550.6,1156.9,550.6z"/>
      <ellipse className="st0" cx="1147.8" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1138.5,550.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,548.9,1136.3,550.6,1138.5,550.6z"/>
      <path className="st0" d="M1129.4,550.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,548.9,1127.2,550.6,1129.4,550.6z"/>
      <path className="st0" d="M1120,550.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,548.9,1117.8,550.6,1120,550.6z"/>
      <ellipse className="st0" cx="1111" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1101.6,550.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,548.9,1099.7,550.6,1101.6,550.6z"/>
      <path className="st0" d="M1092.5,550.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,548.9,1090.3,550.6,1092.5,550.6z"/>
      <path className="st0" d="M1087.3,546.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1085.4,550.6,1087.3,548.6,1087.3,546.7z"/>
      <ellipse className="st0" cx="1074.1" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1068.9,546.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1066.9,550.6,1068.9,548.6,1068.9,546.7z"/>
      <path className="st0" d="M1055.6,550.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,548.9,1053.4,550.6,1055.6,550.6z"/>
      <ellipse className="st0" cx="1046.6" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1037.2,550.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,548.9,1035,550.6,1037.2,550.6z"/>
      <path className="st0" d="M1028.1,550.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024.3,548.9,1025.9,550.6,1028.1,550.6z"/>
      <path className="st0" d="M1018.8,550.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,548.9,1016.6,550.6,1018.8,550.6z"/>
      <ellipse className="st0" cx="1009.7" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1004.2,546.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1002.5,550.6,1004.2,548.6,1004.2,546.7z"/>
      <path className="st0" d="M995.1,546.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S995.1,548.6,995.1,546.7z"
        />
      <path className="st0" d="M986,546.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C984.1,550.6,986,548.6,986,546.7z"/>
      <ellipse className="st0" cx="972.8" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M967.6,546.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C965.7,550.6,967.6,548.6,967.6,546.7z"/>
      <path className="st0" d="M958.2,546.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C956.6,550.6,958.2,548.6,958.2,546.7z"/>
      <path className="st0" d="M949.2,546.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,550.6,949.2,548.6,949.2,546.7z"/>
      <ellipse className="st0" cx="936" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M930.7,546.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C928.8,550.6,930.7,548.6,930.7,546.7z"/>
      <path className="st0" d="M921.4,546.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C919.7,550.6,921.4,548.6,921.4,546.7z"/>
      <ellipse className="st0" cx="908.4" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M895.2,546.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C896.9,542.6,895.2,544.5,895.2,546.7z"/>
      <path className="st0" d="M885.9,546.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C887.8,542.6,885.9,544.5,885.9,546.7z"/>
      <path className="st0" d="M876.8,546.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C878.4,542.6,876.8,544.5,876.8,546.7z"/>
      <ellipse className="st0" cx="871.6" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M858.4,546.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C860,542.6,858.4,544.5,858.4,546.7z"/>
      <path className="st0" d="M849,546.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C850.9,542.6,849,544.5,849,546.7z"/>
      <path className="st0" d="M816.3,542.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C820.1,544.2,818.5,542.6,816.3,542.6z"/>
      <path className="st0" d="M806.9,542.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C810.7,544.2,809.1,542.6,806.9,542.6z"/>
      <ellipse className="st0" cx="797.8" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M788.5,542.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C792.3,544.2,790.7,542.6,788.5,542.6z"/>
      <path className="st0" d="M779.4,542.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C783.2,544.2,781.6,542.6,779.4,542.6z"/>
      <path className="st0" d="M770.3,542.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C774.2,544.2,772.2,542.6,770.3,542.6z"/>
      <ellipse className="st0" cx="760.9" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M747.7,546.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C749.7,542.6,747.7,544.5,747.7,546.7z"/>
      <path className="st0" d="M742.5,542.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C746.4,544.2,744.7,542.6,742.5,542.6z"/>
      <path className="st0" d="M733.4,542.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,544.2,735.4,542.6,733.4,542.6z"/>
      <ellipse className="st0" cx="724.1" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M715,542.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,544.2,717.2,542.6,715,542.6z"/>
      <path className="st0" d="M705.6,542.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,544.2,707.8,542.6,705.6,542.6z"/>
      <ellipse className="st0" cx="696.6" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M687.2,550.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C683.3,548.9,685,550.6,687.2,550.6z"/>
      <path className="st0" d="M678.1,550.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,548.9,675.9,550.6,678.1,550.6z"/>
      <path className="st0" d="M672.9,546.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C671,550.6,672.9,548.6,672.9,546.7z"/>
      <ellipse className="st0" cx="659.7" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M654.5,546.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C652.5,550.6,654.5,548.6,654.5,546.7z"/>
      <ellipse className="st0" cx="641.2" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M636,546.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C634.1,550.6,636,548.6,636,546.7z"
        />
      <ellipse className="st0" cx="622.8" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M613.7,550.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C609.9,548.9,611.5,550.6,613.7,550.6z"/>
      <ellipse className="st0" cx="604.4" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M591.2,546.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C593.1,542.6,591.2,544.5,591.2,546.7z"/>
      <ellipse className="st0" cx="585.9" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M572.7,546.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C574.7,542.6,572.7,544.5,572.7,546.7z"/>
      <path className="st0" d="M563.6,546.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C565.3,542.6,563.6,544.5,563.6,546.7z"/>
      <ellipse className="st0" cx="558.4" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M545.2,546.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C546.9,542.6,545.2,544.5,545.2,546.7z"/>
      <ellipse className="st0" cx="540" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M526.8,546.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C528.4,542.6,526.8,544.5,526.8,546.7z"/>
      <ellipse className="st0" cx="521.5" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M512.2,542.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C516,544.2,514.4,542.6,512.2,542.6z"/>
      <ellipse className="st0" cx="503.1" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M489.9,546.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C491.8,542.6,489.9,544.5,489.9,546.7z"/>
      <ellipse className="st0" cx="484.7" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M471.5,546.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C473.4,542.6,471.5,544.5,471.5,546.7z"/>
      <ellipse className="st0" cx="466.2" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M457.2,542.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C461,544.2,459.1,542.6,457.2,542.6z"/>
      <ellipse className="st0" cx="447.8" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M438.7,550.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C434.9,548.9,436.5,550.6,438.7,550.6z"/>
      <path className="st0" d="M425.5,546.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C427.2,542.6,425.5,544.5,425.5,546.7z"/>
      <ellipse className="st0" cx="420.3" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st0" d="M410.9,550.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C407.1,548.9,408.7,550.6,410.9,550.6z"/>
      <path className="st0" d="M1175.3,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1171.5,539.6,1173.1,541.2,1175.3,541.2z"/>
      <path className="st0" d="M1166.3,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.1,539.6,1164.1,541.2,1166.3,541.2z"/>
      <path className="st0" d="M1156.9,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,539.6,1154.7,541.2,1156.9,541.2z"/>
      <path className="st0" d="M1147.8,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1144,539.6,1145.6,541.2,1147.8,541.2z"/>
      <path className="st0" d="M1138.5,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,539.6,1136.3,541.2,1138.5,541.2z"/>
      <path className="st0" d="M1129.4,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,539.6,1127.2,541.2,1129.4,541.2z"/>
      <path className="st0" d="M1120,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,539.6,1117.8,541.2,1120,541.2z"/>
      <path className="st0" d="M1114.8,537.4c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S1114.8,539.6,1114.8,537.4z"/>
      <path className="st0" d="M1101.6,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,539.6,1099.7,541.2,1101.6,541.2z"/>
      <path className="st0" d="M1092.5,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,539.6,1090.3,541.2,1092.5,541.2z"/>
      <path className="st0" d="M1087.3,537.4c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1085.4,541.2,1087.3,539.6,1087.3,537.4z"/>
      <path className="st0" d="M1074.1,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1070.2,539.6,1071.9,541.2,1074.1,541.2z"/>
      <path className="st0" d="M1068.9,537.4c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1066.9,541.2,1068.9,539.6,1068.9,537.4z"/>
      <path className="st0" d="M1055.6,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,539.6,1053.4,541.2,1055.6,541.2z"/>
      <path className="st0" d="M1046.6,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,539.6,1044.4,541.2,1046.6,541.2z"/>
      <path className="st0" d="M1037.2,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,539.6,1035,541.2,1037.2,541.2z"/>
      <path className="st0" d="M1028.1,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,539.6,1025.9,541.2,1028.1,541.2z"/>
      <path className="st0" d="M1018.8,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,539.6,1016.6,541.2,1018.8,541.2z"/>
      <path className="st0" d="M1009.7,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,539.6,1007.5,541.2,1009.7,541.2z"/>
      <path className="st0" d="M1004.2,537.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1002.5,541.2,1004.2,539.6,1004.2,537.4z"/>
      <path className="st0" d="M995.1,537.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S995.1,539.6,995.1,537.4z"
        />
      <path className="st0" d="M986,537.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C984.1,541.2,986,539.6,986,537.4z"/>
      <path className="st0" d="M976.7,537.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C975,541.2,976.7,539.6,976.7,537.4z"/>
      <path className="st0" d="M967.6,537.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C965.7,541.2,967.6,539.6,967.6,537.4z"/>
      <path className="st0" d="M958.2,537.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C956.6,541.2,958.2,539.6,958.2,537.4z"/>
      <path className="st0" d="M949.2,537.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,541.2,949.2,539.6,949.2,537.4z"/>
      <path className="st0" d="M939.8,537.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C938.2,541.2,939.8,539.6,939.8,537.4z"/>
      <path className="st0" d="M930.7,537.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C928.8,541.2,930.7,539.6,930.7,537.4z"/>
      <path className="st0" d="M921.4,537.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C919.7,541.2,921.4,539.6,921.4,537.4z"/>
      <path className="st0" d="M904.3,537.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C906,533.5,904.3,535.2,904.3,537.4z"/>
      <path className="st0" d="M895.2,537.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C896.9,533.5,895.2,535.2,895.2,537.4z"/>
      <path className="st0" d="M885.9,537.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C887.8,533.5,885.9,535.2,885.9,537.4z"/>
      <path className="st0" d="M876.8,537.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C878.4,533.5,876.8,535.2,876.8,537.4z"/>
      <path className="st0" d="M867.4,537.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C869.1,533.5,867.4,535.2,867.4,537.4z"/>
      <path className="st0" d="M858.4,537.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C860,533.5,858.4,535.2,858.4,537.4z"/>
      <path className="st0" d="M853.1,533.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C857,535.2,855.3,533.5,853.1,533.5z"/>
      <path className="st0" d="M770.3,533.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C774.2,535.2,772.2,533.5,770.3,533.5z"/>
      <path className="st0" d="M760.9,533.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C764.8,535.2,763.1,533.5,760.9,533.5z"/>
      <path className="st0" d="M747.7,537.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C749.7,533.5,747.7,535.2,747.7,537.4z"/>
      <path className="st0" d="M742.5,533.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C746.4,535.2,744.7,533.5,742.5,533.5z"/>
      <path className="st0" d="M733.4,533.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,535.2,735.4,533.5,733.4,533.5z"/>
      <path className="st0" d="M724.1,533.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,535.2,726.3,533.5,724.1,533.5z"/>
      <path className="st0" d="M715,533.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,535.2,717.2,533.5,715,533.5z"/>
      <path className="st0" d="M705.6,533.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,535.2,707.8,533.5,705.6,533.5z"/>
      <path className="st0" d="M687.2,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C683.3,539.6,685,541.2,687.2,541.2z"/>
      <path className="st0" d="M678.1,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,539.6,675.9,541.2,678.1,541.2z"/>
      <path className="st0" d="M672.9,537.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C671,541.2,672.9,539.6,672.9,537.4z"/>
      <path className="st0" d="M663.5,537.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C661.9,541.2,663.5,539.6,663.5,537.4z"/>
      <path className="st0" d="M641.2,533.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C645.1,535.2,643.4,533.5,641.2,533.5z"/>
      <path className="st0" d="M631.9,533.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C636,535.2,634.1,533.5,631.9,533.5z"/>
      <path className="st0" d="M622.8,533.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C626.7,535.2,625,533.5,622.8,533.5z"/>
      <path className="st0" d="M609.6,537.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C611.5,533.5,609.6,535.2,609.6,537.4z"/>
      <path className="st0" d="M600.5,537.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C602.2,533.5,600.5,535.2,600.5,537.4z"/>
      <path className="st0" d="M591.2,537.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C593.1,533.5,591.2,535.2,591.2,537.4z"/>
      <path className="st0" d="M585.9,533.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C589.8,535.2,588.1,533.5,585.9,533.5z"/>
      <path className="st0" d="M572.7,537.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C574.7,533.5,572.7,535.2,572.7,537.4z"/>
      <path className="st0" d="M563.6,537.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S563.6,535.2,563.6,537.4z"/>
      <path className="st0" d="M554.3,537.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C555.9,533.5,554.3,535.2,554.3,537.4z"/>
      <path className="st0" d="M545.2,537.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S545.2,535.2,545.2,537.4z"/>
      <path className="st0" d="M536.1,537.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C537.8,533.5,536.1,535.2,536.1,537.4z"/>
      <path className="st0" d="M526.8,537.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S526.8,535.2,526.8,537.4z"/>
      <path className="st0" d="M517.7,537.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S517.7,535.2,517.7,537.4z"/>
      <path className="st0" d="M512.2,533.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C516.3,535.2,514.4,533.5,512.2,533.5z"/>
      <path className="st0" d="M499.3,537.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S499.3,535.2,499.3,537.4z"/>
      <path className="st0" d="M489.9,537.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C491.8,533.5,489.9,535.2,489.9,537.4z"/>
      <path className="st0" d="M480.8,537.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S480.8,535.2,480.8,537.4z"/>
      <path className="st0" d="M471.5,537.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C473.4,533.5,471.5,535.2,471.5,537.4z"/>
      <path className="st0" d="M466.2,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C462.4,539.6,464,541.2,466.2,541.2z"/>
      <path className="st0" d="M457.2,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C453,539.6,455,541.2,457.2,541.2z"/>
      <path className="st0" d="M443.9,537.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S443.9,535.2,443.9,537.4z"/>
      <path className="st0" d="M407.1,537.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C408.7,533.5,407.1,535.2,407.1,537.4z"/>
      <ellipse className="st0" cx="1175.3" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,532.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.4,530.5,1164.1,532.1,1166.3,532.1z"/>
      <path className="st0" d="M1156.9,532.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,530.5,1154.7,532.1,1156.9,532.1z"/>
      <ellipse className="st0" cx="1147.8" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1138.5,532.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,530.5,1136.3,532.1,1138.5,532.1z"/>
      <path className="st0" d="M1129.4,532.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,530.5,1127.2,532.1,1129.4,532.1z"/>
      <path className="st0" d="M1120,532.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,530.5,1117.8,532.1,1120,532.1z"/>
      <ellipse className="st0" cx="1111" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1101.6,532.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,530.5,1099.7,532.1,1101.6,532.1z"/>
      <path className="st0" d="M1092.5,532.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,530.5,1090.3,532.1,1092.5,532.1z"/>
      <path className="st0" d="M1087.3,528.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1085.4,532.1,1087.3,530.5,1087.3,528.3z"/>
      <ellipse className="st0" cx="1074.1" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1068.9,528.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1066.9,532.1,1068.9,530.5,1068.9,528.3z"/>
      <path className="st0" d="M1055.6,532.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,530.5,1053.4,532.1,1055.6,532.1z"/>
      <ellipse className="st0" cx="1046.6" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1037.2,532.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,530.5,1035,532.1,1037.2,532.1z"/>
      <path className="st0" d="M1028.1,532.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024.3,530.5,1025.9,532.1,1028.1,532.1z"/>
      <path className="st0" d="M1018.8,532.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,530.5,1016.6,532.1,1018.8,532.1z"/>
      <ellipse className="st0" cx="1009.7" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1004.2,528.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1002.5,532.1,1004.2,530.5,1004.2,528.3z"/>
      <path className="st0" d="M995.1,528.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C993.5,532.1,995.1,530.5,995.1,528.3z"/>
      <path className="st0" d="M986,528.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C984.1,532.1,986,530.5,986,528.3z"
        />
      <ellipse className="st0" cx="972.8" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M967.6,528.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C965.7,532.1,967.6,530.5,967.6,528.3z"/>
      <path className="st0" d="M958.2,528.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C956.6,532.1,958.2,530.5,958.2,528.3z"/>
      <path className="st0" d="M949.2,528.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C947.2,532.1,949.2,530.5,949.2,528.3z"/>
      <ellipse className="st0" cx="936" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M930.7,528.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C928.8,532.1,930.7,530.5,930.7,528.3z"/>
      <path className="st0" d="M913.7,528.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C915.3,524.4,913.7,526.1,913.7,528.3z"/>
      <ellipse className="st0" cx="908.4" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M895.2,528.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C896.9,524.4,895.2,526.1,895.2,528.3z"/>
      <path className="st0" d="M885.9,528.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C887.8,524.4,885.9,526.1,885.9,528.3z"/>
      <path className="st0" d="M876.8,528.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C878.4,524.4,876.8,526.1,876.8,528.3z"/>
      <ellipse className="st0" cx="871.6" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M862.2,524.4c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S864.4,524.4,862.2,524.4z"/>
      <path className="st0" d="M770.3,524.4c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S772.2,524.4,770.3,524.4z"/>
      <ellipse className="st0" cx="760.9" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M751.9,524.4c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S753.8,524.4,751.9,524.4z"/>
      <path className="st0" d="M742.5,524.4c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S744.7,524.4,742.5,524.4z"/>
      <path className="st0" d="M733.4,524.4c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S735.4,524.4,733.4,524.4z"/>
      <ellipse className="st0" cx="724.1" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M715,524.4c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S717.2,524.4,715,524.4z"/>
      <path className="st0" d="M705.6,524.4c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S707.8,524.4,705.6,524.4z"/>
      <ellipse className="st0" cx="696.6" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M687.2,524.4c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S689.4,524.4,687.2,524.4z"/>
      <path className="st0" d="M678.1,524.4c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S680.3,524.4,678.1,524.4z"/>
      <path className="st0" d="M672.9,528.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C671,532.1,672.9,530.5,672.9,528.3z"/>
      <ellipse className="st0" cx="659.7" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M646.5,528.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C648.4,524.4,646.5,526.1,646.5,528.3z"/>
      <ellipse className="st0" cx="641.2" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M631.9,524.4c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S634.1,524.4,631.9,524.4z"/>
      <ellipse className="st0" cx="622.8" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M609.6,528.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C611.5,524.4,609.6,526.1,609.6,528.3z"/>
      <ellipse className="st0" cx="604.4" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M591.2,528.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C593.1,524.4,591.2,526.1,591.2,528.3z"/>
      <ellipse className="st0" cx="585.9" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M572.7,528.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C574.7,524.4,572.7,526.1,572.7,528.3z"/>
      <path className="st0" d="M563.6,528.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S563.6,526.1,563.6,528.3z"/>
      <ellipse className="st0" cx="558.4" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M545.2,528.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S545.2,526.1,545.2,528.3z"/>
      <ellipse className="st0" cx="540" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M526.8,528.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S526.8,526.1,526.8,528.3z"/>
      <ellipse className="st0" cx="521.5" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M512.2,524.4c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S514.4,524.4,512.2,524.4z"/>
      <path className="st0" d="M493.8,532.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C489.9,530.5,491.8,532.1,493.8,532.1z"/>
      <ellipse className="st0" cx="484.7" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M475.3,532.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C471.5,530.5,473.4,532.1,475.3,532.1z"/>
      <ellipse className="st0" cx="466.2" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1175.3,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1179.2,517,1177.5,515.1,1175.3,515.1z"/>
      <path className="st0" d="M1166.3,523.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.1,521.1,1164.1,523.1,1166.3,523.1z"/>
      <path className="st0" d="M1156.9,523.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,521.1,1154.7,523.1,1156.9,523.1z"/>
      <path className="st0" d="M1147.8,523.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1144,521.1,1145.6,523.1,1147.8,523.1z"/>
      <path className="st0" d="M1138.5,523.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,521.1,1136.3,523.1,1138.5,523.1z"/>
      <path className="st0" d="M1129.4,523.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,521.1,1127.2,523.1,1129.4,523.1z"/>
      <path className="st0" d="M1120,523.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,521.1,1117.8,523.1,1120,523.1z"/>
      <path className="st0" d="M1114.8,518.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S1114.8,521.1,1114.8,518.9z"/>
      <path className="st0" d="M1101.6,523.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,521.1,1099.7,523.1,1101.6,523.1z"/>
      <path className="st0" d="M1092.5,523.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,521.1,1090.3,523.1,1092.5,523.1z"/>
      <path className="st0" d="M1087.3,518.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1085.4,523.1,1087.3,521.1,1087.3,518.9z"/>
      <path className="st0" d="M1074.1,523.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1070.2,521.1,1071.9,523.1,1074.1,523.1z"/>
      <path className="st0" d="M1068.9,518.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1066.9,523.1,1068.9,521.1,1068.9,518.9z"/>
      <path className="st0" d="M1055.6,523.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,521.1,1053.4,523.1,1055.6,523.1z"/>
      <path className="st0" d="M1046.6,523.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,521.1,1044.4,523.1,1046.6,523.1z"/>
      <path className="st0" d="M1037.2,523.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,521.1,1035,523.1,1037.2,523.1z"/>
      <path className="st0" d="M1028.1,523.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,521.1,1025.9,523.1,1028.1,523.1z"/>
      <path className="st0" d="M1018.8,523.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,521.1,1016.6,523.1,1018.8,523.1z"/>
      <path className="st0" d="M1009.7,523.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,521.1,1007.5,523.1,1009.7,523.1z"/>
      <path className="st0" d="M1004.2,518.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1002.5,523.1,1004.2,521.1,1004.2,518.9z"/>
      <path className="st0" d="M995.1,518.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C993.5,523.1,995.1,521.1,995.1,518.9z"/>
      <path className="st0" d="M986,518.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C984.1,523.1,986,521.1,986,518.9z"/>
      <path className="st0" d="M976.7,518.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C975,522.8,976.7,521.1,976.7,518.9z"/>
      <path className="st0" d="M967.6,518.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C965.7,523.1,967.6,521.1,967.6,518.9z"/>
      <path className="st0" d="M958.2,518.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C956.6,523.1,958.2,521.1,958.2,518.9z"/>
      <path className="st0" d="M949.2,518.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,523.1,949.2,521.1,949.2,518.9z"/>
      <path className="st0" d="M939.8,518.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C938.2,522.8,939.8,521.1,939.8,518.9z"/>
      <path className="st0" d="M922.7,518.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C924.7,515.1,922.7,517,922.7,518.9
        z"/>
      <path className="st0" d="M913.7,518.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C915.3,515.1,913.7,517,913.7,518.9z"/>
      <path className="st0" d="M904.3,518.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C906,515.1,904.3,517,904.3,518.9z"/>
      <path className="st0" d="M895.2,518.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C896.9,515.1,895.2,517,895.2,518.9z"/>
      <path className="st0" d="M885.9,518.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C887.8,515.1,885.9,517,885.9,518.9z"/>
      <path className="st0" d="M876.8,518.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C878.4,515.1,876.8,517,876.8,518.9z"/>
      <path className="st0" d="M867.4,518.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C869.1,515.1,867.4,517,867.4,518.9z"/>
      <path className="st0" d="M742.5,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C746.4,517,744.7,515.1,742.5,515.1z"/>
      <path className="st0" d="M733.4,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,517,735.4,515.1,733.4,515.1z"/>
      <path className="st0" d="M724.1,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,517,726.3,515.1,724.1,515.1z"/>
      <path className="st0" d="M715,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,517,717.2,515.1,715,515.1z"/>
      <path className="st0" d="M705.6,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,517,707.8,515.1,705.6,515.1z"/>
      <path className="st0" d="M696.6,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,517,698.8,515.1,696.6,515.1z"/>
      <path className="st0" d="M687.2,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.3,517,689.4,515.1,687.2,515.1z"/>
      <path className="st0" d="M678.1,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C682,517,680.3,515.1,678.1,515.1z"/>
      <path className="st0" d="M668.8,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C672.9,517,671,515.1,668.8,515.1z"/>
      <path className="st0" d="M659.7,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C663.5,517,661.9,515.1,659.7,515.1z"/>
      <path className="st0" d="M646.5,518.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C648.4,515.1,646.5,517,646.5,518.9z"/>
      <path className="st0" d="M641.2,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C645.1,517,643.4,515.1,641.2,515.1z"/>
      <path className="st0" d="M631.9,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C636,517,634.1,515.1,631.9,515.1z
        "/>
      <path className="st0" d="M622.8,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C626.7,517,625,515.1,622.8,515.1z"/>
      <path className="st0" d="M609.6,518.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C611.5,515.1,609.6,517,609.6,518.9z"/>
      <path className="st0" d="M600.5,518.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C602.2,515.1,600.5,517,600.5,518.9z"/>
      <path className="st0" d="M591.2,518.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C593.1,515.1,591.2,517,591.2,518.9z"/>
      <path className="st0" d="M585.9,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C589.8,517,588.1,515.1,585.9,515.1z"/>
      <path className="st0" d="M572.7,518.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C574.7,515.1,572.7,517,572.7,518.9z"/>
      <path className="st0" d="M563.6,518.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S563.6,517,563.6,518.9z"/>
      <path className="st0" d="M554.3,518.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C555.9,515.1,554.3,517,554.3,518.9z"/>
      <path className="st0" d="M545.2,518.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S545.2,517,545.2,518.9z"/>
      <path className="st0" d="M536.1,518.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C537.8,515.1,536.1,517,536.1,518.9z"/>
      <path className="st0" d="M526.8,518.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S526.8,517,526.8,518.9z"/>
      <path className="st0" d="M517.7,518.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S517.7,517,517.7,518.9z"/>
      <path className="st0" d="M512.2,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C516.3,517,514.4,515.1,512.2,515.1z"/>
      <path className="st0" d="M503.1,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C507,517,505.3,515.1,503.1,515.1z
        "/>
      <path className="st0" d="M489.9,518.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C491.8,515.1,489.9,517,489.9,518.9z"/>
      <path className="st0" d="M484.7,523.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C480.8,521.1,482.5,523.1,484.7,523.1z"/>
      <path className="st0" d="M475.3,523.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C471.5,521.1,473.4,523.1,475.3,523.1z"/>
      <path className="st0" d="M365,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C368.8,517,367.2,515.1,365,515.1z"/>
      <path className="st0" d="M355.6,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C359.7,517,357.8,515.1,355.6,515.1z"/>
      <path className="st0" d="M346.5,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C350.4,517,348.7,515.1,346.5,515.1z"/>
      <ellipse className="st0" cx="1175.3" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,513.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.4,512,1164.1,513.7,1166.3,513.7z"/>
      <path className="st0" d="M1156.9,513.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,512,1154.7,513.7,1156.9,513.7z"/>
      <ellipse className="st0" cx="1147.8" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1138.5,513.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,512,1136.3,513.7,1138.5,513.7z"/>
      <path className="st0" d="M1129.4,513.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,512,1127.2,513.7,1129.4,513.7z"/>
      <path className="st0" d="M1120,513.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,512,1117.8,513.7,1120,513.7z"/>
      <ellipse className="st0" cx="1111" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1101.6,513.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,512,1099.7,513.7,1101.6,513.7z"/>
      <path className="st0" d="M1092.5,513.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,512,1090.3,513.7,1092.5,513.7z"/>
      <path className="st0" d="M1087.3,509.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1085.4,513.7,1087.3,512,1087.3,509.8z"/>
      <ellipse className="st0" cx="1074.1" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1068.9,509.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1066.9,513.7,1068.9,512,1068.9,509.8z"/>
      <path className="st0" d="M1055.6,513.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,512,1053.4,513.7,1055.6,513.7z"/>
      <ellipse className="st0" cx="1046.6" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1037.2,513.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,512,1035,513.7,1037.2,513.7z"/>
      <path className="st0" d="M1028.1,513.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024.3,512,1025.9,513.7,1028.1,513.7z"/>
      <path className="st0" d="M1018.8,513.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,512,1016.6,513.7,1018.8,513.7z"/>
      <ellipse className="st0" cx="1009.7" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1004.2,509.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1002.5,513.7,1004.2,512,1004.2,509.8z"/>
      <path className="st0" d="M995.1,509.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S995.1,512,995.1,509.8z"/>
      <path className="st0" d="M986,509.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C984.1,513.7,986,512,986,509.8z"/>
      <ellipse className="st0" cx="972.8" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M967.6,509.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C965.7,513.7,967.6,512,967.6,509.8z"/>
      <path className="st0" d="M958.2,509.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C956.6,513.7,958.2,512,958.2,509.8z"/>
      <path className="st0" d="M949.2,509.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,513.7,949.2,512,949.2,509.8z"/>
      <ellipse className="st0" cx="936" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M922.7,509.8c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C924.7,506,922.7,507.6,922.7,509.8
        z"/>
      <path className="st0" d="M913.7,509.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C915.3,506,913.7,507.6,913.7,509.8z"/>
      <ellipse className="st0" cx="908.4" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M895.2,509.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C896.9,506,895.2,507.6,895.2,509.8z"/>
      <path className="st0" d="M885.9,509.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C887.8,506,885.9,507.6,885.9,509.8z"/>
      <path className="st0" d="M876.8,509.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C878.4,506,876.8,507.6,876.8,509.8z"/>
      <ellipse className="st0" cx="871.6" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M733.4,506c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,507.6,735.4,506,733.4,506z"/>
      <ellipse className="st0" cx="724.1" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M715,506c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C718.8,507.6,717.2,506,715,506z
        "/>
      <path className="st0" d="M705.6,506c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,507.6,707.8,506,705.6,506z"/>
      <ellipse className="st0" cx="696.6" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M687.2,506c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.1,507.6,689.4,506,687.2,506z"/>
      <path className="st0" d="M678.1,506c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C682,507.6,680.3,506,678.1,506z"/>
      <path className="st0" d="M668.8,506c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C672.6,507.6,671,506,668.8,506z"/>
      <ellipse className="st0" cx="659.7" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M646.5,509.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C648.4,506,646.5,507.6,646.5,509.8z"/>
      <ellipse className="st0" cx="641.2" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M631.9,506c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C635.7,507.6,634.1,506,631.9,506z"
        />
      <ellipse className="st0" cx="622.8" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M609.6,509.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C611.5,506,609.6,507.6,609.6,509.8z"/>
      <ellipse className="st0" cx="604.4" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M591.2,509.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C593.1,506,591.2,507.6,591.2,509.8z"/>
      <ellipse className="st0" cx="585.9" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M572.7,509.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C574.7,506,572.7,507.6,572.7,509.8z"/>
      <path className="st0" d="M563.6,509.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S563.6,507.6,563.6,509.8z"/>
      <ellipse className="st0" cx="558.4" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M545.2,509.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S545.2,507.6,545.2,509.8z"/>
      <ellipse className="st0" cx="540" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M526.8,509.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S526.8,507.6,526.8,509.8z"/>
      <ellipse className="st0" cx="521.5" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M512.2,506c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C516,507.6,514.4,506,512.2,506z"/>
      <ellipse className="st0" cx="503.1" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M489.9,509.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C491.8,506,489.9,507.6,489.9,509.8z"/>
      <ellipse className="st0" cx="484.7" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M475.3,513.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C471.5,512,473.4,513.7,475.3,513.7z"/>
      <ellipse className="st0" cx="466.2" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M392.5,506c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C396.3,507.6,394.7,506,392.5,506z"/>
      <path className="st0" d="M374.1,506c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C377.9,507.6,376.3,506,374.1,506z"/>
      <ellipse className="st0" cx="365" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st0" d="M351.8,509.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C353.7,506,351.8,507.6,351.8,509.8z"/>
      <path className="st0" d="M1175.3,504.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1171.5,502.7,1173.1,504.6,1175.3,504.6z"/>
      <path className="st0" d="M1166.3,504.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.1,502.7,1164.1,504.6,1166.3,504.6z"/>
      <path className="st0" d="M1156.9,504.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,502.7,1154.7,504.6,1156.9,504.6z"/>
      <path className="st0" d="M1147.8,504.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1144,502.7,1145.6,504.6,1147.8,504.6z"/>
      <path className="st0" d="M1138.5,504.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,502.7,1136.3,504.6,1138.5,504.6z"/>
      <path className="st0" d="M1129.4,504.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,502.7,1127.2,504.6,1129.4,504.6z"/>
      <path className="st0" d="M1120,504.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,502.7,1117.8,504.6,1120,504.6z"/>
      <path className="st0" d="M1114.8,500.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S1114.8,502.7,1114.8,500.8z"/>
      <path className="st0" d="M1101.6,504.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,502.7,1099.7,504.6,1101.6,504.6z"/>
      <path className="st0" d="M1092.5,504.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,502.7,1090.3,504.6,1092.5,504.6z"/>
      <path className="st0" d="M1087.3,500.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1085.4,504.6,1087.3,502.7,1087.3,500.8z"/>
      <path className="st0" d="M1074.1,504.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1070.2,502.7,1071.9,504.6,1074.1,504.6z"/>
      <path className="st0" d="M1068.9,500.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1066.9,504.6,1068.9,502.7,1068.9,500.8z"/>
      <path className="st0" d="M1055.6,504.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,502.7,1053.4,504.6,1055.6,504.6z"/>
      <path className="st0" d="M1046.6,504.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,502.7,1044.4,504.6,1046.6,504.6z"/>
      <path className="st0" d="M1037.2,504.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C1033.4,502.7,1035,504.6,1037.2,504.6
        z"/>
      <path className="st0" d="M1028.1,504.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,502.7,1025.9,504.6,1028.1,504.6z"/>
      <path className="st0" d="M1018.8,504.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,502.7,1016.6,504.6,1018.8,504.6z"/>
      <path className="st0" d="M1009.7,504.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,502.7,1007.5,504.6,1009.7,504.6z"/>
      <path className="st0" d="M1004.2,500.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1002.5,504.6,1004.2,502.7,1004.2,500.8z"/>
      <path className="st0" d="M995.1,500.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C993.5,504.6,995.1,502.7,995.1,500.8z"/>
      <path className="st0" d="M986,500.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C984.1,504.6,986,502.7,986,500.8z"/>
      <path className="st0" d="M976.7,500.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C975,504.6,976.7,502.7,976.7,500.8z"/>
      <path className="st0" d="M967.6,500.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C965.7,504.6,967.6,502.7,967.6,500.8z"/>
      <path className="st0" d="M958.2,500.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C956.6,504.6,958.2,502.7,958.2,500.8z"/>
      <path className="st0" d="M941.2,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C943.1,496.6,941.2,498.6,941.2,500.8z"/>
      <path className="st0" d="M932.1,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C933.7,496.9,932.1,498.6,932.1,500.8z"/>
      <path className="st0" d="M922.7,500.8c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C924.7,496.6,922.7,498.6,922.7,500.8z"/>
      <path className="st0" d="M913.7,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C915.3,496.6,913.7,498.6,913.7,500.8z"/>
      <path className="st0" d="M904.3,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C906,496.9,904.3,498.6,904.3,500.8z"/>
      <path className="st0" d="M895.2,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C896.9,496.6,895.2,498.6,895.2,500.8z"/>
      <path className="st0" d="M885.9,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C887.8,496.6,885.9,498.6,885.9,500.8z"/>
      <path className="st0" d="M876.8,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C878.4,496.6,876.8,498.6,876.8,500.8z"/>
      <path className="st0" d="M867.4,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C869.1,496.9,867.4,498.6,867.4,500.8z"/>
      <path className="st0" d="M705.6,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,498.6,707.8,496.6,705.6,496.6z"/>
      <path className="st0" d="M696.6,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,498.6,698.8,496.6,696.6,496.6z"/>
      <path className="st0" d="M687.2,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.3,498.6,689.4,496.6,687.2,496.6z"/>
      <path className="st0" d="M678.1,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C682,498.6,680.3,496.6,678.1,496.6z"/>
      <path className="st0" d="M668.8,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C672.9,498.6,671,496.6,668.8,496.6z"/>
      <path className="st0" d="M659.7,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C663.5,498.6,661.9,496.6,659.7,496.6z"/>
      <path className="st0" d="M646.5,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C648.4,496.6,646.5,498.6,646.5,500.8z"/>
      <path className="st0" d="M641.2,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C645.1,498.6,643.4,496.6,641.2,496.6z"
        />
      <path className="st0" d="M631.9,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C636,498.6,634.1,496.6,631.9,496.6z"/>
      <path className="st0" d="M622.8,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C626.7,498.6,625,496.6,622.8,496.6z"/>
      <path className="st0" d="M609.6,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C611.5,496.6,609.6,498.6,609.6,500.8z"/>
      <path className="st0" d="M600.5,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C602.2,496.9,600.5,498.6,600.5,500.8z"/>
      <path className="st0" d="M591.2,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C593.1,496.6,591.2,498.6,591.2,500.8z"/>
      <path className="st0" d="M585.9,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C589.8,498.6,588.1,496.6,585.9,496.6z"/>
      <path className="st0" d="M572.7,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C574.7,496.6,572.7,498.6,572.7,500.8z"/>
      <path className="st0" d="M563.6,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C565.3,496.6,563.6,498.6,563.6,500.8z"/>
      <path className="st0" d="M554.3,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C555.9,496.9,554.3,498.6,554.3,500.8z"/>
      <path className="st0" d="M545.2,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C546.9,496.6,545.2,498.6,545.2,500.8z"/>
      <path className="st0" d="M536.1,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C537.8,496.9,536.1,498.6,536.1,500.8z"/>
      <path className="st0" d="M526.8,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C528.4,496.6,526.8,498.6,526.8,500.8z"/>
      <path className="st0" d="M517.7,500.8c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S517.7,498.6,517.7,500.8z"/>
      <path className="st0" d="M512.2,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C516.3,498.6,514.4,496.6,512.2,496.6z"
        />
      <path className="st0" d="M503.1,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C507,498.6,505.3,496.6,503.1,496.6z"/>
      <path className="st0" d="M493.8,504.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C489.9,502.7,491.8,504.6,493.8,504.6z"/>
      <path className="st0" d="M480.8,500.8c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S480.8,498.6,480.8,500.8z"/>
      <path className="st0" d="M475.3,504.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C471.5,502.7,473.4,504.6,475.3,504.6z"/>
      <path className="st0" d="M447.8,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C451.7,498.6,450,496.6,447.8,496.6z"/>
      <path className="st0" d="M442.6,500.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C440.6,504.6,442.6,502.7,442.6,500.8z"/>
      <path className="st0" d="M429.4,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C433.2,498.6,431.6,496.6,429.4,496.6z"/>
      <path className="st0" d="M424.1,500.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S424.1,502.7,424.1,500.8z"/>
      <path className="st0" d="M410.9,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C414.8,498.6,413.1,496.6,410.9,496.6z"/>
      <path className="st0" d="M405.7,500.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S405.7,502.7,405.7,500.8z"/>
      <path className="st0" d="M392.5,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C396.3,498.6,394.7,496.6,392.5,496.6z"/>
      <path className="st0" d="M387.3,500.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S387.3,502.7,387.3,500.8z"/>
      <path className="st0" d="M374.1,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C378.2,498.6,376.3,496.6,374.1,496.6z"/>
      <path className="st0" d="M365,504.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C361.1,502.7,362.8,504.6,365,504.6
        z"/>
      <path className="st0" d="M1175.3,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1171.5,493.6,1173.1,495.3,1175.3,495.3z"/>
      <path className="st0" d="M1166.3,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.1,493.6,1164.1,495.3,1166.3,495.3z"/>
      <path className="st0" d="M1156.9,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,493.6,1154.7,495.3,1156.9,495.3z"/>
      <path className="st0" d="M1147.8,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1144,493.6,1145.6,495.3,1147.8,495.3z"/>
      <path className="st0" d="M1138.5,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,493.6,1136.3,495.3,1138.5,495.3z"/>
      <path className="st0" d="M1129.4,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,493.6,1127.2,495.3,1129.4,495.3z"/>
      <path className="st0" d="M1120,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,493.6,1117.8,495.3,1120,495.3z"/>
      <path className="st0" d="M1114.8,491.4c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S1114.8,493.6,1114.8,491.4z"/>
      <path className="st0" d="M1101.6,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,493.6,1099.7,495.3,1101.6,495.3z"/>
      <path className="st0" d="M1092.5,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,493.6,1090.3,495.3,1092.5,495.3z"/>
      <path className="st0" d="M1087.3,491.4c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1085.4,495.3,1087.3,493.6,1087.3,491.4z"/>
      <path className="st0" d="M1074.1,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1070.2,493.6,1071.9,495.3,1074.1,495.3z"/>
      <path className="st0" d="M1068.9,491.4c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1066.9,495.3,1068.9,493.6,1068.9,491.4z"/>
      <path className="st0" d="M1055.6,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,493.6,1053.4,495.3,1055.6,495.3z"/>
      <path className="st0" d="M1046.6,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,493.6,1044.4,495.3,1046.6,495.3z"/>
      <path className="st0" d="M1037.2,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C1033.4,493.6,1035,495.3,1037.2,495.3
        z"/>
      <path className="st0" d="M1028.1,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,493.6,1025.9,495.3,1028.1,495.3z"/>
      <path className="st0" d="M1018.8,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,493.6,1016.6,495.3,1018.8,495.3z"/>
      <path className="st0" d="M1009.7,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,493.6,1007.5,495.3,1009.7,495.3z"/>
      <path className="st0" d="M1004.2,491.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1002.5,495.3,1004.2,493.6,1004.2,491.4z"/>
      <path className="st0" d="M995.1,491.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S995.1,493.6,995.1,491.4z"/>
      <path className="st0" d="M986,491.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C984.1,495.3,986,493.6,986,491.4z"
        />
      <path className="st0" d="M976.7,491.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C975,495.3,976.7,493.6,976.7,491.4z"/>
      <path className="st0" d="M967.6,491.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C965.7,495.3,967.6,493.6,967.6,491.4z"/>
      <path className="st0" d="M958.2,491.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C956.6,495.3,958.2,493.6,958.2,491.4z"/>
      <path className="st0" d="M941.2,491.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C943.1,487.6,941.2,489.2,941.2,491.4z"/>
      <path className="st0" d="M932.1,491.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C933.7,487.6,932.1,489.2,932.1,491.4z"/>
      <path className="st0" d="M922.7,491.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C924.7,487.6,922.7,489.2,922.7,491.4z"
        />
      <path className="st0" d="M913.7,491.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C915.3,487.6,913.7,489.2,913.7,491.4z"/>
      <path className="st0" d="M904.3,491.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C906,487.6,904.3,489.2,904.3,491.4z"/>
      <path className="st0" d="M895.2,491.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C896.9,487.6,895.2,489.2,895.2,491.4z"/>
      <path className="st0" d="M885.9,491.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C887.8,487.6,885.9,489.2,885.9,491.4z"/>
      <path className="st0" d="M876.8,491.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C878.4,487.6,876.8,489.2,876.8,491.4z"/>
      <path className="st0" d="M867.4,491.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C869.1,487.6,867.4,489.2,867.4,491.4z"/>
      <path className="st0" d="M705.6,487.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S707.8,487.6,705.6,487.6z"/>
      <path className="st0" d="M696.6,487.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,489.2,698.8,487.6,696.6,487.6z"/>
      <path className="st0" d="M687.2,487.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.3,489.2,689.4,487.6,687.2,487.6z"/>
      <path className="st0" d="M678.1,487.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S680.3,487.6,678.1,487.6z"/>
      <path className="st0" d="M668.8,487.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C672.9,489.2,671,487.6,668.8,487.6z"/>
      <path className="st0" d="M659.7,487.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S661.9,487.6,659.7,487.6z"/>
      <path className="st0" d="M646.5,491.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C648.4,487.6,646.5,489.2,646.5,491.4z"/>
      <path className="st0" d="M641.2,487.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C645.1,489.2,643.4,487.6,641.2,487.6z"
        />
      <path className="st0" d="M631.9,487.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C636,489.2,634.1,487.6,631.9,487.6z"/>
      <path className="st0" d="M622.8,487.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S625,487.6,622.8,487.6z"/>
      <path className="st0" d="M609.6,491.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C611.5,487.6,609.6,489.2,609.6,491.4z"/>
      <path className="st0" d="M600.5,491.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C602.2,487.6,600.5,489.2,600.5,491.4z"/>
      <path className="st0" d="M591.2,491.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C593.1,487.6,591.2,489.2,591.2,491.4z"/>
      <path className="st0" d="M585.9,487.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S588.1,487.6,585.9,487.6z"/>
      <path className="st0" d="M576.9,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C572.7,493.6,574.7,495.3,576.9,495.3z"
        />
      <path className="st0" d="M567.5,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C563.6,493.6,565.3,495.3,567.5,495.3z"/>
      <path className="st0" d="M558.4,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C554.3,493.6,556.2,495.3,558.4,495.3z"/>
      <path className="st0" d="M549.1,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C545.2,493.6,546.9,495.3,549.1,495.3z"
        />
      <path className="st0" d="M540,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C536.1,493.6,537.8,495.3,540,495.3
        z"/>
      <path className="st0" d="M530.6,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C526.8,493.6,528.4,495.3,530.6,495.3z"
        />
      <path className="st0" d="M521.5,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C517.7,493.6,519.3,495.3,521.5,495.3z"
        />
      <path className="st0" d="M512.2,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C508.3,493.6,510.3,495.3,512.2,495.3z"/>
      <path className="st0" d="M503.1,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C499.3,493.6,500.9,495.3,503.1,495.3z"
        />
      <path className="st0" d="M489.9,491.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C491.8,487.6,489.9,489.2,489.9,491.4z"/>
      <path className="st0" d="M457.2,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C453,493.6,455,495.3,457.2,495.3
        z"/>
      <path className="st0" d="M447.8,487.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C451.7,489.2,450,487.6,447.8,487.6z"/>
      <path className="st0" d="M438.7,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C434.6,493.6,436.5,495.3,438.7,495.3z"/>
      <path className="st0" d="M429.4,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C425.5,493.6,427.2,495.3,429.4,495.3z"/>
      <path className="st0" d="M420.3,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C416.2,493.6,418.1,495.3,420.3,495.3z"/>
      <path className="st0" d="M410.9,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C407.1,493.6,408.7,495.3,410.9,495.3z"/>
      <path className="st0" d="M401.8,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C397.7,493.6,399.6,495.3,401.8,495.3z"
        />
      <path className="st0" d="M392.5,495.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C388.6,493.6,390.3,495.3,392.5,495.3z"/>
      <ellipse className="st0" cx="1175.3" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,486.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1164.1,486.2,1166.3,486.2z"/>
      <path className="st0" d="M1156.9,486.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1154.7,486.2,1156.9,486.2z"/>
      <ellipse className="st0" cx="1147.8" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1138.5,486.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1136.3,486.2,1138.5,486.2z"/>
      <path className="st0" d="M1129.4,486.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1127.2,486.2,1129.4,486.2z"/>
      <path className="st0" d="M1120,486.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1117.8,486.2,1120,486.2z"/>
      <ellipse className="st0" cx="1111" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1101.6,486.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1099.7,486.2,1101.6,486.2z"/>
      <path className="st0" d="M1092.5,486.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1090.3,486.2,1092.5,486.2z"/>
      <path className="st0" d="M1087.3,482.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1085.4,486.2,1087.3,484.5,1087.3,482.3z"/>
      <ellipse className="st0" cx="1074.1" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1068.9,482.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1066.9,486.2,1068.9,484.5,1068.9,482.3z"/>
      <path className="st0" d="M1055.6,486.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1053.4,486.2,1055.6,486.2z"/>
      <ellipse className="st0" cx="1046.6" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1037.2,486.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1035,486.2,1037.2,486.2z"/>
      <path className="st0" d="M1028.1,486.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1025.9,486.2,1028.1,486.2z"/>
      <path className="st0" d="M1018.8,486.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1016.6,486.2,1018.8,486.2z"/>
      <path className="st0" d="M1004.2,482.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1002.5,486.2,1004.2,484.5,1004.2,482.3z"/>
      <path className="st0" d="M995.1,482.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S995.1,484.5,995.1,482.3z"/>
      <path className="st0" d="M986,482.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C984.1,486.2,986,484.5,986,482.3z"
        />
      <ellipse className="st0" cx="972.8" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M967.6,482.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C965.7,486.2,967.6,484.5,967.6,482.3z"/>
      <path className="st0" d="M950.5,482.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C952.2,478.5,950.5,480.1,950.5,482.3z"/>
      <path className="st0" d="M941.2,482.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C943.1,478.5,941.2,480.1,941.2,482.3z"/>
      <ellipse className="st0" cx="936" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M922.7,482.3c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C924.7,478.5,922.7,480.1,922.7,482.3z"
        />
      <path className="st0" d="M913.7,482.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C915.3,478.5,913.7,480.1,913.7,482.3z"/>
      <ellipse className="st0" cx="908.4" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M895.2,482.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C896.9,478.5,895.2,480.1,895.2,482.3z"/>
      <path className="st0" d="M885.9,482.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C887.8,478.5,885.9,480.1,885.9,482.3z"/>
      <path className="st0" d="M876.8,482.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C878.4,478.5,876.8,480.1,876.8,482.3z"/>
      <ellipse className="st0" cx="871.6" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M858.4,482.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C860,478.5,858.4,480.1,858.4,482.3z"/>
      <path className="st0" d="M705.6,478.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S707.8,478.5,705.6,478.5z"/>
      <ellipse className="st0" cx="696.6" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M687.2,478.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S689.4,478.5,687.2,478.5z"/>
      <path className="st0" d="M678.1,478.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S680.3,478.5,678.1,478.5z"/>
      <path className="st0" d="M668.8,478.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S671,478.5,668.8,478.5z"/>
      <ellipse className="st0" cx="659.7" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M646.5,482.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C648.4,478.5,646.5,480.1,646.5,482.3z"/>
      <ellipse className="st0" cx="641.2" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M631.9,478.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S634.1,478.5,631.9,478.5z"/>
      <ellipse className="st0" cx="622.8" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M609.6,482.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C611.5,478.5,609.6,480.1,609.6,482.3z"/>
      <ellipse className="st0" cx="604.4" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M591.2,482.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C593.1,478.5,591.2,480.1,591.2,482.3z"/>
      <ellipse className="st0" cx="585.9" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M576.9,478.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S578.8,478.5,576.9,478.5z"/>
      <path className="st0" d="M567.5,486.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S565.3,486.2,567.5,486.2z"/>
      <ellipse className="st0" cx="558.4" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M549.1,486.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S546.9,486.2,549.1,486.2z"/>
      <ellipse className="st0" cx="540" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M530.6,486.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S528.4,486.2,530.6,486.2z"/>
      <ellipse className="st0" cx="521.5" cy="482.3" rx="3.9" ry="3.9"/>
      <ellipse className="st0" cx="447.8" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M438.7,478.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S440.6,478.5,438.7,478.5z"/>
      <path className="st0" d="M429.4,486.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S427.2,486.2,429.4,486.2z"/>
      <ellipse className="st0" cx="420.3" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M410.9,486.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S408.7,486.2,410.9,486.2z"/>
      <ellipse className="st0" cx="401.8" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M392.5,486.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S390.3,486.2,392.5,486.2z"/>
      <ellipse className="st0" cx="383.4" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M374.1,478.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S376.3,478.5,374.1,478.5z"/>
      <ellipse className="st0" cx="365" cy="482.3" rx="3.9" ry="3.9"/>
      <ellipse className="st0" cx="272.8" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1175.3,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1171.5,475.2,1173.1,477.1,1175.3,477.1z"/>
      <path className="st0" d="M1166.3,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.1,475.2,1164.1,477.1,1166.3,477.1z"/>
      <path className="st0" d="M1156.9,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,475.2,1154.7,477.1,1156.9,477.1z"/>
      <path className="st0" d="M1147.8,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1144,475.2,1145.6,477.1,1147.8,477.1z"/>
      <path className="st0" d="M1138.5,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,475.2,1136.3,477.1,1138.5,477.1z"/>
      <path className="st0" d="M1129.4,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,475.2,1127.2,477.1,1129.4,477.1z"/>
      <path className="st0" d="M1120,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,475.2,1117.8,477.1,1120,477.1z"/>
      <path className="st0" d="M1114.8,473c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S1114.8,475.2,1114.8,473z"/>
      <path className="st0" d="M1101.6,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,475.2,1099.7,477.1,1101.6,477.1z"/>
      <path className="st0" d="M1092.5,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,475.2,1090.3,477.1,1092.5,477.1z"/>
      <path className="st0" d="M1087.3,473c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1085.4,477.1,1087.3,475.2,1087.3,473z"/>
      <path className="st0" d="M1074.1,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1070.2,475.2,1071.9,477.1,1074.1,477.1z"/>
      <path className="st0" d="M1068.9,473c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1066.9,477.1,1068.9,475.2,1068.9,473z"/>
      <path className="st0" d="M1055.6,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,475.2,1053.4,477.1,1055.6,477.1z"/>
      <path className="st0" d="M1046.6,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,475.2,1044.4,477.1,1046.6,477.1z"/>
      <path className="st0" d="M1037.2,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C1033.4,475.2,1035,477.1,1037.2,477.1
        z"/>
      <path className="st0" d="M1028.1,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,475.2,1025.9,477.1,1028.1,477.1z"/>
      <path className="st0" d="M1018.8,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,475.2,1016.6,477.1,1018.8,477.1z"/>
      <path className="st0" d="M1009.7,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,475.2,1007.5,477.1,1009.7,477.1z"/>
      <path className="st0" d="M1004.2,473c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1002.5,477.1,1004.2,475.2,1004.2,473z"/>
      <path className="st0" d="M995.1,473c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C993.5,477.1,995.1,475.2,995.1,473z"/>
      <path className="st0" d="M986,473c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C984.1,477.1,986,475.2,986,473z
        "/>
      <path className="st0" d="M976.7,473c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C975,476.8,976.7,475.2,976.7,473z"/>
      <path className="st0" d="M959.6,473c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C961.5,469.1,959.6,471,959.6,473z"/>
      <path className="st0" d="M950.5,473c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C952.2,469.1,950.5,471,950.5,473z"/>
      <path className="st0" d="M941.2,473c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C943.1,469.1,941.2,471,941.2,473z"/>
      <path className="st0" d="M932.1,473c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C933.7,469.1,932.1,471,932.1,473z"/>
      <path className="st0" d="M922.7,473c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C924.7,469.1,922.7,471,922.7,473z"/>
      <path className="st0" d="M913.7,473c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C915.3,469.1,913.7,471,913.7,473z"/>
      <path className="st0" d="M904.3,473c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C906,469.1,904.3,471,904.3,473
        z"/>
      <path className="st0" d="M895.2,473c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C896.9,469.1,895.2,471,895.2,473z"/>
      <path className="st0" d="M885.9,473c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C887.8,469.1,885.9,471,885.9,473z"/>
      <path className="st0" d="M876.8,473c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C878.4,469.1,876.8,471,876.8,473z"/>
      <path className="st0" d="M867.4,473c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C869.1,469.1,867.4,471,867.4,473z"/>
      <path className="st0" d="M858.4,473c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C860,469.1,858.4,471,858.4,473
        z"/>
      <path className="st0" d="M705.6,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,471,707.8,469.1,705.6,469.1z"/>
      <path className="st0" d="M696.6,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,471,698.8,469.1,696.6,469.1z"/>
      <path className="st0" d="M687.2,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.3,471,689.4,469.1,687.2,469.1z"/>
      <path className="st0" d="M678.1,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C682,471,680.3,469.1,678.1,469.1z
        "/>
      <path className="st0" d="M668.8,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C672.9,471,671,469.1,668.8,469.1z
        "/>
      <path className="st0" d="M659.7,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C663.5,471,661.9,469.1,659.7,469.1z"/>
      <path className="st0" d="M646.5,473c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C648.4,469.1,646.5,471,646.5,473z"/>
      <path className="st0" d="M641.2,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C645.1,471,643.4,469.1,641.2,469.1z"/>
      <path className="st0" d="M631.9,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C636,471,634.1,469.1,631.9,469.1z"/>
      <path className="st0" d="M622.8,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C626.7,471,625,469.1,622.8,469.1z
        "/>
      <path className="st0" d="M609.6,473c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C611.5,469.1,609.6,471,609.6,473z"/>
      <path className="st0" d="M600.5,473c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C602.2,469.1,600.5,471,600.5,473z"/>
      <path className="st0" d="M591.2,473c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C593.1,469.1,591.2,471,591.2,473z"/>
      <path className="st0" d="M585.9,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C589.8,471,588.1,469.1,585.9,469.1z"/>
      <path className="st0" d="M576.9,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C580.7,471,578.8,469.1,576.9,469.1z"/>
      <path className="st0" d="M567.5,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C571.4,471,569.7,469.1,567.5,469.1z"/>
      <path className="st0" d="M554.3,473c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C555.9,469.1,554.3,471,554.3,473z"/>
      <path className="st0" d="M549.1,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C545.2,475.2,546.9,477.1,549.1,477.1z"
        />
      <path className="st0" d="M540,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C536.1,475.2,537.8,477.1,540,477.1
        z"/>
      <path className="st0" d="M530.6,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C526.8,475.2,528.4,477.1,530.6,477.1z"
        />
      <path className="st0" d="M516.3,473c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C514.4,477.1,516.3,475.2,516.3,473z
        "/>
      <path className="st0" d="M461,473c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C459.1,477.1,461,475.2,461,473z
        "/>
      <path className="st0" d="M447.8,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C451.7,471,450,469.1,447.8,469.1z"/>
      <path className="st0" d="M429.4,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C433.2,471,431.6,469.1,429.4,469.1z"/>
      <path className="st0" d="M420.3,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C416.2,475.2,418.1,477.1,420.3,477.1z"/>
      <path className="st0" d="M410.9,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C407.1,475.2,408.7,477.1,410.9,477.1z"/>
      <path className="st0" d="M401.8,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C397.7,475.2,399.6,477.1,401.8,477.1z"
        />
      <path className="st0" d="M392.5,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C388.6,475.2,390.3,477.1,392.5,477.1z"/>
      <path className="st0" d="M383.4,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C379.6,475.2,381.2,477.1,383.4,477.1z"
        />
      <path className="st0" d="M374.1,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C370.2,475.2,371.9,477.1,374.1,477.1z"/>
      <path className="st0" d="M365,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C361.1,475.2,362.8,477.1,365,477.1
        z"/>
      <path className="st0" d="M355.6,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C359.7,471,357.8,469.1,355.6,469.1z"/>
      <path className="st0" d="M346.5,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C350.4,471,348.7,469.1,346.5,469.1z"/>
      <path className="st0" d="M291.2,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C295.1,471,293.4,469.1,291.2,469.1z"/>
      <path className="st0" d="M286,473c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S286,475.2,286,473z"/>
      <path className="st0" d="M272.8,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C268.9,475.2,270.6,477.1,272.8,477.1z"
        />
      <path className="st0" d="M267.6,473c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C265.9,477.1,267.6,475.2,267.6,473z
        "/>
      <path className="st0" d="M254.4,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C258.2,471,256.6,469.1,254.4,469.1z"/>
      <ellipse className="st0" cx="1175.3" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,467.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1164.1,467.7,1166.3,467.7z"/>
      <path className="st0" d="M1156.9,467.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1154.7,467.7,1156.9,467.7z"/>
      <ellipse className="st0" cx="1147.8" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1138.5,467.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1136.3,467.7,1138.5,467.7z"/>
      <path className="st0" d="M1129.4,467.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1127.2,467.7,1129.4,467.7z"/>
      <path className="st0" d="M1120,467.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1117.8,467.7,1120,467.7z"/>
      <ellipse className="st0" cx="1111" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1101.6,467.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1099.7,467.7,1101.6,467.7z"/>
      <path className="st0" d="M1092.5,467.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1090.3,467.7,1092.5,467.7z"/>
      <path className="st0" d="M1087.3,463.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1085.4,467.7,1087.3,466.1,1087.3,463.9z"/>
      <ellipse className="st0" cx="1074.1" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1068.9,463.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1066.9,467.7,1068.9,466.1,1068.9,463.9z"/>
      <path className="st0" d="M1055.6,467.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1053.4,467.7,1055.6,467.7z"/>
      <ellipse className="st0" cx="1046.6" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1037.2,467.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1035,467.7,1037.2,467.7z"/>
      <path className="st0" d="M1028.1,467.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1025.9,467.7,1028.1,467.7z"/>
      <path className="st0" d="M1018.8,467.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1016.6,467.7,1018.8,467.7z"/>
      <ellipse className="st0" cx="1009.7" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1004.2,463.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1002.5,467.7,1004.2,466.1,1004.2,463.9z"/>
      <path className="st0" d="M995.1,463.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S995.1,466.1,995.1,463.9z"/>
      <path className="st0" d="M986,463.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C984.1,467.7,986,466.1,986,463.9z"
        />
      <ellipse className="st0" cx="972.8" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M959.6,463.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C961.5,460,959.6,461.7,959.6,463.9z"/>
      <path className="st0" d="M950.5,463.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C952.2,460,950.5,461.7,950.5,463.9z"/>
      <path className="st0" d="M941.2,463.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C943.1,460,941.2,461.7,941.2,463.9z"/>
      <ellipse className="st0" cx="936" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M922.7,463.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C924.7,460,922.7,461.7,922.7,463.9z"/>
      <path className="st0" d="M913.7,463.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C915.3,460,913.7,461.7,913.7,463.9z"/>
      <ellipse className="st0" cx="908.4" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M895.2,463.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C896.9,460,895.2,461.7,895.2,463.9z"/>
      <path className="st0" d="M885.9,463.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C887.8,460,885.9,461.7,885.9,463.9z"/>
      <path className="st0" d="M876.8,463.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C878.4,460,876.8,461.7,876.8,463.9z"/>
      <ellipse className="st0" cx="871.6" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M858.4,463.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C860,460,858.4,461.7,858.4,463.9z
        "/>
      <ellipse className="st0" cx="696.6" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M687.2,460c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S689.4,460,687.2,460z"/>
      <path className="st0" d="M668.8,460c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S671,460,668.8,460z"/>
      <ellipse className="st0" cx="659.7" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M646.5,463.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C648.4,460,646.5,461.7,646.5,463.9z"/>
      <ellipse className="st0" cx="641.2" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M631.9,460c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S634.1,460,631.9,460z"/>
      <ellipse className="st0" cx="622.8" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M609.6,463.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C611.5,460,609.6,461.7,609.6,463.9z"/>
      <ellipse className="st0" cx="604.4" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M591.2,463.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C593.1,460,591.2,461.7,591.2,463.9z"/>
      <ellipse className="st0" cx="585.9" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M576.9,460c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S578.8,460,576.9,460z"/>
      <path className="st0" d="M567.5,460c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S569.7,460,567.5,460z"/>
      <ellipse className="st0" cx="558.4" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M549.1,460c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S551.3,460,549.1,460z"/>
      <ellipse className="st0" cx="540" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M526.8,463.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S526.8,461.7,526.8,463.9z"/>
      <ellipse className="st0" cx="521.5" cy="463.9" rx="3.9" ry="3.9"/>
      <ellipse className="st0" cx="466.2" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M457.2,460c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S459.1,460,457.2,460z"/>
      <path className="st0" d="M438.7,460c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S440.6,460,438.7,460z"/>
      <path className="st0" d="M429.4,460c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S431.6,460,429.4,460z"/>
      <ellipse className="st0" cx="420.3" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M410.9,460c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S413.1,460,410.9,460z"/>
      <ellipse className="st0" cx="401.8" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M392.5,467.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S390.3,467.7,392.5,467.7z"/>
      <ellipse className="st0" cx="383.4" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M374.1,467.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S371.9,467.7,374.1,467.7z"/>
      <ellipse className="st0" cx="365" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M322.9,463.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C320.9,467.7,322.9,466.1,322.9,463.9z"
        />
      <ellipse className="st0" cx="309.7" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M304.4,463.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C302.5,467.7,304.4,466.1,304.4,463.9z"
        />
      <path className="st0" d="M291.2,460c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S293.4,460,291.2,460z"/>
      <ellipse className="st0" cx="282.1" cy="463.9" rx="3.9" ry="3.9"/>
      <ellipse className="st0" cx="272.8" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M263.7,467.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S261.5,467.7,263.7,467.7z"/>
      <path className="st0" d="M250.5,463.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C252.2,460,250.5,461.7,250.5,463.9z"/>
      <path className="st0" d="M1175.3,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1173.1,458.7,1175.3,458.7z"/>
      <path className="st0" d="M1166.3,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.1,457,1164.1,458.7,1166.3,458.7z"/>
      <path className="st0" d="M1156.9,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1154.7,458.7,1156.9,458.7z"/>
      <path className="st0" d="M1147.8,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1145.6,458.7,1147.8,458.7z"/>
      <path className="st0" d="M1138.5,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1136.3,458.7,1138.5,458.7z"/>
      <path className="st0" d="M1129.4,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1127.2,458.7,1129.4,458.7z"/>
      <path className="st0" d="M1120,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,457,1117.8,458.7,1120,458.7z"/>
      <path className="st0" d="M1114.8,454.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S1114.8,457,1114.8,454.8z"/>
      <path className="st0" d="M1101.6,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1099.7,458.7,1101.6,458.7z"/>
      <path className="st0" d="M1092.5,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1090.3,458.7,1092.5,458.7z"/>
      <path className="st0" d="M1087.3,454.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C1085.4,458.7,1087.3,457,1087.3,454.8z
        "/>
      <path className="st0" d="M1074.1,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1071.9,458.7,1074.1,458.7z"/>
      <path className="st0" d="M1068.9,454.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C1066.9,458.7,1068.9,457,1068.9,454.8z
        "/>
      <path className="st0" d="M1055.6,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1053.4,458.7,1055.6,458.7z"/>
      <path className="st0" d="M1046.6,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,457,1044.4,458.7,1046.6,458.7z"/>
      <path className="st0" d="M1037.2,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1035,458.7,1037.2,458.7z"/>
      <path className="st0" d="M1028.1,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,457,1025.9,458.7,1028.1,458.7z"/>
      <path className="st0" d="M1018.8,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1016.6,458.7,1018.8,458.7z"/>
      <path className="st0" d="M1009.7,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C1005.6,457,1007.5,458.7,1009.7,458.7
        z"/>
      <path className="st0" d="M1004.2,454.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1002.5,458.7,1004.2,457,1004.2,454.8z"/>
      <path className="st0" d="M995.1,454.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S995.1,457,995.1,454.8z"/>
      <path className="st0" d="M986,454.8c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C984.1,458.7,986,457,986,454.8z"/>
      <path className="st0" d="M969,454.8c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S969,452.6,969,454.8z"/>
      <path className="st0" d="M959.6,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C961.5,450.7,959.6,452.6,959.6,454.8z"/>
      <path className="st0" d="M950.5,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C952.2,450.7,950.5,452.6,950.5,454.8z"/>
      <path className="st0" d="M941.2,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C943.1,450.7,941.2,452.6,941.2,454.8z"/>
      <path className="st0" d="M932.1,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C933.7,451,932.1,452.6,932.1,454.8z"/>
      <path className="st0" d="M922.7,454.8c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C924.7,450.7,922.7,452.6,922.7,454.8z"
        />
      <path className="st0" d="M913.7,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C915.3,450.7,913.7,452.6,913.7,454.8z"/>
      <path className="st0" d="M904.3,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C906,451,904.3,452.6,904.3,454.8z
        "/>
      <path className="st0" d="M895.2,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C896.9,450.7,895.2,452.6,895.2,454.8z"/>
      <path className="st0" d="M885.9,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C887.8,450.7,885.9,452.6,885.9,454.8z"/>
      <path className="st0" d="M876.8,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C878.4,450.7,876.8,452.6,876.8,454.8z"/>
      <path className="st0" d="M867.4,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C869.1,451,867.4,452.6,867.4,454.8z"/>
      <path className="st0" d="M858.4,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C860,450.7,858.4,452.6,858.4,454.8z"/>
      <path className="st0" d="M696.6,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,452.6,698.8,450.7,696.6,450.7z"/>
      <path className="st0" d="M687.2,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.3,452.6,689.4,450.7,687.2,450.7z"/>
      <path className="st0" d="M678.1,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C682,452.6,680.3,450.7,678.1,450.7z"/>
      <path className="st0" d="M668.8,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C672.9,452.6,671,450.7,668.8,450.7z"/>
      <path className="st0" d="M659.7,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C663.5,452.6,661.9,450.7,659.7,450.7z"/>
      <path className="st0" d="M646.5,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C648.4,450.7,646.5,452.6,646.5,454.8z"/>
      <path className="st0" d="M641.2,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C645.1,452.6,643.4,450.7,641.2,450.7z"/>
      <path className="st0" d="M631.9,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C636,452.6,634.1,450.7,631.9,450.7z"/>
      <path className="st0" d="M622.8,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C626.7,452.6,625,450.7,622.8,450.7z"/>
      <path className="st0" d="M609.6,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C611.5,450.7,609.6,452.6,609.6,454.8z"/>
      <path className="st0" d="M600.5,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C602.2,451,600.5,452.6,600.5,454.8z"/>
      <path className="st0" d="M591.2,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C593.1,450.7,591.2,452.6,591.2,454.8z"/>
      <path className="st0" d="M585.9,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C589.8,452.6,588.1,450.7,585.9,450.7z"/>
      <path className="st0" d="M576.9,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C580.7,452.6,578.8,450.7,576.9,450.7z"/>
      <path className="st0" d="M567.5,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C571.4,452.6,569.7,450.7,567.5,450.7z"/>
      <path className="st0" d="M554.3,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C555.9,451,554.3,452.6,554.3,454.8z"/>
      <path className="st0" d="M549.1,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C552.9,452.6,551.3,450.7,549.1,450.7z"/>
      <path className="st0" d="M536.1,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C537.8,451,536.1,452.6,536.1,454.8z"/>
      <path className="st0" d="M530.6,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S528.4,458.7,530.6,458.7z"/>
      <path className="st0" d="M521.5,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S519.3,458.7,521.5,458.7z"/>
      <path className="st0" d="M462.4,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C464,451,462.4,452.6,462.4,454.8z
        "/>
      <path className="st0" d="M457.2,451c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C461,452.9,459.1,451,457.2,451z"/>
      <path className="st0" d="M447.8,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S445.6,458.7,447.8,458.7z"/>
      <path className="st0" d="M438.7,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C434.6,457,436.5,458.7,438.7,458.7z"/>
      <path className="st0" d="M429.4,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C433.2,452.6,431.6,450.7,429.4,450.7z"/>
      <path className="st0" d="M420.3,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C424.1,452.6,422.5,450.7,420.3,450.7z"/>
      <path className="st0" d="M410.9,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C414.8,452.6,413.1,450.7,410.9,450.7z"/>
      <path className="st0" d="M401.8,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C405.7,452.6,404,450.7,401.8,450.7z"/>
      <path className="st0" d="M388.6,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C390.3,450.7,388.6,452.6,388.6,454.8z"/>
      <path className="st0" d="M379.6,454.8c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S379.6,452.6,379.6,454.8z"/>
      <path className="st0" d="M374.1,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C370.2,457,371.9,458.7,374.1,458.7z"/>
      <path className="st0" d="M365,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C361.1,457,362.8,458.7,365,458.7z"
        />
      <path className="st0" d="M319,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C322.9,452.6,320.9,450.7,319,450.7z
        "/>
      <path className="st0" d="M309.7,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C305.8,457,307.5,458.7,309.7,458.7z"/>
      <path className="st0" d="M300.6,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C296.5,457,298.4,458.7,300.6,458.7z"/>
      <path className="st0" d="M295.1,454.8c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S295.1,457,295.1,454.8z"/>
      <path className="st0" d="M282.1,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C278,457,279.9,458.7,282.1,458.7z"/>
      <path className="st0" d="M272.8,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S270.6,458.7,272.8,458.7z"/>
      <path className="st0" d="M263.7,458.7c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C259.6,457,261.5,458.7,263.7,458.7z"/>
      <path className="st0" d="M1175.3,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1177.5,441.6,1175.3,441.6z"/>
      <path className="st0" d="M1166.3,449.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.1,447.7,1164.1,449.3,1166.3,449.3z"/>
      <path className="st0" d="M1156.9,449.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1154.7,449.3,1156.9,449.3z"/>
      <path className="st0" d="M1147.8,449.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1145.6,449.3,1147.8,449.3z"/>
      <path className="st0" d="M1138.5,449.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1136.3,449.3,1138.5,449.3z"/>
      <path className="st0" d="M1129.4,449.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1127.2,449.3,1129.4,449.3z"/>
      <path className="st0" d="M1120,449.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,447.7,1117.8,449.3,1120,449.3z"/>
      <path className="st0" d="M1114.8,445.5c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S1114.8,447.7,1114.8,445.5z"/>
      <path className="st0" d="M1101.6,449.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1099.7,449.3,1101.6,449.3z"/>
      <path className="st0" d="M1092.5,449.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1090.3,449.3,1092.5,449.3z"/>
      <path className="st0" d="M1087.3,445.5c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1085.4,449.3,1087.3,447.7,1087.3,445.5z"/>
      <path className="st0" d="M1074.1,449.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1071.9,449.3,1074.1,449.3z"/>
      <path className="st0" d="M1068.9,445.5c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1066.9,449.3,1068.9,447.7,1068.9,445.5z"/>
      <path className="st0" d="M1055.6,449.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1053.4,449.3,1055.6,449.3z"/>
      <path className="st0" d="M1046.6,449.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,447.7,1044.4,449.3,1046.6,449.3z"/>
      <path className="st0" d="M1037.2,449.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1035,449.3,1037.2,449.3z"/>
      <path className="st0" d="M1028.1,449.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,447.7,1025.9,449.3,1028.1,449.3z"/>
      <path className="st0" d="M1018.8,449.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1016.6,449.3,1018.8,449.3z"/>
      <path className="st0" d="M1009.7,449.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,447.7,1007.5,449.3,1009.7,449.3z"/>
      <path className="st0" d="M1004.2,445.5c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1002.5,449.3,1004.2,447.7,1004.2,445.5z"/>
      <path className="st0" d="M995.1,445.5c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S995.1,447.7,995.1,445.5z"/>
      <path className="st0" d="M981.9,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C986,443.3,984.1,441.6,981.9,441.6z"/>
      <path className="st0" d="M969,445.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S969,443.3,969,445.5z"/>
      <path className="st0" d="M959.6,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C961.5,441.6,959.6,443.3,959.6,445.5z"/>
      <path className="st0" d="M950.5,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C952.2,441.6,950.5,443.3,950.5,445.5z"/>
      <path className="st0" d="M941.2,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C943.1,441.6,941.2,443.3,941.2,445.5z"/>
      <path className="st0" d="M932.1,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C933.7,441.6,932.1,443.3,932.1,445.5z"/>
      <path className="st0" d="M922.7,445.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C924.7,441.6,922.7,443.3,922.7,445.5z"
        />
      <path className="st0" d="M913.7,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C915.3,441.6,913.7,443.3,913.7,445.5z"/>
      <path className="st0" d="M904.3,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C906,441.6,904.3,443.3,904.3,445.5z"/>
      <path className="st0" d="M895.2,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C896.9,441.6,895.2,443.3,895.2,445.5z"/>
      <path className="st0" d="M885.9,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C887.8,441.6,885.9,443.3,885.9,445.5z"/>
      <path className="st0" d="M876.8,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C878.4,441.6,876.8,443.3,876.8,445.5z"/>
      <path className="st0" d="M867.4,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C869.1,441.6,867.4,443.3,867.4,445.5z"/>
      <path className="st0" d="M696.6,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,443.3,698.8,441.6,696.6,441.6z"/>
      <path className="st0" d="M687.2,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.3,443.3,689.4,441.6,687.2,441.6z"/>
      <path className="st0" d="M678.1,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S680.3,441.6,678.1,441.6z"/>
      <path className="st0" d="M668.8,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C672.9,443.3,671,441.6,668.8,441.6z"/>
      <path className="st0" d="M659.7,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S661.9,441.6,659.7,441.6z"/>
      <path className="st0" d="M646.5,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C648.4,441.6,646.5,443.3,646.5,445.5z"/>
      <path className="st0" d="M641.2,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C645.1,443.3,643.4,441.6,641.2,441.6z"
        />
      <path className="st0" d="M631.9,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C636,443.3,634.1,441.6,631.9,441.6z"/>
      <path className="st0" d="M622.8,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S625,441.6,622.8,441.6z"/>
      <path className="st0" d="M609.6,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C611.5,441.6,609.6,443.3,609.6,445.5z"/>
      <path className="st0" d="M600.5,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C602.2,441.6,600.5,443.3,600.5,445.5z"/>
      <path className="st0" d="M591.2,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C593.1,441.6,591.2,443.3,591.2,445.5z"/>
      <path className="st0" d="M585.9,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S588.1,441.6,585.9,441.6z"/>
      <path className="st0" d="M576.9,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S578.8,441.6,576.9,441.6z"/>
      <path className="st0" d="M567.5,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C571.4,443.3,569.7,441.6,567.5,441.6z"/>
      <path className="st0" d="M554.3,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C555.9,441.6,554.3,443.3,554.3,445.5z"/>
      <path className="st0" d="M549.1,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C552.9,443.3,551.3,441.6,549.1,441.6z"/>
      <path className="st0" d="M540,449.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S537.8,449.3,540,449.3z"/>
      <path className="st0" d="M526.8,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C528.4,441.6,526.8,443.3,526.8,445.5z"/>
      <path className="st0" d="M457.2,449.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C453,447.7,455,449.3,457.2,449.3
        z"/>
      <path className="st0" d="M443.9,445.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S443.9,443.3,443.9,445.5z"/>
      <path className="st0" d="M429.4,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C433.2,443.3,431.6,441.6,429.4,441.6z"/>
      <path className="st0" d="M420.3,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C424.1,443.3,422.5,441.6,420.3,441.6z"/>
      <path className="st0" d="M410.9,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S413.1,441.6,410.9,441.6z"/>
      <path className="st0" d="M401.8,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C405.7,443.3,404,441.6,401.8,441.6z"/>
      <path className="st0" d="M388.6,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C390.3,441.6,388.6,443.3,388.6,445.5z"/>
      <path className="st0" d="M379.6,445.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S379.6,443.3,379.6,445.5z"/>
      <path className="st0" d="M374.1,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C378.2,443.3,376.3,441.6,374.1,441.6z"/>
      <path className="st0" d="M365,449.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C361.1,447.7,362.8,449.3,365,449.3
        z"/>
      <path className="st0" d="M355.6,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C359.7,443.3,357.8,441.6,355.6,441.6z"/>
      <path className="st0" d="M328.1,449.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S325.9,449.3,328.1,449.3z"/>
      <path className="st0" d="M319,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S320.9,441.6,319,441.6z"/>
      <path className="st0" d="M309.7,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S311.9,441.6,309.7,441.6z"/>
      <path className="st0" d="M300.6,449.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C296.5,447.7,298.4,449.3,300.6,449.3z"
        />
      <path className="st0" d="M291.2,449.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S289,449.3,291.2,449.3z"/>
      <path className="st0" d="M282.1,449.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C278,447.7,279.9,449.3,282.1,449.3z"/>
      <path className="st0" d="M272.8,449.3c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S270.6,449.3,272.8,449.3z"/>
      <ellipse className="st0" cx="1175.3" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,432.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1168.5,432.5,1166.3,432.5z"/>
      <path className="st0" d="M1156.9,440.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1154.7,440.2,1156.9,440.2z"/>
      <ellipse className="st0" cx="1147.8" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1138.5,440.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1136.3,440.2,1138.5,440.2z"/>
      <path className="st0" d="M1129.4,440.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1127.2,440.2,1129.4,440.2z"/>
      <path className="st0" d="M1120,440.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1117.8,440.2,1120,440.2z"/>
      <ellipse className="st0" cx="1111" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1101.6,440.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1099.7,440.2,1101.6,440.2z"/>
      <path className="st0" d="M1092.5,440.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1090.3,440.2,1092.5,440.2z"/>
      <path className="st0" d="M1087.3,436.4c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1085.4,440.2,1087.3,438.6,1087.3,436.4z"/>
      <ellipse className="st0" cx="1074.1" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1068.9,436.4c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1066.9,440.2,1068.9,438.6,1068.9,436.4z"/>
      <path className="st0" d="M1055.6,440.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1053.4,440.2,1055.6,440.2z"/>
      <ellipse className="st0" cx="1046.6" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1037.2,440.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1035,440.2,1037.2,440.2z"/>
      <path className="st0" d="M1028.1,440.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1025.9,440.2,1028.1,440.2z"/>
      <path className="st0" d="M1018.8,440.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1016.6,440.2,1018.8,440.2z"/>
      <ellipse className="st0" cx="1009.7" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1004.2,436.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1002.5,440.2,1004.2,438.6,1004.2,436.4z"/>
      <path className="st0" d="M995.1,436.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S995.1,438.6,995.1,436.4z"/>
      <path className="st0" d="M978.1,436.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C979.7,432.5,978.1,434.2,978.1,436.4z"/>
      <ellipse className="st0" cx="972.8" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M959.6,436.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C961.5,432.5,959.6,434.2,959.6,436.4z"/>
      <path className="st0" d="M950.5,436.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C952.2,432.5,950.5,434.2,950.5,436.4z"/>
      <path className="st0" d="M941.2,436.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C943.1,432.5,941.2,434.2,941.2,436.4z"/>
      <ellipse className="st0" cx="936" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M922.7,436.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C924.7,432.5,922.7,434.2,922.7,436.4z"
        />
      <path className="st0" d="M913.7,436.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C915.3,432.5,913.7,434.2,913.7,436.4z"/>
      <ellipse className="st0" cx="908.4" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M895.2,436.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C896.9,432.5,895.2,434.2,895.2,436.4z"/>
      <path className="st0" d="M885.9,436.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C887.8,432.5,885.9,434.2,885.9,436.4z"/>
      <path className="st0" d="M876.8,436.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C878.4,432.5,876.8,434.2,876.8,436.4z"/>
      <ellipse className="st0" cx="871.6" cy="436.4" rx="3.9" ry="3.9"/>
      <ellipse className="st0" cx="696.6" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M687.2,432.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S689.4,432.5,687.2,432.5z"/>
      <path className="st0" d="M678.1,432.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S680.3,432.5,678.1,432.5z"/>
      <path className="st0" d="M668.8,432.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S671,432.5,668.8,432.5z"/>
      <ellipse className="st0" cx="659.7" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M646.5,436.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C648.4,432.5,646.5,434.2,646.5,436.4z"/>
      <ellipse className="st0" cx="641.2" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M631.9,432.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S634.1,432.5,631.9,432.5z"/>
      <ellipse className="st0" cx="622.8" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M609.6,436.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C611.5,432.5,609.6,434.2,609.6,436.4z"/>
      <ellipse className="st0" cx="604.4" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M591.2,436.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C593.1,432.5,591.2,434.2,591.2,436.4z"/>
      <ellipse className="st0" cx="585.9" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M576.9,432.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S578.8,432.5,576.9,432.5z"/>
      <path className="st0" d="M567.5,432.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S569.7,432.5,567.5,432.5z"/>
      <ellipse className="st0" cx="558.4" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M545.2,436.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S545.2,434.2,545.2,436.4z"/>
      <ellipse className="st0" cx="540" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M442.6,436.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C440.6,440.2,442.6,438.6,442.6,436.4z"/>
      <path className="st0" d="M429.4,432.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S431.6,432.5,429.4,432.5z"/>
      <ellipse className="st0" cx="420.3" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M410.9,432.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S413.1,432.5,410.9,432.5z"/>
      <ellipse className="st0" cx="401.8" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M392.5,440.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S390.3,440.2,392.5,440.2z"/>
      <ellipse className="st0" cx="383.4" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M370.2,436.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S370.2,434.2,370.2,436.4z"/>
      <ellipse className="st0" cx="365" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M319,432.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S320.9,432.5,319,432.5z"/>
      <ellipse className="st0" cx="309.7" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M300.6,432.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S302.5,432.5,300.6,432.5z"/>
      <path className="st0" d="M291.2,440.2c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S289,440.2,291.2,440.2z"/>
      <ellipse className="st0" cx="282.1" cy="436.4" rx="3.9" ry="3.9"/>
      <ellipse className="st0" cx="272.8" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1175.3,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1179.2,425.1,1177.5,423.2,1175.3,423.2z"/>
      <path className="st0" d="M1166.3,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,425.1,1168.5,423.2,1166.3,423.2z"/>
      <path className="st0" d="M1156.9,431.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,429.2,1154.7,431.1,1156.9,431.1z"/>
      <path className="st0" d="M1147.8,431.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1144,429.2,1145.6,431.1,1147.8,431.1z"/>
      <path className="st0" d="M1138.5,431.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,429.2,1136.3,431.1,1138.5,431.1z"/>
      <path className="st0" d="M1129.4,431.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,429.2,1127.2,431.1,1129.4,431.1z"/>
      <path className="st0" d="M1120,431.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,429.2,1117.8,431.1,1120,431.1z"/>
      <path className="st0" d="M1114.8,427.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S1114.8,429.2,1114.8,427.3z"/>
      <path className="st0" d="M1101.6,431.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,429.2,1099.7,431.1,1101.6,431.1z"/>
      <path className="st0" d="M1092.5,431.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,429.2,1090.3,431.1,1092.5,431.1z"/>
      <path className="st0" d="M1087.3,427.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1085.4,431.1,1087.3,429.2,1087.3,427.3z"/>
      <path className="st0" d="M1074.1,431.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1070.2,429.2,1071.9,431.1,1074.1,431.1z"/>
      <path className="st0" d="M1068.9,427.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1066.9,431.1,1068.9,429.2,1068.9,427.3z"/>
      <path className="st0" d="M1055.6,431.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,429.2,1053.4,431.1,1055.6,431.1z"/>
      <path className="st0" d="M1046.6,431.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,429.2,1044.4,431.1,1046.6,431.1z"/>
      <path className="st0" d="M1037.2,431.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C1033.4,429.2,1035,431.1,1037.2,431.1
        z"/>
      <path className="st0" d="M1028.1,431.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,429.2,1025.9,431.1,1028.1,431.1z"/>
      <path className="st0" d="M1018.8,431.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,429.2,1016.6,431.1,1018.8,431.1z"/>
      <path className="st0" d="M1009.7,431.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,429.2,1007.5,431.1,1009.7,431.1z"/>
      <path className="st0" d="M1004.2,427.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1002.5,431.1,1004.2,429.2,1004.2,427.3z"/>
      <path className="st0" d="M987.4,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C989.1,423.2,987.4,425.1,987.4,427.3z"/>
      <path className="st0" d="M978.1,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C979.7,423.2,978.1,425.1,978.1,427.3z"/>
      <path className="st0" d="M969,427.3c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S969,425.1,969,427.3z"/>
      <path className="st0" d="M959.6,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C961.5,423.2,959.6,425.1,959.6,427.3z"/>
      <path className="st0" d="M950.5,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C952.2,423.2,950.5,425.1,950.5,427.3z"/>
      <path className="st0" d="M941.2,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C943.1,423.2,941.2,425.1,941.2,427.3z"/>
      <path className="st0" d="M932.1,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C933.7,423.4,932.1,425.1,932.1,427.3z"/>
      <path className="st0" d="M922.7,427.3c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C924.7,423.2,922.7,425.1,922.7,427.3z"
        />
      <path className="st0" d="M913.7,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C915.3,423.2,913.7,425.1,913.7,427.3z"/>
      <path className="st0" d="M904.3,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C906,423.4,904.3,425.1,904.3,427.3z"/>
      <path className="st0" d="M895.2,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C896.9,423.2,895.2,425.1,895.2,427.3z"/>
      <path className="st0" d="M885.9,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C887.8,423.2,885.9,425.1,885.9,427.3z"/>
      <path className="st0" d="M876.8,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C878.4,423.2,876.8,425.1,876.8,427.3z"/>
      <path className="st0" d="M867.4,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C869.1,423.4,867.4,425.1,867.4,427.3z"/>
      <path className="st0" d="M696.6,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,425.1,698.8,423.2,696.6,423.2z"/>
      <path className="st0" d="M687.2,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.3,425.1,689.4,423.2,687.2,423.2z"/>
      <path className="st0" d="M678.1,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C682,425.1,680.3,423.2,678.1,423.2z"/>
      <path className="st0" d="M668.8,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C672.9,425.1,671,423.2,668.8,423.2z"/>
      <path className="st0" d="M659.7,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C663.5,425.1,661.9,423.2,659.7,423.2z"/>
      <path className="st0" d="M646.5,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C648.4,423.2,646.5,425.1,646.5,427.3z"/>
      <path className="st0" d="M641.2,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C645.1,425.1,643.4,423.2,641.2,423.2z"
        />
      <path className="st0" d="M631.9,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C636,425.1,634.1,423.2,631.9,423.2z"/>
      <path className="st0" d="M622.8,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C626.7,425.1,625,423.2,622.8,423.2z"/>
      <path className="st0" d="M609.6,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C611.5,423.2,609.6,425.1,609.6,427.3z"/>
      <path className="st0" d="M600.5,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C602.2,423.4,600.5,425.1,600.5,427.3z"/>
      <path className="st0" d="M595.3,431.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.2,429.2,593.1,431.1,595.3,431.1z"/>
      <path className="st0" d="M582.1,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C583.7,423.4,582.1,425.1,582.1,427.3z"/>
      <path className="st0" d="M576.9,431.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C572.7,429.2,574.7,431.1,576.9,431.1z"
        />
      <path className="st0" d="M563.6,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C565.3,423.2,563.6,425.1,563.6,427.3z"/>
      <path className="st0" d="M558.4,431.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C554.3,429.2,556.2,431.1,558.4,431.1z"/>
      <path className="st0" d="M438.7,431.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C434.6,429.2,436.5,431.1,438.7,431.1z"/>
      <path className="st0" d="M429.4,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C433.2,425.1,431.6,423.2,429.4,423.2z"/>
      <path className="st0" d="M420.3,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C424.1,425.1,422.5,423.2,420.3,423.2z"/>
      <path className="st0" d="M410.9,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C414.8,425.1,413.1,423.2,410.9,423.2z"/>
      <path className="st0" d="M401.8,431.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C397.7,429.2,399.6,431.1,401.8,431.1z"
        />
      <path className="st0" d="M392.5,431.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C388.6,429.2,390.3,431.1,392.5,431.1z"/>
      <path className="st0" d="M383.4,431.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C379.6,429.2,381.2,431.1,383.4,431.1z"
        />
      <path className="st0" d="M319,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C322.9,425.1,320.9,423.2,319,423.2z"/>
      <path className="st0" d="M300.6,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C304.4,425.1,302.5,423.2,300.6,423.2z"
        />
      <path className="st0" d="M287.4,427.3c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C289,423.2,287.4,425.1,287.4,427.3z"/>
      <path className="st0" d="M282.1,431.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C278,429.2,279.9,431.1,282.1,431.1z"/>
      <path className="st0" d="M272.8,431.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C268.9,429.2,270.6,431.1,272.8,431.1z"
        />
      <path className="st0" d="M267.6,427.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S267.6,429.2,267.6,427.3z"/>
      <ellipse className="st0" cx="1175.3" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,414.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1168.5,414.1,1166.3,414.1z"/>
      <path className="st0" d="M1156.9,414.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1159.1,414.1,1156.9,414.1z"/>
      <ellipse className="st0" cx="1147.8" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1138.5,421.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,420.1,1136.3,421.8,1138.5,421.8z"/>
      <path className="st0" d="M1129.4,421.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,420.1,1127.2,421.8,1129.4,421.8z"/>
      <path className="st0" d="M1120,421.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,420.1,1117.8,421.8,1120,421.8z"/>
      <ellipse className="st0" cx="1111" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1101.6,421.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,420.1,1099.7,421.8,1101.6,421.8z"/>
      <path className="st0" d="M1092.5,421.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,420.1,1090.3,421.8,1092.5,421.8z"/>
      <path className="st0" d="M1087.3,417.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1085.4,421.8,1087.3,420.1,1087.3,417.9z"/>
      <ellipse className="st0" cx="1074.1" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1068.9,417.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1066.9,421.8,1068.9,420.1,1068.9,417.9z"/>
      <path className="st0" d="M1055.6,421.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,420.1,1053.4,421.8,1055.6,421.8z"/>
      <ellipse className="st0" cx="1046.6" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1037.2,421.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,420.1,1035,421.8,1037.2,421.8z"/>
      <path className="st0" d="M1028.1,421.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024.3,420.1,1025.9,421.8,1028.1,421.8z"/>
      <path className="st0" d="M1018.8,421.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,420.1,1016.6,421.8,1018.8,421.8z"/>
      <ellipse className="st0" cx="1009.7" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1004.2,417.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1002.5,421.8,1004.2,420.1,1004.2,417.9z"/>
      <path className="st0" d="M987.4,417.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C989.1,414.1,987.4,415.7,987.4,417.9z"/>
      <path className="st0" d="M978.1,417.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C979.7,414.1,978.1,415.7,978.1,417.9z"/>
      <ellipse className="st0" cx="972.8" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M959.6,417.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C961.5,414.1,959.6,415.7,959.6,417.9z"/>
      <path className="st0" d="M950.5,417.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C952.2,414.1,950.5,415.7,950.5,417.9z"/>
      <path className="st0" d="M941.2,417.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C943.1,414.1,941.2,415.7,941.2,417.9z"/>
      <ellipse className="st0" cx="936" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M922.7,417.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C924.7,414.1,922.7,415.7,922.7,417.9z"/>
      <path className="st0" d="M913.7,417.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C915.3,414.1,913.7,415.7,913.7,417.9z"/>
      <ellipse className="st0" cx="908.4" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M895.2,417.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C896.9,414.1,895.2,415.7,895.2,417.9z"/>
      <path className="st0" d="M885.9,417.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C887.8,414.1,885.9,415.7,885.9,417.9z"/>
      <path className="st0" d="M876.8,417.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C878.4,414.1,876.8,415.7,876.8,417.9z"/>
      <ellipse className="st0" cx="871.6" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M858.4,417.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C860,414.1,858.4,415.7,858.4,417.9z"/>
      <ellipse className="st0" cx="696.6" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M687.2,414.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S689.4,414.1,687.2,414.1z"/>
      <path className="st0" d="M678.1,414.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S680.3,414.1,678.1,414.1z"/>
      <path className="st0" d="M668.8,414.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S671,414.1,668.8,414.1z"/>
      <ellipse className="st0" cx="659.7" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M650.3,421.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C646.5,420.1,648.4,421.8,650.3,421.8z"/>
      <ellipse className="st0" cx="641.2" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M631.9,414.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S634.1,414.1,631.9,414.1z"/>
      <ellipse className="st0" cx="622.8" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M613.7,421.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C609.9,420.1,611.5,421.8,613.7,421.8z"/>
      <ellipse className="st0" cx="604.4" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M595.3,421.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.4,420.1,593.1,421.8,595.3,421.8z"/>
      <path className="st0" d="M576.9,421.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C573,420.1,574.7,421.8,576.9,421.8z"/>
      <path className="st0" d="M429.4,414.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S431.6,414.1,429.4,414.1z"/>
      <ellipse className="st0" cx="420.3" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M410.9,414.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S413.1,414.1,410.9,414.1z"/>
      <ellipse className="st0" cx="401.8" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M392.5,421.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C388.6,420.1,390.3,421.8,392.5,421.8z"/>
      <ellipse className="st0" cx="383.4" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M319,414.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S320.9,414.1,319,414.1z"/>
      <ellipse className="st0" cx="309.7" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M296.5,417.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C298.4,414.1,296.5,415.7,296.5,417.9z"/>
      <path className="st0" d="M287.4,417.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C289,414.1,287.4,415.7,287.4,417.9
        z"/>
      <ellipse className="st0" cx="282.1" cy="417.9" rx="3.9" ry="3.9"/>
      <ellipse className="st0" cx="272.8" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M263.7,421.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C259.9,420.1,261.5,421.8,263.7,421.8z"/>
      <path className="st0" d="M1175.3,405c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1177.5,405,1175.3,405z"/>
      <path className="st0" d="M1166.3,405c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1168.5,405,1166.3,405z"/>
      <path className="st0" d="M1156.9,405c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1159.1,405,1156.9,405z"/>
      <path className="st0" d="M1147.8,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1144,411.1,1145.6,412.7,1147.8,412.7z"/>
      <path className="st0" d="M1138.5,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,411.1,1136.3,412.7,1138.5,412.7z"/>
      <path className="st0" d="M1129.4,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,411.1,1127.2,412.7,1129.4,412.7z"/>
      <path className="st0" d="M1120,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,411.1,1117.8,412.7,1120,412.7z"/>
      <path className="st0" d="M1114.8,408.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S1114.8,411.1,1114.8,408.9z"/>
      <path className="st0" d="M1101.6,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,411.1,1099.7,412.7,1101.6,412.7z"/>
      <path className="st0" d="M1092.5,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,411.1,1090.3,412.7,1092.5,412.7z"/>
      <path className="st0" d="M1087.3,408.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1085.4,412.7,1087.3,411.1,1087.3,408.9z"/>
      <path className="st0" d="M1074.1,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1070.2,411.1,1071.9,412.7,1074.1,412.7z"/>
      <path className="st0" d="M1068.9,408.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1066.9,412.7,1068.9,411.1,1068.9,408.9z"/>
      <path className="st0" d="M1055.6,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,411.1,1053.4,412.7,1055.6,412.7z"/>
      <path className="st0" d="M1046.6,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,411.1,1044.4,412.7,1046.6,412.7z"/>
      <path className="st0" d="M1037.2,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,411.1,1035,412.7,1037.2,412.7z"/>
      <path className="st0" d="M1028.1,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,411.1,1025.9,412.7,1028.1,412.7z"/>
      <path className="st0" d="M1018.8,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,411.1,1016.6,412.7,1018.8,412.7z"/>
      <path className="st0" d="M1009.7,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,411.1,1007.5,412.7,1009.7,412.7z"/>
      <path className="st0" d="M996.5,408.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C998.1,405,996.5,406.7,996.5,408.9z"/>
      <path className="st0" d="M987.4,408.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C989.1,405,987.4,406.7,987.4,408.9z"/>
      <path className="st0" d="M978.1,408.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C979.7,405,978.1,406.7,978.1,408.9z"/>
      <path className="st0" d="M969,408.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S969,406.7,969,408.9z"/>
      <path className="st0" d="M959.6,408.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C961.5,405,959.6,406.7,959.6,408.9z"/>
      <path className="st0" d="M950.5,408.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C952.2,405,950.5,406.7,950.5,408.9z"/>
      <path className="st0" d="M941.2,408.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C943.1,405,941.2,406.7,941.2,408.9z"/>
      <path className="st0" d="M932.1,408.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C933.7,405,932.1,406.7,932.1,408.9z"/>
      <path className="st0" d="M922.7,408.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C924.7,405,922.7,406.7,922.7,408.9
        z"/>
      <path className="st0" d="M913.7,408.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C915.3,405,913.7,406.7,913.7,408.9z"/>
      <path className="st0" d="M904.3,408.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C906,405,904.3,406.7,904.3,408.9z"/>
      <path className="st0" d="M895.2,408.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C896.9,405,895.2,406.7,895.2,408.9z"/>
      <path className="st0" d="M885.9,408.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C887.8,405,885.9,406.7,885.9,408.9z"/>
      <path className="st0" d="M876.8,408.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C878.4,405,876.8,406.7,876.8,408.9z"/>
      <path className="st0" d="M867.4,408.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C869.1,405,867.4,406.7,867.4,408.9z"/>
      <path className="st0" d="M858.4,408.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C860,405,858.4,406.7,858.4,408.9z"/>
      <path className="st0" d="M705.6,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C701.8,411.1,703.4,412.7,705.6,412.7z"/>
      <path className="st0" d="M683.3,408.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S683.3,406.7,683.3,408.9z"/>
      <path className="st0" d="M674.3,408.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C675.9,405,674.3,406.7,674.3,408.9
        z"/>
      <path className="st0" d="M668.8,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C664.9,411.1,666.6,412.7,668.8,412.7z"/>
      <path className="st0" d="M659.7,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C655.8,411.1,657.5,412.7,659.7,412.7z"/>
      <path className="st0" d="M650.3,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C646.5,411.1,648.4,412.7,650.3,412.7z"/>
      <path className="st0" d="M631.9,405c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C636,406.7,634.1,405,631.9,405z"/>
      <path className="st0" d="M622.8,405c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S625,405,622.8,405z"/>
      <path className="st0" d="M613.7,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C609.6,411.1,611.5,412.7,613.7,412.7z"/>
      <path className="st0" d="M604.4,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C600.5,411.1,602.2,412.7,604.4,412.7z"/>
      <path className="st0" d="M429.4,405c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C433.2,406.7,431.6,405,429.4,405z"
        />
      <path className="st0" d="M420.3,405c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C424.1,406.7,422.5,405,420.3,405z"
        />
      <path className="st0" d="M410.9,405c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S413.1,405,410.9,405z"/>
      <path className="st0" d="M401.8,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C397.7,411.1,399.6,412.7,401.8,412.7z"/>
      <path className="st0" d="M392.5,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C388.6,411.1,390.3,412.7,392.5,412.7z"/>
      <path className="st0" d="M383.4,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C379.6,411.1,381.2,412.7,383.4,412.7z"/>
      <path className="st0" d="M328.1,405c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S330.3,405,328.1,405z"/>
      <path className="st0" d="M319,405c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S320.9,405,319,405z"/>
      <path className="st0" d="M309.7,405c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S311.9,405,309.7,405z"/>
      <path className="st0" d="M300.6,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C296.5,411.1,298.4,412.7,300.6,412.7z"/>
      <path className="st0" d="M291.2,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C287.4,411.1,289,412.7,291.2,412.7z"/>
      <path className="st0" d="M282.1,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C278,411.1,279.9,412.7,282.1,412.7z"/>
      <path className="st0" d="M268.9,408.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S268.9,406.7,268.9,408.9z"/>
      <path className="st0" d="M263.7,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C259.6,411.1,261.5,412.7,263.7,412.7z"/>
      <ellipse className="st0" cx="1175.3" cy="399.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,395.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,397.3,1168.5,395.7,1166.3,395.7z"/>
      <path className="st0" d="M1156.9,395.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1160.8,397.3,1159.1,395.7,1156.9,395.7z"/>
      <ellipse className="st0" cx="1147.8" cy="399.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1138.5,403.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1136.3,403.6,1138.5,403.6z"/>
      <path className="st0" d="M1129.4,403.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1127.2,403.6,1129.4,403.6z"/>
      <path className="st0" d="M1120,403.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1117.8,403.6,1120,403.6z"/>
      <ellipse className="st0" cx="1111" cy="399.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1101.6,403.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1099.7,403.6,1101.6,403.6z"/>
      <path className="st0" d="M1092.5,403.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1090.3,403.6,1092.5,403.6z"/>
      <path className="st0" d="M1087.3,399.5c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1085.4,403.6,1087.3,401.7,1087.3,399.5z"/>
      <ellipse className="st0" cx="1074.1" cy="399.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1068.9,399.5c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1066.9,403.6,1068.9,401.7,1068.9,399.5z"/>
      <path className="st0" d="M1055.6,403.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1053.4,403.6,1055.6,403.6z"/>
      <ellipse className="st0" cx="1046.6" cy="399.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1037.2,403.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1035,403.6,1037.2,403.6z"/>
      <path className="st0" d="M1028.1,403.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1025.9,403.6,1028.1,403.6z"/>
      <path className="st0" d="M1018.8,395.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1022.6,397.3,1021,395.7,1018.8,395.7z"/>
      <ellipse className="st0" cx="1009.7" cy="399.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M996.5,399.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C998.1,395.7,996.5,397.6,996.5,399.5z"/>
      <path className="st0" d="M987.4,399.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C989.1,395.7,987.4,397.6,987.4,399.5z"/>
      <path className="st0" d="M978.1,399.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C979.7,395.7,978.1,397.6,978.1,399.5z"/>
      <ellipse className="st0" cx="972.8" cy="399.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M959.6,399.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C961.5,395.7,959.6,397.6,959.6,399.5z"/>
      <path className="st0" d="M950.5,399.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C952.2,395.7,950.5,397.6,950.5,399.5z"/>
      <path className="st0" d="M941.2,399.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C943.1,395.7,941.2,397.6,941.2,399.5z"/>
      <ellipse className="st0" cx="936" cy="399.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M922.7,399.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C924.7,395.7,922.7,397.6,922.7,399.5z"/>
      <path className="st0" d="M913.7,399.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C915.3,395.7,913.7,397.6,913.7,399.5z"/>
      <ellipse className="st0" cx="908.4" cy="399.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M895.2,399.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C896.9,395.7,895.2,397.6,895.2,399.5z"/>
      <path className="st0" d="M885.9,399.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C887.8,395.7,885.9,397.6,885.9,399.5z"/>
      <path className="st0" d="M876.8,399.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C878.4,395.7,876.8,397.6,876.8,399.5z"/>
      <ellipse className="st0" cx="871.6" cy="399.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M858.4,399.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C860,395.7,858.4,397.6,858.4,399.5z"/>
      <path className="st0" d="M849,399.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C850.9,395.7,849,397.6,849,399.5z"/>
      <path className="st0" d="M839.9,399.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C841.6,395.7,839.9,397.6,839.9,399.5z"/>
      <ellipse className="st0" cx="834.7" cy="399.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M821.5,399.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C823.1,395.7,821.5,397.6,821.5,399.5z"/>
      <path className="st0" d="M816.3,403.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S814.1,403.6,816.3,403.6z"/>
      <path className="st0" d="M806.9,403.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S805,403.6,806.9,403.6z"/>
      <path className="st0" d="M664.9,399.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S664.9,397.6,664.9,399.5z"/>
      <path className="st0" d="M631.9,403.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S630,403.6,631.9,403.6z"/>
      <ellipse className="st0" cx="622.8" cy="399.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M613.7,395.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C617.6,397.3,615.7,395.7,613.7,395.7z"/>
      <ellipse className="st0" cx="604.4" cy="399.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M425.5,399.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C427.2,395.7,425.5,397.6,425.5,399.5z"/>
      <ellipse className="st0" cx="420.3" cy="399.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M410.9,395.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C414.8,397.3,413.1,395.7,410.9,395.7z"/>
      <ellipse className="st0" cx="401.8" cy="399.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M392.5,403.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S390.3,403.6,392.5,403.6z"/>
      <ellipse className="st0" cx="383.4" cy="399.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M374.1,395.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C377.9,397.3,376.3,395.7,374.1,395.7z"/>
      <path className="st0" d="M337.2,403.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S335.3,403.6,337.2,403.6z"/>
      <ellipse className="st0" cx="328.1" cy="399.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M322.9,399.5c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C320.9,403.6,322.9,401.7,322.9,399.5z"/>
      <ellipse className="st0" cx="309.7" cy="399.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M300.6,403.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S298.4,403.6,300.6,403.6z"/>
      <path className="st0" d="M291.2,403.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S289,403.6,291.2,403.6z"/>
      <path className="st0" d="M263.7,403.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S261.5,403.6,263.7,403.6z"/>
      <path className="st0" d="M1175.3,386.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1179.2,388.2,1177.5,386.6,1175.3,386.6z"/>
      <path className="st0" d="M1166.3,386.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,388.2,1168.5,386.6,1166.3,386.6z"/>
      <path className="st0" d="M1156.9,386.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1160.8,388.2,1159.1,386.6,1156.9,386.6z"/>
      <path className="st0" d="M1147.8,386.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1151.7,388.2,1150,386.6,1147.8,386.6z"/>
      <path className="st0" d="M1138.5,394.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1136.3,394.3,1138.5,394.3
        z"/>
      <path className="st0" d="M1129.4,394.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1127.2,394.3,1129.4,394.3
        z"/>
      <path className="st0" d="M1120,394.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,392.6,1117.8,394.3,1120,394.3z"/>
      <path className="st0" d="M1114.8,390.4c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S1114.8,392.6,1114.8,390.4z"/>
      <path className="st0" d="M1101.6,394.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1099.7,394.3,1101.6,394.3
        z"/>
      <path className="st0" d="M1092.5,394.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1090.3,394.3,1092.5,394.3
        z"/>
      <path className="st0" d="M1087.3,390.4c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1085.4,394.3,1087.3,392.6,1087.3,390.4z"/>
      <path className="st0" d="M1074.1,394.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1071.9,394.3,1074.1,394.3z"/>
      <path className="st0" d="M1068.9,390.4c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1066.9,394.3,1068.9,392.6,1068.9,390.4z"/>
      <path className="st0" d="M1055.6,386.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1059.5,388.2,1057.9,386.6,1055.6,386.6z"/>
      <path className="st0" d="M1042.4,390.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1042.4,388.2,1042.4,390.4z"/>
      <path className="st0" d="M1033.4,390.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1035,386.6,1033.4,388.2,1033.4,390.4z"/>
      <path className="st0" d="M1024,390.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C1025.9,386.6,1024,388.2,1024,390.4
        z"/>
      <path className="st0" d="M1014.9,390.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1016.6,386.6,1014.9,388.2,1014.9,390.4z"/>
      <path className="st0" d="M1005.6,390.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1007.2,386.6,1005.6,388.2,1005.6,390.4z"/>
      <path className="st0" d="M996.5,390.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C998.1,386.6,996.5,388.2,996.5,390.4z"/>
      <path className="st0" d="M987.4,390.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C989.1,386.6,987.4,388.2,987.4,390.4z"/>
      <path className="st0" d="M978.1,390.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C979.7,386.6,978.1,388.2,978.1,390.4z"/>
      <path className="st0" d="M969,390.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S969,388.2,969,390.4z"/>
      <path className="st0" d="M959.6,390.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C961.5,386.6,959.6,388.2,959.6,390.4z"/>
      <path className="st0" d="M950.5,390.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C952.2,386.6,950.5,388.2,950.5,390.4z"/>
      <path className="st0" d="M941.2,390.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C943.1,386.6,941.2,388.2,941.2,390.4z"/>
      <path className="st0" d="M932.1,390.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C933.7,386.6,932.1,388.2,932.1,390.4z"/>
      <path className="st0" d="M922.7,390.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C924.7,386.6,922.7,388.2,922.7,390.4z"/>
      <path className="st0" d="M913.7,390.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C915.3,386.6,913.7,388.2,913.7,390.4z"/>
      <path className="st0" d="M904.3,390.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C906,386.6,904.3,388.2,904.3,390.4z"/>
      <path className="st0" d="M895.2,390.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C896.9,386.6,895.2,388.2,895.2,390.4z"/>
      <path className="st0" d="M885.9,390.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C887.8,386.6,885.9,388.2,885.9,390.4z"/>
      <path className="st0" d="M876.8,390.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C878.4,386.6,876.8,388.2,876.8,390.4z"/>
      <path className="st0" d="M867.4,390.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C869.1,386.6,867.4,388.2,867.4,390.4z"/>
      <path className="st0" d="M858.4,390.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C860,386.6,858.4,388.2,858.4,390.4z"/>
      <path className="st0" d="M849,390.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C850.9,386.6,849,388.2,849,390.4z"/>
      <path className="st0" d="M839.9,390.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C841.6,386.6,839.9,388.2,839.9,390.4z"/>
      <path className="st0" d="M830.8,390.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C832.5,386.6,830.8,388.2,830.8,390.4z"/>
      <path className="st0" d="M821.5,390.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C823.1,386.6,821.5,388.2,821.5,390.4z"/>
      <path className="st0" d="M816.3,386.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C820.1,388.2,818.5,386.6,816.3,386.6z"/>
      <path className="st0" d="M806.9,394.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S805,394.3,806.9,394.3z"/>
      <path className="st0" d="M613.7,394.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C609.6,392.6,611.5,394.3,613.7,394.3z"/>
      <path className="st0" d="M604.4,394.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S602.2,394.3,604.4,394.3z"
        />
      <path className="st0" d="M420.3,394.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C416.2,392.6,418.1,394.3,420.3,394.3z"/>
      <path className="st0" d="M410.9,386.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C414.8,388.2,413.1,386.6,410.9,386.6z"/>
      <path className="st0" d="M401.8,386.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C405.7,388.2,404,386.6,401.8,386.6z"/>
      <path className="st0" d="M392.5,394.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C388.6,392.6,390.3,394.3,392.5,394.3z"/>
      <path className="st0" d="M383.4,394.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S381.2,394.3,383.4,394.3z"/>
      <path className="st0" d="M370.2,390.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S370.2,388.2,370.2,390.4z"/>
      <path className="st0" d="M328.1,386.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C332,388.2,330.3,386.6,328.1,386.6z"/>
      <path className="st0" d="M319,386.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C322.9,388.2,320.9,386.6,319,386.6z
        "/>
      <path className="st0" d="M309.7,386.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C313.5,388.2,311.9,386.6,309.7,386.6z"/>
      <path className="st0" d="M300.6,386.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C304.4,388.2,302.5,386.6,300.6,386.6z"/>
      <path className="st0" d="M291.2,394.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S289,394.3,291.2,394.3z"/>
      <path className="st0" d="M286,390.4c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S286,392.6,286,390.4z"/>
      <path className="st0" d="M1175.3,377.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1179.2,379.1,1177.5,377.2,1175.3,377.2z"/>
      <path className="st0" d="M1166.3,377.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,379.1,1168.5,377.2,1166.3,377.2z"/>
      <path className="st0" d="M1156.9,377.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1160.8,379.1,1159.1,377.2,1156.9,377.2z"/>
      <path className="st0" d="M1147.8,377.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1151.7,379.1,1150,377.2,1147.8,377.2z"/>
      <path className="st0" d="M1138.5,377.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1142.6,379.1,1140.7,377.2,1138.5,377.2z"/>
      <path className="st0" d="M1129.4,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,383.3,1127.2,385.2,1129.4,385.2z"/>
      <path className="st0" d="M1120,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,383.3,1117.8,385.2,1120,385.2z"/>
      <path className="st0" d="M1114.8,381.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S1114.8,383.3,1114.8,381.3z"/>
      <path className="st0" d="M1101.6,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,383.3,1099.7,385.2,1101.6,385.2z"/>
      <path className="st0" d="M1092.5,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,383.3,1090.3,385.2,1092.5,385.2z"/>
      <path className="st0" d="M1087.3,381.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1085.4,385.2,1087.3,383.3,1087.3,381.3z"/>
      <path className="st0" d="M1074.1,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1070.2,383.3,1071.9,385.2,1074.1,385.2z"/>
      <path className="st0" d="M1068.9,381.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C1066.9,385.2,1068.9,383.3,1068.9,381.3z"/>
      <path className="st0" d="M1051.8,381.3c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1053.4,377.2,1051.8,379.1,1051.8,381.3z"/>
      <path className="st0" d="M1042.4,381.3c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1042.4,379.1,1042.4,381.3z"/>
      <path className="st0" d="M1033.4,381.3c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1035,377.2,1033.4,379.1,1033.4,381.3z"/>
      <path className="st0" d="M1024,381.3c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C1025.9,377.2,1024,379.1,1024,381.3
        z"/>
      <path className="st0" d="M1014.9,381.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1016.6,377.2,1014.9,379.1,1014.9,381.3z"/>
      <path className="st0" d="M1005.6,381.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1007.2,377.5,1005.6,379.1,1005.6,381.3z"/>
      <path className="st0" d="M996.5,381.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C998.1,377.2,996.5,379.1,996.5,381.3z"/>
      <path className="st0" d="M987.4,381.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C989.1,377.2,987.4,379.1,987.4,381.3z"/>
      <path className="st0" d="M981.9,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C978.1,383.3,979.7,385.2,981.9,385.2z"/>
      <path className="st0" d="M963.5,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C959.6,383.3,961.5,385.2,963.5,385.2z"/>
      <path className="st0" d="M954.4,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C950.5,383.3,952.2,385.2,954.4,385.2z"/>
      <path className="st0" d="M945,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C941.2,383.3,943.1,385.2,945,385.2z"/>
      <path className="st0" d="M936,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C932.1,383.3,933.7,385.2,936,385.2z"/>
      <path className="st0" d="M926.6,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C922.7,383.3,924.7,385.2,926.6,385.2z"/>
      <path className="st0" d="M917.5,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C913.7,383.3,915.3,385.2,917.5,385.2z"/>
      <path className="st0" d="M908.4,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C904.3,383.3,906.2,385.2,908.4,385.2z"/>
      <path className="st0" d="M899.1,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C895.2,383.3,896.9,385.2,899.1,385.2z"/>
      <path className="st0" d="M890,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C885.9,383.3,887.8,385.2,890,385.2z
        "/>
      <path className="st0" d="M880.6,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C876.8,383.3,878.4,385.2,880.6,385.2z"/>
      <path className="st0" d="M871.6,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C867.4,383.3,869.4,385.2,871.6,385.2z"/>
      <path className="st0" d="M862.2,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C858.4,383.3,860,385.2,862.2,385.2z"/>
      <path className="st0" d="M853.1,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C849,383.3,850.9,385.2,853.1,385.2z"/>
      <path className="st0" d="M843.8,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C839.9,383.3,841.6,385.2,843.8,385.2z"/>
      <path className="st0" d="M834.7,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C830.8,383.3,832.5,385.2,834.7,385.2z"/>
      <path className="st0" d="M825.3,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,383.3,823.1,385.2,825.3,385.2z"/>
      <path className="st0" d="M816.3,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C812.4,383.3,814.1,385.2,816.3,385.2z"/>
      <path className="st0" d="M806.9,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C803,383.3,805,385.2,806.9,385.2z
        "/>
      <path className="st0" d="M599.1,381.3c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C597.2,385.2,599.1,383.3,599.1,381.3z"/>
      <path className="st0" d="M410.9,377.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C414.8,379.1,413.1,377.2,410.9,377.2z"/>
      <path className="st0" d="M401.8,377.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C405.7,379.1,404,377.2,401.8,377.2z"/>
      <path className="st0" d="M392.5,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C388.6,383.3,390.3,385.2,392.5,385.2z"/>
      <path className="st0" d="M383.4,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C379.6,383.3,381.2,385.2,383.4,385.2z"/>
      <path className="st0" d="M365,377.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C368.8,379.1,367.2,377.2,365,377.2z"/>
      <path className="st0" d="M355.6,377.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C359.7,379.1,357.8,377.2,355.6,377.2z"/>
      <path className="st0" d="M346.5,377.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C350.4,379.1,348.7,377.2,346.5,377.2z"/>
      <path className="st0" d="M328.1,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C324.3,383.3,325.9,385.2,328.1,385.2z"/>
      <path className="st0" d="M319,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C314.9,383.3,316.8,385.2,319,385.2z
        "/>
      <path className="st0" d="M319,375.8c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S316.8,375.8,319,375.8z"/>
      <path className="st0" d="M305.8,381.3c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S305.8,379.1,305.8,381.3z"/>
      <path className="st0" d="M300.6,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C296.5,383.3,298.4,385.2,300.6,385.2z"/>
      <path className="st0" d="M287.4,381.3c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C289,377.2,287.4,379.1,287.4,381.3
        z"/>
      <ellipse className="st0" cx="1175.3" cy="372" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,368.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1168.5,368.1,1166.3,368.1z"/>
      <path className="st0" d="M1156.9,368.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1159.1,368.1,1156.9,368.1z"/>
      <ellipse className="st0" cx="1147.8" cy="372" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1138.5,368.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1140.7,368.1,1138.5,368.1z"/>
      <path className="st0" d="M1129.4,368.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1131.6,368.1,1129.4,368.1z"/>
      <path className="st0" d="M1120,375.8c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1117.8,375.8,1120,375.8z"/>
      <ellipse className="st0" cx="1111" cy="372" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1101.6,375.8c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1099.7,375.8,1101.6,375.8z"/>
      <path className="st0" d="M1092.5,375.8c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1090.3,375.8,1092.5,375.8z"/>
      <path className="st0" d="M1087.3,372c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C1085.4,375.8,1087.3,374.2,1087.3,372z"
        />
      <ellipse className="st0" cx="1074.1" cy="372" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1060.9,372c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C1062.8,368.1,1060.9,369.8,1060.9,372z"
        />
      <path className="st0" d="M1051.8,372c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S1051.8,369.8,1051.8,372z"/>
      <ellipse className="st0" cx="1046.6" cy="372" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1033.4,372c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C1035,368.1,1033.4,369.8,1033.4,372z"/>
      <path className="st0" d="M1024,372c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C1025.9,368.1,1024,369.8,1024,372z"/>
      <path className="st0" d="M1014.9,372c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C1016.6,368.1,1014.9,369.8,1014.9,372z"/>
      <ellipse className="st0" cx="1009.7" cy="372" rx="3.9" ry="3.9"/>
      <path className="st0" d="M996.5,372c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C998.1,368.1,996.5,369.8,996.5,372z
        "/>
      <path className="st0" d="M987.4,372c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C989.1,368.1,987.4,369.8,987.4,372z
        "/>
      <path className="st0" d="M981.9,368.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S984.1,368.1,981.9,368.1z"/>
      <ellipse className="st0" cx="972.8" cy="372" rx="3.9" ry="3.9"/>
      <path className="st0" d="M959.6,372c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C961.5,368.1,959.6,369.8,959.6,372z
        "/>
      <path className="st0" d="M950.5,372c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C952.2,368.1,950.5,369.8,950.5,372z
        "/>
      <path className="st0" d="M941.2,372c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C943.1,368.1,941.2,369.8,941.2,372z
        "/>
      <ellipse className="st0" cx="936" cy="372" rx="3.9" ry="3.9"/>
      <path className="st0" d="M922.7,372c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C924.7,368.1,922.7,369.8,922.7,372z"/>
      <path className="st0" d="M913.7,372c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C915.3,368.1,913.7,369.8,913.7,372z
        "/>
      <ellipse className="st0" cx="908.4" cy="372" rx="3.9" ry="3.9"/>
      <path className="st0" d="M895.2,372c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C896.9,368.1,895.2,369.8,895.2,372z
        "/>
      <path className="st0" d="M885.9,372c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C887.8,368.1,885.9,369.8,885.9,372z
        "/>
      <ellipse className="st0" cx="871.6" cy="372" rx="3.9" ry="3.9"/>
      <path className="st0" d="M862.2,375.8c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S860,375.8,862.2,375.8z"/>
      <path className="st0" d="M853.1,375.8c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S850.9,375.8,853.1,375.8z"/>
      <path className="st0" d="M843.8,375.8c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S841.6,375.8,843.8,375.8z"/>
      <ellipse className="st0" cx="834.7" cy="372" rx="3.9" ry="3.9"/>
      <path className="st0" d="M825.3,375.8c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S823.1,375.8,825.3,375.8z"/>
      <ellipse className="st0" cx="659.7" cy="372" rx="3.9" ry="3.9"/>
      <path className="st0" d="M654.5,372c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C652.5,375.8,654.5,374.2,654.5,372z
        "/>
      <path className="st0" d="M631.9,375.8c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S630,375.8,631.9,375.8z"/>
      <ellipse className="st0" cx="622.8" cy="372" rx="3.9" ry="3.9"/>
      <ellipse className="st0" cx="604.4" cy="372" rx="3.9" ry="3.9"/>
      <path className="st0" d="M604.4,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C600.5,383.3,602.2,385.2,604.4,385.2z"/>
      <path className="st0" d="M595.3,375.8c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S593.1,375.8,595.3,375.8z"/>
      <path className="st0" d="M407.1,372c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C408.7,368.1,407.1,369.8,407.1,372z
        "/>
      <ellipse className="st0" cx="401.8" cy="372" rx="3.9" ry="3.9"/>
      <path className="st0" d="M392.5,368.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S394.7,368.1,392.5,368.1z"/>
      <ellipse className="st0" cx="383.4" cy="372" rx="3.9" ry="3.9"/>
      <path className="st0" d="M374.1,368.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S376.3,368.1,374.1,368.1z"/>
      <ellipse className="st0" cx="365" cy="372" rx="3.9" ry="3.9"/>
      <path className="st0" d="M355.6,375.8c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S353.7,375.8,355.6,375.8z"/>
      <path className="st0" d="M1175.3,359.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1179.2,360.7,1177.5,359.1,1175.3,359.1z"/>
      <path className="st0" d="M1166.3,359.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,360.7,1168.5,359.1,1166.3,359.1z"/>
      <path className="st0" d="M1156.9,359.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1160.8,360.7,1159.1,359.1,1156.9,359.1z"/>
      <path className="st0" d="M1147.8,359.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1151.7,360.7,1150,359.1,1147.8,359.1z"/>
      <path className="st0" d="M1138.5,359.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1142.6,360.7,1140.7,359.1,1138.5,359.1z"/>
      <path className="st0" d="M1129.4,359.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1133.2,360.7,1131.6,359.1,1129.4,359.1z"/>
      <path className="st0" d="M1120,359.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1124.2,360.7,1122.2,359.1,1120,359.1z"/>
      <path className="st0" d="M1114.8,362.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S1114.8,365.1,1114.8,362.9z"/>
      <path className="st0" d="M1101.6,366.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,365.1,1099.7,366.8,1101.6,366.8z"/>
      <path className="st0" d="M1092.5,359.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1096.4,360.7,1094.7,359.1,1092.5,359.1z"/>
      <path className="st0" d="M1079.3,362.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1081.2,359.1,1079.3,360.7,1079.3,362.9z"/>
      <path className="st0" d="M1070.2,362.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1070.2,360.7,1070.2,362.9z"/>
      <path className="st0" d="M1060.9,362.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1062.8,359.1,1060.9,360.7,1060.9,362.9z"/>
      <path className="st0" d="M1051.8,362.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1051.8,360.7,1051.8,362.9z"/>
      <path className="st0" d="M1042.4,362.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1042.4,360.7,1042.4,362.9z"/>
      <path className="st0" d="M1033.4,362.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1035,359.1,1033.4,360.7,1033.4,362.9z"/>
      <path className="st0" d="M1024,362.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C1025.9,359.1,1024,360.7,1024,362.9
        z"/>
      <path className="st0" d="M1014.9,362.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1016.6,359.1,1014.9,360.7,1014.9,362.9z"/>
      <path className="st0" d="M1005.6,362.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1007.2,359.1,1005.6,360.7,1005.6,362.9z"/>
      <path className="st0" d="M996.5,362.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C998.1,359.1,996.5,360.7,996.5,362.9z"/>
      <path className="st0" d="M987.4,362.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C989.1,359.1,987.4,360.7,987.4,362.9z"/>
      <path className="st0" d="M981.9,359.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C986,360.7,984.1,359.1,981.9,359.1z"/>
      <path className="st0" d="M969,362.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S969,360.7,969,362.9z"/>
      <path className="st0" d="M959.6,362.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C961.5,359.1,959.6,360.7,959.6,362.9z"/>
      <path className="st0" d="M950.5,362.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C952.2,359.1,950.5,360.7,950.5,362.9z"/>
      <path className="st0" d="M941.2,362.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C943.1,359.1,941.2,360.7,941.2,362.9z"/>
      <path className="st0" d="M932.1,362.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C933.7,359.1,932.1,360.7,932.1,362.9z"/>
      <path className="st0" d="M922.7,362.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C924.7,359.1,922.7,360.7,922.7,362.9z"/>
      <path className="st0" d="M913.7,362.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C915.3,359.1,913.7,360.7,913.7,362.9z"/>
      <path className="st0" d="M904.3,362.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C906,359.1,904.3,360.7,904.3,362.9z"/>
      <path className="st0" d="M895.2,362.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C896.9,359.1,895.2,360.7,895.2,362.9z"/>
      <path className="st0" d="M885.9,362.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C887.8,359.1,885.9,360.7,885.9,362.9z"/>
      <path className="st0" d="M876.8,362.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C878.4,359.1,876.8,360.7,876.8,362.9z"/>
      <path className="st0" d="M871.6,359.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C875.4,360.7,873.8,359.1,871.6,359.1z"/>
      <path className="st0" d="M862.2,366.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C858.4,365.1,860,366.8,862.2,366.8z"/>
      <path className="st0" d="M853.1,366.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C849,365.1,850.9,366.8,853.1,366.8z"/>
      <path className="st0" d="M843.8,366.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C839.9,365.1,841.6,366.8,843.8,366.8z"/>
      <path className="st0" d="M834.7,366.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C830.8,365.1,832.5,366.8,834.7,366.8z"/>
      <path className="st0" d="M825.3,366.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,365.1,823.1,366.8,825.3,366.8z"/>
      <path className="st0" d="M696.6,366.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C692.7,365.1,694.4,366.8,696.6,366.8z"/>
      <path className="st0" d="M687.2,359.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.3,360.7,689.4,359.1,687.2,359.1z"/>
      <path className="st0" d="M678.1,359.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C682,360.7,680.3,359.1,678.1,359.1z"/>
      <path className="st0" d="M659.7,366.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C655.8,365.1,657.5,366.8,659.7,366.8z"/>
      <path className="st0" d="M650.3,366.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C646.5,365.1,648.4,366.8,650.3,366.8z"/>
      <path className="st0" d="M641.2,359.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C645.1,360.7,643.4,359.1,641.2,359.1z"/>
      <path className="st0" d="M619,362.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C620.6,359.1,619,360.7,619,362.9z"/>
      <path className="st0" d="M604.4,359.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C608.2,360.7,606.6,359.1,604.4,359.1z"/>
      <path className="st0" d="M595.3,366.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.2,365.1,593.1,366.8,595.3,366.8z"/>
      <path className="st0" d="M401.8,359.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C405.7,360.7,404,359.1,401.8,359.1z"/>
      <path className="st0" d="M392.5,359.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C396.3,360.7,394.7,359.1,392.5,359.1z"/>
      <path className="st0" d="M383.4,359.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C387.3,360.7,385.6,359.1,383.4,359.1z"/>
      <path className="st0" d="M374.1,366.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C370.2,365.1,371.9,366.8,374.1,366.8z"/>
      <path className="st0" d="M368.8,362.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C367.2,366.8,368.8,365.1,368.8,362.9z"/>
      <path className="st0" d="M355.6,366.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C351.8,365.1,353.7,366.8,355.6,366.8z"/>
      <ellipse className="st0" cx="1175.3" cy="353.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,349.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1168.5,349.7,1166.3,349.7z"/>
      <path className="st0" d="M1156.9,349.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1159.1,349.7,1156.9,349.7z"/>
      <ellipse className="st0" cx="1147.8" cy="353.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1138.5,349.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1140.7,349.7,1138.5,349.7z"/>
      <path className="st0" d="M1129.4,349.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1131.6,349.7,1129.4,349.7z"/>
      <path className="st0" d="M1120,349.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1122.2,349.7,1120,349.7z"/>
      <ellipse className="st0" cx="1111" cy="353.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1101.6,349.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1103.8,349.7,1101.6,349.7z"/>
      <path className="st0" d="M1088.7,353.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C1090.3,349.7,1088.7,351.6,1088.7,353.5z"/>
      <path className="st0" d="M1079.3,353.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C1081.2,349.7,1079.3,351.6,1079.3,353.5z"/>
      <ellipse className="st0" cx="1074.1" cy="353.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1060.9,353.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C1062.8,349.7,1060.9,351.6,1060.9,353.5z"/>
      <path className="st0" d="M1051.8,353.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C1053.4,349.7,1051.8,351.6,1051.8,353.5z"/>
      <ellipse className="st0" cx="1046.6" cy="353.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1033.4,353.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C1035,349.7,1033.4,351.6,1033.4,353.5z
        "/>
      <path className="st0" d="M1024,353.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C1025.9,349.7,1024,351.6,1024,353.5z"/>
      <path className="st0" d="M1014.9,353.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C1016.6,349.7,1014.9,351.6,1014.9,353.5z"/>
      <ellipse className="st0" cx="1009.7" cy="353.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M996.5,353.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C998.1,349.7,996.5,351.6,996.5,353.5z"/>
      <path className="st0" d="M987.4,353.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C989.1,349.7,987.4,351.6,987.4,353.5z"/>
      <path className="st0" d="M981.9,349.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S984.1,349.7,981.9,349.7z"/>
      <ellipse className="st0" cx="972.8" cy="353.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M959.6,353.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C961.5,349.7,959.6,351.6,959.6,353.5z"/>
      <path className="st0" d="M950.5,353.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C952.2,349.7,950.5,351.6,950.5,353.5z"/>
      <path className="st0" d="M941.2,353.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C943.1,349.7,941.2,351.6,941.2,353.5z"/>
      <ellipse className="st0" cx="936" cy="353.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M913.7,353.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C915.3,349.7,913.7,351.6,913.7,353.5z"/>
      <ellipse className="st0" cx="908.4" cy="353.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M895.2,353.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C896.9,349.7,895.2,351.6,895.2,353.5z"/>
      <path className="st0" d="M885.9,353.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C887.8,349.7,885.9,351.6,885.9,353.5z"/>
      <path className="st0" d="M876.8,353.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C878.4,349.7,876.8,351.6,876.8,353.5z"/>
      <ellipse className="st0" cx="871.6" cy="353.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M862.2,349.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S864.4,349.7,862.2,349.7z"/>
      <path className="st0" d="M853.1,357.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C849.3,356,850.9,357.7,853.1,357.7z"/>
      <path className="st0" d="M843.8,357.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C839.9,356,841.6,357.7,843.8,357.7z"/>
      <ellipse className="st0" cx="834.7" cy="353.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M825.3,357.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,356,823.1,357.7,825.3,357.7z"/>
      <path className="st0" d="M687.2,349.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S689.4,349.7,687.2,349.7z"/>
      <path className="st0" d="M678.1,357.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,356,675.9,357.7,678.1,357.7z"/>
      <ellipse className="st0" cx="659.7" cy="353.5" rx="3.9" ry="3.9"/>
      <ellipse className="st0" cx="641.2" cy="353.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M617.6,353.5c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C615.7,357.7,617.6,355.8,617.6,353.5z"
        />
      <ellipse className="st0" cx="604.4" cy="353.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M595.3,357.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.4,356,593.1,357.7,595.3,357.7z"/>
      <ellipse className="st0" cx="585.9" cy="353.5" rx="3.9" ry="3.9"/>
      <ellipse className="st0" cx="401.8" cy="353.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M392.5,357.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C388.6,356,390.3,357.7,392.5,357.7z"/>
      <ellipse className="st0" cx="383.4" cy="353.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M374.1,349.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S376.3,349.7,374.1,349.7z"/>
      <ellipse className="st0" cx="365" cy="353.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M355.6,357.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C351.8,356,353.7,357.7,355.6,357.7z"/>
      <path className="st0" d="M1175.3,340.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1179.2,342.3,1177.5,340.6,1175.3,340.6z"/>
      <path className="st0" d="M1166.3,340.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,342.3,1168.5,340.6,1166.3,340.6z"/>
      <path className="st0" d="M1156.9,340.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1160.8,342.3,1159.1,340.6,1156.9,340.6z"/>
      <path className="st0" d="M1147.8,340.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1151.7,342.3,1150,340.6,1147.8,340.6z"/>
      <path className="st0" d="M1138.5,340.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1142.6,342.3,1140.7,340.6,1138.5,340.6z"/>
      <path className="st0" d="M1129.4,340.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1133.2,342.3,1131.6,340.6,1129.4,340.6z"/>
      <path className="st0" d="M1120,340.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1124.2,342.3,1122.2,340.6,1120,340.6z"/>
      <path className="st0" d="M1111,340.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1114.8,342.3,1113.2,340.6,1111,340.6z"/>
      <path className="st0" d="M1097.8,344.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1099.7,340.6,1097.8,342.3,1097.8,344.5z"/>
      <path className="st0" d="M1088.7,344.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1088.7,342.3,1088.7,344.5z"/>
      <path className="st0" d="M1079.3,344.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1081.2,340.6,1079.3,342.3,1079.3,344.5z"/>
      <path className="st0" d="M1070.2,344.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1070.2,342.3,1070.2,344.5z"/>
      <path className="st0" d="M1060.9,344.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1062.8,340.6,1060.9,342.3,1060.9,344.5z"/>
      <path className="st0" d="M1051.8,344.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1051.8,342.3,1051.8,344.5z"/>
      <path className="st0" d="M1042.4,344.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1042.4,342.3,1042.4,344.5z"/>
      <path className="st0" d="M1033.4,344.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1035,340.6,1033.4,342.3,1033.4,344.5z"/>
      <path className="st0" d="M1024,344.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C1025.9,340.6,1024,342.3,1024,344.5
        z"/>
      <path className="st0" d="M1014.9,344.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1016.6,340.6,1014.9,342.3,1014.9,344.5z"/>
      <path className="st0" d="M1005.6,344.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1007.2,340.6,1005.6,342.3,1005.6,344.5z"/>
      <path className="st0" d="M996.5,344.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C998.1,340.6,996.5,342.3,996.5,344.5z"/>
      <path className="st0" d="M987.4,344.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C989.1,340.6,987.4,342.3,987.4,344.5z"/>
      <path className="st0" d="M981.9,340.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C986,342.3,984.1,340.6,981.9,340.6z"/>
      <path className="st0" d="M969,344.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S969,342.3,969,344.5z"/>
      <path className="st0" d="M959.6,344.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C961.5,340.6,959.6,342.3,959.6,344.5z"/>
      <path className="st0" d="M950.5,344.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C952.2,340.6,950.5,342.3,950.5,344.5z"/>
      <path className="st0" d="M941.2,344.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C943.1,340.6,941.2,342.3,941.2,344.5z"/>
      <path className="st0" d="M932.1,344.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C933.7,340.6,932.1,342.3,932.1,344.5z"/>
      <path className="st0" d="M922.7,344.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C924.7,340.6,922.7,342.3,922.7,344.5z"/>
      <path className="st0" d="M913.7,344.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C915.3,340.6,913.7,342.3,913.7,344.5z"/>
      <path className="st0" d="M904.3,344.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C906,340.6,904.3,342.3,904.3,344.5z"/>
      <path className="st0" d="M895.2,344.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C896.9,340.6,895.2,342.3,895.2,344.5z"/>
      <path className="st0" d="M885.9,344.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C887.8,340.6,885.9,342.3,885.9,344.5z"/>
      <path className="st0" d="M876.8,344.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C878.4,340.6,876.8,342.3,876.8,344.5z"/>
      <path className="st0" d="M871.6,340.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C875.4,342.3,873.8,340.6,871.6,340.6z"/>
      <path className="st0" d="M862.2,340.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C866.1,342.3,864.4,340.6,862.2,340.6z"/>
      <path className="st0" d="M853.1,348.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C849,346.7,850.9,348.3,853.1,348.3z"/>
      <path className="st0" d="M843.8,348.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C839.9,346.7,841.6,348.3,843.8,348.3z"/>
      <path className="st0" d="M834.7,348.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C830.8,346.7,832.5,348.3,834.7,348.3z"/>
      <path className="st0" d="M825.3,348.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,346.7,823.1,348.3,825.3,348.3z"/>
      <path className="st0" d="M700.4,344.5c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C698.8,348.3,700.4,346.7,700.4,344.5z"/>
      <path className="st0" d="M687.2,340.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.3,342.3,689.4,340.6,687.2,340.6z"/>
      <path className="st0" d="M678.1,348.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,346.7,675.9,348.3,678.1,348.3z"/>
      <path className="st0" d="M668.8,340.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C672.9,342.3,671,340.6,668.8,340.6z"/>
      <path className="st0" d="M622.8,340.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C626.7,342.3,625,340.6,622.8,340.6z"/>
      <path className="st0" d="M613.7,340.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C617.6,342.3,615.7,340.6,613.7,340.6z"/>
      <path className="st0" d="M600.5,344.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C602.2,340.6,600.5,342.3,600.5,344.5z"/>
      <path className="st0" d="M595.3,348.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.2,346.7,593.1,348.3,595.3,348.3z"/>
      <path className="st0" d="M388.6,344.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S388.6,342.3,388.6,344.5z"/>
      <path className="st0" d="M374.1,340.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C378.2,342.3,376.3,340.6,374.1,340.6z"/>
      <path className="st0" d="M365,340.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C368.8,342.3,367.2,340.6,365,340.6z"/>
      <path className="st0" d="M355.6,348.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C351.8,346.7,353.7,348.3,355.6,348.3z"/>
      <path className="st0" d="M337.2,348.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C333.3,346.7,335.3,348.3,337.2,348.3z"/>
      <path className="st0" d="M1175.3,331.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1179.2,333.2,1177.5,331.3,1175.3,331.3z"/>
      <path className="st0" d="M1166.3,331.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,333.2,1168.5,331.3,1166.3,331.3z"/>
      <path className="st0" d="M1156.9,331.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1160.8,333.2,1159.1,331.3,1156.9,331.3z"/>
      <path className="st0" d="M1147.8,331.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1151.7,333.2,1150,331.3,1147.8,331.3z"/>
      <path className="st0" d="M1138.5,331.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1142.6,333.2,1140.7,331.3,1138.5,331.3z"/>
      <path className="st0" d="M1129.4,331.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1133.2,333.2,1131.6,331.3,1129.4,331.3z"/>
      <path className="st0" d="M1120,331.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1124.2,333.2,1122.2,331.3,1120,331.3z"/>
      <path className="st0" d="M1111,331.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1114.8,333.2,1113.2,331.3,1111,331.3z"/>
      <path className="st0" d="M1097.8,335.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1099.7,331.3,1097.8,333.2,1097.8,335.4z"/>
      <path className="st0" d="M1088.7,335.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1090.3,331.3,1088.7,333.2,1088.7,335.4z"/>
      <path className="st0" d="M1079.3,335.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1081.2,331.3,1079.3,333.2,1079.3,335.4z"/>
      <path className="st0" d="M1070.2,335.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1070.2,333.2,1070.2,335.4z"/>
      <path className="st0" d="M1060.9,335.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1062.8,331.3,1060.9,333.2,1060.9,335.4z"/>
      <path className="st0" d="M1051.8,335.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1053.4,331.3,1051.8,333.2,1051.8,335.4z"/>
      <path className="st0" d="M1042.4,335.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1042.4,333.2,1042.4,335.4z"/>
      <path className="st0" d="M1033.4,335.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1035,331.3,1033.4,333.2,1033.4,335.4z"/>
      <path className="st0" d="M1024,335.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C1025.9,331.3,1024,333.2,1024,335.4
        z"/>
      <path className="st0" d="M1014.9,335.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1016.6,331.3,1014.9,333.2,1014.9,335.4z"/>
      <path className="st0" d="M1005.6,335.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1007.2,331.5,1005.6,333.2,1005.6,335.4z"/>
      <path className="st0" d="M996.5,335.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C998.1,331.3,996.5,333.2,996.5,335.4z"/>
      <path className="st0" d="M987.4,335.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C989.1,331.3,987.4,333.2,987.4,335.4z"/>
      <path className="st0" d="M981.9,331.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C986,333.2,984.1,331.3,981.9,331.3z"/>
      <path className="st0" d="M969,335.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S969,333.2,969,335.4z"/>
      <path className="st0" d="M959.6,335.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C961.5,331.3,959.6,333.2,959.6,335.4z"/>
      <path className="st0" d="M950.5,335.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C952.2,331.3,950.5,333.2,950.5,335.4z"/>
      <path className="st0" d="M941.2,335.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C943.1,331.3,941.2,333.2,941.2,335.4z"/>
      <path className="st0" d="M932.1,335.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C933.7,331.5,932.1,333.2,932.1,335.4z"/>
      <path className="st0" d="M922.7,335.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C924.7,331.3,922.7,333.2,922.7,335.4z"/>
      <path className="st0" d="M913.7,335.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C915.3,331.3,913.7,333.2,913.7,335.4z"/>
      <path className="st0" d="M904.3,335.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C906,331.5,904.3,333.2,904.3,335.4z"/>
      <path className="st0" d="M895.2,335.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C896.9,331.3,895.2,333.2,895.2,335.4z"/>
      <path className="st0" d="M885.9,335.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C887.8,331.3,885.9,333.2,885.9,335.4z"/>
      <path className="st0" d="M876.8,335.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C878.4,331.3,876.8,333.2,876.8,335.4z"/>
      <path className="st0" d="M871.6,331.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C875.4,333.2,873.8,331.3,871.6,331.3z"/>
      <path className="st0" d="M862.2,331.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C866.1,333.2,864.4,331.3,862.2,331.3z"/>
      <path className="st0" d="M853.1,331.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C857,333.2,855.3,331.3,853.1,331.3z"/>
      <path className="st0" d="M843.8,339.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S841.6,339.2,843.8,339.2z"
        />
      <path className="st0" d="M834.7,339.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S832.5,339.2,834.7,339.2z"
        />
      <path className="st0" d="M825.3,339.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S823.1,339.2,825.3,339.2z"
        />
      <path className="st0" d="M724.1,331.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,333.2,726.3,331.3,724.1,331.3z"/>
      <path className="st0" d="M715,331.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,333.2,717.2,331.3,715,331.3z"/>
      <path className="st0" d="M709.5,335.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C707.8,339.2,709.5,337.6,709.5,335.4z"/>
      <path className="st0" d="M696.6,331.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,333.2,698.8,331.3,696.6,331.3z"/>
      <path className="st0" d="M691.3,335.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C689.4,339.2,691.3,337.6,691.3,335.4z"/>
      <path className="st0" d="M678.1,339.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S675.9,339.2,678.1,339.2z"/>
      <path className="st0" d="M668.8,339.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S666.6,339.2,668.8,339.2z"/>
      <path className="st0" d="M631.9,339.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C628,337.6,630,339.2,631.9,339.2z"/>
      <path className="st0" d="M622.8,331.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C626.7,333.2,625,331.3,622.8,331.3z"/>
      <path className="st0" d="M613.7,339.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C609.6,337.6,611.5,339.2,613.7,339.2z"/>
      <path className="st0" d="M604.4,339.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S602.2,339.2,604.4,339.2z"
        />
      <path className="st0" d="M595.3,339.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.2,337.6,593.1,339.2,595.3,339.2z"/>
      <path className="st0" d="M585.9,331.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C589.8,333.2,588.1,331.3,585.9,331.3z"/>
      <path className="st0" d="M387.3,335.4c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S387.3,337.6,387.3,335.4z"/>
      <path className="st0" d="M374.1,331.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C378.2,333.2,376.3,331.3,374.1,331.3z"/>
      <path className="st0" d="M365,331.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C368.8,333.2,367.2,331.3,365,331.3z"/>
      <path className="st0" d="M355.6,339.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S353.7,339.2,355.6,339.2z"
        />
      <path className="st0" d="M346.5,331.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C350.4,333.2,348.7,331.3,346.5,331.3z"/>
      <ellipse className="st0" cx="1175.3" cy="326" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,322.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,323.8,1168.5,322.2,1166.3,322.2z"/>
      <path className="st0" d="M1156.9,322.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1160.8,323.8,1159.1,322.2,1156.9,322.2z"/>
      <ellipse className="st0" cx="1147.8" cy="326" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1138.5,322.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1142.3,323.8,1140.7,322.2,1138.5,322.2z"/>
      <path className="st0" d="M1129.4,322.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1133.2,323.8,1131.6,322.2,1129.4,322.2z"/>
      <path className="st0" d="M1120,322.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1123.9,323.8,1122.2,322.2,1120,322.2z"/>
      <ellipse className="st0" cx="1111" cy="326" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1097.8,326c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C1099.7,322.2,1097.8,323.8,1097.8,326z"/>
      <path className="st0" d="M1088.7,326c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C1090.3,322.2,1088.7,323.8,1088.7,326z"
        />
      <path className="st0" d="M1079.3,326c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C1081.2,322.2,1079.3,323.8,1079.3,326z"
        />
      <ellipse className="st0" cx="1074.1" cy="326" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1060.9,326c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C1062.8,322.2,1060.9,323.8,1060.9,326z"
        />
      <path className="st0" d="M1051.8,326c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C1053.4,322.2,1051.8,323.8,1051.8,326z"
        />
      <ellipse className="st0" cx="1046.6" cy="326" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1033.4,326c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C1035,322.2,1033.4,323.8,1033.4,326z"/>
      <path className="st0" d="M1024,326c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C1025.9,322.2,1024,323.8,1024,326z"/>
      <path className="st0" d="M1014.9,326c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C1016.6,322.2,1014.9,323.8,1014.9,326z"/>
      <ellipse className="st0" cx="1009.7" cy="326" rx="3.9" ry="3.9"/>
      <path className="st0" d="M996.5,326c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C998.1,322.2,996.5,323.8,996.5,326z
        "/>
      <path className="st0" d="M987.4,326c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C989.1,322.2,987.4,323.8,987.4,326z
        "/>
      <path className="st0" d="M981.9,322.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C985.8,323.8,984.1,322.2,981.9,322.2z"/>
      <ellipse className="st0" cx="972.8" cy="326" rx="3.9" ry="3.9"/>
      <path className="st0" d="M959.6,326c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C961.5,322.2,959.6,323.8,959.6,326z
        "/>
      <path className="st0" d="M950.5,326c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C952.2,322.2,950.5,323.8,950.5,326z
        "/>
      <path className="st0" d="M941.2,326c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C943.1,322.2,941.2,323.8,941.2,326z
        "/>
      <ellipse className="st0" cx="936" cy="326" rx="3.9" ry="3.9"/>
      <path className="st0" d="M922.7,326c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C924.7,322.2,922.7,323.8,922.7,326z"/>
      <path className="st0" d="M913.7,326c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C915.3,322.2,913.7,323.8,913.7,326z
        "/>
      <ellipse className="st0" cx="908.4" cy="326" rx="3.9" ry="3.9"/>
      <path className="st0" d="M895.2,326c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C896.9,322.2,895.2,323.8,895.2,326z
        "/>
      <path className="st0" d="M885.9,326c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C887.8,322.2,885.9,323.8,885.9,326z
        "/>
      <path className="st0" d="M876.8,326c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C878.4,322.2,876.8,323.8,876.8,326z
        "/>
      <ellipse className="st0" cx="871.6" cy="326" rx="3.9" ry="3.9"/>
      <path className="st0" d="M862.2,322.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C866.1,323.8,864.4,322.2,862.2,322.2z"/>
      <path className="st0" d="M853.1,322.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C857,323.8,855.3,322.2,853.1,322.2z"/>
      <path className="st0" d="M843.8,322.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C847.6,323.8,846,322.2,843.8,322.2z"/>
      <ellipse className="st0" cx="834.7" cy="326" rx="3.9" ry="3.9"/>
      <path className="st0" d="M825.3,329.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S823.1,329.9,825.3,329.9z"/>
      <ellipse className="st0" cx="724.1" cy="326" rx="3.9" ry="3.9"/>
      <path className="st0" d="M715,329.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S712.8,329.9,715,329.9z"/>
      <path className="st0" d="M705.6,329.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S703.4,329.9,705.6,329.9z"/>
      <ellipse className="st0" cx="696.6" cy="326" rx="3.9" ry="3.9"/>
      <path className="st0" d="M691.3,326c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C689.4,329.9,691.3,328.2,691.3,326z
        "/>
      <path className="st0" d="M678.1,329.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S675.9,329.9,678.1,329.9z"/>
      <ellipse className="st0" cx="622.8" cy="326" rx="3.9" ry="3.9"/>
      <path className="st0" d="M613.7,329.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S611.5,329.9,613.7,329.9z"/>
      <ellipse className="st0" cx="604.4" cy="326" rx="3.9" ry="3.9"/>
      <ellipse className="st0" cx="383.4" cy="326" rx="3.9" ry="3.9"/>
      <path className="st0" d="M378.2,326c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C376.3,329.9,378.2,328.2,378.2,326z
        "/>
      <ellipse className="st0" cx="365" cy="326" rx="3.9" ry="3.9"/>
      <path className="st0" d="M355.6,329.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S353.7,329.9,355.6,329.9z"/>
      <ellipse className="st0" cx="346.5" cy="326" rx="3.9" ry="3.9"/>
      <path className="st0" d="M337.2,329.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S335.3,329.9,337.2,329.9z"/>
      <path className="st0" d="M1175.3,313.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1179.2,314.8,1177.5,313.1,1175.3,313.1z"/>
      <path className="st0" d="M1166.3,313.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,314.8,1168.5,313.1,1166.3,313.1z"/>
      <path className="st0" d="M1156.9,313.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1160.8,314.8,1159.1,313.1,1156.9,313.1z"/>
      <path className="st0" d="M1147.8,313.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1151.7,314.8,1150,313.1,1147.8,313.1z"/>
      <path className="st0" d="M1138.5,313.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1142.6,314.8,1140.7,313.1,1138.5,313.1z"/>
      <path className="st0" d="M1129.4,313.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1133.2,314.8,1131.6,313.1,1129.4,313.1z"/>
      <path className="st0" d="M1120,313.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1124.2,314.8,1122.2,313.1,1120,313.1z"/>
      <path className="st0" d="M1107.1,317c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1107.1,314.8,1107.1,317z"/>
      <path className="st0" d="M1097.8,317c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1099.7,313.1,1097.8,314.8,1097.8,317z"/>
      <path className="st0" d="M1088.7,317c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1088.7,314.8,1088.7,317z"/>
      <path className="st0" d="M1079.3,317c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1081.2,313.1,1079.3,314.8,1079.3,317z"/>
      <path className="st0" d="M1070.2,317c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1070.2,314.8,1070.2,317z"/>
      <path className="st0" d="M1060.9,317c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1062.8,313.1,1060.9,314.8,1060.9,317z"/>
      <path className="st0" d="M1051.8,317c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1051.8,314.8,1051.8,317z"/>
      <path className="st0" d="M1042.4,317c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1042.4,314.8,1042.4,317z"/>
      <path className="st0" d="M1033.4,317c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C1035,313.1,1033.4,314.8,1033.4,317
        z"/>
      <path className="st0" d="M1024,317c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C1025.9,313.1,1024,314.8,1024,317z"/>
      <path className="st0" d="M1014.9,317c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1016.6,313.1,1014.9,314.8,1014.9,317z"/>
      <path className="st0" d="M1005.6,317c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1007.2,313.1,1005.6,314.8,1005.6,317z"/>
      <path className="st0" d="M996.5,317c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C998.1,313.1,996.5,314.8,996.5,317z"/>
      <path className="st0" d="M987.4,317c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C989.1,313.1,987.4,314.8,987.4,317z"/>
      <path className="st0" d="M981.9,313.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C986,314.8,984.1,313.1,981.9,313.1z"/>
      <path className="st0" d="M969,317c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S969,314.8,969,317z"/>
      <path className="st0" d="M959.6,317c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C961.5,313.1,959.6,314.8,959.6,317z"/>
      <path className="st0" d="M950.5,317c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C952.2,313.1,950.5,314.8,950.5,317z"/>
      <path className="st0" d="M941.2,317c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C943.1,313.1,941.2,314.8,941.2,317z"/>
      <path className="st0" d="M932.1,317c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C933.7,313.1,932.1,314.8,932.1,317z"/>
      <path className="st0" d="M922.7,317c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C924.7,313.1,922.7,314.8,922.7,317z"
        />
      <path className="st0" d="M913.7,317c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C915.3,313.1,913.7,314.8,913.7,317z"/>
      <path className="st0" d="M904.3,317c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C906,313.1,904.3,314.8,904.3,317z"/>
      <path className="st0" d="M895.2,317c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C896.9,313.1,895.2,314.8,895.2,317z"/>
      <path className="st0" d="M885.9,317c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C887.8,313.1,885.9,314.8,885.9,317z"/>
      <path className="st0" d="M880.6,320.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C876.8,319.2,878.4,320.8,880.6,320.8z"/>
      <path className="st0" d="M871.6,320.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C867.4,319.2,869.4,320.8,871.6,320.8z"/>
      <path className="st0" d="M858.4,317c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C860,313.1,858.4,314.8,858.4,317z"/>
      <path className="st0" d="M853.1,313.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C857,314.8,855.3,313.1,853.1,313.1z"/>
      <path className="st0" d="M843.8,320.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C839.9,319.2,841.6,320.8,843.8,320.8z"/>
      <path className="st0" d="M834.7,320.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C830.8,319.2,832.5,320.8,834.7,320.8z"/>
      <path className="st0" d="M821.5,317c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C823.1,313.1,821.5,314.8,821.5,317z"/>
      <path className="st0" d="M737.3,317c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C735.4,320.8,737.3,319.2,737.3,317z"/>
      <path className="st0" d="M724.1,313.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,314.8,726.3,313.1,724.1,313.1z"/>
      <path className="st0" d="M715,313.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,314.8,717.2,313.1,715,313.1z"/>
      <path className="st0" d="M705.6,320.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C701.8,319.2,703.4,320.8,705.6,320.8z"/>
      <path className="st0" d="M700.4,317c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C698.8,320.8,700.4,319.2,700.4,317z"/>
      <path className="st0" d="M687.2,320.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C683.3,319.2,685,320.8,687.2,320.8z"/>
      <path className="st0" d="M678.1,320.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,319.2,675.9,320.8,678.1,320.8z"/>
      <path className="st0" d="M668.8,320.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C664.9,319.2,666.6,320.8,668.8,320.8z"/>
      <path className="st0" d="M622.8,313.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C626.7,314.8,625,313.1,622.8,313.1z"/>
      <path className="st0" d="M613.7,313.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C617.6,314.8,615.7,313.1,613.7,313.1z"/>
      <path className="st0" d="M604.4,320.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C600.5,319.2,602.2,320.8,604.4,320.8z"/>
      <path className="st0" d="M599.1,317c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C597.2,320.8,599.1,319.2,599.1,317z"/>
      <path className="st0" d="M392.5,313.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C396.3,314.8,394.7,313.1,392.5,313.1z"/>
      <path className="st0" d="M383.4,313.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C387.3,314.8,385.6,313.1,383.4,313.1z"/>
      <path className="st0" d="M374.1,313.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C378.2,314.8,376.3,313.1,374.1,313.1z"/>
      <path className="st0" d="M365,313.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C368.8,314.8,367.2,313.1,365,313.1z"/>
      <path className="st0" d="M355.6,320.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C351.8,319.2,353.7,320.8,355.6,320.8z"/>
      <path className="st0" d="M346.5,320.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C342.7,319.2,344.3,320.8,346.5,320.8z"/>
      <path className="st0" d="M337.2,320.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C333.3,319.2,335.3,320.8,337.2,320.8z"/>
      <path className="st0" d="M1175.3,303.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1179.2,305.7,1177.5,303.7,1175.3,303.7z"/>
      <path className="st0" d="M1166.3,303.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,305.7,1168.5,303.7,1166.3,303.7z"/>
      <path className="st0" d="M1156.9,303.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1160.8,305.7,1159.1,303.7,1156.9,303.7z"/>
      <path className="st0" d="M1147.8,303.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1151.7,305.7,1150,303.7,1147.8,303.7z"/>
      <path className="st0" d="M1138.5,303.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1142.6,305.7,1140.7,303.7,1138.5,303.7z"/>
      <path className="st0" d="M1129.4,303.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1133.2,305.7,1131.6,303.7,1129.4,303.7z"/>
      <path className="st0" d="M1116.2,307.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1117.8,303.7,1116.2,305.7,1116.2,307.9z"/>
      <path className="st0" d="M1107.1,307.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1107.1,305.7,1107.1,307.9z"/>
      <path className="st0" d="M1097.8,307.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1099.7,303.7,1097.8,305.7,1097.8,307.9z"/>
      <path className="st0" d="M1088.7,307.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1090.3,303.7,1088.7,305.7,1088.7,307.9z"/>
      <path className="st0" d="M1079.3,307.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1081.2,303.7,1079.3,305.7,1079.3,307.9z"/>
      <path className="st0" d="M1070.2,307.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1070.2,305.7,1070.2,307.9z"/>
      <path className="st0" d="M1060.9,307.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1062.8,303.7,1060.9,305.7,1060.9,307.9z"/>
      <path className="st0" d="M1051.8,307.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1053.4,303.7,1051.8,305.7,1051.8,307.9z"/>
      <path className="st0" d="M1042.4,307.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1042.4,305.7,1042.4,307.9z"/>
      <path className="st0" d="M1033.4,307.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1035,303.7,1033.4,305.7,1033.4,307.9z"/>
      <path className="st0" d="M1024,307.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C1025.9,303.7,1024,305.7,1024,307.9
        z"/>
      <path className="st0" d="M1014.9,307.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1016.6,303.7,1014.9,305.7,1014.9,307.9z"/>
      <path className="st0" d="M1005.6,307.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1007.2,304,1005.6,305.7,1005.6,307.9z"/>
      <path className="st0" d="M996.5,307.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C998.1,303.7,996.5,305.7,996.5,307.9z"/>
      <path className="st0" d="M987.4,307.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C989.1,303.7,987.4,305.7,987.4,307.9z"/>
      <path className="st0" d="M981.9,303.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C986,305.7,984.1,303.7,981.9,303.7z"/>
      <path className="st0" d="M972.8,311.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C969,309.8,970.6,311.7,972.8,311.7z"/>
      <path className="st0" d="M963.5,311.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C959.6,309.8,961.5,311.7,963.5,311.7z"/>
      <path className="st0" d="M954.4,311.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C950.5,309.8,952.2,311.7,954.4,311.7z"/>
      <path className="st0" d="M945,311.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C941.2,309.8,943.1,311.7,945,311.7z"/>
      <path className="st0" d="M936,311.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C932.1,309.8,933.7,311.7,936,311.7z"/>
      <path className="st0" d="M926.6,311.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C922.7,309.8,924.7,311.7,926.6,311.7z"/>
      <path className="st0" d="M917.5,311.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C913.7,309.8,915.3,311.7,917.5,311.7z"/>
      <path className="st0" d="M908.4,311.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C904.3,309.8,906.2,311.7,908.4,311.7z"/>
      <path className="st0" d="M899.1,311.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C895.2,309.8,896.9,311.7,899.1,311.7z"/>
      <path className="st0" d="M890,311.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C885.9,309.8,887.8,311.7,890,311.7z
        "/>
      <path className="st0" d="M880.6,311.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C876.8,309.8,878.4,311.7,880.6,311.7z"/>
      <path className="st0" d="M853.1,303.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C857,305.7,855.3,303.7,853.1,303.7z"/>
      <path className="st0" d="M843.8,303.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C847.6,305.7,846,303.7,843.8,303.7z"/>
      <path className="st0" d="M834.7,311.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C830.8,309.8,832.5,311.7,834.7,311.7z"/>
      <path className="st0" d="M770.3,311.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C766.2,309.8,768.1,311.7,770.3,311.7z"/>
      <path className="st0" d="M733.4,303.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,305.7,735.4,303.7,733.4,303.7z"/>
      <path className="st0" d="M727.9,307.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S727.9,309.8,727.9,307.9z"/>
      <path className="st0" d="M718.8,307.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C717.2,311.7,718.8,309.8,718.8,307.9z"/>
      <path className="st0" d="M705.6,311.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C701.8,309.8,703.4,311.7,705.6,311.7z"/>
      <path className="st0" d="M687.2,311.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C683.3,309.8,685,311.7,687.2,311.7z"/>
      <path className="st0" d="M678.1,311.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,309.8,675.9,311.7,678.1,311.7z"/>
      <path className="st0" d="M668.8,311.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C664.9,309.8,666.6,311.7,668.8,311.7z"/>
      <path className="st0" d="M663.5,307.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C661.9,311.7,663.5,309.8,663.5,307.9z"/>
      <path className="st0" d="M622.8,311.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C619,309.8,620.6,311.7,622.8,311.7z"/>
      <path className="st0" d="M613.7,311.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C609.6,309.8,611.5,311.7,613.7,311.7z"/>
      <path className="st0" d="M600.5,307.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C602.2,304,600.5,305.7,600.5,307.9z"/>
      <path className="st0" d="M405.7,307.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S405.7,309.8,405.7,307.9z"/>
      <path className="st0" d="M392.5,303.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C396.3,305.7,394.7,303.7,392.5,303.7z"/>
      <path className="st0" d="M387.3,307.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S387.3,309.8,387.3,307.9z"/>
      <path className="st0" d="M374.1,303.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C378.2,305.7,376.3,303.7,374.1,303.7z"/>
      <path className="st0" d="M365,303.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C368.8,305.7,367.2,303.7,365,303.7z"/>
      <path className="st0" d="M355.6,311.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C351.8,309.8,353.7,311.7,355.6,311.7z"/>
      <path className="st0" d="M346.5,311.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C342.7,309.8,344.3,311.7,346.5,311.7z"/>
      <path className="st0" d="M1175.3,294.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1177.5,294.7,1175.3,294.7z"/>
      <path className="st0" d="M1166.3,294.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1168.5,294.7,1166.3,294.7
        z"/>
      <path className="st0" d="M1156.9,294.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1159.1,294.7,1156.9,294.7z"/>
      <path className="st0" d="M1147.8,294.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1151.7,296.3,1150,294.7,1147.8,294.7z"/>
      <path className="st0" d="M1138.5,294.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1142.6,296.3,1140.7,294.7,1138.5,294.7z"/>
      <path className="st0" d="M1129.4,294.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1131.6,294.7,1129.4,294.7
        z"/>
      <path className="st0" d="M1116.2,298.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1117.8,294.7,1116.2,296.3,1116.2,298.5z"/>
      <path className="st0" d="M1107.1,298.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1107.1,296.3,1107.1,298.5z"/>
      <path className="st0" d="M1097.8,298.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1099.7,294.7,1097.8,296.3,1097.8,298.5z"/>
      <path className="st0" d="M1088.7,298.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1090.3,294.7,1088.7,296.3,1088.7,298.5z"/>
      <path className="st0" d="M1079.3,298.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1081.2,294.7,1079.3,296.3,1079.3,298.5z"/>
      <path className="st0" d="M1070.2,298.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1070.2,296.3,1070.2,298.5z"/>
      <path className="st0" d="M1060.9,298.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1062.8,294.7,1060.9,296.3,1060.9,298.5z"/>
      <path className="st0" d="M1051.8,298.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1053.4,294.7,1051.8,296.3,1051.8,298.5z"/>
      <path className="st0" d="M1042.4,298.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1042.4,296.3,1042.4,298.5z"/>
      <path className="st0" d="M1033.4,298.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1035,294.7,1033.4,296.3,1033.4,298.5z"/>
      <path className="st0" d="M1024,298.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C1025.9,294.7,1024,296.3,1024,298.5
        z"/>
      <path className="st0" d="M1014.9,298.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1016.6,294.7,1014.9,296.3,1014.9,298.5z"/>
      <path className="st0" d="M1005.6,298.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1007.2,294.7,1005.6,296.3,1005.6,298.5z"/>
      <path className="st0" d="M996.5,298.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C998.1,294.7,996.5,296.3,996.5,298.5z"/>
      <path className="st0" d="M987.4,298.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C989.1,294.7,987.4,296.3,987.4,298.5z"/>
      <path className="st0" d="M981.9,294.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C986,296.3,984.1,294.7,981.9,294.7z"/>
      <path className="st0" d="M972.8,294.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S975,294.7,972.8,294.7z"/>
      <path className="st0" d="M959.6,298.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C961.5,294.7,959.6,296.3,959.6,298.5z"/>
      <path className="st0" d="M950.5,298.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C952.2,294.7,950.5,296.3,950.5,298.5z"/>
      <path className="st0" d="M945,302.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C941.2,300.7,943.1,302.4,945,302.4z"/>
      <path className="st0" d="M936,302.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C932.1,300.7,933.7,302.4,936,302.4z"/>
      <path className="st0" d="M926.6,302.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C922.7,300.7,924.7,302.4,926.6,302.4z"/>
      <path className="st0" d="M917.5,302.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C913.7,300.7,915.3,302.4,917.5,302.4z"/>
      <path className="st0" d="M908.4,302.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C904.3,300.7,906.2,302.4,908.4,302.4z"/>
      <path className="st0" d="M899.1,302.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C895.2,300.7,896.9,302.4,899.1,302.4z"/>
      <path className="st0" d="M890,302.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C885.9,300.7,887.8,302.4,890,302.4z
        "/>
      <path className="st0" d="M849,298.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C850.9,294.7,849,296.3,849,298.5z"/>
      <path className="st0" d="M843.8,302.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C839.9,300.7,841.6,302.4,843.8,302.4z"/>
      <path className="st0" d="M834.7,302.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C830.8,300.7,832.5,302.4,834.7,302.4z"/>
      <path className="st0" d="M770.3,302.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C766.2,300.7,768.1,302.4,770.3,302.4z"/>
      <path className="st0" d="M733.4,294.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S735.4,294.7,733.4,294.7z"
        />
      <path className="st0" d="M724.1,294.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,296.3,726.3,294.7,724.1,294.7z"/>
      <path className="st0" d="M715,294.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S717.2,294.7,715,294.7z"/>
      <path className="st0" d="M705.6,294.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S707.8,294.7,705.6,294.7z"
        />
      <path className="st0" d="M696.6,302.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C692.7,300.7,694.4,302.4,696.6,302.4z"/>
      <path className="st0" d="M687.2,302.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C683.3,300.7,685,302.4,687.2,302.4z"/>
      <path className="st0" d="M678.1,302.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,300.7,675.9,302.4,678.1,302.4z"/>
      <path className="st0" d="M668.8,302.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C664.9,300.7,666.6,302.4,668.8,302.4z"/>
      <path className="st0" d="M659.7,302.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C655.8,300.7,657.5,302.4,659.7,302.4z"/>
      <path className="st0" d="M619,298.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C620.6,294.7,619,296.3,619,298.5z"/>
      <path className="st0" d="M401.8,302.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C397.7,300.7,399.6,302.4,401.8,302.4z"/>
      <path className="st0" d="M388.6,298.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C390.3,294.7,388.6,296.3,388.6,298.5z"/>
      <path className="st0" d="M383.4,302.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C379.6,300.7,381.2,302.4,383.4,302.4z"/>
      <path className="st0" d="M370.2,298.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C371.9,294.7,370.2,296.3,370.2,298.5z"/>
      <path className="st0" d="M365,302.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C361.1,300.7,362.8,302.4,365,302.4z"/>
      <path className="st0" d="M355.6,294.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C359.7,296.3,357.8,294.7,355.6,294.7z"/>
      <path className="st0" d="M346.5,302.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C342.7,300.7,344.3,302.4,346.5,302.4z"/>
      <path className="st0" d="M341.3,298.5c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C339.4,302.4,341.3,300.7,341.3,298.5z"/>
      <path className="st0" d="M1175.3,285.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1179.2,287.2,1177.5,285.6,1175.3,285.6z"/>
      <path className="st0" d="M1166.3,285.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,287.2,1168.5,285.6,1166.3,285.6z"/>
      <path className="st0" d="M1156.9,285.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1160.8,287.2,1159.1,285.6,1156.9,285.6z"/>
      <path className="st0" d="M1147.8,285.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1151.7,287.2,1150,285.6,1147.8,285.6z"/>
      <path className="st0" d="M1138.5,285.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1142.6,287.2,1140.7,285.6,1138.5,285.6z"/>
      <path className="st0" d="M1125.5,289.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1127.2,285.6,1125.5,287.2,1125.5,289.4z"/>
      <path className="st0" d="M1116.2,289.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1117.8,285.6,1116.2,287.2,1116.2,289.4z"/>
      <path className="st0" d="M1107.1,289.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1107.1,287.2,1107.1,289.4z"/>
      <path className="st0" d="M1097.8,289.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1099.7,285.6,1097.8,287.2,1097.8,289.4z"/>
      <path className="st0" d="M1088.7,289.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1088.7,287.2,1088.7,289.4z"/>
      <path className="st0" d="M1079.3,289.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1081.2,285.6,1079.3,287.2,1079.3,289.4z"/>
      <path className="st0" d="M1070.2,289.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1070.2,287.2,1070.2,289.4z"/>
      <path className="st0" d="M1060.9,289.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1062.8,285.6,1060.9,287.2,1060.9,289.4z"/>
      <path className="st0" d="M1051.8,289.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1051.8,287.2,1051.8,289.4z"/>
      <path className="st0" d="M1042.4,289.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1042.4,287.2,1042.4,289.4z"/>
      <path className="st0" d="M1033.4,289.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1035,285.6,1033.4,287.2,1033.4,289.4z"/>
      <path className="st0" d="M1024,289.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C1025.9,285.6,1024,287.2,1024,289.4
        z"/>
      <path className="st0" d="M1014.9,289.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1016.6,285.6,1014.9,287.2,1014.9,289.4z"/>
      <path className="st0" d="M1005.6,289.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1007.2,285.6,1005.6,287.2,1005.6,289.4z"/>
      <path className="st0" d="M996.5,289.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C998.1,285.6,996.5,287.2,996.5,289.4z"/>
      <path className="st0" d="M987.4,289.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C989.1,285.6,987.4,287.2,987.4,289.4z"/>
      <path className="st0" d="M981.9,285.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C986,287.2,984.1,285.6,981.9,285.6z"/>
      <path className="st0" d="M972.8,285.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C976.7,287.2,975,285.6,972.8,285.6z"/>
      <path className="st0" d="M959.6,289.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C961.5,285.6,959.6,287.2,959.6,289.4z"/>
      <path className="st0" d="M950.5,289.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C952.2,285.6,950.5,287.2,950.5,289.4z"/>
      <path className="st0" d="M945,285.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C949.2,287.2,947.2,285.6,945,285.6z"/>
      <path className="st0" d="M936,293.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C932.1,291.6,933.7,293.3,936,293.3z"/>
      <path className="st0" d="M926.6,293.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C922.7,291.6,924.7,293.3,926.6,293.3z"/>
      <path className="st0" d="M917.5,293.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C913.7,291.6,915.3,293.3,917.5,293.3z"/>
      <path className="st0" d="M908.4,293.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C904.3,291.6,906.2,293.3,908.4,293.3z"/>
      <path className="st0" d="M899.1,293.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C895.2,291.6,896.9,293.3,899.1,293.3z"/>
      <path className="st0" d="M890,293.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C885.9,291.6,887.8,293.3,890,293.3z
        "/>
      <path className="st0" d="M884.5,289.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C882.8,293.3,884.5,291.6,884.5,289.4z"/>
      <path className="st0" d="M843.8,293.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C839.9,291.6,841.6,293.3,843.8,293.3z"/>
      <path className="st0" d="M779.4,293.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C775.5,291.6,777.2,293.3,779.4,293.3z"/>
      <path className="st0" d="M733.4,285.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,287.2,735.4,285.6,733.4,285.6z"/>
      <path className="st0" d="M724.1,285.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,287.2,726.3,285.6,724.1,285.6z"/>
      <path className="st0" d="M715,285.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,287.2,717.2,285.6,715,285.6z"/>
      <path className="st0" d="M705.6,285.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,287.2,707.8,285.6,705.6,285.6z"/>
      <path className="st0" d="M696.6,285.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,287.2,698.8,285.6,696.6,285.6z"/>
      <path className="st0" d="M687.2,293.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C683.3,291.6,685,293.3,687.2,293.3z"/>
      <path className="st0" d="M678.1,293.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,291.6,675.9,293.3,678.1,293.3z"/>
      <path className="st0" d="M668.8,293.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C664.9,291.6,666.6,293.3,668.8,293.3z"/>
      <path className="st0" d="M659.7,293.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C655.8,291.6,657.5,293.3,659.7,293.3z"/>
      <path className="st0" d="M383.4,293.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C379.6,291.6,381.2,293.3,383.4,293.3z"/>
      <path className="st0" d="M355.6,285.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C359.7,287.2,357.8,285.6,355.6,285.6z"/>
      <path className="st0" d="M346.5,293.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C342.7,291.6,344.3,293.3,346.5,293.3z"/>
      <path className="st0" d="M337.2,293.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C333.3,291.6,335.3,293.3,337.2,293.3z"/>
      <path className="st0" d="M1175.3,276.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1179.2,278.2,1177.5,276.2,1175.3,276.2z"/>
      <path className="st0" d="M1166.3,276.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,278.2,1168.5,276.2,1166.3,276.2z"/>
      <path className="st0" d="M1156.9,276.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1160.8,278.2,1159.1,276.2,1156.9,276.2z"/>
      <path className="st0" d="M1147.8,276.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1151.7,278.2,1150,276.2,1147.8,276.2z"/>
      <path className="st0" d="M1138.5,276.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1142.6,278.2,1140.7,276.2,1138.5,276.2z"/>
      <path className="st0" d="M1125.5,280.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1127.2,276.2,1125.5,278.2,1125.5,280.1z"/>
      <path className="st0" d="M1116.2,280.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1117.8,276.2,1116.2,278.2,1116.2,280.1z"/>
      <path className="st0" d="M1107.1,280.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1107.1,278.2,1107.1,280.1z"/>
      <path className="st0" d="M1097.8,280.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1099.7,276.2,1097.8,278.2,1097.8,280.1z"/>
      <path className="st0" d="M1088.7,280.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1090.3,276.2,1088.7,278.2,1088.7,280.1z"/>
      <path className="st0" d="M1079.3,280.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1081.2,276.2,1079.3,278.2,1079.3,280.1z"/>
      <path className="st0" d="M1070.2,280.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1070.2,278.2,1070.2,280.1z"/>
      <path className="st0" d="M1060.9,280.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1062.8,276.2,1060.9,278.2,1060.9,280.1z"/>
      <path className="st0" d="M1051.8,280.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1053.4,276.2,1051.8,278.2,1051.8,280.1z"/>
      <path className="st0" d="M1042.4,280.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1042.4,278.2,1042.4,280.1z"/>
      <path className="st0" d="M1033.4,280.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1035,276.2,1033.4,278.2,1033.4,280.1z"/>
      <path className="st0" d="M1024,280.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C1025.9,276.2,1024,278.2,1024,280.1
        z"/>
      <path className="st0" d="M1014.9,280.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1016.6,276.2,1014.9,278.2,1014.9,280.1z"/>
      <path className="st0" d="M1005.6,280.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1007.2,276.2,1005.6,278.2,1005.6,280.1z"/>
      <path className="st0" d="M996.5,280.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C998.1,276.2,996.5,278.2,996.5,280.1z"/>
      <path className="st0" d="M987.4,280.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C989.1,276.2,987.4,278.2,987.4,280.1z"/>
      <path className="st0" d="M981.9,276.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C986,278.2,984.1,276.2,981.9,276.2z"/>
      <path className="st0" d="M972.8,276.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C976.7,278.2,975,276.2,972.8,276.2z"/>
      <path className="st0" d="M959.6,280.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C961.5,276.2,959.6,278.2,959.6,280.1z"/>
      <path className="st0" d="M950.5,280.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C952.2,276.2,950.5,278.2,950.5,280.1z"/>
      <path className="st0" d="M945,276.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C949.2,278.2,947.2,276.2,945,276.2z"/>
      <path className="st0" d="M936,276.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C939.8,278.2,938.2,276.2,936,276.2z"/>
      <path className="st0" d="M926.6,284.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C922.7,282.3,924.7,284.2,926.6,284.2z"/>
      <path className="st0" d="M917.5,284.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C913.7,282.3,915.3,284.2,917.5,284.2z"/>
      <path className="st0" d="M908.4,284.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C904.3,282.3,906.2,284.2,908.4,284.2z"/>
      <path className="st0" d="M899.1,284.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C895.2,282.3,896.9,284.2,899.1,284.2z"/>
      <path className="st0" d="M890,284.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C885.9,282.3,887.8,284.2,890,284.2z
        "/>
      <path className="st0" d="M880.6,284.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C876.8,282.3,878.4,284.2,880.6,284.2z"/>
      <path className="st0" d="M733.4,276.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,278.2,735.4,276.2,733.4,276.2z"/>
      <path className="st0" d="M724.1,276.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,278.2,726.3,276.2,724.1,276.2z"/>
      <path className="st0" d="M715,276.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,278.2,717.2,276.2,715,276.2z"/>
      <path className="st0" d="M705.6,276.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,278.2,707.8,276.2,705.6,276.2z"/>
      <path className="st0" d="M696.6,276.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,278.2,698.8,276.2,696.6,276.2z"/>
      <path className="st0" d="M687.2,284.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C683.3,282.3,685,284.2,687.2,284.2z"/>
      <path className="st0" d="M678.1,284.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,282.3,675.9,284.2,678.1,284.2z"/>
      <path className="st0" d="M668.8,284.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C664.9,282.3,666.6,284.2,668.8,284.2z"/>
      <path className="st0" d="M659.7,284.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C655.8,282.3,657.5,284.2,659.7,284.2z"/>
      <path className="st0" d="M365,276.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C368.8,278.2,367.2,276.2,365,276.2z"/>
      <path className="st0" d="M355.6,276.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C359.7,278.2,357.8,276.2,355.6,276.2z"/>
      <path className="st0" d="M346.5,284.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C342.7,282.3,344.3,284.2,346.5,284.2z"/>
      <path className="st0" d="M1175.3,267.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1179.2,268.8,1177.5,267.1,1175.3,267.1z"/>
      <path className="st0" d="M1166.3,267.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,268.8,1168.5,267.1,1166.3,267.1z"/>
      <path className="st0" d="M1156.9,267.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1160.8,268.8,1159.1,267.1,1156.9,267.1z"/>
      <path className="st0" d="M1147.8,267.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1151.7,268.8,1150,267.1,1147.8,267.1z"/>
      <path className="st0" d="M1134.6,271c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1136.3,267.1,1134.6,268.8,1134.6,271z"/>
      <path className="st0" d="M1125.5,271c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1127.2,267.1,1125.5,268.8,1125.5,271z"/>
      <path className="st0" d="M1116.2,271c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1117.8,267.1,1116.2,268.8,1116.2,271z"/>
      <path className="st0" d="M1107.1,271c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1107.1,268.8,1107.1,271z"/>
      <path className="st0" d="M1097.8,271c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1099.7,267.1,1097.8,268.8,1097.8,271z"/>
      <path className="st0" d="M1088.7,271c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1090.3,267.1,1088.7,268.8,1088.7,271z"/>
      <path className="st0" d="M1079.3,271c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1081.2,267.1,1079.3,268.8,1079.3,271z"/>
      <path className="st0" d="M1070.2,271c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1070.2,268.8,1070.2,271z"/>
      <path className="st0" d="M1060.9,271c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1062.8,267.1,1060.9,268.8,1060.9,271z"/>
      <path className="st0" d="M1051.8,271c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1053.4,267.1,1051.8,268.8,1051.8,271z"/>
      <path className="st0" d="M1042.4,271c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1042.4,268.8,1042.4,271z"/>
      <path className="st0" d="M1033.4,271c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C1035,267.1,1033.4,268.8,1033.4,271
        z"/>
      <path className="st0" d="M1024,271c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C1025.9,267.1,1024,268.8,1024,271z"/>
      <path className="st0" d="M1014.9,271c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1016.6,267.1,1014.9,268.8,1014.9,271z"/>
      <path className="st0" d="M1005.6,271c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1007.2,267.1,1005.6,268.8,1005.6,271z"/>
      <path className="st0" d="M996.5,271c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C998.1,267.1,996.5,268.8,996.5,271z"/>
      <path className="st0" d="M987.4,271c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C989.1,267.1,987.4,268.8,987.4,271z"/>
      <path className="st0" d="M981.9,267.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C986,268.8,984.1,267.1,981.9,267.1z"/>
      <path className="st0" d="M972.8,267.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C976.7,268.8,975,267.1,972.8,267.1z"/>
      <path className="st0" d="M959.6,271c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C961.5,267.1,959.6,268.8,959.6,271z"/>
      <path className="st0" d="M950.5,271c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C952.2,267.1,950.5,268.8,950.5,271z"/>
      <path className="st0" d="M945,267.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C949.2,268.8,947.2,267.1,945,267.1z"/>
      <path className="st0" d="M936,267.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C939.8,268.8,938.2,267.1,936,267.1z"/>
      <path className="st0" d="M926.6,267.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C930.7,268.8,928.8,267.1,926.6,267.1z"/>
      <path className="st0" d="M917.5,274.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C913.7,273.2,915.3,274.9,917.5,274.9z"/>
      <path className="st0" d="M908.4,274.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C904.3,273.2,906.2,274.9,908.4,274.9z"/>
      <path className="st0" d="M899.1,274.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C895.2,273.2,896.9,274.9,899.1,274.9z"/>
      <path className="st0" d="M890,274.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C885.9,273.2,887.8,274.9,890,274.9z
        "/>
      <path className="st0" d="M843.8,274.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C839.9,273.2,841.6,274.9,843.8,274.9z"/>
      <path className="st0" d="M733.4,267.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,268.8,735.4,267.1,733.4,267.1z"/>
      <path className="st0" d="M724.1,267.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,268.8,726.3,267.1,724.1,267.1z"/>
      <path className="st0" d="M715,267.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,268.8,717.2,267.1,715,267.1z"/>
      <path className="st0" d="M705.6,267.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,268.8,707.8,267.1,705.6,267.1z"/>
      <path className="st0" d="M696.6,267.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,268.8,698.8,267.1,696.6,267.1z"/>
      <path className="st0" d="M687.2,274.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C683.3,273.2,685,274.9,687.2,274.9z"/>
      <path className="st0" d="M678.1,274.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,273.2,675.9,274.9,678.1,274.9z"/>
      <path className="st0" d="M668.8,274.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C664.9,273.2,666.6,274.9,668.8,274.9z"/>
      <path className="st0" d="M659.7,274.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C655.8,273.2,657.5,274.9,659.7,274.9z"/>
      <path className="st0" d="M585.9,267.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C589.8,268.8,588.1,267.1,585.9,267.1z"/>
      <path className="st0" d="M580.7,271c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C578.8,274.9,580.7,273.2,580.7,271z
        "/>
      <path className="st0" d="M567.5,267.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C571.4,268.8,569.7,267.1,567.5,267.1z"/>
      <path className="st0" d="M562.3,271c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C560.6,274.9,562.3,273.2,562.3,271z"/>
      <path className="st0" d="M374.1,267.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C378.2,268.8,376.3,267.1,374.1,267.1z"/>
      <path className="st0" d="M365,267.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C368.8,268.8,367.2,267.1,365,267.1z"/>
      <path className="st0" d="M355.6,274.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C351.8,273.2,353.7,274.9,355.6,274.9z"/>
      <path className="st0" d="M342.7,271c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C344.3,267.1,342.7,268.8,342.7,271z"/>
      <ellipse className="st0" cx="1175.3" cy="261.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,257.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1168.5,257.8,1166.3,257.8z"/>
      <path className="st0" d="M1156.9,257.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1159.1,257.8,1156.9,257.8z"/>
      <ellipse className="st0" cx="1147.8" cy="261.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1134.6,261.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C1136.3,257.8,1134.6,259.7,1134.6,261.9z"/>
      <path className="st0" d="M1125.5,261.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C1127.2,257.8,1125.5,259.7,1125.5,261.9z"/>
      <path className="st0" d="M1116.2,261.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C1117.8,257.8,1116.2,259.7,1116.2,261.9z"/>
      <ellipse className="st0" cx="1111" cy="261.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1097.8,261.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C1099.7,257.8,1097.8,259.7,1097.8,261.9z"/>
      <path className="st0" d="M1088.7,261.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C1090.3,257.8,1088.7,259.7,1088.7,261.9z"/>
      <path className="st0" d="M1079.3,261.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C1081.2,257.8,1079.3,259.7,1079.3,261.9z"/>
      <ellipse className="st0" cx="1074.1" cy="261.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1060.9,261.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C1062.8,257.8,1060.9,259.7,1060.9,261.9z"/>
      <path className="st0" d="M1051.8,261.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C1053.4,257.8,1051.8,259.7,1051.8,261.9z"/>
      <ellipse className="st0" cx="1046.6" cy="261.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1033.4,261.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C1035,257.8,1033.4,259.7,1033.4,261.9z
        "/>
      <path className="st0" d="M1024,261.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C1025.9,257.8,1024,259.7,1024,261.9z"/>
      <path className="st0" d="M1014.9,261.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C1016.6,257.8,1014.9,259.7,1014.9,261.9z"/>
      <ellipse className="st0" cx="1009.7" cy="261.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M996.5,261.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C998.1,257.8,996.5,259.7,996.5,261.9z"/>
      <path className="st0" d="M987.4,261.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C989.1,257.8,987.4,259.7,987.4,261.9z"/>
      <path className="st0" d="M981.9,257.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S984.1,257.8,981.9,257.8z"/>
      <ellipse className="st0" cx="972.8" cy="261.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M959.6,261.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C961.5,257.8,959.6,259.7,959.6,261.9z"/>
      <path className="st0" d="M950.5,261.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C952.2,257.8,950.5,259.7,950.5,261.9z"/>
      <path className="st0" d="M945,257.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S947.2,257.8,945,257.8z"/>
      <ellipse className="st0" cx="936" cy="261.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M926.6,257.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S928.8,257.8,926.6,257.8z"/>
      <path className="st0" d="M917.5,265.8c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S915.3,265.8,917.5,265.8z"/>
      <ellipse className="st0" cx="908.4" cy="261.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M899.1,265.8c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S896.9,265.8,899.1,265.8z"/>
      <path className="st0" d="M890,265.8c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S887.8,265.8,890,265.8z"/>
      <path className="st0" d="M884.5,261.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C882.8,265.8,884.5,263.8,884.5,261.9z"/>
      <ellipse className="st0" cx="871.6" cy="261.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M849,261.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C850.9,257.8,849,259.7,849,261.9z"
        />
      <path className="st0" d="M843.8,265.8c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S841.6,265.8,843.8,265.8z"/>
      <path className="st0" d="M746.4,261.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C744.7,265.8,746.4,263.8,746.4,261.9z"/>
      <path className="st0" d="M733.4,257.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S735.4,257.8,733.4,257.8z"/>
      <ellipse className="st0" cx="724.1" cy="261.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M715,257.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S717.2,257.8,715,257.8z"/>
      <path className="st0" d="M705.6,257.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S707.8,257.8,705.6,257.8z"/>
      <ellipse className="st0" cx="696.6" cy="261.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M687.2,265.8c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S685,265.8,687.2,265.8z"/>
      <path className="st0" d="M678.1,265.8c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S675.9,265.8,678.1,265.8z"/>
      <path className="st0" d="M668.8,265.8c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S666.6,265.8,668.8,265.8z"/>
      <ellipse className="st0" cx="659.7" cy="261.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M654.5,261.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C652.5,265.8,654.5,263.8,654.5,261.9z"/>
      <path className="st0" d="M599.1,261.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C597.2,265.8,599.1,263.8,599.1,261.9z"/>
      <ellipse className="st0" cx="585.9" cy="261.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M576.9,265.8c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S574.7,265.8,576.9,265.8z"/>
      <path className="st0" d="M567.5,265.8c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S565.3,265.8,567.5,265.8z"/>
      <ellipse className="st0" cx="558.4" cy="261.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M549.1,257.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S551.3,257.8,549.1,257.8z"/>
      <ellipse className="st0" cx="383.4" cy="261.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M370.2,261.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C371.9,257.8,370.2,259.7,370.2,261.9z"/>
      <ellipse className="st0" cx="365" cy="261.9" rx="3.9" ry="3.9"/>
      <path className="st0" d="M351.8,261.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C353.7,257.8,351.8,259.7,351.8,261.9z"/>
      <path className="st0" d="M1175.3,248.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1179.2,250.4,1177.5,248.7,1175.3,248.7z"/>
      <path className="st0" d="M1166.3,248.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,250.4,1168.5,248.7,1166.3,248.7z"/>
      <path className="st0" d="M1156.9,248.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1160.8,250.4,1159.1,248.7,1156.9,248.7z"/>
      <path className="st0" d="M1144,252.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1145.6,248.7,1144,250.4,1144,252.6z"/>
      <path className="st0" d="M1134.6,252.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1136.3,248.7,1134.6,250.4,1134.6,252.6z"/>
      <path className="st0" d="M1125.5,252.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1127.2,248.7,1125.5,250.4,1125.5,252.6z"/>
      <path className="st0" d="M1116.2,252.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1117.8,248.7,1116.2,250.4,1116.2,252.6z"/>
      <path className="st0" d="M1107.1,252.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1107.1,250.4,1107.1,252.6z"/>
      <path className="st0" d="M1097.8,252.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1099.7,248.7,1097.8,250.4,1097.8,252.6z"/>
      <path className="st0" d="M1088.7,252.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1090.3,248.7,1088.7,250.4,1088.7,252.6z"/>
      <path className="st0" d="M1079.3,252.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1081.2,248.7,1079.3,250.4,1079.3,252.6z"/>
      <path className="st0" d="M1070.2,252.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1070.2,250.4,1070.2,252.6z"/>
      <path className="st0" d="M1060.9,252.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1062.8,248.7,1060.9,250.4,1060.9,252.6z"/>
      <path className="st0" d="M1051.8,252.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1053.4,248.7,1051.8,250.4,1051.8,252.6z"/>
      <path className="st0" d="M1042.4,252.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1042.4,250.4,1042.4,252.6z"/>
      <path className="st0" d="M1033.4,252.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1035,248.7,1033.4,250.4,1033.4,252.6z"/>
      <path className="st0" d="M1024,252.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C1025.9,248.7,1024,250.4,1024,252.6
        z"/>
      <path className="st0" d="M1014.9,252.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1016.6,248.7,1014.9,250.4,1014.9,252.6z"/>
      <path className="st0" d="M1005.6,252.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1007.2,248.7,1005.6,250.4,1005.6,252.6z"/>
      <path className="st0" d="M996.5,252.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C998.1,248.7,996.5,250.4,996.5,252.6z"/>
      <path className="st0" d="M987.4,252.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C989.1,248.7,987.4,250.4,987.4,252.6z"/>
      <path className="st0" d="M981.9,248.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C986,250.4,984.1,248.7,981.9,248.7z"/>
      <path className="st0" d="M972.8,248.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C976.7,250.4,975,248.7,972.8,248.7z"/>
      <path className="st0" d="M959.6,252.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C961.5,248.7,959.6,250.4,959.6,252.6z"/>
      <path className="st0" d="M950.5,252.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C952.2,248.7,950.5,250.4,950.5,252.6z"/>
      <path className="st0" d="M945,248.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C949.2,250.4,947.2,248.7,945,248.7z"/>
      <path className="st0" d="M936,248.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C939.8,250.4,938.2,248.7,936,248.7z"/>
      <path className="st0" d="M926.6,248.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C930.7,250.4,928.8,248.7,926.6,248.7z"/>
      <path className="st0" d="M917.5,248.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C921.4,250.4,919.7,248.7,917.5,248.7z"/>
      <path className="st0" d="M904.3,252.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C906,248.7,904.3,250.4,904.3,252.6z"/>
      <path className="st0" d="M899.1,248.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C902.9,250.4,901.3,248.7,899.1,248.7z"/>
      <path className="st0" d="M890,256.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C885.9,254.8,887.8,256.4,890,256.4z
        "/>
      <path className="st0" d="M880.6,248.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C884.5,250.4,882.8,248.7,880.6,248.7z"/>
      <path className="st0" d="M871.6,256.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C867.4,254.8,869.4,256.4,871.6,256.4z"/>
      <path className="st0" d="M751.9,248.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C755.7,250.4,753.8,248.7,751.9,248.7z"/>
      <path className="st0" d="M742.5,248.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C746.4,250.4,744.7,248.7,742.5,248.7z"/>
      <path className="st0" d="M737.3,252.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C735.4,256.4,737.3,254.8,737.3,252.6z"/>
      <path className="st0" d="M727.9,252.6c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S727.9,254.8,727.9,252.6z"/>
      <path className="st0" d="M718.8,252.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C717.2,256.4,718.8,254.8,718.8,252.6z"/>
      <path className="st0" d="M709.5,252.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C707.8,256.4,709.5,254.8,709.5,252.6z"/>
      <path className="st0" d="M696.6,256.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C692.7,254.8,694.4,256.4,696.6,256.4z"/>
      <path className="st0" d="M687.2,256.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C683.3,254.8,685,256.4,687.2,256.4z"/>
      <path className="st0" d="M678.1,256.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,254.8,675.9,256.4,678.1,256.4z"/>
      <path className="st0" d="M668.8,256.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C664.9,254.8,666.6,256.4,668.8,256.4z"/>
      <path className="st0" d="M659.7,256.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C655.8,254.8,657.5,256.4,659.7,256.4z"/>
      <path className="st0" d="M650.3,256.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C646.5,254.8,648.4,256.4,650.3,256.4z"/>
      <path className="st0" d="M641.2,248.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C645.1,250.4,643.4,248.7,641.2,248.7z"/>
      <path className="st0" d="M604.4,248.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C608.2,250.4,606.6,248.7,604.4,248.7z"/>
      <path className="st0" d="M595.3,248.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C599.1,250.4,597.2,248.7,595.3,248.7z"/>
      <path className="st0" d="M589.8,252.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C588.1,256.4,589.8,254.8,589.8,252.6z"/>
      <path className="st0" d="M576.9,256.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C572.7,254.8,574.7,256.4,576.9,256.4z"/>
      <path className="st0" d="M567.5,256.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C563.6,254.8,565.3,256.4,567.5,256.4z"/>
      <path className="st0" d="M558.4,256.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C554.3,254.8,556.2,256.4,558.4,256.4z"/>
      <path className="st0" d="M549.1,256.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C545.2,254.8,546.9,256.4,549.1,256.4z"/>
      <path className="st0" d="M543.8,252.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C542.2,256.4,543.8,254.8,543.8,252.6z"/>
      <path className="st0" d="M1175.3,239.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1177.5,239.6,1175.3,239.6z"/>
      <path className="st0" d="M1166.3,239.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1168.5,239.6,1166.3,239.6
        z"/>
      <path className="st0" d="M1156.9,239.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1159.1,239.6,1156.9,239.6z"/>
      <path className="st0" d="M1144,243.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1145.6,239.6,1144,241.3,1144,243.5z"/>
      <path className="st0" d="M1134.6,243.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1136.3,239.6,1134.6,241.3,1134.6,243.5z"/>
      <path className="st0" d="M1125.5,243.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1127.2,239.6,1125.5,241.3,1125.5,243.5z"/>
      <path className="st0" d="M1116.2,243.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1117.8,239.6,1116.2,241.3,1116.2,243.5z"/>
      <path className="st0" d="M1107.1,243.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1107.1,241.3,1107.1,243.5z"/>
      <path className="st0" d="M1097.8,243.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1099.7,239.6,1097.8,241.3,1097.8,243.5z"/>
      <path className="st0" d="M1088.7,243.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1090.3,239.6,1088.7,241.3,1088.7,243.5z"/>
      <path className="st0" d="M1079.3,243.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1081.2,239.6,1079.3,241.3,1079.3,243.5z"/>
      <path className="st0" d="M1070.2,243.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1070.2,241.3,1070.2,243.5z"/>
      <path className="st0" d="M1060.9,243.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1062.8,239.6,1060.9,241.3,1060.9,243.5z"/>
      <path className="st0" d="M1051.8,243.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1053.4,239.6,1051.8,241.3,1051.8,243.5z"/>
      <path className="st0" d="M1042.4,243.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1042.4,241.3,1042.4,243.5z"/>
      <path className="st0" d="M1033.4,243.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1035,239.6,1033.4,241.3,1033.4,243.5z"/>
      <path className="st0" d="M1024,243.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C1025.9,239.6,1024,241.3,1024,243.5
        z"/>
      <path className="st0" d="M1014.9,243.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1016.6,239.6,1014.9,241.3,1014.9,243.5z"/>
      <path className="st0" d="M1005.6,243.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1007.2,239.6,1005.6,241.3,1005.6,243.5z"/>
      <path className="st0" d="M996.5,243.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C998.1,239.6,996.5,241.3,996.5,243.5z"/>
      <path className="st0" d="M987.4,243.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C989.1,239.6,987.4,241.3,987.4,243.5z"/>
      <path className="st0" d="M981.9,239.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C986,241.3,984.1,239.6,981.9,239.6z"/>
      <path className="st0" d="M972.8,239.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S975,239.6,972.8,239.6z"/>
      <path className="st0" d="M959.6,243.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C961.5,239.6,959.6,241.3,959.6,243.5z"/>
      <path className="st0" d="M950.5,243.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C952.2,239.6,950.5,241.3,950.5,243.5z"/>
      <path className="st0" d="M945,239.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C949.2,241.3,947.2,239.6,945,239.6z"/>
      <path className="st0" d="M936,239.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C939.8,241.3,938.2,239.6,936,239.6z"/>
      <path className="st0" d="M926.6,239.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C930.7,241.3,928.8,239.6,926.6,239.6z"/>
      <path className="st0" d="M917.5,239.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S919.7,239.6,917.5,239.6z"
        />
      <path className="st0" d="M904.3,243.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C906,239.6,904.3,241.3,904.3,243.5z"/>
      <path className="st0" d="M899.1,239.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S901.3,239.6,899.1,239.6z"
        />
      <path className="st0" d="M890,239.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S891.9,239.6,890,239.6z"/>
      <path className="st0" d="M880.6,247.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C876.8,245.7,878.4,247.3,880.6,247.3z"/>
      <path className="st0" d="M871.6,247.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C867.4,245.7,869.4,247.3,871.6,247.3z"/>
      <path className="st0" d="M747.7,243.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C749.7,239.6,747.7,241.3,747.7,243.5z"/>
      <path className="st0" d="M742.5,239.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S744.7,239.6,742.5,239.6z"
        />
      <path className="st0" d="M733.4,239.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S735.4,239.6,733.4,239.6z"
        />
      <path className="st0" d="M724.1,239.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,241.3,726.3,239.6,724.1,239.6z"/>
      <path className="st0" d="M715,239.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S717.2,239.6,715,239.6z"/>
      <path className="st0" d="M705.6,239.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S707.8,239.6,705.6,239.6z"
        />
      <path className="st0" d="M696.6,239.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,241.3,698.8,239.6,696.6,239.6z"/>
      <path className="st0" d="M687.2,247.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C683.3,245.7,685,247.3,687.2,247.3z"/>
      <path className="st0" d="M678.1,247.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,245.7,675.9,247.3,678.1,247.3z"/>
      <path className="st0" d="M668.8,247.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C664.9,245.7,666.6,247.3,668.8,247.3z"/>
      <path className="st0" d="M659.7,247.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C655.8,245.7,657.5,247.3,659.7,247.3z"/>
      <path className="st0" d="M650.3,247.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C646.5,245.7,648.4,247.3,650.3,247.3z"/>
      <path className="st0" d="M641.2,247.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C637.4,245.7,639,247.3,641.2,247.3z"/>
      <path className="st0" d="M617.6,243.5c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C615.7,247.3,617.6,245.7,617.6,243.5z"/>
      <path className="st0" d="M604.4,239.6c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S606.6,239.6,604.4,239.6z"
        />
      <path className="st0" d="M599.1,243.5c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C597.2,247.3,599.1,245.7,599.1,243.5z"/>
      <path className="st0" d="M589.8,243.5c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C588.1,247.3,589.8,245.7,589.8,243.5z"/>
      <path className="st0" d="M576.9,247.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C572.7,245.7,574.7,247.3,576.9,247.3z"/>
      <path className="st0" d="M567.5,247.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C563.6,245.7,565.3,247.3,567.5,247.3z"/>
      <path className="st0" d="M558.4,247.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C554.3,245.7,556.2,247.3,558.4,247.3z"/>
      <path className="st0" d="M549.1,247.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C545.2,245.7,546.9,247.3,549.1,247.3z"/>
      <path className="st0" d="M1175.3,230.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1179.2,232.2,1177.5,230.3,1175.3,230.3z"/>
      <path className="st0" d="M1166.3,230.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,232.2,1168.5,230.3,1166.3,230.3z"/>
      <path className="st0" d="M1153.1,234.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1154.7,230.3,1153.1,232.2,1153.1,234.1z"/>
      <path className="st0" d="M1144,234.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1145.6,230.3,1144,232.2,1144,234.1z"/>
      <path className="st0" d="M1134.6,234.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1136.3,230.3,1134.6,232.2,1134.6,234.1z"/>
      <path className="st0" d="M1125.5,234.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1127.2,230.3,1125.5,232.2,1125.5,234.1z"/>
      <path className="st0" d="M1116.2,234.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1117.8,230.3,1116.2,232.2,1116.2,234.1z"/>
      <path className="st0" d="M1107.1,234.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1107.1,232.2,1107.1,234.1z"/>
      <path className="st0" d="M1097.8,234.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1099.7,230.3,1097.8,232.2,1097.8,234.1z"/>
      <path className="st0" d="M1088.7,234.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1088.7,232.2,1088.7,234.1z"/>
      <path className="st0" d="M1079.3,234.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1081.2,230.3,1079.3,232.2,1079.3,234.1z"/>
      <path className="st0" d="M1070.2,234.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1070.2,232.2,1070.2,234.1z"/>
      <path className="st0" d="M1060.9,234.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1062.8,230.3,1060.9,232.2,1060.9,234.1z"/>
      <path className="st0" d="M1051.8,234.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1051.8,232.2,1051.8,234.1z"/>
      <path className="st0" d="M1042.4,234.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1042.4,232.2,1042.4,234.1z"/>
      <path className="st0" d="M1033.4,234.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1035,230.3,1033.4,232.2,1033.4,234.1z"/>
      <path className="st0" d="M1024,234.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C1025.9,230.3,1024,232.2,1024,234.1
        z"/>
      <path className="st0" d="M1014.9,234.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1016.6,230.3,1014.9,232.2,1014.9,234.1z"/>
      <path className="st0" d="M1005.6,234.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1007.2,230.3,1005.6,232.2,1005.6,234.1z"/>
      <path className="st0" d="M996.5,234.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C998.1,230.3,996.5,232.2,996.5,234.1z"/>
      <path className="st0" d="M987.4,234.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C989.1,230.3,987.4,232.2,987.4,234.1z"/>
      <path className="st0" d="M981.9,230.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C986,232.2,984.1,230.3,981.9,230.3z"/>
      <path className="st0" d="M972.8,230.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C976.7,232.2,975,230.3,972.8,230.3z"/>
      <path className="st0" d="M959.6,234.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C961.5,230.3,959.6,232.2,959.6,234.1z"/>
      <path className="st0" d="M954.4,238.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C950.5,236.3,952.2,238.3,954.4,238.3z"/>
      <path className="st0" d="M945,238.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C941.2,236.3,943.1,238.3,945,238.3z"/>
      <path className="st0" d="M932.1,234.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C933.7,230.3,932.1,232.2,932.1,234.1z"/>
      <path className="st0" d="M922.7,234.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C924.7,230.3,922.7,232.2,922.7,234.1z"/>
      <path className="st0" d="M917.5,230.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C921.4,232.2,919.7,230.3,917.5,230.3z"/>
      <path className="st0" d="M908.4,238.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C904.3,236.3,906.2,238.3,908.4,238.3z"/>
      <path className="st0" d="M895.2,234.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C896.9,230.3,895.2,232.2,895.2,234.1z"/>
      <path className="st0" d="M885.9,234.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C887.8,230.3,885.9,232.2,885.9,234.1z"/>
      <path className="st0" d="M880.6,238.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C876.8,236.3,878.4,238.3,880.6,238.3z"/>
      <path className="st0" d="M742.5,238.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C738.7,236.3,740.3,238.3,742.5,238.3z"/>
      <path className="st0" d="M733.4,230.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,232.2,735.4,230.3,733.4,230.3z"/>
      <path className="st0" d="M724.1,230.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,232.2,726.3,230.3,724.1,230.3z"/>
      <path className="st0" d="M715,230.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,232.2,717.2,230.3,715,230.3z"/>
      <path className="st0" d="M705.6,230.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,232.2,707.8,230.3,705.6,230.3z"/>
      <path className="st0" d="M696.6,230.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,232.2,698.8,230.3,696.6,230.3z"/>
      <path className="st0" d="M687.2,230.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.3,232.2,689.4,230.3,687.2,230.3z"/>
      <path className="st0" d="M678.1,238.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,236.3,675.9,238.3,678.1,238.3z"/>
      <path className="st0" d="M668.8,238.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C664.9,236.3,666.6,238.3,668.8,238.3z"/>
      <path className="st0" d="M659.7,238.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C655.8,236.3,657.5,238.3,659.7,238.3z"/>
      <path className="st0" d="M650.3,238.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C646.5,236.3,648.4,238.3,650.3,238.3z"/>
      <path className="st0" d="M641.2,238.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C637.4,236.3,639,238.3,641.2,238.3z"/>
      <path className="st0" d="M622.8,230.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C626.7,232.2,625,230.3,622.8,230.3z"/>
      <path className="st0" d="M613.7,230.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C617.6,232.2,615.7,230.3,613.7,230.3z"/>
      <path className="st0" d="M608.2,234.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C606.6,238,608.2,236.3,608.2,234.1z"/>
      <path className="st0" d="M599.1,234.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C597.2,238.3,599.1,236.3,599.1,234.1z"/>
      <path className="st0" d="M589.8,234.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C588.1,238,589.8,236.3,589.8,234.1z"/>
      <path className="st0" d="M576.9,238.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C572.7,236.3,574.7,238.3,576.9,238.3z"/>
      <path className="st0" d="M567.5,238.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C563.6,236.3,565.3,238.3,567.5,238.3z"/>
      <path className="st0" d="M558.4,238.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C554.3,236.3,556.2,238.3,558.4,238.3z"/>
      <path className="st0" d="M549.1,238.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C545.2,236.3,546.9,238.3,549.1,238.3z"/>
      <path className="st0" d="M1175.3,221.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1179.2,222.8,1177.5,221.2,1175.3,221.2z"/>
      <path className="st0" d="M1166.3,221.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,222.8,1168.5,221.2,1166.3,221.2z"/>
      <path className="st0" d="M1153.1,225c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1154.7,221.2,1153.1,222.8,1153.1,225z"/>
      <path className="st0" d="M1144,225c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1145.6,221.2,1144,222.8,1144,225z"/>
      <path className="st0" d="M1134.6,225c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1136.3,221.2,1134.6,222.8,1134.6,225z"/>
      <path className="st0" d="M1125.5,225c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1127.2,221.2,1125.5,222.8,1125.5,225z"/>
      <path className="st0" d="M1116.2,225c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1117.8,221.2,1116.2,222.8,1116.2,225z"/>
      <path className="st0" d="M1107.1,225c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1107.1,222.8,1107.1,225z"/>
      <path className="st0" d="M1097.8,225c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1099.7,221.2,1097.8,222.8,1097.8,225z"/>
      <path className="st0" d="M1088.7,225c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1090.3,221.2,1088.7,222.8,1088.7,225z"/>
      <path className="st0" d="M1079.3,225c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1081.2,221.2,1079.3,222.8,1079.3,225z"/>
      <path className="st0" d="M1070.2,225c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1070.2,222.8,1070.2,225z"/>
      <path className="st0" d="M1060.9,225c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1062.8,221.2,1060.9,222.8,1060.9,225z"/>
      <path className="st0" d="M1051.8,225c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1053.4,221.2,1051.8,222.8,1051.8,225z"/>
      <path className="st0" d="M1042.4,225c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1042.4,222.8,1042.4,225z"/>
      <path className="st0" d="M1033.4,225c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C1035,221.2,1033.4,222.8,1033.4,225
        z"/>
      <path className="st0" d="M1024,225c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C1025.9,221.2,1024,222.8,1024,225z"/>
      <path className="st0" d="M1014.9,225c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1016.6,221.2,1014.9,222.8,1014.9,225z"/>
      <path className="st0" d="M1005.6,225c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1007.2,221.2,1005.6,222.8,1005.6,225z"/>
      <path className="st0" d="M996.5,225c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C998.1,221.2,996.5,222.8,996.5,225z"/>
      <path className="st0" d="M987.4,225c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C989.1,221.2,987.4,222.8,987.4,225z"/>
      <path className="st0" d="M981.9,221.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C986,222.8,984.1,221.2,981.9,221.2z"/>
      <path className="st0" d="M972.8,221.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C976.7,222.8,975,221.2,972.8,221.2z"/>
      <path className="st0" d="M963.5,228.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C959.6,227.2,961.5,228.9,963.5,228.9z"/>
      <path className="st0" d="M950.5,225c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C952.2,221.2,950.5,222.8,950.5,225z"/>
      <path className="st0" d="M917.5,228.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C913.7,227.2,915.3,228.9,917.5,228.9z"/>
      <path className="st0" d="M908.4,228.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C904.3,227.2,906.2,228.9,908.4,228.9z"/>
      <path className="st0" d="M774.2,225c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C772.2,228.9,774.2,227.2,774.2,225z
        "/>
      <path className="st0" d="M760.9,221.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C764.8,222.8,763.1,221.2,760.9,221.2z"/>
      <path className="st0" d="M733.4,221.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,222.8,735.4,221.2,733.4,221.2z"/>
      <path className="st0" d="M724.1,221.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,222.8,726.3,221.2,724.1,221.2z"/>
      <path className="st0" d="M715,221.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,222.8,717.2,221.2,715,221.2z"/>
      <path className="st0" d="M705.6,221.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,222.8,707.8,221.2,705.6,221.2z"/>
      <path className="st0" d="M696.6,221.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,222.8,698.8,221.2,696.6,221.2z"/>
      <path className="st0" d="M687.2,221.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.3,222.8,689.4,221.2,687.2,221.2z"/>
      <path className="st0" d="M678.1,228.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,227.2,675.9,228.9,678.1,228.9z"/>
      <path className="st0" d="M668.8,228.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C664.9,227.2,666.6,228.9,668.8,228.9z"/>
      <path className="st0" d="M659.7,228.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C655.8,227.2,657.5,228.9,659.7,228.9z"/>
      <path className="st0" d="M650.3,228.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C646.5,227.2,648.4,228.9,650.3,228.9z"/>
      <path className="st0" d="M641.2,228.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C637.4,227.2,639,228.9,641.2,228.9z"/>
      <path className="st0" d="M622.8,221.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C626.7,222.8,625,221.2,622.8,221.2z"/>
      <path className="st0" d="M617.6,225c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C615.7,228.9,617.6,227.2,617.6,225z
        "/>
      <path className="st0" d="M608.2,225c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C606.6,228.9,608.2,227.2,608.2,225z"/>
      <path className="st0" d="M599.1,225c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C597.2,228.9,599.1,227.2,599.1,225z"/>
      <path className="st0" d="M589.8,225c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C588.1,228.9,589.8,227.2,589.8,225z"/>
      <path className="st0" d="M576.9,228.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C572.7,227.2,574.7,228.9,576.9,228.9z"/>
      <path className="st0" d="M567.5,228.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C563.6,227.2,565.3,228.9,567.5,228.9z"/>
      <path className="st0" d="M558.4,228.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C554.3,227.2,556.2,228.9,558.4,228.9z"/>
      <path className="st0" d="M549.1,228.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C545.2,227.2,546.9,228.9,549.1,228.9z"/>
      <path className="st0" d="M543.8,225c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C542.2,228.9,543.8,227.2,543.8,225z"/>
      <path className="st0" d="M1175.3,211.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1179.2,213.8,1177.5,211.8,1175.3,211.8z"/>
      <path className="st0" d="M1166.3,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1162.1,218.2,1164.1,219.8,1166.3,219.8z"/>
      <path className="st0" d="M1156.9,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,218.2,1154.7,219.8,1156.9,219.8z"/>
      <path className="st0" d="M1147.8,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1144,218.2,1145.6,219.8,1147.8,219.8z"/>
      <path className="st0" d="M1138.5,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,218.2,1136.3,219.8,1138.5,219.8z"/>
      <path className="st0" d="M1129.4,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,218.2,1127.2,219.8,1129.4,219.8z"/>
      <path className="st0" d="M1120,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,218.2,1117.8,219.8,1120,219.8z"/>
      <path className="st0" d="M1111,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1107.1,218.2,1108.8,219.8,1111,219.8z"/>
      <path className="st0" d="M1101.6,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,218.2,1099.7,219.8,1101.6,219.8z"/>
      <path className="st0" d="M1092.5,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,218.2,1090.3,219.8,1092.5,219.8z"/>
      <path className="st0" d="M1083.2,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1079.3,218.2,1081.2,219.8,1083.2,219.8z"/>
      <path className="st0" d="M1074.1,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1070.2,218.2,1071.9,219.8,1074.1,219.8z"/>
      <path className="st0" d="M1065,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1060.9,218.2,1062.8,219.8,1065,219.8z"/>
      <path className="st0" d="M1055.6,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,218.2,1053.4,219.8,1055.6,219.8z"/>
      <path className="st0" d="M1046.6,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,218.2,1044.4,219.8,1046.6,219.8z"/>
      <path className="st0" d="M1037.2,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,218.2,1035,219.8,1037.2,219.8z"/>
      <path className="st0" d="M1028.1,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,218.2,1025.9,219.8,1028.1,219.8z"/>
      <path className="st0" d="M1018.8,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,218.2,1016.6,219.8,1018.8,219.8z"/>
      <path className="st0" d="M1009.7,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,218.2,1007.5,219.8,1009.7,219.8z"/>
      <path className="st0" d="M1000.3,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C996.5,218.2,998.1,219.8,1000.3,219.8z"/>
      <path className="st0" d="M991.3,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C987.4,218.2,989.1,219.8,991.3,219.8z"/>
      <path className="st0" d="M981.9,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C978.1,218.2,979.7,219.8,981.9,219.8z"/>
      <path className="st0" d="M972.8,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C969,218.2,970.6,219.8,972.8,219.8z"/>
      <path className="st0" d="M959.6,216c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C961.5,211.8,959.6,213.8,959.6,216z"/>
      <path className="st0" d="M779.4,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C775.5,218.2,777.2,219.8,779.4,219.8z"/>
      <path className="st0" d="M770.3,211.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C774.2,213.8,772.2,211.8,770.3,211.8z"/>
      <path className="st0" d="M760.9,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C757.1,218.2,758.7,219.8,760.9,219.8z"/>
      <path className="st0" d="M751.9,211.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C755.7,213.8,753.8,211.8,751.9,211.8z"/>
      <path className="st0" d="M746.4,216c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C744.7,219.8,746.4,218.2,746.4,216z"/>
      <path className="st0" d="M733.4,211.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,213.8,735.4,211.8,733.4,211.8z"/>
      <path className="st0" d="M724.1,211.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,213.8,726.3,211.8,724.1,211.8z"/>
      <path className="st0" d="M715,211.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,213.8,717.2,211.8,715,211.8z"/>
      <path className="st0" d="M705.6,211.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,213.8,707.8,211.8,705.6,211.8z"/>
      <path className="st0" d="M696.6,211.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,213.8,698.8,211.8,696.6,211.8z"/>
      <path className="st0" d="M687.2,211.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.3,213.8,689.4,211.8,687.2,211.8z"/>
      <path className="st0" d="M678.1,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,218.2,675.9,219.8,678.1,219.8z"/>
      <path className="st0" d="M668.8,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C664.9,218.2,666.6,219.8,668.8,219.8z"/>
      <path className="st0" d="M659.7,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C655.8,218.2,657.5,219.8,659.7,219.8z"/>
      <path className="st0" d="M650.3,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C646.5,218.2,648.4,219.8,650.3,219.8z"/>
      <path className="st0" d="M641.2,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C637.4,218.2,639,219.8,641.2,219.8z"/>
      <path className="st0" d="M636,216c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C634.1,219.8,636,218.2,636,216z"/>
      <path className="st0" d="M622.8,211.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C626.7,213.8,625,211.8,622.8,211.8z"/>
      <path className="st0" d="M613.7,211.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C617.6,213.8,615.7,211.8,613.7,211.8z"/>
      <path className="st0" d="M604.4,211.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C608.2,213.8,606.6,211.8,604.4,211.8z"/>
      <path className="st0" d="M595.3,211.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C599.1,213.8,597.2,211.8,595.3,211.8z"/>
      <path className="st0" d="M585.9,211.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C589.8,213.8,588.1,211.8,585.9,211.8z"/>
      <path className="st0" d="M576.9,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C572.7,218.2,574.7,219.8,576.9,219.8z"/>
      <path className="st0" d="M567.5,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C563.6,218.2,565.3,219.8,567.5,219.8z"/>
      <path className="st0" d="M558.4,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C554.3,218.2,556.2,219.8,558.4,219.8z"/>
      <path className="st0" d="M549.1,219.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C545.2,218.2,546.9,219.8,549.1,219.8z"/>
      <ellipse className="st0" cx="1175.3" cy="206.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,202.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,204.4,1168.5,202.8,1166.3,202.8z"/>
      <path className="st0" d="M1153.1,206.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1154.7,202.8,1153.1,204.4,1153.1,206.6z"/>
      <ellipse className="st0" cx="1147.8" cy="206.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1134.6,206.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1136.3,202.8,1134.6,204.4,1134.6,206.6z"/>
      <path className="st0" d="M1125.5,206.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1127.2,202.8,1125.5,204.4,1125.5,206.6z"/>
      <path className="st0" d="M1120,210.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,208.8,1117.8,210.5,1120,210.5z"/>
      <ellipse className="st0" cx="1111" cy="206.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1101.6,210.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,208.8,1099.7,210.5,1101.6,210.5z"/>
      <path className="st0" d="M1092.5,210.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,208.8,1090.3,210.5,1092.5,210.5z"/>
      <path className="st0" d="M1083.2,210.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1079.3,208.8,1081.2,210.5,1083.2,210.5z"/>
      <ellipse className="st0" cx="1074.1" cy="206.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1065,210.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1061.2,208.8,1062.8,210.5,1065,210.5z"/>
      <path className="st0" d="M1055.6,210.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,208.8,1053.4,210.5,1055.6,210.5z"/>
      <ellipse className="st0" cx="1046.6" cy="206.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1037.2,210.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,208.8,1035,210.5,1037.2,210.5z"/>
      <path className="st0" d="M1028.1,210.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024.3,208.8,1025.9,210.5,1028.1,210.5z"/>
      <path className="st0" d="M1018.8,210.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,208.8,1016.6,210.5,1018.8,210.5z"/>
      <ellipse className="st0" cx="1009.7" cy="206.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1000.3,210.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C996.5,208.8,998.1,210.5,1000.3,210.5z"/>
      <path className="st0" d="M991.3,210.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C987.4,208.8,989.1,210.5,991.3,210.5z"/>
      <path className="st0" d="M884.5,206.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C882.8,210.5,884.5,208.8,884.5,206.6z"/>
      <ellipse className="st0" cx="871.6" cy="206.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M862.2,202.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C866.1,204.4,864.4,202.8,862.2,202.8z"/>
      <path className="st0" d="M770.3,210.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C766.4,208.8,768.1,210.5,770.3,210.5z"/>
      <ellipse className="st0" cx="760.9" cy="206.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M751.9,210.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C748,208.8,749.7,210.5,751.9,210.5z"/>
      <path className="st0" d="M742.5,210.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C738.7,208.8,740.3,210.5,742.5,210.5z"/>
      <path className="st0" d="M737.3,206.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C735.4,210.5,737.3,208.8,737.3,206.6z"/>
      <ellipse className="st0" cx="724.1" cy="206.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M718.8,206.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C717.2,210.5,718.8,208.8,718.8,206.6z"/>
      <path className="st0" d="M709.5,206.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C707.8,210.5,709.5,208.8,709.5,206.6z"/>
      <ellipse className="st0" cx="696.6" cy="206.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M687.2,210.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C683.3,208.8,685,210.5,687.2,210.5z"/>
      <path className="st0" d="M678.1,210.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,208.8,675.9,210.5,678.1,210.5z"/>
      <path className="st0" d="M668.8,210.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C664.9,208.8,666.6,210.5,668.8,210.5z"/>
      <ellipse className="st0" cx="659.7" cy="206.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M650.3,210.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C646.5,208.8,648.4,210.5,650.3,210.5z"/>
      <ellipse className="st0" cx="641.2" cy="206.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M631.9,210.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C628,208.8,630,210.5,631.9,210.5z"/>
      <ellipse className="st0" cx="622.8" cy="206.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M617.6,206.6c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C615.7,210.5,617.6,208.8,617.6,206.6z"/>
      <ellipse className="st0" cx="604.4" cy="206.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M599.1,206.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C597.2,210.5,599.1,208.8,599.1,206.6z"/>
      <ellipse className="st0" cx="585.9" cy="206.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M576.9,210.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C573,208.8,574.7,210.5,576.9,210.5z"/>
      <path className="st0" d="M567.5,210.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C563.6,208.8,565.3,210.5,567.5,210.5z"/>
      <ellipse className="st0" cx="558.4" cy="206.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M549.1,210.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C545.2,208.8,546.9,210.5,549.1,210.5z"/>
      <path className="st0" d="M1171.5,197.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1171.5,195.3,1171.5,197.5z"/>
      <path className="st0" d="M1166.3,193.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1168.5,193.7,1166.3,193.7
        z"/>
      <path className="st0" d="M1153.1,197.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1154.7,193.7,1153.1,195.3,1153.1,197.5z"/>
      <path className="st0" d="M1144,197.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1145.6,193.7,1144,195.3,1144,197.5z"/>
      <path className="st0" d="M1134.6,197.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1136.3,193.7,1134.6,195.3,1134.6,197.5z"/>
      <path className="st0" d="M1125.5,197.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1127.2,193.7,1125.5,195.3,1125.5,197.5z"/>
      <path className="st0" d="M1120,193.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1124.2,195.3,1122.2,193.7,1120,193.7z"/>
      <path className="st0" d="M1111,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1107.1,199.7,1108.8,201.4,1111,201.4z"/>
      <path className="st0" d="M1101.6,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,199.7,1099.7,201.4,1101.6,201.4z"/>
      <path className="st0" d="M1092.5,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,199.7,1090.3,201.4,1092.5,201.4z"/>
      <path className="st0" d="M1083.2,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1079.3,199.7,1081.2,201.4,1083.2,201.4z"/>
      <path className="st0" d="M1074.1,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1070.2,199.7,1071.9,201.4,1074.1,201.4z"/>
      <path className="st0" d="M1065,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1060.9,199.7,1062.8,201.4,1065,201.4z"/>
      <path className="st0" d="M1055.6,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,199.7,1053.4,201.4,1055.6,201.4z"/>
      <path className="st0" d="M1046.6,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,199.7,1044.4,201.4,1046.6,201.4z"/>
      <path className="st0" d="M1037.2,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,199.7,1035,201.4,1037.2,201.4z"/>
      <path className="st0" d="M1028.1,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,199.7,1025.9,201.4,1028.1,201.4z"/>
      <path className="st0" d="M1018.8,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,199.7,1016.6,201.4,1018.8,201.4z"/>
      <path className="st0" d="M1009.7,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,199.7,1007.5,201.4,1009.7,201.4z"/>
      <path className="st0" d="M1000.3,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C996.5,199.7,998.1,201.4,1000.3,201.4z"/>
      <path className="st0" d="M991.3,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C987.4,199.7,989.1,201.4,991.3,201.4z"/>
      <path className="st0" d="M986,197.5c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C984.1,201.4,986,199.7,986,197.5z"/>
      <path className="st0" d="M976.7,197.5c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C975,201.4,976.7,199.7,976.7,197.5z"/>
      <path className="st0" d="M963.5,193.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C967.6,195.3,965.7,193.7,963.5,193.7z"/>
      <path className="st0" d="M899.1,193.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S901.3,193.7,899.1,193.7z"
        />
      <path className="st0" d="M890,193.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S891.9,193.7,890,193.7z"/>
      <path className="st0" d="M880.6,193.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S882.8,193.7,880.6,193.7z"
        />
      <path className="st0" d="M871.6,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C867.4,199.7,869.4,201.4,871.6,201.4z"/>
      <path className="st0" d="M862.2,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C858.4,199.7,860,201.4,862.2,201.4z"/>
      <path className="st0" d="M853.1,193.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S855.3,193.7,853.1,193.7z"
        />
      <path className="st0" d="M757.1,197.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C758.7,193.7,757.1,195.3,757.1,197.5z"/>
      <path className="st0" d="M751.9,193.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S753.8,193.7,751.9,193.7z"
        />
      <path className="st0" d="M742.5,193.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S744.7,193.7,742.5,193.7z"
        />
      <path className="st0" d="M737.3,197.5c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C735.4,201.4,737.3,199.7,737.3,197.5z"/>
      <path className="st0" d="M727.9,197.5c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S727.9,199.7,727.9,197.5z"/>
      <path className="st0" d="M718.8,197.5c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C717.2,201.4,718.8,199.7,718.8,197.5z"/>
      <path className="st0" d="M709.5,197.5c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C707.8,201.4,709.5,199.7,709.5,197.5z"/>
      <path className="st0" d="M700.4,197.5c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C698.8,201.4,700.4,199.7,700.4,197.5z"/>
      <path className="st0" d="M687.2,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C683.3,199.7,685,201.4,687.2,201.4z"/>
      <path className="st0" d="M678.1,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C674.3,199.7,675.9,201.4,678.1,201.4z"/>
      <path className="st0" d="M668.8,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C664.9,199.7,666.6,201.4,668.8,201.4z"/>
      <path className="st0" d="M659.7,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C655.8,199.7,657.5,201.4,659.7,201.4z"/>
      <path className="st0" d="M650.3,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C646.5,199.7,648.4,201.4,650.3,201.4z"/>
      <path className="st0" d="M641.2,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C637.4,199.7,639,201.4,641.2,201.4z"/>
      <path className="st0" d="M631.9,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C628,199.7,630,201.4,631.9,201.4z"/>
      <path className="st0" d="M626.7,197.5c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C625,201.4,626.7,199.7,626.7,197.5z"/>
      <path className="st0" d="M617.6,197.5c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C615.7,201.4,617.6,199.7,617.6,197.5z"/>
      <path className="st0" d="M608.2,197.5c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C606.6,201.4,608.2,199.7,608.2,197.5z"/>
      <path className="st0" d="M599.1,197.5c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C597.2,201.4,599.1,199.7,599.1,197.5z"/>
      <path className="st0" d="M585.9,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C582.1,199.7,583.7,201.4,585.9,201.4z"/>
      <path className="st0" d="M576.9,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C572.7,199.7,574.7,201.4,576.9,201.4z"/>
      <path className="st0" d="M567.5,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C563.6,199.7,565.3,201.4,567.5,201.4z"/>
      <path className="st0" d="M558.4,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C554.3,199.7,556.2,201.4,558.4,201.4z"/>
      <path className="st0" d="M549.1,201.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C545.2,199.7,546.9,201.4,549.1,201.4z"/>
      <path className="st0" d="M1171.5,188.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1171.5,186.2,1171.5,188.4z"/>
      <path className="st0" d="M1166.3,184.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,186.2,1168.5,184.3,1166.3,184.3z"/>
      <path className="st0" d="M1153.1,188.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1154.7,184.3,1153.1,186.2,1153.1,188.4z"/>
      <path className="st0" d="M1144,188.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1145.6,184.6,1144,186.2,1144,188.4z"/>
      <path className="st0" d="M1134.6,188.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1136.3,184.3,1134.6,186.2,1134.6,188.4z"/>
      <path className="st0" d="M1125.5,188.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1127.2,184.3,1125.5,186.2,1125.5,188.4z"/>
      <path className="st0" d="M1120,184.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1124.2,186.2,1122.2,184.3,1120,184.3z"/>
      <path className="st0" d="M1111,184.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1114.8,186.2,1113.2,184.3,1111,184.3z"/>
      <path className="st0" d="M1101.6,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1097.8,190.4,1099.7,192.3,1101.6,192.3z"/>
      <path className="st0" d="M1092.5,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,190.4,1090.3,192.3,1092.5,192.3z"/>
      <path className="st0" d="M1083.2,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1079.3,190.4,1081.2,192.3,1083.2,192.3z"/>
      <path className="st0" d="M1074.1,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1070.2,190.4,1071.9,192.3,1074.1,192.3z"/>
      <path className="st0" d="M1065,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1060.9,190.4,1062.8,192.3,1065,192.3z"/>
      <path className="st0" d="M1055.6,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,190.4,1053.4,192.3,1055.6,192.3z"/>
      <path className="st0" d="M1046.6,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,190.4,1044.4,192.3,1046.6,192.3z"/>
      <path className="st0" d="M1037.2,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,190.4,1035,192.3,1037.2,192.3z"/>
      <path className="st0" d="M1028.1,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,190.4,1025.9,192.3,1028.1,192.3z"/>
      <path className="st0" d="M1018.8,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,190.4,1016.6,192.3,1018.8,192.3z"/>
      <path className="st0" d="M1009.7,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,190.4,1007.5,192.3,1009.7,192.3z"/>
      <path className="st0" d="M1000.3,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C996.5,190.4,998.1,192.3,1000.3,192.3z"/>
      <path className="st0" d="M991.3,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C987.4,190.4,989.1,192.3,991.3,192.3z"/>
      <path className="st0" d="M981.9,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C978.1,190.4,979.7,192.3,981.9,192.3z"/>
      <path className="st0" d="M972.8,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C969,190.4,970.6,192.3,972.8,192.3z"/>
      <path className="st0" d="M963.5,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C959.6,190.4,961.5,192.3,963.5,192.3z"/>
      <path className="st0" d="M949.2,188.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,192.3,949.2,190.4,949.2,188.4z"/>
      <path className="st0" d="M936,184.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C939.8,186.2,938.2,184.3,936,184.3z"/>
      <path className="st0" d="M926.6,184.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C930.7,186.2,928.8,184.3,926.6,184.3z"/>
      <path className="st0" d="M921.4,188.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C919.7,192.3,921.4,190.4,921.4,188.4z"/>
      <path className="st0" d="M912.3,188.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C910.6,192.3,912.3,190.4,912.3,188.4z"/>
      <path className="st0" d="M899.1,184.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C902.9,186.2,901.3,184.3,899.1,184.3z"/>
      <path className="st0" d="M890,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C885.9,190.4,887.8,192.3,890,192.3z
        "/>
      <path className="st0" d="M884.5,188.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C882.8,192.3,884.5,190.4,884.5,188.4z"/>
      <path className="st0" d="M871.6,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C867.4,190.4,869.4,192.3,871.6,192.3z"/>
      <path className="st0" d="M862.2,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C858.4,190.4,860,192.3,862.2,192.3z"/>
      <path className="st0" d="M853.1,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C849,190.4,850.9,192.3,853.1,192.3z"/>
      <path className="st0" d="M847.6,188.4c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S847.6,190.4,847.6,188.4z"/>
      <path className="st0" d="M838.5,188.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C836.9,192.3,838.5,190.4,838.5,188.4z"/>
      <path className="st0" d="M747.7,188.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C749.7,184.3,747.7,186.2,747.7,188.4z"/>
      <path className="st0" d="M742.5,184.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C746.4,186.2,744.7,184.3,742.5,184.3z"/>
      <path className="st0" d="M733.4,184.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,186.2,735.4,184.3,733.4,184.3z"/>
      <path className="st0" d="M724.1,184.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,186.2,726.3,184.3,724.1,184.3z"/>
      <path className="st0" d="M715,184.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,186.2,717.2,184.3,715,184.3z"/>
      <path className="st0" d="M705.6,184.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,186.2,707.8,184.3,705.6,184.3z"/>
      <path className="st0" d="M696.6,184.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,186.2,698.8,184.3,696.6,184.3z"/>
      <path className="st0" d="M687.2,184.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.3,186.2,689.4,184.3,687.2,184.3z"/>
      <path className="st0" d="M678.1,184.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C682,186.2,680.3,184.3,678.1,184.3z"/>
      <path className="st0" d="M668.8,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C664.9,190.4,666.6,192.3,668.8,192.3z"/>
      <path className="st0" d="M659.7,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C655.8,190.4,657.5,192.3,659.7,192.3z"/>
      <path className="st0" d="M650.3,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C646.5,190.4,648.4,192.3,650.3,192.3z"/>
      <path className="st0" d="M641.2,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C637.4,190.4,639,192.3,641.2,192.3z"/>
      <path className="st0" d="M631.9,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C628,190.4,630,192.3,631.9,192.3z"/>
      <path className="st0" d="M626.7,188.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C625,192.3,626.7,190.4,626.7,188.4z"/>
      <path className="st0" d="M617.6,188.4c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C615.7,192.3,617.6,190.4,617.6,188.4z"/>
      <path className="st0" d="M608.2,188.4c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C606.6,192.3,608.2,190.4,608.2,188.4z"/>
      <path className="st0" d="M595.3,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.2,190.4,593.1,192.3,595.3,192.3z"/>
      <path className="st0" d="M585.9,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C582.1,190.4,583.7,192.3,585.9,192.3z"/>
      <path className="st0" d="M576.9,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C572.7,190.4,574.7,192.3,576.9,192.3z"/>
      <path className="st0" d="M567.5,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C563.6,190.4,565.3,192.3,567.5,192.3z"/>
      <path className="st0" d="M558.4,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C554.3,190.4,556.2,192.3,558.4,192.3z"/>
      <path className="st0" d="M549.1,192.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C545.2,190.4,546.9,192.3,549.1,192.3z"/>
      <path className="st0" d="M1171.5,179.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1171.5,176.9,1171.5,179.1z"/>
      <path className="st0" d="M1166.3,175.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,176.9,1168.5,175.2,1166.3,175.2z"/>
      <path className="st0" d="M1153.1,179.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1154.7,175.2,1153.1,176.9,1153.1,179.1z"/>
      <path className="st0" d="M1144,179.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1145.6,175.2,1144,176.9,1144,179.1z"/>
      <path className="st0" d="M1134.6,179.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1136.3,175.2,1134.6,176.9,1134.6,179.1z"/>
      <path className="st0" d="M1125.5,179.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1127.2,175.2,1125.5,176.9,1125.5,179.1z"/>
      <path className="st0" d="M1120,175.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1124.2,176.9,1122.2,175.2,1120,175.2z"/>
      <path className="st0" d="M1111,175.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1114.8,176.9,1113.2,175.2,1111,175.2z"/>
      <path className="st0" d="M1101.6,175.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1105.7,176.9,1103.8,175.2,1101.6,175.2z"/>
      <path className="st0" d="M1092.5,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1088.7,181.3,1090.3,182.9,1092.5,182.9z"/>
      <path className="st0" d="M1083.2,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1079.3,181.3,1081.2,182.9,1083.2,182.9z"/>
      <path className="st0" d="M1074.1,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1070.2,181.3,1071.9,182.9,1074.1,182.9z"/>
      <path className="st0" d="M1065,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1060.9,181.3,1062.8,182.9,1065,182.9z"/>
      <path className="st0" d="M1055.6,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,181.3,1053.4,182.9,1055.6,182.9z"/>
      <path className="st0" d="M1046.6,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,181.3,1044.4,182.9,1046.6,182.9z"/>
      <path className="st0" d="M1037.2,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,181.3,1035,182.9,1037.2,182.9z"/>
      <path className="st0" d="M1028.1,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,181.3,1025.9,182.9,1028.1,182.9z"/>
      <path className="st0" d="M1018.8,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,181.3,1016.6,182.9,1018.8,182.9z"/>
      <path className="st0" d="M1009.7,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,181.3,1007.5,182.9,1009.7,182.9z"/>
      <path className="st0" d="M1000.3,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C996.5,181.3,998.1,182.9,1000.3,182.9z"/>
      <path className="st0" d="M991.3,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C987.4,181.3,989.1,182.9,991.3,182.9z"/>
      <path className="st0" d="M981.9,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C978.1,181.3,979.7,182.9,981.9,182.9z"/>
      <path className="st0" d="M972.8,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C969,181.3,970.6,182.9,972.8,182.9z"/>
      <path className="st0" d="M963.5,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C959.6,181.3,961.5,182.9,963.5,182.9z"/>
      <path className="st0" d="M954.4,175.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C958.2,176.9,956.6,175.2,954.4,175.2z"/>
      <path className="st0" d="M945,175.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C949.2,176.9,947.2,175.2,945,175.2z"/>
      <path className="st0" d="M936,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C932.1,181.3,933.7,182.9,936,182.9z"/>
      <path className="st0" d="M926.6,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C922.7,181.3,924.7,182.9,926.6,182.9z"/>
      <path className="st0" d="M917.5,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C913.7,181.3,915.3,182.9,917.5,182.9z"/>
      <path className="st0" d="M908.4,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C904.3,181.3,906.2,182.9,908.4,182.9z"/>
      <path className="st0" d="M902.9,179.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C901.3,182.9,902.9,181.3,902.9,179.1z"/>
      <path className="st0" d="M890,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C885.9,181.3,887.8,182.9,890,182.9z
        "/>
      <path className="st0" d="M884.5,179.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C882.8,182.9,884.5,181.3,884.5,179.1z"/>
      <path className="st0" d="M871.6,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C867.4,181.3,869.4,182.9,871.6,182.9z"/>
      <path className="st0" d="M862.2,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C858.4,181.3,860,182.9,862.2,182.9z"/>
      <path className="st0" d="M853.1,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C849,181.3,850.9,182.9,853.1,182.9z"/>
      <path className="st0" d="M843.8,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C839.9,181.3,841.6,182.9,843.8,182.9z"/>
      <path className="st0" d="M834.7,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C830.8,181.3,832.5,182.9,834.7,182.9z"/>
      <path className="st0" d="M825.3,175.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C829.5,176.9,827.5,175.2,825.3,175.2z"/>
      <path className="st0" d="M742.5,175.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C746.4,176.9,744.7,175.2,742.5,175.2z"/>
      <path className="st0" d="M733.4,175.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,176.9,735.4,175.2,733.4,175.2z"/>
      <path className="st0" d="M724.1,175.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,176.9,726.3,175.2,724.1,175.2z"/>
      <path className="st0" d="M715,175.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,176.9,717.2,175.2,715,175.2z"/>
      <path className="st0" d="M705.6,175.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,176.9,707.8,175.2,705.6,175.2z"/>
      <path className="st0" d="M696.6,175.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,176.9,698.8,175.2,696.6,175.2z"/>
      <path className="st0" d="M687.2,175.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.3,176.9,689.4,175.2,687.2,175.2z"/>
      <path className="st0" d="M678.1,175.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C682,176.9,680.3,175.2,678.1,175.2z"/>
      <path className="st0" d="M668.8,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C664.9,181.3,666.6,182.9,668.8,182.9z"/>
      <path className="st0" d="M659.7,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C655.8,181.3,657.5,182.9,659.7,182.9z"/>
      <path className="st0" d="M650.3,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C646.5,181.3,648.4,182.9,650.3,182.9z"/>
      <path className="st0" d="M641.2,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C637.4,181.3,639,182.9,641.2,182.9z"/>
      <path className="st0" d="M631.9,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C628,181.3,630,182.9,631.9,182.9z"/>
      <path className="st0" d="M626.7,179.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C625,182.9,626.7,181.3,626.7,179.1z"/>
      <path className="st0" d="M617.6,179.1c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C615.7,182.9,617.6,181.3,617.6,179.1z"/>
      <path className="st0" d="M608.2,179.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C606.6,182.9,608.2,181.3,608.2,179.1z"/>
      <path className="st0" d="M595.3,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.2,181.3,593.1,182.9,595.3,182.9z"/>
      <path className="st0" d="M585.9,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C582.1,181.3,583.7,182.9,585.9,182.9z"/>
      <path className="st0" d="M576.9,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C572.7,181.3,574.7,182.9,576.9,182.9z"/>
      <path className="st0" d="M567.5,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C563.6,181.3,565.3,182.9,567.5,182.9z"/>
      <path className="st0" d="M558.4,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C554.3,181.3,556.2,182.9,558.4,182.9z"/>
      <path className="st0" d="M549.1,182.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C545.2,181.3,546.9,182.9,549.1,182.9z"/>
      <path className="st0" d="M543.8,179.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C542.2,182.9,543.8,181.3,543.8,179.1z"/>
      <path className="st0" d="M1171.5,170c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1171.5,167.8,1171.5,170z"/>
      <path className="st0" d="M1166.3,166.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1168.5,166.2,1166.3,166.2
        z"/>
      <path className="st0" d="M1153.1,170c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1154.7,166.2,1153.1,167.8,1153.1,170z"/>
      <path className="st0" d="M1144,170c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1145.6,166.2,1144,167.8,1144,170z"/>
      <path className="st0" d="M1134.6,170c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1136.3,166.2,1134.6,167.8,1134.6,170z"/>
      <path className="st0" d="M1125.5,170c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1127.2,166.2,1125.5,167.8,1125.5,170z"/>
      <path className="st0" d="M1120,166.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1124.2,167.8,1122.2,166.2,1120,166.2z"/>
      <path className="st0" d="M1111,166.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1113.2,166.2,1111,166.2z"/>
      <path className="st0" d="M1101.6,166.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1105.7,167.8,1103.8,166.2,1101.6,166.2z"/>
      <path className="st0" d="M1092.5,166.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1094.7,166.2,1092.5,166.2
        z"/>
      <path className="st0" d="M1083.2,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1079.3,172.2,1081.2,173.9,1083.2,173.9z"/>
      <path className="st0" d="M1074.1,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1070.2,172.2,1071.9,173.9,1074.1,173.9z"/>
      <path className="st0" d="M1065,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1060.9,172.2,1062.8,173.9,1065,173.9z"/>
      <path className="st0" d="M1055.6,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,172.2,1053.4,173.9,1055.6,173.9z"/>
      <path className="st0" d="M1046.6,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,172.2,1044.4,173.9,1046.6,173.9z"/>
      <path className="st0" d="M1037.2,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,172.2,1035,173.9,1037.2,173.9z"/>
      <path className="st0" d="M1028.1,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,172.2,1025.9,173.9,1028.1,173.9z"/>
      <path className="st0" d="M1018.8,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,172.2,1016.6,173.9,1018.8,173.9z"/>
      <path className="st0" d="M1009.7,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,172.2,1007.5,173.9,1009.7,173.9z"/>
      <path className="st0" d="M1000.3,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C996.5,172.2,998.1,173.9,1000.3,173.9z"/>
      <path className="st0" d="M991.3,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C987.4,172.2,989.1,173.9,991.3,173.9z"/>
      <path className="st0" d="M981.9,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C978.1,172.2,979.7,173.9,981.9,173.9z"/>
      <path className="st0" d="M972.8,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C969,172.2,970.6,173.9,972.8,173.9z"/>
      <path className="st0" d="M963.5,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C959.6,172.2,961.5,173.9,963.5,173.9z"/>
      <path className="st0" d="M954.4,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C950.5,172.2,952.2,173.9,954.4,173.9z"/>
      <path className="st0" d="M949.2,170c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,173.9,949.2,172.2,949.2,170z"/>
      <path className="st0" d="M936,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C932.1,172.2,933.7,173.9,936,173.9z"/>
      <path className="st0" d="M926.6,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C922.7,172.2,924.7,173.9,926.6,173.9z"/>
      <path className="st0" d="M917.5,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C913.7,172.2,915.3,173.9,917.5,173.9z"/>
      <path className="st0" d="M908.4,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C904.3,172.2,906.2,173.9,908.4,173.9z"/>
      <path className="st0" d="M902.9,170c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C901.3,173.9,902.9,172.2,902.9,170z"/>
      <path className="st0" d="M890,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C885.9,172.2,887.8,173.9,890,173.9z
        "/>
      <path className="st0" d="M884.5,170c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C882.8,173.9,884.5,172.2,884.5,170z"/>
      <path className="st0" d="M871.6,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C867.4,172.2,869.4,173.9,871.6,173.9z"/>
      <path className="st0" d="M862.2,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C858.4,172.2,860,173.9,862.2,173.9z"/>
      <path className="st0" d="M853.1,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C849,172.2,850.9,173.9,853.1,173.9z"/>
      <path className="st0" d="M843.8,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C839.9,172.2,841.6,173.9,843.8,173.9z"/>
      <path className="st0" d="M834.7,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C830.8,172.2,832.5,173.9,834.7,173.9z"/>
      <path className="st0" d="M742.5,166.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S744.7,166.2,742.5,166.2z"
        />
      <path className="st0" d="M733.4,166.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S735.4,166.2,733.4,166.2z"
        />
      <path className="st0" d="M724.1,166.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,167.8,726.3,166.2,724.1,166.2z"/>
      <path className="st0" d="M715,166.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S717.2,166.2,715,166.2z"/>
      <path className="st0" d="M705.6,166.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S707.8,166.2,705.6,166.2z"
        />
      <path className="st0" d="M696.6,166.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,167.8,698.8,166.2,696.6,166.2z"/>
      <path className="st0" d="M687.2,166.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.3,167.8,689.4,166.2,687.2,166.2z"/>
      <path className="st0" d="M678.1,166.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S680.3,166.2,678.1,166.2z"
        />
      <path className="st0" d="M668.8,166.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C672.9,167.8,671,166.2,668.8,166.2z"/>
      <path className="st0" d="M659.7,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C655.8,172.2,657.5,173.9,659.7,173.9z"/>
      <path className="st0" d="M650.3,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C646.5,172.2,648.4,173.9,650.3,173.9z"/>
      <path className="st0" d="M641.2,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C637.4,172.2,639,173.9,641.2,173.9z"/>
      <path className="st0" d="M631.9,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C628,172.2,630,173.9,631.9,173.9z"/>
      <path className="st0" d="M626.7,170c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C625,173.9,626.7,172.2,626.7,170z"/>
      <path className="st0" d="M617.6,170c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C615.7,173.9,617.6,172.2,617.6,170z
        "/>
      <path className="st0" d="M604.4,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C600.5,172.2,602.2,173.9,604.4,173.9z"/>
      <path className="st0" d="M595.3,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.2,172.2,593.1,173.9,595.3,173.9z"/>
      <path className="st0" d="M585.9,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C582.1,172.2,583.7,173.9,585.9,173.9z"/>
      <path className="st0" d="M576.9,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C572.7,172.2,574.7,173.9,576.9,173.9z"/>
      <path className="st0" d="M567.5,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C563.6,172.2,565.3,173.9,567.5,173.9z"/>
      <path className="st0" d="M558.4,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C554.3,172.2,556.2,173.9,558.4,173.9z"/>
      <path className="st0" d="M549.1,173.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C545.2,172.2,546.9,173.9,549.1,173.9z"/>
      <path className="st0" d="M1171.5,160.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1171.5,158.7,1171.5,160.7z"/>
      <path className="st0" d="M1166.3,156.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,158.7,1168.5,156.8,1166.3,156.8z"/>
      <path className="st0" d="M1153.1,160.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1154.7,156.8,1153.1,158.7,1153.1,160.7z"/>
      <path className="st0" d="M1144,160.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1145.6,156.8,1144,158.7,1144,160.7z"/>
      <path className="st0" d="M1134.6,160.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1136.3,156.8,1134.6,158.7,1134.6,160.7z"/>
      <path className="st0" d="M1125.5,160.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1127.2,156.8,1125.5,158.7,1125.5,160.7z"/>
      <path className="st0" d="M1120,156.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1124.2,158.7,1122.2,156.8,1120,156.8z"/>
      <path className="st0" d="M1111,156.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1114.8,158.7,1113.2,156.8,1111,156.8z"/>
      <path className="st0" d="M1101.6,156.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1105.7,158.7,1103.8,156.8,1101.6,156.8z"/>
      <path className="st0" d="M1092.5,156.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1096.4,158.7,1094.7,156.8,1092.5,156.8z"/>
      <path className="st0" d="M1083.2,156.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1087.3,158.7,1085.4,156.8,1083.2,156.8z"/>
      <path className="st0" d="M1074.1,156.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1077.9,158.7,1076.3,156.8,1074.1,156.8z"/>
      <path className="st0" d="M1065,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1060.9,162.9,1062.8,164.8,1065,164.8z"/>
      <path className="st0" d="M1055.6,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,162.9,1053.4,164.8,1055.6,164.8z"/>
      <path className="st0" d="M1046.6,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,162.9,1044.4,164.8,1046.6,164.8z"/>
      <path className="st0" d="M1037.2,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,162.9,1035,164.8,1037.2,164.8z"/>
      <path className="st0" d="M1028.1,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,162.9,1025.9,164.8,1028.1,164.8z"/>
      <path className="st0" d="M1018.8,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,162.9,1016.6,164.8,1018.8,164.8z"/>
      <path className="st0" d="M1009.7,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,162.9,1007.5,164.8,1009.7,164.8z"/>
      <path className="st0" d="M1000.3,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C996.5,162.9,998.1,164.8,1000.3,164.8z"/>
      <path className="st0" d="M991.3,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C987.4,162.9,989.1,164.8,991.3,164.8z"/>
      <path className="st0" d="M981.9,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C978.1,162.9,979.7,164.8,981.9,164.8z"/>
      <path className="st0" d="M972.8,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C969,162.9,970.6,164.8,972.8,164.8z"/>
      <path className="st0" d="M963.5,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C959.6,162.9,961.5,164.8,963.5,164.8z"/>
      <path className="st0" d="M954.4,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C950.5,162.9,952.2,164.8,954.4,164.8z"/>
      <path className="st0" d="M949.2,160.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,164.8,949.2,162.9,949.2,160.7z"/>
      <path className="st0" d="M936,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C932.1,162.9,933.7,164.8,936,164.8z"/>
      <path className="st0" d="M926.6,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C922.7,162.9,924.7,164.8,926.6,164.8z"/>
      <path className="st0" d="M917.5,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C913.7,162.9,915.3,164.8,917.5,164.8z"/>
      <path className="st0" d="M908.4,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C904.3,162.9,906.2,164.8,908.4,164.8z"/>
      <path className="st0" d="M902.9,160.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C901.3,164.8,902.9,162.9,902.9,160.7z"/>
      <path className="st0" d="M890,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C885.9,162.9,887.8,164.8,890,164.8z
        "/>
      <path className="st0" d="M884.5,160.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C882.8,164.8,884.5,162.9,884.5,160.7z"/>
      <path className="st0" d="M871.6,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C867.4,162.9,869.4,164.8,871.6,164.8z"/>
      <path className="st0" d="M862.2,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C858.4,162.9,860,164.8,862.2,164.8z"/>
      <path className="st0" d="M853.1,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C849,162.9,850.9,164.8,853.1,164.8z"/>
      <path className="st0" d="M843.8,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C839.9,162.9,841.6,164.8,843.8,164.8z"/>
      <path className="st0" d="M834.7,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C830.8,162.9,832.5,164.8,834.7,164.8z"/>
      <path className="st0" d="M742.5,156.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C746.4,158.7,744.7,156.8,742.5,156.8z"/>
      <path className="st0" d="M733.4,156.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,158.7,735.4,156.8,733.4,156.8z"/>
      <path className="st0" d="M724.1,156.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,158.7,726.3,156.8,724.1,156.8z"/>
      <path className="st0" d="M715,156.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,158.7,717.2,156.8,715,156.8z"/>
      <path className="st0" d="M705.6,156.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,158.7,707.8,156.8,705.6,156.8z"/>
      <path className="st0" d="M696.6,156.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,158.7,698.8,156.8,696.6,156.8z"/>
      <path className="st0" d="M687.2,156.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.3,158.7,689.4,156.8,687.2,156.8z"/>
      <path className="st0" d="M678.1,156.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C682,158.7,680.3,156.8,678.1,156.8z"/>
      <path className="st0" d="M668.8,156.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C672.9,158.7,671,156.8,668.8,156.8z"/>
      <path className="st0" d="M659.7,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C655.8,162.9,657.5,164.8,659.7,164.8z"/>
      <path className="st0" d="M650.3,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C646.5,162.9,648.4,164.8,650.3,164.8z"/>
      <path className="st0" d="M641.2,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C637.4,162.9,639,164.8,641.2,164.8z"/>
      <path className="st0" d="M631.9,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C628,162.9,630,164.8,631.9,164.8z"/>
      <path className="st0" d="M626.7,160.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C625,164.5,626.7,162.9,626.7,160.7z"/>
      <path className="st0" d="M617.6,160.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C615.7,164.8,617.6,162.9,617.6,160.7z"/>
      <path className="st0" d="M600.5,160.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C602.2,156.8,600.5,158.7,600.5,160.7z"/>
      <path className="st0" d="M591.2,160.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C593.1,156.8,591.2,158.7,591.2,160.7z"/>
      <path className="st0" d="M585.9,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C582.1,162.9,583.7,164.8,585.9,164.8z"/>
      <path className="st0" d="M576.9,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C572.7,162.9,574.7,164.8,576.9,164.8z"/>
      <path className="st0" d="M567.5,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C563.6,162.9,565.3,164.8,567.5,164.8z"/>
      <path className="st0" d="M558.4,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C554.3,162.9,556.2,164.8,558.4,164.8z"/>
      <path className="st0" d="M549.1,164.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C545.2,162.9,546.9,164.8,549.1,164.8z"/>
      <path className="st0" d="M543.8,160.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C542.2,164.5,543.8,162.9,543.8,160.7z"/>
      <path className="st0" d="M530.6,156.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C534.8,158.7,532.8,156.8,530.6,156.8z"/>
      <ellipse className="st0" cx="1175.3" cy="151.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,147.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,149.4,1168.5,147.7,1166.3,147.7z"/>
      <path className="st0" d="M1153.1,151.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1154.7,147.7,1153.1,149.4,1153.1,151.6z"/>
      <ellipse className="st0" cx="1147.8" cy="151.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1134.6,151.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1136.3,147.7,1134.6,149.4,1134.6,151.6z"/>
      <path className="st0" d="M1125.5,151.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1127.2,147.7,1125.5,149.4,1125.5,151.6z"/>
      <path className="st0" d="M1120,147.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1123.9,149.4,1122.2,147.7,1120,147.7z"/>
      <ellipse className="st0" cx="1111" cy="151.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1101.6,147.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1105.5,149.4,1103.8,147.7,1101.6,147.7z"/>
      <path className="st0" d="M1092.5,147.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1096.4,149.4,1094.7,147.7,1092.5,147.7z"/>
      <path className="st0" d="M1083.2,147.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1087,149.4,1085.4,147.7,1083.2,147.7z"/>
      <ellipse className="st0" cx="1074.1" cy="151.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1065,147.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1068.9,149.4,1066.9,147.7,1065,147.7z"/>
      <path className="st0" d="M1055.6,155.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1051.8,153.8,1053.4,155.4,1055.6,155.4z"/>
      <ellipse className="st0" cx="1046.6" cy="151.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1037.2,155.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,153.8,1035,155.4,1037.2,155.4z"/>
      <path className="st0" d="M1028.1,155.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024.3,153.8,1025.9,155.4,1028.1,155.4z"/>
      <path className="st0" d="M1018.8,155.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,153.8,1016.6,155.4,1018.8,155.4z"/>
      <ellipse className="st0" cx="1009.7" cy="151.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1000.3,155.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C996.5,153.8,998.1,155.4,1000.3,155.4z"/>
      <path className="st0" d="M991.3,155.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C987.4,153.8,989.1,155.4,991.3,155.4z"/>
      <path className="st0" d="M981.9,155.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C978.1,153.8,979.7,155.4,981.9,155.4z"/>
      <ellipse className="st0" cx="972.8" cy="151.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M963.5,155.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C959.6,153.8,961.5,155.4,963.5,155.4z"/>
      <path className="st0" d="M954.4,155.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C950.5,153.8,952.2,155.4,954.4,155.4z"/>
      <path className="st0" d="M949.2,151.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,155.4,949.2,153.8,949.2,151.6z"/>
      <ellipse className="st0" cx="936" cy="151.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M926.6,155.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C922.7,153.8,924.7,155.4,926.6,155.4z"/>
      <path className="st0" d="M917.5,155.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C913.7,153.8,915.3,155.4,917.5,155.4z"/>
      <ellipse className="st0" cx="908.4" cy="151.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M902.9,151.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C901.3,155.4,902.9,153.8,902.9,151.6z"/>
      <path className="st0" d="M890,155.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C886.1,153.8,887.8,155.4,890,155.4z
        "/>
      <path className="st0" d="M884.5,151.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C882.8,155.4,884.5,153.8,884.5,151.6z"/>
      <ellipse className="st0" cx="871.6" cy="151.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M862.2,155.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C858.4,153.8,860,155.4,862.2,155.4z"/>
      <path className="st0" d="M853.1,155.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C849.3,153.8,850.9,155.4,853.1,155.4z"/>
      <path className="st0" d="M843.8,155.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C839.9,153.8,841.6,155.4,843.8,155.4z"/>
      <ellipse className="st0" cx="834.7" cy="151.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M742.5,147.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C746.4,149.4,744.7,147.7,742.5,147.7z"/>
      <path className="st0" d="M733.4,147.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,149.4,735.4,147.7,733.4,147.7z"/>
      <ellipse className="st0" cx="724.1" cy="151.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M715,147.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,149.4,717.2,147.7,715,147.7z"/>
      <path className="st0" d="M705.6,147.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,149.4,707.8,147.7,705.6,147.7z"/>
      <ellipse className="st0" cx="696.6" cy="151.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M687.2,147.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.1,149.4,689.4,147.7,687.2,147.7z"/>
      <path className="st0" d="M678.1,147.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C682,149.4,680.3,147.7,678.1,147.7z"/>
      <path className="st0" d="M668.8,147.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C672.6,149.4,671,147.7,668.8,147.7z"/>
      <ellipse className="st0" cx="659.7" cy="151.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M650.3,155.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C646.5,153.8,648.4,155.4,650.3,155.4z"/>
      <ellipse className="st0" cx="641.2" cy="151.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M631.9,147.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C635.7,149.4,634.1,147.7,631.9,147.7z"/>
      <ellipse className="st0" cx="622.8" cy="151.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M609.6,151.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C611.5,147.7,609.6,149.4,609.6,151.6z"/>
      <ellipse className="st0" cx="604.4" cy="151.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M591.2,151.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C593.1,147.7,591.2,149.4,591.2,151.6z"/>
      <ellipse className="st0" cx="585.9" cy="151.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M576.9,147.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C580.7,149.4,578.8,147.7,576.9,147.7z"/>
      <path className="st0" d="M563.6,151.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S563.6,149.4,563.6,151.6z"/>
      <ellipse className="st0" cx="558.4" cy="151.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M549.1,155.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C545.2,153.8,546.9,155.4,549.1,155.4z"/>
      <ellipse className="st0" cx="540" cy="151.6" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1171.5,142.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1171.5,140.3,1171.5,142.5z"/>
      <path className="st0" d="M1166.3,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,140.3,1168.5,138.4,1166.3,138.4z"/>
      <path className="st0" d="M1153.1,142.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1154.7,138.4,1153.1,140.3,1153.1,142.5z"/>
      <path className="st0" d="M1144,142.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1145.6,138.6,1144,140.3,1144,142.5z"/>
      <path className="st0" d="M1134.6,142.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1136.3,138.4,1134.6,140.3,1134.6,142.5z"/>
      <path className="st0" d="M1125.5,142.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1127.2,138.4,1125.5,140.3,1125.5,142.5z"/>
      <path className="st0" d="M1120,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1124.2,140.3,1122.2,138.4,1120,138.4z"/>
      <path className="st0" d="M1111,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1114.8,140.3,1113.2,138.4,1111,138.4z"/>
      <path className="st0" d="M1101.6,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1105.7,140.3,1103.8,138.4,1101.6,138.4z"/>
      <path className="st0" d="M1092.5,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1096.4,140.3,1094.7,138.4,1092.5,138.4z"/>
      <path className="st0" d="M1083.2,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1087.3,140.3,1085.4,138.4,1083.2,138.4z"/>
      <path className="st0" d="M1074.1,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1077.9,140.3,1076.3,138.4,1074.1,138.4z"/>
      <path className="st0" d="M1065,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1068.9,140.3,1066.9,138.4,1065,138.4z"/>
      <path className="st0" d="M1055.6,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1059.5,140.3,1057.9,138.4,1055.6,138.4z"/>
      <path className="st0" d="M1046.6,146.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1042.4,144.4,1044.4,146.3,1046.6,146.3z"/>
      <path className="st0" d="M1037.2,146.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1033.4,144.4,1035,146.3,1037.2,146.3z"/>
      <path className="st0" d="M1028.1,146.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,144.4,1025.9,146.3,1028.1,146.3z"/>
      <path className="st0" d="M1018.8,146.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,144.4,1016.6,146.3,1018.8,146.3z"/>
      <path className="st0" d="M1009.7,146.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,144.4,1007.5,146.3,1009.7,146.3z"/>
      <path className="st0" d="M1000.3,146.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C996.5,144.4,998.1,146.3,1000.3,146.3z"/>
      <path className="st0" d="M991.3,146.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C987.4,144.4,989.1,146.3,991.3,146.3z"/>
      <path className="st0" d="M981.9,146.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C978.1,144.4,979.7,146.3,981.9,146.3z"/>
      <path className="st0" d="M972.8,146.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C969,144.4,970.6,146.3,972.8,146.3z"/>
      <path className="st0" d="M963.5,146.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C959.6,144.4,961.5,146.3,963.5,146.3z"/>
      <path className="st0" d="M954.4,146.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C950.5,144.4,952.2,146.3,954.4,146.3z"/>
      <path className="st0" d="M949.2,142.5c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,146.3,949.2,144.4,949.2,142.5z"/>
      <path className="st0" d="M936,146.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C932.1,144.4,933.7,146.3,936,146.3z"/>
      <path className="st0" d="M926.6,146.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C922.7,144.4,924.7,146.3,926.6,146.3z"/>
      <path className="st0" d="M917.5,146.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C913.7,144.4,915.3,146.3,917.5,146.3z"/>
      <path className="st0" d="M908.4,146.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C904.3,144.4,906.2,146.3,908.4,146.3z"/>
      <path className="st0" d="M902.9,142.5c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C901.3,146.3,902.9,144.4,902.9,142.5z"/>
      <path className="st0" d="M890,146.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C885.9,144.4,887.8,146.3,890,146.3z
        "/>
      <path className="st0" d="M880.6,146.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C876.8,144.4,878.4,146.3,880.6,146.3z"/>
      <path className="st0" d="M871.6,146.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C867.4,144.4,869.4,146.3,871.6,146.3z"/>
      <path className="st0" d="M862.2,146.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C858.4,144.4,860,146.3,862.2,146.3z"/>
      <path className="st0" d="M853.1,146.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C849,144.4,850.9,146.3,853.1,146.3z"/>
      <path className="st0" d="M843.8,146.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C839.9,144.4,841.6,146.3,843.8,146.3z"/>
      <path className="st0" d="M834.7,146.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C830.8,144.4,832.5,146.3,834.7,146.3z"/>
      <path className="st0" d="M751.9,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C755.7,140.3,753.8,138.4,751.9,138.4z"/>
      <path className="st0" d="M742.5,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C746.4,140.3,744.7,138.4,742.5,138.4z"/>
      <path className="st0" d="M733.4,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,140.3,735.4,138.4,733.4,138.4z"/>
      <path className="st0" d="M724.1,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,140.3,726.3,138.4,724.1,138.4z"/>
      <path className="st0" d="M715,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,140.3,717.2,138.4,715,138.4z"/>
      <path className="st0" d="M705.6,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,140.3,707.8,138.4,705.6,138.4z"/>
      <path className="st0" d="M696.6,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,140.3,698.8,138.4,696.6,138.4z"/>
      <path className="st0" d="M687.2,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.3,140.3,689.4,138.4,687.2,138.4z"/>
      <path className="st0" d="M678.1,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C682,140.3,680.3,138.4,678.1,138.4z"/>
      <path className="st0" d="M668.8,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C672.9,140.3,671,138.4,668.8,138.4z"/>
      <path className="st0" d="M659.7,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C663.5,140.3,661.9,138.4,659.7,138.4z"/>
      <path className="st0" d="M646.5,142.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C648.4,138.4,646.5,140.3,646.5,142.5z"/>
      <path className="st0" d="M637.4,142.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S637.4,140.3,637.4,142.5z"/>
      <path className="st0" d="M628,142.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C630,138.4,628,140.3,628,142.5z"/>
      <path className="st0" d="M622.8,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C626.7,140.3,625,138.4,622.8,138.4z"/>
      <path className="st0" d="M609.6,142.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C611.5,138.4,609.6,140.3,609.6,142.5z"/>
      <path className="st0" d="M600.5,142.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C602.2,138.6,600.5,140.3,600.5,142.5z"/>
      <path className="st0" d="M591.2,142.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C593.1,138.4,591.2,140.3,591.2,142.5z"/>
      <path className="st0" d="M585.9,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C589.8,140.3,588.1,138.4,585.9,138.4z"/>
      <path className="st0" d="M576.9,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C580.7,140.3,578.8,138.4,576.9,138.4z"/>
      <path className="st0" d="M563.6,142.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C565.3,138.4,563.6,140.3,563.6,142.5z"/>
      <path className="st0" d="M558.4,138.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C562.3,140.3,560.6,138.4,558.4,138.4z"/>
      <path className="st0" d="M545.2,142.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C546.9,138.4,545.2,140.3,545.2,142.5z"/>
      <path className="st0" d="M540,146.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C536.1,144.4,537.8,146.3,540,146.3z"/>
      <path className="st0" d="M1171.5,133.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1171.5,130.9,1171.5,133.1z"/>
      <path className="st0" d="M1166.3,129.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,130.9,1168.5,129.3,1166.3,129.3z"/>
      <path className="st0" d="M1153.1,133.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1154.7,129.3,1153.1,130.9,1153.1,133.1z"/>
      <path className="st0" d="M1144,133.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1145.6,129.3,1144,130.9,1144,133.1z"/>
      <path className="st0" d="M1134.6,133.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1136.3,129.3,1134.6,130.9,1134.6,133.1z"/>
      <path className="st0" d="M1125.5,133.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1127.2,129.3,1125.5,130.9,1125.5,133.1z"/>
      <path className="st0" d="M1120,129.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1124.2,130.9,1122.2,129.3,1120,129.3z"/>
      <path className="st0" d="M1111,129.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1114.8,130.9,1113.2,129.3,1111,129.3z"/>
      <path className="st0" d="M1101.6,129.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1105.7,130.9,1103.8,129.3,1101.6,129.3z"/>
      <path className="st0" d="M1092.5,129.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1096.4,130.9,1094.7,129.3,1092.5,129.3z"/>
      <path className="st0" d="M1083.2,129.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1087.3,130.9,1085.4,129.3,1083.2,129.3z"/>
      <path className="st0" d="M1074.1,129.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1077.9,130.9,1076.3,129.3,1074.1,129.3z"/>
      <path className="st0" d="M1065,129.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1068.9,130.9,1066.9,129.3,1065,129.3z"/>
      <path className="st0" d="M1055.6,129.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1059.5,130.9,1057.9,129.3,1055.6,129.3z"/>
      <path className="st0" d="M1046.6,129.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1050.4,130.9,1048.5,129.3,1046.6,129.3z"/>
      <path className="st0" d="M1037.2,137c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1035,137,1037.2,137z"/>
      <path className="st0" d="M1028.1,137c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,135.3,1025.9,137,1028.1,137z"/>
      <path className="st0" d="M1018.8,137c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1016.6,137,1018.8,137z"/>
      <path className="st0" d="M1009.7,137c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,135.3,1007.5,137,1009.7,137z"/>
      <path className="st0" d="M1000.3,137c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C996.5,135.3,998.1,137,1000.3,137z"/>
      <path className="st0" d="M991.3,137c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S989.1,137,991.3,137z"/>
      <path className="st0" d="M981.9,137c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S979.7,137,981.9,137z"/>
      <path className="st0" d="M972.8,137c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C969,135.3,970.6,137,972.8,137z"/>
      <path className="st0" d="M963.5,137c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S961.5,137,963.5,137z"/>
      <path className="st0" d="M954.4,137c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S952.2,137,954.4,137z"/>
      <path className="st0" d="M949.2,133.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,137,949.2,135.3,949.2,133.1z"/>
      <path className="st0" d="M936,137c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S933.7,137,936,137z"/>
      <path className="st0" d="M926.6,137c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S924.7,137,926.6,137z"/>
      <path className="st0" d="M917.5,137c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S915.3,137,917.5,137z"/>
      <path className="st0" d="M908.4,137c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C904.3,135.3,906.2,137,908.4,137z"/>
      <path className="st0" d="M902.9,133.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C901.3,137,902.9,135.3,902.9,133.1z"/>
      <path className="st0" d="M890,137c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C885.9,135.3,887.8,137,890,137z"/>
      <path className="st0" d="M880.6,137c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C876.8,135.3,878.4,137,880.6,137z"/>
      <path className="st0" d="M871.6,137c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C867.4,135.3,869.4,137,871.6,137z"/>
      <path className="st0" d="M862.2,137c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S860,137,862.2,137z"/>
      <path className="st0" d="M853.1,137c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C849,135.3,850.9,137,853.1,137z"/>
      <path className="st0" d="M843.8,137c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S841.6,137,843.8,137z"/>
      <path className="st0" d="M834.7,137c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S832.5,137,834.7,137z"/>
      <path className="st0" d="M825.3,129.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C829.5,130.9,827.5,129.3,825.3,129.3z"/>
      <path className="st0" d="M751.9,129.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C755.7,130.9,753.8,129.3,751.9,129.3z"/>
      <path className="st0" d="M746.4,133.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C744.7,137,746.4,135.3,746.4,133.1z"/>
      <path className="st0" d="M737.3,133.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C735.4,137,737.3,135.3,737.3,133.1z"/>
      <path className="st0" d="M727.9,133.1c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S727.9,135.3,727.9,133.1z"/>
      <path className="st0" d="M718.8,133.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C717.2,137,718.8,135.3,718.8,133.1z"/>
      <path className="st0" d="M709.5,133.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C707.8,137,709.5,135.3,709.5,133.1z"/>
      <path className="st0" d="M700.4,133.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C698.8,137,700.4,135.3,700.4,133.1z"/>
      <path className="st0" d="M691.3,133.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C689.4,137,691.3,135.3,691.3,133.1z"/>
      <path className="st0" d="M682,133.1c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C680.3,137,682,135.3,682,133.1z"/>
      <path className="st0" d="M672.9,133.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C671,137,672.9,135.3,672.9,133.1z"/>
      <path className="st0" d="M655.8,133.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C657.5,129.3,655.8,130.9,655.8,133.1z"/>
      <path className="st0" d="M646.5,133.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C648.4,129.3,646.5,130.9,646.5,133.1z"/>
      <path className="st0" d="M637.4,133.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S637.4,130.9,637.4,133.1z"/>
      <path className="st0" d="M628,133.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C630,129.3,628,130.9,628,133.1z"/>
      <path className="st0" d="M622.8,129.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C626.7,130.9,625,129.3,622.8,129.3z"/>
      <path className="st0" d="M609.6,133.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C611.5,129.3,609.6,130.9,609.6,133.1z"/>
      <path className="st0" d="M600.5,133.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C602.2,129.3,600.5,130.9,600.5,133.1z"/>
      <path className="st0" d="M591.2,133.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C593.1,129.3,591.2,130.9,591.2,133.1z"/>
      <path className="st0" d="M585.9,129.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C589.8,130.9,588.1,129.3,585.9,129.3z"/>
      <path className="st0" d="M576.9,129.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C580.7,130.9,578.8,129.3,576.9,129.3z"/>
      <path className="st0" d="M563.6,133.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S563.6,130.9,563.6,133.1z"/>
      <path className="st0" d="M558.4,129.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C562.3,130.9,560.6,129.3,558.4,129.3z"/>
      <path className="st0" d="M549.1,137c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S546.9,137,549.1,137z"/>
      <path className="st0" d="M540,137c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S537.8,137,540,137z"/>
      <path className="st0" d="M1171.5,124.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1171.5,121.9,1171.5,124.1z"/>
      <path className="st0" d="M1166.3,120.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,121.9,1168.5,120.2,1166.3,120.2z"/>
      <path className="st0" d="M1153.1,124.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1154.7,120.2,1153.1,121.9,1153.1,124.1z"/>
      <path className="st0" d="M1144,124.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1145.6,120.2,1144,121.9,1144,124.1z"/>
      <path className="st0" d="M1134.6,124.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1136.3,120.2,1134.6,121.9,1134.6,124.1z"/>
      <path className="st0" d="M1125.5,124.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1127.2,120.2,1125.5,121.9,1125.5,124.1z"/>
      <path className="st0" d="M1120,120.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1124.2,121.9,1122.2,120.2,1120,120.2z"/>
      <path className="st0" d="M1111,120.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1114.8,121.9,1113.2,120.2,1111,120.2z"/>
      <path className="st0" d="M1101.6,120.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1105.7,121.9,1103.8,120.2,1101.6,120.2z"/>
      <path className="st0" d="M1092.5,120.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1096.4,121.9,1094.7,120.2,1092.5,120.2z"/>
      <path className="st0" d="M1083.2,120.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1087.3,121.9,1085.4,120.2,1083.2,120.2z"/>
      <path className="st0" d="M1074.1,120.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1077.9,121.9,1076.3,120.2,1074.1,120.2z"/>
      <path className="st0" d="M1065,120.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1068.9,121.9,1066.9,120.2,1065,120.2z"/>
      <path className="st0" d="M1055.6,120.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1059.5,121.9,1057.9,120.2,1055.6,120.2z"/>
      <path className="st0" d="M1046.6,120.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1050.4,121.9,1048.5,120.2,1046.6,120.2z"/>
      <path className="st0" d="M1037.2,120.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1041.1,121.9,1039.4,120.2,1037.2,120.2z"/>
      <path className="st0" d="M1028.1,127.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1024,126.3,1025.9,127.9,1028.1,127.9z"/>
      <path className="st0" d="M1018.8,127.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,126.3,1016.6,127.9,1018.8,127.9z"/>
      <path className="st0" d="M1009.7,127.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,126.3,1007.5,127.9,1009.7,127.9z"/>
      <path className="st0" d="M1000.3,127.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C996.5,126.3,998.1,127.9,1000.3,127.9z"/>
      <path className="st0" d="M991.3,127.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C987.4,126.3,989.1,127.9,991.3,127.9z"/>
      <path className="st0" d="M981.9,127.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C978.1,126.3,979.7,127.9,981.9,127.9z"/>
      <path className="st0" d="M972.8,127.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C969,126.3,970.6,127.9,972.8,127.9z"/>
      <path className="st0" d="M963.5,127.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C959.6,126.3,961.5,127.9,963.5,127.9z"/>
      <path className="st0" d="M954.4,127.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C950.5,126.3,952.2,127.9,954.4,127.9z"/>
      <path className="st0" d="M949.2,124.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,127.9,949.2,126.3,949.2,124.1z"/>
      <path className="st0" d="M936,127.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C932.1,126.3,933.7,127.9,936,127.9z"/>
      <path className="st0" d="M926.6,127.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C922.7,126.3,924.7,127.9,926.6,127.9z"/>
      <path className="st0" d="M917.5,127.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C913.7,126.3,915.3,127.9,917.5,127.9z"/>
      <path className="st0" d="M908.4,127.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C904.3,126.3,906.2,127.9,908.4,127.9z"/>
      <path className="st0" d="M902.9,124.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C901.3,127.9,902.9,126.3,902.9,124.1z"/>
      <path className="st0" d="M890,127.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C885.9,126.3,887.8,127.9,890,127.9z
        "/>
      <path className="st0" d="M880.6,127.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C876.8,126.3,878.4,127.9,880.6,127.9z"/>
      <path className="st0" d="M871.6,127.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C867.4,126.3,869.4,127.9,871.6,127.9z"/>
      <path className="st0" d="M862.2,127.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C858.4,126.3,860,127.9,862.2,127.9z"/>
      <path className="st0" d="M853.1,127.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C849,126.3,850.9,127.9,853.1,127.9z"/>
      <path className="st0" d="M843.8,127.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C839.9,126.3,841.6,127.9,843.8,127.9z"/>
      <path className="st0" d="M834.7,127.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C830.8,126.3,832.5,127.9,834.7,127.9z"/>
      <path className="st0" d="M825.3,127.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,126.3,823.1,127.9,825.3,127.9z"/>
      <path className="st0" d="M751.9,120.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C755.7,121.9,753.8,120.2,751.9,120.2z"/>
      <path className="st0" d="M742.5,120.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C746.4,121.9,744.7,120.2,742.5,120.2z"/>
      <path className="st0" d="M733.4,120.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,121.9,735.4,120.2,733.4,120.2z"/>
      <path className="st0" d="M724.1,120.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,121.9,726.3,120.2,724.1,120.2z"/>
      <path className="st0" d="M715,120.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,121.9,717.2,120.2,715,120.2z"/>
      <path className="st0" d="M705.6,120.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,121.9,707.8,120.2,705.6,120.2z"/>
      <path className="st0" d="M696.6,120.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,121.9,698.8,120.2,696.6,120.2z"/>
      <path className="st0" d="M687.2,120.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.3,121.9,689.4,120.2,687.2,120.2z"/>
      <path className="st0" d="M678.1,120.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C682,121.9,680.3,120.2,678.1,120.2z"/>
      <path className="st0" d="M664.9,124.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S664.9,121.9,664.9,124.1z"/>
      <path className="st0" d="M655.8,124.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C657.5,120.2,655.8,121.9,655.8,124.1z"/>
      <path className="st0" d="M646.5,124.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C648.4,120.2,646.5,121.9,646.5,124.1z"/>
      <path className="st0" d="M637.4,124.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S637.4,121.9,637.4,124.1z"/>
      <path className="st0" d="M628,124.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C630,120.2,628,121.9,628,124.1z"/>
      <path className="st0" d="M622.8,120.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C626.7,121.9,625,120.2,622.8,120.2z"/>
      <path className="st0" d="M609.6,124.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C611.5,120.2,609.6,121.9,609.6,124.1z"/>
      <path className="st0" d="M600.5,124.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C602.2,120.2,600.5,121.9,600.5,124.1z"/>
      <path className="st0" d="M591.2,124.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C593.1,120.2,591.2,121.9,591.2,124.1z"/>
      <path className="st0" d="M585.9,120.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C589.8,121.9,588.1,120.2,585.9,120.2z"/>
      <path className="st0" d="M576.9,120.2c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C580.7,121.9,578.8,120.2,576.9,120.2z"/>
      <path className="st0" d="M567.5,127.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C563.6,126.3,565.3,127.9,567.5,127.9z"/>
      <path className="st0" d="M558.4,127.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C554.3,126.3,556.2,127.9,558.4,127.9z"/>
      <path className="st0" d="M545.2,124.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S545.2,121.9,545.2,124.1z"/>
      <path className="st0" d="M1171.5,114.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1171.5,112.8,1171.5,114.7z"/>
      <path className="st0" d="M1166.3,110.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,112.8,1168.5,110.9,1166.3,110.9z"/>
      <path className="st0" d="M1153.1,114.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1154.7,110.9,1153.1,112.8,1153.1,114.7z"/>
      <path className="st0" d="M1144,114.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1145.6,110.9,1144,112.8,1144,114.7z"/>
      <path className="st0" d="M1134.6,114.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1136.3,110.9,1134.6,112.8,1134.6,114.7z"/>
      <path className="st0" d="M1125.5,114.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1127.2,110.9,1125.5,112.8,1125.5,114.7z"/>
      <path className="st0" d="M1120,110.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1124.2,112.8,1122.2,110.9,1120,110.9z"/>
      <path className="st0" d="M1111,110.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1114.8,112.8,1113.2,110.9,1111,110.9z"/>
      <path className="st0" d="M1101.6,110.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1105.7,112.8,1103.8,110.9,1101.6,110.9z"/>
      <path className="st0" d="M1092.5,110.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1096.4,112.8,1094.7,110.9,1092.5,110.9z"/>
      <path className="st0" d="M1083.2,110.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1087.3,112.8,1085.4,110.9,1083.2,110.9z"/>
      <path className="st0" d="M1074.1,110.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1077.9,112.8,1076.3,110.9,1074.1,110.9z"/>
      <path className="st0" d="M1065,110.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1068.9,112.8,1066.9,110.9,1065,110.9z"/>
      <path className="st0" d="M1055.6,110.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1059.5,112.8,1057.9,110.9,1055.6,110.9z"/>
      <path className="st0" d="M1046.6,110.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1050.4,112.8,1048.5,110.9,1046.6,110.9z"/>
      <path className="st0" d="M1037.2,110.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1041.1,112.8,1039.4,110.9,1037.2,110.9z"/>
      <path className="st0" d="M1028.1,110.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1032,112.8,1030.1,110.9,1028.1,110.9z"/>
      <path className="st0" d="M1018.8,118.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1014.9,116.9,1016.6,118.8,1018.8,118.8z"/>
      <path className="st0" d="M1009.7,118.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,116.9,1007.5,118.8,1009.7,118.8z"/>
      <path className="st0" d="M1000.3,118.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C996.5,116.9,998.1,118.8,1000.3,118.8z"/>
      <path className="st0" d="M991.3,118.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C987.4,116.9,989.1,118.8,991.3,118.8z"/>
      <path className="st0" d="M981.9,118.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C978.1,116.9,979.7,118.8,981.9,118.8z"/>
      <path className="st0" d="M972.8,118.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C969,116.9,970.6,118.8,972.8,118.8z"/>
      <path className="st0" d="M963.5,118.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C959.6,116.9,961.5,118.8,963.5,118.8z"/>
      <path className="st0" d="M954.4,118.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C950.5,116.9,952.2,118.8,954.4,118.8z"/>
      <path className="st0" d="M949.2,114.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,118.8,949.2,116.9,949.2,114.7z"/>
      <path className="st0" d="M936,118.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C932.1,116.9,933.7,118.8,936,118.8z"/>
      <path className="st0" d="M926.6,118.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C922.7,116.9,924.7,118.8,926.6,118.8z"/>
      <path className="st0" d="M917.5,118.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C913.7,116.9,915.3,118.8,917.5,118.8z"/>
      <path className="st0" d="M908.4,118.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C904.3,116.9,906.2,118.8,908.4,118.8z"/>
      <path className="st0" d="M902.9,114.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C901.3,118.8,902.9,116.9,902.9,114.7z"/>
      <path className="st0" d="M885.9,114.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C887.8,110.9,885.9,112.8,885.9,114.7z"/>
      <path className="st0" d="M876.8,114.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C878.4,110.9,876.8,112.8,876.8,114.7z"/>
      <path className="st0" d="M867.4,114.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C869.1,110.9,867.4,112.8,867.4,114.7z"/>
      <path className="st0" d="M858.4,114.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C860,110.9,858.4,112.8,858.4,114.7z"/>
      <path className="st0" d="M849,114.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C850.9,110.9,849,112.8,849,114.7z"/>
      <path className="st0" d="M839.9,114.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C841.6,110.9,839.9,112.8,839.9,114.7z"/>
      <path className="st0" d="M830.8,114.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C832.5,110.9,830.8,112.8,830.8,114.7z"/>
      <path className="st0" d="M825.3,118.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,116.9,823.1,118.8,825.3,118.8z"/>
      <path className="st0" d="M751.9,110.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C755.7,112.8,753.8,110.9,751.9,110.9z"/>
      <path className="st0" d="M742.5,110.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C746.4,112.8,744.7,110.9,742.5,110.9z"/>
      <path className="st0" d="M733.4,110.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,112.8,735.4,110.9,733.4,110.9z"/>
      <path className="st0" d="M724.1,110.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,112.8,726.3,110.9,724.1,110.9z"/>
      <path className="st0" d="M715,110.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,112.8,717.2,110.9,715,110.9z"/>
      <path className="st0" d="M705.6,110.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,112.8,707.8,110.9,705.6,110.9z"/>
      <path className="st0" d="M696.6,110.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,112.8,698.8,110.9,696.6,110.9z"/>
      <path className="st0" d="M687.2,110.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.3,112.8,689.4,110.9,687.2,110.9z"/>
      <path className="st0" d="M678.1,110.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C682,112.8,680.3,110.9,678.1,110.9z"/>
      <path className="st0" d="M664.9,114.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S664.9,112.8,664.9,114.7z"/>
      <path className="st0" d="M655.8,114.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C657.5,110.9,655.8,112.8,655.8,114.7z"/>
      <path className="st0" d="M646.5,114.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C648.4,110.9,646.5,112.8,646.5,114.7z"/>
      <path className="st0" d="M637.4,114.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S637.4,112.8,637.4,114.7z"/>
      <path className="st0" d="M628,114.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C630,110.9,628,112.8,628,114.7z"/>
      <path className="st0" d="M622.8,110.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C626.7,112.8,625,110.9,622.8,110.9z"/>
      <path className="st0" d="M613.7,118.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C609.6,116.9,611.5,118.8,613.7,118.8z"/>
      <path className="st0" d="M604.4,118.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C600.5,116.9,602.2,118.8,604.4,118.8z"/>
      <path className="st0" d="M595.3,118.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.2,116.9,593.1,118.8,595.3,118.8z"/>
      <path className="st0" d="M582.1,114.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C583.7,110.9,582.1,112.8,582.1,114.7z"/>
      <path className="st0" d="M576.9,118.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C572.7,116.9,574.7,118.8,576.9,118.8z"/>
      <path className="st0" d="M563.6,114.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S563.6,112.8,563.6,114.7z"/>
      <path className="st0" d="M1171.5,105.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1171.5,103.4,1171.5,105.6z"/>
      <path className="st0" d="M1166.3,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,103.4,1168.5,101.8,1166.3,101.8z"/>
      <path className="st0" d="M1153.1,105.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1154.7,101.8,1153.1,103.4,1153.1,105.6z"/>
      <path className="st0" d="M1144,105.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1145.6,101.8,1144,103.4,1144,105.6z"/>
      <path className="st0" d="M1134.6,105.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1136.3,101.8,1134.6,103.4,1134.6,105.6z"/>
      <path className="st0" d="M1125.5,105.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1127.2,101.8,1125.5,103.4,1125.5,105.6z"/>
      <path className="st0" d="M1120,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1124.2,103.4,1122.2,101.8,1120,101.8z"/>
      <path className="st0" d="M1111,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1114.8,103.4,1113.2,101.8,1111,101.8z"/>
      <path className="st0" d="M1101.6,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1105.7,103.4,1103.8,101.8,1101.6,101.8z"/>
      <path className="st0" d="M1092.5,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1096.4,103.4,1094.7,101.8,1092.5,101.8z"/>
      <path className="st0" d="M1083.2,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1087.3,103.4,1085.4,101.8,1083.2,101.8z"/>
      <path className="st0" d="M1074.1,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1077.9,103.4,1076.3,101.8,1074.1,101.8z"/>
      <path className="st0" d="M1065,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1068.9,103.4,1066.9,101.8,1065,101.8z"/>
      <path className="st0" d="M1055.6,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1059.5,103.4,1057.9,101.8,1055.6,101.8z"/>
      <path className="st0" d="M1046.6,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1050.4,103.4,1048.5,101.8,1046.6,101.8z"/>
      <path className="st0" d="M1037.2,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1041.1,103.4,1039.4,101.8,1037.2,101.8z"/>
      <path className="st0" d="M1028.1,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1032,103.4,1030.1,101.8,1028.1,101.8z"/>
      <path className="st0" d="M1018.8,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1022.6,103.4,1021,101.8,1018.8,101.8z"/>
      <path className="st0" d="M1009.7,109.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1005.6,107.8,1007.5,109.5,1009.7,109.5z"/>
      <path className="st0" d="M1000.3,109.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C996.5,107.8,998.1,109.5,1000.3,109.5z"/>
      <path className="st0" d="M991.3,109.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C987.4,107.8,989.1,109.5,991.3,109.5z"/>
      <path className="st0" d="M981.9,109.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C978.1,107.8,979.7,109.5,981.9,109.5z"/>
      <path className="st0" d="M972.8,109.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C969,107.8,970.6,109.5,972.8,109.5z"/>
      <path className="st0" d="M963.5,109.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C959.6,107.8,961.5,109.5,963.5,109.5z"/>
      <path className="st0" d="M954.4,109.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C950.5,107.8,952.2,109.5,954.4,109.5z"/>
      <path className="st0" d="M949.2,105.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,109.5,949.2,107.8,949.2,105.6z"/>
      <path className="st0" d="M936,109.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C932.1,107.8,933.7,109.5,936,109.5z"/>
      <path className="st0" d="M926.6,109.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C922.7,107.8,924.7,109.5,926.6,109.5z"/>
      <path className="st0" d="M917.5,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C921.4,103.4,919.7,101.8,917.5,101.8z"/>
      <path className="st0" d="M904.3,105.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C906,101.8,904.3,103.4,904.3,105.6z"/>
      <path className="st0" d="M895.2,105.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C896.9,101.8,895.2,103.4,895.2,105.6z"/>
      <path className="st0" d="M885.9,105.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C887.8,101.8,885.9,103.4,885.9,105.6z"/>
      <path className="st0" d="M876.8,105.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C878.4,101.8,876.8,103.4,876.8,105.6z"/>
      <path className="st0" d="M867.4,105.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C869.1,101.8,867.4,103.4,867.4,105.6z"/>
      <path className="st0" d="M858.4,105.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C860,101.8,858.4,103.4,858.4,105.6z"/>
      <path className="st0" d="M849,105.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C850.9,101.8,849,103.4,849,105.6z"/>
      <path className="st0" d="M843.8,109.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C839.9,107.8,841.6,109.5,843.8,109.5z"/>
      <path className="st0" d="M834.7,109.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C830.8,107.8,832.5,109.5,834.7,109.5z"/>
      <path className="st0" d="M821.5,105.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C823.1,101.8,821.5,103.4,821.5,105.6z"/>
      <path className="st0" d="M760.9,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C764.8,103.4,763.1,101.8,760.9,101.8z"/>
      <path className="st0" d="M751.9,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C755.7,103.4,753.8,101.8,751.9,101.8z"/>
      <path className="st0" d="M742.5,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C746.4,103.4,744.7,101.8,742.5,101.8z"/>
      <path className="st0" d="M733.4,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,103.4,735.4,101.8,733.4,101.8z"/>
      <path className="st0" d="M724.1,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,103.4,726.3,101.8,724.1,101.8z"/>
      <path className="st0" d="M715,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,103.4,717.2,101.8,715,101.8z"/>
      <path className="st0" d="M705.6,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,103.4,707.8,101.8,705.6,101.8z"/>
      <path className="st0" d="M696.6,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,103.4,698.8,101.8,696.6,101.8z"/>
      <path className="st0" d="M687.2,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C691.3,103.4,689.4,101.8,687.2,101.8z"/>
      <path className="st0" d="M674.3,105.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C675.9,101.8,674.3,103.4,674.3,105.6z"/>
      <path className="st0" d="M664.9,105.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S664.9,103.4,664.9,105.6z"/>
      <path className="st0" d="M655.8,105.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C657.5,101.8,655.8,103.4,655.8,105.6z"/>
      <path className="st0" d="M646.5,105.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C648.4,101.8,646.5,103.4,646.5,105.6z"/>
      <path className="st0" d="M637.4,105.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S637.4,103.4,637.4,105.6z"/>
      <path className="st0" d="M628,105.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C630,101.8,628,103.4,628,105.6z"/>
      <path className="st0" d="M622.8,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C626.7,103.4,625,101.8,622.8,101.8z"/>
      <path className="st0" d="M613.7,101.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C617.6,103.4,615.7,101.8,613.7,101.8z"/>
      <path className="st0" d="M604.4,109.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C600.5,107.8,602.2,109.5,604.4,109.5z"/>
      <path className="st0" d="M595.3,109.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.2,107.8,593.1,109.5,595.3,109.5z"/>
      <ellipse className="st0" cx="1175.3" cy="96.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,92.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,94.1,1168.5,92.4,1166.3,92.4z"/>
      <path className="st0" d="M1153.1,96.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1154.7,92.4,1153.1,94.3,1153.1,96.5z"/>
      <ellipse className="st0" cx="1147.8" cy="96.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1134.6,96.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1136.3,92.4,1134.6,94.3,1134.6,96.5z"/>
      <path className="st0" d="M1125.5,96.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1127.2,92.4,1125.5,94.3,1125.5,96.5z"/>
      <path className="st0" d="M1120,92.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1123.9,94.1,1122.2,92.4,1120,92.4z"/>
      <ellipse className="st0" cx="1111" cy="96.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1101.6,92.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1105.5,94.1,1103.8,92.4,1101.6,92.4z"/>
      <path className="st0" d="M1092.5,92.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1096.4,94.1,1094.7,92.4,1092.5,92.4z"/>
      <path className="st0" d="M1083.2,92.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1087,94.1,1085.4,92.4,1083.2,92.4z"/>
      <ellipse className="st0" cx="1074.1" cy="96.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1065,92.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C1068.9,94.1,1066.9,92.4,1065,92.4z
        "/>
      <path className="st0" d="M1055.6,92.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1059.5,94.1,1057.9,92.4,1055.6,92.4z"/>
      <ellipse className="st0" cx="1046.6" cy="96.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1037.2,92.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1041.1,94.1,1039.4,92.4,1037.2,92.4z"/>
      <path className="st0" d="M1028.1,92.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1032,94.1,1030.1,92.4,1028.1,92.4z"/>
      <path className="st0" d="M1018.8,92.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1022.6,94.1,1021,92.4,1018.8,92.4z"/>
      <ellipse className="st0" cx="1009.7" cy="96.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1000.3,100.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C996.5,98.7,998.1,100.4,1000.3,100.4z"/>
      <path className="st0" d="M991.3,100.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C987.4,98.7,989.1,100.4,991.3,100.4z"/>
      <path className="st0" d="M981.9,100.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C978.1,98.7,979.7,100.4,981.9,100.4z"/>
      <ellipse className="st0" cx="972.8" cy="96.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M963.5,100.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C959.6,98.7,961.5,100.4,963.5,100.4z"/>
      <path className="st0" d="M954.4,100.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C950.5,98.7,952.2,100.4,954.4,100.4z"/>
      <path className="st0" d="M949.2,96.5c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,100.4,949.2,98.7,949.2,96.5z"/>
      <ellipse className="st0" cx="936" cy="96.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M922.7,96.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C924.7,92.4,922.7,94.3,922.7,96.5z"
        />
      <path className="st0" d="M913.7,96.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C915.3,92.4,913.7,94.3,913.7,96.5z"/>
      <ellipse className="st0" cx="908.4" cy="96.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M895.2,96.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C896.9,92.4,895.2,94.3,895.2,96.5z"/>
      <path className="st0" d="M885.9,96.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C887.8,92.4,885.9,94.3,885.9,96.5z"/>
      <path className="st0" d="M876.8,96.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C878.4,92.4,876.8,94.3,876.8,96.5z"/>
      <ellipse className="st0" cx="871.6" cy="96.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M858.4,96.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C860,92.4,858.4,94.3,858.4,96.5z"/>
      <path className="st0" d="M849,96.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C850.9,92.4,849,94.3,849,96.5z"
        />
      <path className="st0" d="M843.8,92.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C847.6,94.1,846,92.4,843.8,92.4z"/>
      <ellipse className="st0" cx="834.7" cy="96.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M774.2,96.5c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C772.2,100.4,774.2,98.7,774.2,96.5
        z"/>
      <ellipse className="st0" cx="760.9" cy="96.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M755.7,96.5c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C753.8,100.4,755.7,98.7,755.7,96.5z"/>
      <path className="st0" d="M746.4,96.5c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C744.7,100.4,746.4,98.7,746.4,96.5z"/>
      <path className="st0" d="M737.3,96.5c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C735.4,100.4,737.3,98.7,737.3,96.5z"/>
      <ellipse className="st0" cx="724.1" cy="96.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M718.8,96.5c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C717.2,100.4,718.8,98.7,718.8,96.5z"/>
      <path className="st0" d="M709.5,96.5c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C707.8,100.4,709.5,98.7,709.5,96.5z"/>
      <ellipse className="st0" cx="696.6" cy="96.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M683.3,96.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C685,92.4,683.3,94.3,683.3,96.5z"/>
      <path className="st0" d="M674.3,96.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C675.9,92.4,674.3,94.3,674.3,96.5z"
        />
      <path className="st0" d="M664.9,96.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C666.6,92.4,664.9,94.3,664.9,96.5z"/>
      <ellipse className="st0" cx="659.7" cy="96.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M646.5,96.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C648.4,92.4,646.5,94.3,646.5,96.5z"/>
      <ellipse className="st0" cx="641.2" cy="96.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M628,96.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C630,92.4,628,94.3,628,96.5z"/>
      <ellipse className="st0" cx="622.8" cy="96.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M613.7,92.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C617.6,94.1,615.7,92.4,613.7,92.4z
        "/>
      <ellipse className="st0" cx="604.4" cy="96.5" rx="3.9" ry="3.9"/>
      <path className="st0" d="M595.3,100.4c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C591.4,98.7,593.1,100.4,595.3,100.4z"/>
      <path className="st0" d="M1171.5,87.2c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1171.5,85,1171.5,87.2z"/>
      <path className="st0" d="M1166.3,83.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,85,1168.5,83.3,1166.3,83.3z"/>
      <path className="st0" d="M1153.1,87.2c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C1154.7,83.3,1153.1,85,1153.1,87.2
        z"/>
      <path className="st0" d="M1144,87.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C1145.6,83.3,1144,85,1144,87.2
        z"/>
      <path className="st0" d="M1134.6,87.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1136.3,83.3,1134.6,85,1134.6,87.2z"/>
      <path className="st0" d="M1125.5,87.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1127.2,83.3,1125.5,85,1125.5,87.2z"/>
      <path className="st0" d="M1120,83.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1124.2,85,1122.2,83.3,1120,83.3z"/>
      <path className="st0" d="M1111,83.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C1114.8,85,1113.2,83.3,1111,83.3z"
        />
      <path className="st0" d="M1101.6,83.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1105.7,85,1103.8,83.3,1101.6,83.3z"/>
      <path className="st0" d="M1092.5,83.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1096.4,85,1094.7,83.3,1092.5,83.3z"/>
      <path className="st0" d="M1083.2,83.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1087.3,85,1085.4,83.3,1083.2,83.3z"/>
      <path className="st0" d="M1074.1,83.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1077.9,85,1076.3,83.3,1074.1,83.3z"/>
      <path className="st0" d="M1065,83.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C1068.9,85,1066.9,83.3,1065,83.3z"
        />
      <path className="st0" d="M1055.6,83.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1059.5,85,1057.9,83.3,1055.6,83.3z"/>
      <path className="st0" d="M1046.6,83.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1050.4,85,1048.5,83.3,1046.6,83.3z"/>
      <path className="st0" d="M1037.2,83.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1041.1,85,1039.4,83.3,1037.2,83.3z"/>
      <path className="st0" d="M1028.1,83.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1032,85,1030.1,83.3,1028.1,83.3z"/>
      <path className="st0" d="M1018.8,83.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1022.6,85,1021,83.3,1018.8,83.3z"/>
      <path className="st0" d="M1009.7,83.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1013.5,85,1011.9,83.3,1009.7,83.3z"/>
      <path className="st0" d="M1000.3,83.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1004.2,85,1002.5,83.3,1000.3,83.3z"/>
      <path className="st0" d="M991.3,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C987.4,89.4,989.1,91,991.3,91z
        "/>
      <path className="st0" d="M981.9,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C978.1,89.4,979.7,91,981.9,91z
        "/>
      <path className="st0" d="M972.8,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C969,89.4,970.6,91,972.8,91z"
        />
      <path className="st0" d="M963.5,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C959.6,89.4,961.5,91,963.5,91z
        "/>
      <path className="st0" d="M954.4,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C950.5,89.4,952.2,91,954.4,91z
        "/>
      <path className="st0" d="M949.2,87.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C947.2,91,949.2,89.4,949.2,87.2z"/>
      <path className="st0" d="M936,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C932.1,89.4,933.7,91,936,91z"/>
      <path className="st0" d="M926.6,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C922.7,89.4,924.7,91,926.6,91z"/>
      <path className="st0" d="M917.5,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C913.7,89.4,915.3,91,917.5,91z
        "/>
      <path className="st0" d="M908.4,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C904.3,89.4,906.2,91,908.4,91z
        "/>
      <path className="st0" d="M899.1,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C895.2,89.4,896.9,91,899.1,91z
        "/>
      <path className="st0" d="M890,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C885.9,89.4,887.8,91,890,91z"/>
      <path className="st0" d="M880.6,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C876.8,89.4,878.4,91,880.6,91z
        "/>
      <path className="st0" d="M871.6,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C867.4,89.4,869.4,91,871.6,91z
        "/>
      <path className="st0" d="M862.2,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C858.4,89.4,860,91,862.2,91z"
        />
      <path className="st0" d="M853.1,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C849,89.4,850.9,91,853.1,91z"
        />
      <path className="st0" d="M843.8,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C839.9,89.4,841.6,91,843.8,91z
        "/>
      <path className="st0" d="M834.7,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C830.8,89.4,832.5,91,834.7,91z
        "/>
      <path className="st0" d="M770.3,83.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C774.2,85,772.2,83.3,770.3,83.3z"
        />
      <path className="st0" d="M764.8,87.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C763.1,91,764.8,89.4,764.8,87.2z"/>
      <path className="st0" d="M755.7,87.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C753.8,91,755.7,89.4,755.7,87.2z"/>
      <path className="st0" d="M746.4,87.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C744.7,91,746.4,89.4,746.4,87.2z"/>
      <path className="st0" d="M737.3,87.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C735.4,91,737.3,89.4,737.3,87.2z"/>
      <path className="st0" d="M727.9,87.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S727.9,89.4,727.9,87.2z"/>
      <path className="st0" d="M718.8,87.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C717.2,91,718.8,89.4,718.8,87.2z"/>
      <path className="st0" d="M709.5,87.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C707.8,91,709.5,89.4,709.5,87.2z"/>
      <path className="st0" d="M696.6,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C692.7,89.4,694.4,91,696.6,91z
        "/>
      <path className="st0" d="M687.2,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C683.3,89.4,685,91,687.2,91z"
        />
      <path className="st0" d="M678.1,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C674.3,89.4,675.9,91,678.1,91z"/>
      <path className="st0" d="M668.8,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C664.9,89.4,666.6,91,668.8,91z"/>
      <path className="st0" d="M659.7,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C655.8,89.4,657.5,91,659.7,91z
        "/>
      <path className="st0" d="M650.3,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C646.5,89.4,648.4,91,650.3,91z"/>
      <path className="st0" d="M641.2,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C637.4,89.4,639,91,641.2,91z"/>
      <path className="st0" d="M631.9,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C628,89.4,630,91,631.9,91z"/>
      <path className="st0" d="M619,87.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C620.6,83.3,619,85,619,87.2z"/>
      <path className="st0" d="M613.7,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C609.6,89.4,611.5,91,613.7,91z
        "/>
      <path className="st0" d="M600.5,87.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C602.2,83.3,600.5,85,600.5,87.2z"/>
      <path className="st0" d="M595.3,91c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C591.2,89.4,593.1,91,595.3,91z
        "/>
      <path className="st0" d="M1171.5,78.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1171.5,75.9,1171.5,78.1z"/>
      <path className="st0" d="M1166.3,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,75.9,1168.5,74.3,1166.3,74.3z"/>
      <path className="st0" d="M1153.1,78.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1154.7,74.3,1153.1,75.9,1153.1,78.1z"/>
      <path className="st0" d="M1144,78.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1145.6,74.3,1144,75.9,1144,78.1z"/>
      <path className="st0" d="M1134.6,78.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1136.3,74.3,1134.6,75.9,1134.6,78.1z"/>
      <path className="st0" d="M1125.5,78.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1127.2,74.3,1125.5,75.9,1125.5,78.1z"/>
      <path className="st0" d="M1120,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1124.2,75.9,1122.2,74.3,1120,74.3z"/>
      <path className="st0" d="M1111,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C1114.8,75.9,1113.2,74.3,1111,74.3z
        "/>
      <path className="st0" d="M1101.6,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1105.7,75.9,1103.8,74.3,1101.6,74.3z"/>
      <path className="st0" d="M1092.5,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1096.4,75.9,1094.7,74.3,1092.5,74.3z"/>
      <path className="st0" d="M1083.2,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1087.3,75.9,1085.4,74.3,1083.2,74.3z"/>
      <path className="st0" d="M1074.1,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1077.9,75.9,1076.3,74.3,1074.1,74.3z"/>
      <path className="st0" d="M1065,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C1068.9,75.9,1066.9,74.3,1065,74.3z
        "/>
      <path className="st0" d="M1055.6,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1059.5,75.9,1057.9,74.3,1055.6,74.3z"/>
      <path className="st0" d="M1046.6,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1050.4,75.9,1048.5,74.3,1046.6,74.3z"/>
      <path className="st0" d="M1037.2,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1041.1,75.9,1039.4,74.3,1037.2,74.3z"/>
      <path className="st0" d="M1028.1,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1032,75.9,1030.1,74.3,1028.1,74.3z"/>
      <path className="st0" d="M1018.8,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1022.6,75.9,1021,74.3,1018.8,74.3z"/>
      <path className="st0" d="M1009.7,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1013.5,75.9,1011.9,74.3,1009.7,74.3z"/>
      <path className="st0" d="M1000.3,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1004.2,75.9,1002.5,74.3,1000.3,74.3z"/>
      <path className="st0" d="M991.3,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C995.1,75.9,993.5,74.3,991.3,74.3z"/>
      <path className="st0" d="M981.9,82c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C978.1,80.3,979.7,82,981.9,82z
        "/>
      <path className="st0" d="M972.8,82c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C969,80.3,970.6,82,972.8,82z"
        />
      <path className="st0" d="M963.5,82c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C959.6,80.3,961.5,82,963.5,82z
        "/>
      <path className="st0" d="M950.5,78.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C952.2,74.3,950.5,75.9,950.5,78.1z"/>
      <path className="st0" d="M941.2,78.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C943.1,74.3,941.2,75.9,941.2,78.1z"/>
      <path className="st0" d="M932.1,78.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C933.7,74.3,932.1,75.9,932.1,78.1z"/>
      <path className="st0" d="M922.7,78.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C924.7,74.3,922.7,75.9,922.7,78.1z"
        />
      <path className="st0" d="M913.7,78.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C915.3,74.3,913.7,75.9,913.7,78.1z"/>
      <path className="st0" d="M904.3,78.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C906,74.3,904.3,75.9,904.3,78.1z"/>
      <path className="st0" d="M895.2,78.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C896.9,74.3,895.2,75.9,895.2,78.1z"/>
      <path className="st0" d="M885.9,78.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C887.8,74.3,885.9,75.9,885.9,78.1z"/>
      <path className="st0" d="M876.8,78.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C878.4,74.3,876.8,75.9,876.8,78.1z"/>
      <path className="st0" d="M867.4,78.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C869.1,74.3,867.4,75.9,867.4,78.1z"/>
      <path className="st0" d="M858.4,78.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C860,74.3,858.4,75.9,858.4,78.1z"/>
      <path className="st0" d="M853.1,82c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C849,80.3,850.9,82,853.1,82z"
        />
      <path className="st0" d="M783.2,78.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C781.6,82,783.2,80.3,783.2,78.1z"/>
      <path className="st0" d="M770.3,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C774.2,75.9,772.2,74.3,770.3,74.3z
        "/>
      <path className="st0" d="M760.9,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C764.8,75.9,763.1,74.3,760.9,74.3z"/>
      <path className="st0" d="M751.9,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C755.7,75.9,753.8,74.3,751.9,74.3z"/>
      <path className="st0" d="M742.5,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C746.4,75.9,744.7,74.3,742.5,74.3z"/>
      <path className="st0" d="M733.4,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,75.9,735.4,74.3,733.4,74.3z"/>
      <path className="st0" d="M724.1,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,75.9,726.3,74.3,724.1,74.3z"/>
      <path className="st0" d="M715,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C718.8,75.9,717.2,74.3,715,74.3z"/>
      <path className="st0" d="M705.6,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C709.5,75.9,707.8,74.3,705.6,74.3z"/>
      <path className="st0" d="M696.6,82c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C692.7,80.3,694.4,82,696.6,82z
        "/>
      <path className="st0" d="M687.2,82c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C683.3,80.3,685,82,687.2,82z"
        />
      <path className="st0" d="M678.1,82c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C674.3,80.3,675.9,82,678.1,82z"/>
      <path className="st0" d="M668.8,82c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C664.9,80.3,666.6,82,668.8,82z"/>
      <path className="st0" d="M659.7,82c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C655.8,80.3,657.5,82,659.7,82z
        "/>
      <path className="st0" d="M650.3,82c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C646.5,80.3,648.4,82,650.3,82z"/>
      <path className="st0" d="M641.2,82c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C637.4,80.3,639,82,641.2,82z"/>
      <path className="st0" d="M631.9,82c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C628,80.3,630,82,631.9,82z"/>
      <path className="st0" d="M613.7,82c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C609.6,80.3,611.5,82,613.7,82z
        "/>
      <path className="st0" d="M97.8,74.3c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C101.6,75.9,100,74.3,97.8,74.3z"/>
      <path className="st0" d="M1171.5,69c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1171.5,66.8,1171.5,69z"/>
      <path className="st0" d="M1166.3,64.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,66.8,1168.5,64.9,1166.3,64.9z"/>
      <path className="st0" d="M1156.9,72.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C1153.1,71,1154.7,72.9,1156.9,72.9z"/>
      <path className="st0" d="M1147.8,72.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1144,71,1145.6,72.9,1147.8,72.9z"/>
      <path className="st0" d="M1138.5,72.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,71,1136.3,72.9,1138.5,72.9z"/>
      <path className="st0" d="M1129.4,72.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,71,1127.2,72.9,1129.4,72.9z"/>
      <path className="st0" d="M1120,72.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1116.2,71,1117.8,72.9,1120,72.9z"/>
      <path className="st0" d="M1111,64.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C1114.8,66.8,1113.2,64.9,1111,64.9z
        "/>
      <path className="st0" d="M1101.6,64.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1105.7,66.8,1103.8,64.9,1101.6,64.9z"/>
      <path className="st0" d="M1092.5,64.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1096.4,66.8,1094.7,64.9,1092.5,64.9z"/>
      <path className="st0" d="M1083.2,64.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1087.3,66.8,1085.4,64.9,1083.2,64.9z"/>
      <path className="st0" d="M1074.1,64.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1077.9,66.8,1076.3,64.9,1074.1,64.9z"/>
      <path className="st0" d="M1065,64.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C1068.9,66.8,1066.9,64.9,1065,64.9z
        "/>
      <path className="st0" d="M1055.6,64.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1059.5,66.8,1057.9,64.9,1055.6,64.9z"/>
      <path className="st0" d="M1046.6,64.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1050.4,66.8,1048.5,64.9,1046.6,64.9z"/>
      <path className="st0" d="M1037.2,64.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1041.1,66.8,1039.4,64.9,1037.2,64.9z"/>
      <path className="st0" d="M1028.1,64.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1032,66.8,1030.1,64.9,1028.1,64.9z"/>
      <path className="st0" d="M1018.8,64.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1022.6,66.8,1021,64.9,1018.8,64.9z"/>
      <path className="st0" d="M1009.7,64.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1013.5,66.8,1011.9,64.9,1009.7,64.9z"/>
      <path className="st0" d="M1000.3,64.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1004.2,66.8,1002.5,64.9,1000.3,64.9z"/>
      <path className="st0" d="M991.3,64.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C995.1,66.8,993.5,64.9,991.3,64.9z"/>
      <path className="st0" d="M981.9,64.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C986,66.8,984.1,64.9,981.9,64.9z"/>
      <path className="st0" d="M969,69c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S969,66.8,969,69z"/>
      <path className="st0" d="M959.6,69c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C961.5,64.9,959.6,66.8,959.6,69
        z"/>
      <path className="st0" d="M950.5,69c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C952.2,64.9,950.5,66.8,950.5,69
        z"/>
      <path className="st0" d="M941.2,69c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C943.1,64.9,941.2,66.8,941.2,69
        z"/>
      <path className="st0" d="M932.1,69c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C933.7,65.2,932.1,66.8,932.1,69
        z"/>
      <path className="st0" d="M922.7,69c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C924.7,64.9,922.7,66.8,922.7,69z"/>
      <path className="st0" d="M913.7,69c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C915.3,64.9,913.7,66.8,913.7,69
        z"/>
      <path className="st0" d="M904.3,69c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C906,65.2,904.3,66.8,904.3,69z"
        />
      <path className="st0" d="M895.2,69c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C896.9,64.9,895.2,66.8,895.2,69
        z"/>
      <path className="st0" d="M885.9,69c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C887.8,64.9,885.9,66.8,885.9,69
        z"/>
      <path className="st0" d="M876.8,69c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C878.4,64.9,876.8,66.8,876.8,69
        z"/>
      <path className="st0" d="M867.4,69c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C869.1,65.2,867.4,66.8,867.4,69
        z"/>
      <path className="st0" d="M862.2,72.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C858.4,71,860,72.9,862.2,72.9z"/>
      <path className="st0" d="M849,69c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C850.9,64.9,849,66.8,849,69z"/>
      <path className="st0" d="M797.8,64.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C801.7,66.8,800,64.9,797.8,64.9z"/>
      <path className="st0" d="M788.5,64.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C792.6,66.8,790.7,64.9,788.5,64.9z"/>
      <path className="st0" d="M779.4,64.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C783.2,66.8,781.6,64.9,779.4,64.9z"/>
      <path className="st0" d="M774.2,69c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C772.2,72.9,774.2,71,774.2,69z"/>
      <path className="st0" d="M764.8,69c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C763.1,72.9,764.8,71,764.8,69z
        "/>
      <path className="st0" d="M755.7,69c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C753.8,72.9,755.7,71,755.7,69z
        "/>
      <path className="st0" d="M746.4,69c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C744.7,72.9,746.4,71,746.4,69z
        "/>
      <path className="st0" d="M737.3,69c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C735.4,72.9,737.3,71,737.3,69z
        "/>
      <path className="st0" d="M727.9,69c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S727.9,71,727.9,69z"/>
      <path className="st0" d="M718.8,69c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C717.2,72.9,718.8,71,718.8,69z
        "/>
      <path className="st0" d="M709.5,69c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C707.8,72.9,709.5,71,709.5,69z
        "/>
      <path className="st0" d="M696.6,72.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C692.7,71,694.4,72.9,696.6,72.9z"/>
      <path className="st0" d="M687.2,72.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C683.3,71,685,72.9,687.2,72.9z"/>
      <path className="st0" d="M678.1,72.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C674.3,71,675.9,72.9,678.1,72.9z"
        />
      <path className="st0" d="M668.8,72.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C664.9,71,666.6,72.9,668.8,72.9z"
        />
      <path className="st0" d="M659.7,72.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C655.8,71,657.5,72.9,659.7,72.9z"/>
      <path className="st0" d="M650.3,72.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C646.5,71,648.4,72.9,650.3,72.9z"
        />
      <path className="st0" d="M641.2,72.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C637.4,71,639,72.9,641.2,72.9z"/>
      <path className="st0" d="M631.9,72.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C628,71,630,72.9,631.9,72.9z
        "/>
      <path className="st0" d="M622.8,64.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C626.7,66.8,625,64.9,622.8,64.9z"/>
      <path className="st0" d="M111,69c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S111,71,111,69z"/>
      <path className="st0" d="M97.8,64.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C101.6,66.8,100,64.9,97.8,64.9z"/>
      <path className="st0" d="M92.6,69c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S92.6,71,92.6,69z"/>
      <path className="st0" d="M79.3,64.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C83.5,66.8,81.6,64.9,79.3,64.9z"/>
      <path className="st0" d="M74.1,69c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C72.5,72.9,74.1,71,74.1,69z"/>
      <path className="st0" d="M1171.5,59.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S1171.5,57.5,1171.5,59.7z"/>
      <path className="st0" d="M1166.3,55.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,57.5,1168.5,55.8,1166.3,55.8z"/>
      <path className="st0" d="M1156.9,55.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1160.8,57.5,1159.1,55.8,1156.9,55.8z"/>
      <path className="st0" d="M1147.8,63.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1144,61.9,1145.6,63.5,1147.8,63.5z"/>
      <path className="st0" d="M1138.5,63.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1134.6,61.9,1136.3,63.5,1138.5,63.5z"/>
      <path className="st0" d="M1129.4,63.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C1125.5,61.9,1127.2,63.5,1129.4,63.5z"/>
      <path className="st0" d="M1111,63.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C1107.1,61.9,1108.8,63.5,1111,63.5z
        "/>
      <path className="st0" d="M1097.8,59.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C1099.7,55.8,1097.8,57.5,1097.8,59.7z"/>
      <path className="st0" d="M1092.5,55.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1096.4,57.5,1094.7,55.8,1092.5,55.8z"/>
      <path className="st0" d="M1083.2,55.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1087.3,57.5,1085.4,55.8,1083.2,55.8z"/>
      <path className="st0" d="M1074.1,55.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1077.9,57.5,1076.3,55.8,1074.1,55.8z"/>
      <path className="st0" d="M1065,55.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C1068.9,57.5,1066.9,55.8,1065,55.8z
        "/>
      <path className="st0" d="M1055.6,55.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1059.5,57.5,1057.9,55.8,1055.6,55.8z"/>
      <path className="st0" d="M1046.6,55.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1050.4,57.5,1048.5,55.8,1046.6,55.8z"/>
      <path className="st0" d="M1037.2,55.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1041.1,57.5,1039.4,55.8,1037.2,55.8z"/>
      <path className="st0" d="M1028.1,55.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1032,57.5,1030.1,55.8,1028.1,55.8z"/>
      <path className="st0" d="M1018.8,55.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1022.6,57.5,1021,55.8,1018.8,55.8z"/>
      <path className="st0" d="M1009.7,55.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1013.5,57.5,1011.9,55.8,1009.7,55.8z"/>
      <path className="st0" d="M1000.3,55.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1004.2,57.5,1002.5,55.8,1000.3,55.8z"/>
      <path className="st0" d="M991.3,55.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C995.1,57.5,993.5,55.8,991.3,55.8z"/>
      <path className="st0" d="M981.9,55.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C986,57.5,984.1,55.8,981.9,55.8z"/>
      <path className="st0" d="M969,59.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S969,57.5,969,59.7z"/>
      <path className="st0" d="M959.6,59.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C961.5,55.8,959.6,57.5,959.6,59.7z"/>
      <path className="st0" d="M950.5,59.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C952.2,55.8,950.5,57.5,950.5,59.7z"/>
      <path className="st0" d="M941.2,59.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C943.1,55.8,941.2,57.5,941.2,59.7z"/>
      <path className="st0" d="M932.1,59.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C933.7,55.8,932.1,57.5,932.1,59.7z"/>
      <path className="st0" d="M922.7,59.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C924.7,55.8,922.7,57.5,922.7,59.7z"
        />
      <path className="st0" d="M913.7,59.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C915.3,55.8,913.7,57.5,913.7,59.7z"/>
      <path className="st0" d="M904.3,59.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C906,55.8,904.3,57.5,904.3,59.7z"/>
      <path className="st0" d="M899.1,63.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C895.2,61.9,896.9,63.5,899.1,63.5z"/>
      <path className="st0" d="M890,63.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C885.9,61.9,887.8,63.5,890,63.5z"/>
      <path className="st0" d="M880.6,63.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C876.8,61.9,878.4,63.5,880.6,63.5z"/>
      <path className="st0" d="M871.6,63.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C867.4,61.9,869.4,63.5,871.6,63.5z"/>
      <path className="st0" d="M858.4,59.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C860,55.8,858.4,57.5,858.4,59.7z"/>
      <path className="st0" d="M811,59.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C809.1,63.5,811,61.9,811,59.7z
        "/>
      <path className="st0" d="M797.8,55.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C801.7,57.5,800,55.8,797.8,55.8z"/>
      <path className="st0" d="M788.5,63.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C784.6,61.9,786.5,63.5,788.5,63.5z"/>
      <path className="st0" d="M783.2,59.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C781.6,63.5,783.2,61.9,783.2,59.7z"/>
      <path className="st0" d="M774.2,59.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C772.2,63.5,774.2,61.9,774.2,59.7z
        "/>
      <path className="st0" d="M764.8,59.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C763.1,63.5,764.8,61.9,764.8,59.7z"/>
      <path className="st0" d="M755.7,59.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C753.8,63.5,755.7,61.9,755.7,59.7z"/>
      <path className="st0" d="M746.4,59.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C744.7,63.5,746.4,61.9,746.4,59.7z"/>
      <path className="st0" d="M737.3,59.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C735.4,63.5,737.3,61.9,737.3,59.7z"/>
      <path className="st0" d="M727.9,59.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S727.9,61.9,727.9,59.7z"/>
      <path className="st0" d="M718.8,59.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C717.2,63.5,718.8,61.9,718.8,59.7z"/>
      <path className="st0" d="M701.8,59.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C703.4,55.8,701.8,57.5,701.8,59.7z"/>
      <path className="st0" d="M692.7,59.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C694.4,55.8,692.7,57.5,692.7,59.7z"/>
      <path className="st0" d="M683.3,59.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S683.3,57.5,683.3,59.7z"/>
      <path className="st0" d="M674.3,59.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C675.9,55.8,674.3,57.5,674.3,59.7z"
        />
      <path className="st0" d="M664.9,59.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S664.9,57.5,664.9,59.7z"/>
      <path className="st0" d="M655.8,59.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C657.5,55.8,655.8,57.5,655.8,59.7z"/>
      <path className="st0" d="M646.5,59.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C648.4,55.8,646.5,57.5,646.5,59.7z"/>
      <path className="st0" d="M641.2,55.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C645.1,57.5,643.4,55.8,641.2,55.8z
        "/>
      <path className="st0" d="M628,59.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C630,55.8,628,57.5,628,59.7z"/>
      <path className="st0" d="M622.8,63.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C619,61.9,620.6,63.5,622.8,63.5z"/>
      <path className="st0" d="M134.7,55.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C138.5,57.5,136.9,55.8,134.7,55.8z"/>
      <path className="st0" d="M129.4,59.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C127.5,63.5,129.4,61.9,129.4,59.7z"/>
      <path className="st0" d="M116.2,55.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C120.1,57.5,118.4,55.8,116.2,55.8z"/>
      <path className="st0" d="M107.1,55.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C111,57.5,109.3,55.8,107.1,55.8z"
        />
      <path className="st0" d="M97.8,63.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C93.9,61.9,95.6,63.5,97.8,63.5z"/>
      <path className="st0" d="M88.7,63.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C84.9,61.9,86.5,63.5,88.7,63.5z"/>
      <path className="st0" d="M79.3,63.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C75.5,61.9,77.1,63.5,79.3,63.5z"/>
      <path className="st0" d="M70.3,63.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C66.4,61.9,68.1,63.5,70.3,63.5z"/>
      <path className="st0" d="M65,59.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C63.1,63.5,65,61.9,65,59.7z"/>
      <path className="st0" d="M51.8,55.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C55.7,57.5,54,55.8,51.8,55.8z"/>
      <path className="st0" d="M42.5,55.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C46.6,57.5,44.7,55.8,42.5,55.8z"/>
      <path className="st0" d="M33.4,55.8c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C37.2,57.5,35.6,55.8,33.4,55.8z"/>
      <path className="st0" d="M1171.5,50.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S1171.5,48.4,1171.5,50.6z"/>
      <path className="st0" d="M1166.3,46.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1168.5,46.7,1166.3,46.7z"/>
      <path className="st0" d="M1156.9,46.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1159.1,46.7,1156.9,46.7z"/>
      <path className="st0" d="M1147.8,46.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1151.7,48.4,1150,46.7,1147.8,46.7z"/>
      <path className="st0" d="M1138.5,54.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1136.3,54.4,1138.5,54.4z"/>
      <path className="st0" d="M1129.4,54.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1127.2,54.4,1129.4,54.4z"/>
      <path className="st0" d="M1092.5,54.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1090.3,54.4,1092.5,54.4z"/>
      <path className="st0" d="M1083.2,46.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C1087.3,48.4,1085.4,46.7,1083.2,46.7z"
        />
      <path className="st0" d="M1074.1,46.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1076.3,46.7,1074.1,46.7z"/>
      <path className="st0" d="M1065,46.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1066.9,46.7,1065,46.7z"/>
      <path className="st0" d="M1055.6,46.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1057.9,46.7,1055.6,46.7z"/>
      <path className="st0" d="M1046.6,46.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1048.5,46.7,1046.6,46.7z"/>
      <path className="st0" d="M1037.2,46.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1039.4,46.7,1037.2,46.7z"/>
      <path className="st0" d="M1028.1,46.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1032,48.4,1030.1,46.7,1028.1,46.7z"/>
      <path className="st0" d="M1018.8,46.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1021,46.7,1018.8,46.7z"/>
      <path className="st0" d="M1009.7,46.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1011.9,46.7,1009.7,46.7z"/>
      <path className="st0" d="M1000.3,46.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1002.5,46.7,1000.3,46.7z"/>
      <path className="st0" d="M991.3,46.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S993.5,46.7,991.3,46.7z"/>
      <path className="st0" d="M981.9,46.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C986,48.4,984.1,46.7,981.9,46.7z"
        />
      <path className="st0" d="M969,50.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S969,48.4,969,50.6z"/>
      <path className="st0" d="M959.6,50.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C961.5,46.7,959.6,48.4,959.6,50.6z
        "/>
      <path className="st0" d="M950.5,50.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C952.2,46.7,950.5,48.4,950.5,50.6z
        "/>
      <path className="st0" d="M941.2,50.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C943.1,46.7,941.2,48.4,941.2,50.6z
        "/>
      <path className="st0" d="M932.1,50.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C933.7,46.7,932.1,48.4,932.1,50.6z
        "/>
      <path className="st0" d="M922.7,50.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C924.7,46.7,922.7,48.4,922.7,50.6z"/>
      <path className="st0" d="M913.7,50.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C915.3,46.7,913.7,48.4,913.7,50.6z
        "/>
      <path className="st0" d="M904.3,50.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C906,46.7,904.3,48.4,904.3,50.6z"
        />
      <path className="st0" d="M899.1,46.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S901.3,46.7,899.1,46.7z"/>
      <path className="st0" d="M885.9,50.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C887.8,46.7,885.9,48.4,885.9,50.6z
        "/>
      <path className="st0" d="M876.8,50.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C878.4,46.7,876.8,48.4,876.8,50.6z
        "/>
      <path className="st0" d="M871.6,54.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C867.4,52.8,869.4,54.4,871.6,54.4
        z"/>
      <path className="st0" d="M820.1,50.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C818.5,54.4,820.1,52.8,820.1,50.6z
        "/>
      <path className="st0" d="M806.9,46.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C811,48.4,809.1,46.7,806.9,46.7z"/>
      <path className="st0" d="M801.7,50.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C800,54.4,801.7,52.8,801.7,50.6z"
        />
      <path className="st0" d="M788.5,54.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C784.6,52.8,786.5,54.4,788.5,54.4
        z"/>
      <path className="st0" d="M783.2,50.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C781.6,54.4,783.2,52.8,783.2,50.6z
        "/>
      <path className="st0" d="M774.2,50.6c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C772.2,54.4,774.2,52.8,774.2,50.6z"/>
      <path className="st0" d="M764.8,50.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C763.1,54.4,764.8,52.8,764.8,50.6z
        "/>
      <path className="st0" d="M755.7,50.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C753.8,54.4,755.7,52.8,755.7,50.6z
        "/>
      <path className="st0" d="M746.4,50.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C744.7,54.4,746.4,52.8,746.4,50.6z
        "/>
      <path className="st0" d="M737.3,50.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C735.4,54.4,737.3,52.8,737.3,50.6z
        "/>
      <path className="st0" d="M727.9,50.6c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S727.9,52.8,727.9,50.6z"/>
      <path className="st0" d="M710.9,50.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C712.8,46.7,710.9,48.4,710.9,50.6z
        "/>
      <path className="st0" d="M701.8,50.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C703.4,46.7,701.8,48.4,701.8,50.6z
        "/>
      <path className="st0" d="M692.7,50.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C694.4,46.7,692.7,48.4,692.7,50.6z
        "/>
      <path className="st0" d="M683.3,50.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C685,46.7,683.3,48.4,683.3,50.6z"
        />
      <path className="st0" d="M674.3,50.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C675.9,46.7,674.3,48.4,674.3,50.6z"/>
      <path className="st0" d="M664.9,50.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C666.6,46.7,664.9,48.4,664.9,50.6z
        "/>
      <path className="st0" d="M655.8,50.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C657.5,46.7,655.8,48.4,655.8,50.6z
        "/>
      <path className="st0" d="M646.5,50.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C648.4,46.7,646.5,48.4,646.5,50.6z
        "/>
      <path className="st0" d="M641.2,46.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C645.1,48.4,643.4,46.7,641.2,46.7z"/>
      <path className="st0" d="M631.9,54.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C628,52.8,630,54.4,631.9,54.4z"/>
      <path className="st0" d="M619,50.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C620.6,46.7,619,48.4,619,50.6z"/>
      <path className="st0" d="M147.9,50.6c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C145.9,54.4,147.9,52.8,147.9,50.6z"/>
      <path className="st0" d="M134.7,46.7c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S136.9,46.7,134.7,46.7z"/>
      <path className="st0" d="M125.6,54.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C121.5,52.8,123.4,54.4,125.6,54.4
        z"/>
      <path className="st0" d="M116.2,54.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S114,54.4,116.2,54.4z"/>
      <path className="st0" d="M111,50.6c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S111,52.8,111,50.6z"/>
      <path className="st0" d="M97.8,54.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S95.6,54.4,97.8,54.4z"/>
      <path className="st0" d="M88.7,54.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S86.5,54.4,88.7,54.4z"/>
      <path className="st0" d="M79.3,54.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S77.1,54.4,79.3,54.4z"/>
      <path className="st0" d="M70.3,54.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S68.1,54.4,70.3,54.4z"/>
      <path className="st0" d="M60.9,54.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S59,54.4,60.9,54.4z"/>
      <path className="st0" d="M51.8,54.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S48,48.4,48,50.6S49.6,54.4,51.8,54.4z"/>
      <path className="st0" d="M38.6,50.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C40.6,46.7,38.6,48.4,38.6,50.6z"/>
      <ellipse className="st0" cx="1175.3" cy="41.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1166.3,37.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1170.1,39,1168.5,37.4,1166.3,37.4z"/>
      <path className="st0" d="M1156.9,37.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1160.8,39,1159.1,37.4,1156.9,37.4z"/>
      <ellipse className="st0" cx="1147.8" cy="41.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1138.5,37.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1142.3,39,1140.7,37.4,1138.5,37.4z"/>
      <path className="st0" d="M1129.4,45.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1127.2,45.4,1129.4,45.4z"/>
      <path className="st0" d="M1083.2,45.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1081.2,45.4,1083.2,45.4z"/>
      <ellipse className="st0" cx="1074.1" cy="41.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1065,37.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C1068.9,39,1066.9,37.4,1065,37.4z"
        />
      <path className="st0" d="M1055.6,37.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1059.5,39,1057.9,37.4,1055.6,37.4z"/>
      <ellipse className="st0" cx="1046.6" cy="41.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1037.2,37.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C1041.1,39,1039.4,37.4,1037.2,37.4z"/>
      <path className="st0" d="M1028.1,37.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1032,39,1030.1,37.4,1028.1,37.4z"/>
      <path className="st0" d="M1018.8,37.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1022.6,39,1021,37.4,1018.8,37.4z"/>
      <ellipse className="st0" cx="1009.7" cy="41.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1000.3,37.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1004.2,39,1002.5,37.4,1000.3,37.4z"/>
      <path className="st0" d="M991.3,37.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C995.1,39,993.5,37.4,991.3,37.4z"/>
      <path className="st0" d="M981.9,37.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C985.8,39,984.1,37.4,981.9,37.4z"/>
      <ellipse className="st0" cx="972.8" cy="41.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M959.6,41.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C961.5,37.4,959.6,39.3,959.6,41.2z"/>
      <path className="st0" d="M950.5,41.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C952.2,37.4,950.5,39.3,950.5,41.2z"/>
      <path className="st0" d="M941.2,41.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C943.1,37.4,941.2,39.3,941.2,41.2z"/>
      <ellipse className="st0" cx="936" cy="41.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M922.7,41.2c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C924.7,37.4,922.7,39.3,922.7,41.2z"
        />
      <path className="st0" d="M913.7,41.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C915.3,37.4,913.7,39.3,913.7,41.2z"/>
      <ellipse className="st0" cx="908.4" cy="41.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M899.1,37.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C902.9,39,901.3,37.4,899.1,37.4z"/>
      <path className="st0" d="M885.9,41.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C887.8,37.4,885.9,39.3,885.9,41.2z"/>
      <path className="st0" d="M880.6,45.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S878.4,45.4,880.6,45.4z"/>
      <ellipse className="st0" cx="871.6" cy="41.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M816.3,37.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C820.1,39,818.5,37.4,816.3,37.4z"/>
      <path className="st0" d="M811,41.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C809.1,45.4,811,43.4,811,41.2z
        "/>
      <ellipse className="st0" cx="797.8" cy="41.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M788.5,45.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S786.5,45.4,788.5,45.4z"/>
      <path className="st0" d="M783.2,41.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C781.6,45.4,783.2,43.4,783.2,41.2z"/>
      <path className="st0" d="M774.2,41.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C772.2,45.4,774.2,43.4,774.2,41.2z
        "/>
      <ellipse className="st0" cx="760.9" cy="41.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M755.7,41.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C753.8,45.4,755.7,43.4,755.7,41.2z"/>
      <path className="st0" d="M746.4,41.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C744.7,45.4,746.4,43.4,746.4,41.2z"/>
      <path className="st0" d="M737.3,41.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C735.4,45.4,737.3,43.4,737.3,41.2z"/>
      <ellipse className="st0" cx="724.1" cy="41.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M710.9,41.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C712.8,37.4,710.9,39.3,710.9,41.2z"/>
      <path className="st0" d="M701.8,41.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C703.4,37.4,701.8,39.3,701.8,41.2z"/>
      <ellipse className="st0" cx="696.6" cy="41.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M683.3,41.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9S683.3,39.3,683.3,41.2z"/>
      <path className="st0" d="M674.3,41.2c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C675.9,37.4,674.3,39.3,674.3,41.2z"
        />
      <path className="st0" d="M668.8,45.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S666.6,45.4,668.8,45.4z"/>
      <ellipse className="st0" cx="659.7" cy="41.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M650.3,45.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S648.4,45.4,650.3,45.4z"/>
      <ellipse className="st0" cx="641.2" cy="41.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M631.9,45.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S630,45.4,631.9,45.4z"/>
      <path className="st0" d="M166.3,41.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9C164.4,45.4,166.3,43.4,166.3,41.2z
        "/>
      <ellipse className="st0" cx="153.1" cy="41.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M144,37.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C147.9,39,145.9,37.4,144,37.4z
        "/>
      <ellipse className="st0" cx="134.7" cy="41.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M125.6,45.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S123.4,45.4,125.6,45.4z"/>
      <path className="st0" d="M116.2,45.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S114,45.4,116.2,45.4z"/>
      <ellipse className="st0" cx="107.1" cy="41.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M97.8,45.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S95.6,45.4,97.8,45.4z"/>
      <ellipse className="st0" cx="88.7" cy="41.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M79.3,45.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S77.1,45.4,79.3,45.4z"/>
      <ellipse className="st0" cx="70.3" cy="41.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M60.9,45.4c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S59,45.4,60.9,45.4z"/>
      <ellipse className="st0" cx="51.8" cy="41.2" rx="3.9" ry="3.9"/>
      <path className="st0" d="M1175.3,36c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1173.1,36,1175.3,36z"/>
      <path className="st0" d="M1162.1,32.2c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C1164.1,28.3,1162.1,30,1162.1,32.2z"/>
      <path className="st0" d="M1156.9,36c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1154.7,36,1156.9,36z"/>
      <path className="st0" d="M1147.8,36c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1145.6,36,1147.8,36z"/>
      <path className="st0" d="M1138.5,36c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1136.3,36,1138.5,36z"/>
      <path className="st0" d="M1129.4,36c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S1127.2,36,1129.4,36z"/>
      <path className="st0" d="M1124.2,32.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9
        C1122.2,36,1124.2,34.4,1124.2,32.2z"/>
      <path className="st0" d="M1114.8,32.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S1114.8,34.4,1114.8,32.2z"/>
      <path className="st0" d="M1065,28.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1066.9,28.3,1065,28.3z"/>
      <path className="st0" d="M1055.6,28.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1057.9,28.3,1055.6,28.3z"/>
      <path className="st0" d="M1046.6,28.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1048.5,28.3,1046.6,28.3z"/>
      <path className="st0" d="M1037.2,28.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1039.4,28.3,1037.2,28.3z"/>
      <path className="st0" d="M1028.1,28.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C1032,30,1030.1,28.3,1028.1,28.3z
        "/>
      <path className="st0" d="M1018.8,28.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1021,28.3,1018.8,28.3z"/>
      <path className="st0" d="M1009.7,28.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1011.9,28.3,1009.7,28.3z"/>
      <path className="st0" d="M1000.3,28.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1002.5,28.3,1000.3,28.3z"/>
      <path className="st0" d="M991.3,28.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S993.5,28.3,991.3,28.3z"/>
      <path className="st0" d="M978.1,32.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C979.7,28.3,978.1,30,978.1,32.2z"
        />
      <path className="st0" d="M969,32.2c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S969,30,969,32.2z"/>
      <path className="st0" d="M959.6,32.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C961.5,28.3,959.6,30,959.6,32.2z"
        />
      <path className="st0" d="M950.5,32.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C952.2,28.3,950.5,30,950.5,32.2z"
        />
      <path className="st0" d="M941.2,32.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C943.1,28.3,941.2,30,941.2,32.2z"
        />
      <path className="st0" d="M932.1,32.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C933.7,28.3,932.1,30,932.1,32.2z"
        />
      <path className="st0" d="M922.7,32.2c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C924.7,28.3,922.7,30,922.7,32.2z"/>
      <path className="st0" d="M913.7,32.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C915.3,28.3,913.7,30,913.7,32.2z"
        />
      <path className="st0" d="M904.3,32.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C906,28.3,904.3,30,904.3,32.2z"/>
      <path className="st0" d="M899.1,28.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S901.3,28.3,899.1,28.3z"/>
      <path className="st0" d="M890,36c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C885.9,34.4,887.8,36,890,36z"/>
      <path className="st0" d="M880.6,36c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C876.8,34.4,878.4,36,880.6,36z"/>
      <path className="st0" d="M821.5,32.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C823.1,28.3,821.5,30,821.5,32.2z"
        />
      <path className="st0" d="M816.3,28.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C820.1,30,818.5,28.3,816.3,28.3z"
        />
      <path className="st0" d="M806.9,28.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C811,30,809.1,28.3,806.9,28.3z"/>
      <path className="st0" d="M797.8,28.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S800,28.3,797.8,28.3z"/>
      <path className="st0" d="M788.5,28.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C792.6,30,790.7,28.3,788.5,28.3z"
        />
      <path className="st0" d="M783.2,32.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C781.6,36,783.2,34.4,783.2,32.2z"
        />
      <path className="st0" d="M774.2,32.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C772.2,36,774.2,34.4,774.2,32.2z"/>
      <path className="st0" d="M764.8,32.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C763.1,36,764.8,34.4,764.8,32.2z"
        />
      <path className="st0" d="M755.7,32.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C753.8,36,755.7,34.4,755.7,32.2z"
        />
      <path className="st0" d="M746.4,32.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C744.7,36,746.4,34.4,746.4,32.2z"
        />
      <path className="st0" d="M729.3,32.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C731.2,28.3,729.3,30,729.3,32.2z"
        />
      <path className="st0" d="M720.2,32.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C721.9,28.3,720.2,30,720.2,32.2z"
        />
      <path className="st0" d="M710.9,32.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C712.8,28.3,710.9,30,710.9,32.2z"
        />
      <path className="st0" d="M701.8,32.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C703.4,28.3,701.8,30,701.8,32.2z"
        />
      <path className="st0" d="M692.7,32.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C694.4,28.3,692.7,30,692.7,32.2z"
        />
      <path className="st0" d="M683.3,32.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S683.3,30,683.3,32.2z"/>
      <path className="st0" d="M674.3,32.2c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C675.9,28.3,674.3,30,674.3,32.2z"/>
      <path className="st0" d="M668.8,28.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C672.9,30,671,28.3,668.8,28.3z"/>
      <path className="st0" d="M655.8,32.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C657.5,28.3,655.8,30,655.8,32.2z"
        />
      <path className="st0" d="M650.3,36c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S648.4,36,650.3,36z"/>
      <path className="st0" d="M171.5,28.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S173.7,28.3,171.5,28.3z"/>
      <path className="st0" d="M162.5,28.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S164.4,28.3,162.5,28.3z"/>
      <path className="st0" d="M153.1,36c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C149.2,34.4,150.9,36,153.1,36z"/>
      <path className="st0" d="M147.9,32.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C145.9,36,147.9,34.4,147.9,32.2z"/>
      <path className="st0" d="M138.5,32.2c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C136.9,36,138.5,34.4,138.5,32.2z"
        />
      <path className="st0" d="M125.6,36c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C121.5,34.4,123.4,36,125.6,36z"/>
      <path className="st0" d="M116.2,36c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S114,36,116.2,36z"/>
      <path className="st0" d="M111,32.2c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S111,34.4,111,32.2z"/>
      <path className="st0" d="M97.8,36c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S95.6,36,97.8,36z"/>
      <path className="st0" d="M88.7,36c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S86.5,36,88.7,36z"/>
      <path className="st0" d="M79.3,36c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S77.1,36,79.3,36z"/>
      <path className="st0" d="M70.3,36c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S68.1,36,70.3,36z"/>
      <path className="st0" d="M60.9,36c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S59,36,60.9,36z"/>
      <path className="st0" d="M51.8,36c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S48,30,48,32.2S49.6,36,51.8,36z"/>
      <path className="st0" d="M42.5,28.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C46.6,30,44.7,28.3,42.5,28.3z"/>
      <path className="st0" d="M33.4,28.3c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C37.2,30,35.6,28.3,33.4,28.3z"/>
      <path className="st0" d="M1175.3,26.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C1171.5,25,1173.1,26.9,1175.3,26.9z"/>
      <path className="st0" d="M1134.6,23.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C1136.3,18.9,1134.6,20.9,1134.6,23.1z"/>
      <path className="st0" d="M1120,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C1124.2,20.9,1122.2,18.9,1120,18.9z
        "/>
      <path className="st0" d="M1111,26.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C1107.1,25,1108.8,26.9,1111,26.9z"/>
      <path className="st0" d="M1101.6,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1105.7,20.9,1103.8,18.9,1101.6,18.9z"/>
      <path className="st0" d="M1065,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C1068.9,20.9,1066.9,18.9,1065,18.9z"/>
      <path className="st0" d="M1055.6,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C1059.5,20.9,1057.9,18.9,1055.6,18.9z"
        />
      <path className="st0" d="M1046.6,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1050.4,20.9,1048.5,18.9,1046.6,18.9z"/>
      <path className="st0" d="M1037.2,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C1041.1,20.9,1039.4,18.9,1037.2,18.9z"
        />
      <path className="st0" d="M1028.1,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1032,20.9,1030.1,18.9,1028.1,18.9z"/>
      <path className="st0" d="M1018.8,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1022.6,20.9,1021,18.9,1018.8,18.9z"/>
      <path className="st0" d="M1009.7,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C1013.5,20.9,1011.9,18.9,1009.7,18.9z"
        />
      <path className="st0" d="M1000.3,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C1004.2,20.9,1002.5,18.9,1000.3,18.9z"/>
      <path className="st0" d="M991.3,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C995.1,20.9,993.5,18.9,991.3,18.9z
        "/>
      <path className="st0" d="M981.9,26.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C978.1,25,979.7,26.9,981.9,26.9z"
        />
      <path className="st0" d="M972.8,26.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C969,25,970.6,26.9,972.8,26.9z"/>
      <path className="st0" d="M963.5,26.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C959.6,25,961.5,26.9,963.5,26.9z"
        />
      <path className="st0" d="M954.4,26.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C950.5,25,952.2,26.9,954.4,26.9z"
        />
      <path className="st0" d="M945,26.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C941.2,25,943.1,26.9,945,26.9z"/>
      <path className="st0" d="M936,26.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C932.1,25,933.7,26.9,936,26.9z"/>
      <path className="st0" d="M926.6,26.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C922.7,25,924.7,26.9,926.6,26.9z"/>
      <path className="st0" d="M917.5,26.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C913.7,25,915.3,26.9,917.5,26.9z"
        />
      <path className="st0" d="M908.4,26.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C904.3,25,906.2,26.9,908.4,26.9z"
        />
      <path className="st0" d="M895.2,23.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C896.9,18.9,895.2,20.9,895.2,23.1z
        "/>
      <path className="st0" d="M885.9,23.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C887.8,18.9,885.9,20.9,885.9,23.1z
        "/>
      <path className="st0" d="M816.3,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C820.1,20.9,818.5,18.9,816.3,18.9z
        "/>
      <path className="st0" d="M806.9,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C811,20.9,809.1,18.9,806.9,18.9z"/>
      <path className="st0" d="M797.8,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C801.7,20.9,800,18.9,797.8,18.9z"
        />
      <path className="st0" d="M788.5,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C792.6,20.9,790.7,18.9,788.5,18.9z
        "/>
      <path className="st0" d="M779.4,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C783.2,20.9,781.6,18.9,779.4,18.9z
        "/>
      <path className="st0" d="M770.3,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C774.2,20.9,772.2,18.9,770.3,18.9z"/>
      <path className="st0" d="M760.9,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C764.8,20.9,763.1,18.9,760.9,18.9z
        "/>
      <path className="st0" d="M751.9,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C755.7,20.9,753.8,18.9,751.9,18.9z
        "/>
      <path className="st0" d="M742.5,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C746.4,20.9,744.7,18.9,742.5,18.9z
        "/>
      <path className="st0" d="M729.3,23.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C731.2,18.9,729.3,20.9,729.3,23.1z
        "/>
      <path className="st0" d="M720.2,23.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C721.9,19.2,720.2,20.9,720.2,23.1z
        "/>
      <path className="st0" d="M710.9,23.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C712.8,18.9,710.9,20.9,710.9,23.1z
        "/>
      <path className="st0" d="M701.8,23.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C703.4,18.9,701.8,20.9,701.8,23.1z
        "/>
      <path className="st0" d="M692.7,23.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C694.4,19.2,692.7,20.9,692.7,23.1z
        "/>
      <path className="st0" d="M683.3,23.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C685,18.9,683.3,20.9,683.3,23.1z"
        />
      <path className="st0" d="M674.3,23.1c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C675.9,18.9,674.3,20.9,674.3,23.1z"/>
      <path className="st0" d="M668.8,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C672.9,20.9,671,18.9,668.8,18.9z"
        />
      <path className="st0" d="M659.7,26.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C655.8,25,657.5,26.9,659.7,26.9z"
        />
      <path className="st0" d="M650.3,26.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C646.5,25,648.4,26.9,650.3,26.9z"/>
      <path className="st0" d="M184.7,23.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C182.8,26.9,184.7,25,184.7,23.1z"
        />
      <path className="st0" d="M171.5,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C175.4,20.9,173.7,18.9,171.5,18.9z
        "/>
      <path className="st0" d="M166.3,23.1c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C164.4,26.9,166.3,25,166.3,23.1z"/>
      <path className="st0" d="M153.1,26.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C149.2,25,150.9,26.9,153.1,26.9z"
        />
      <path className="st0" d="M147.9,23.1c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C145.9,26.9,147.9,25,147.9,23.1z"/>
      <path className="st0" d="M138.5,23.1c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C136.9,26.9,138.5,25,138.5,23.1z"
        />
      <path className="st0" d="M125.6,26.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C121.5,25,123.4,26.9,125.6,26.9z"
        />
      <path className="st0" d="M112.4,23.1c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C114,18.9,112.4,20.9,112.4,23.1z"
        />
      <path className="st0" d="M111,23.1c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9S111,25,111,23.1z"/>
      <path className="st0" d="M97.8,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C101.6,20.9,100,18.9,97.8,18.9z"/>
      <path className="st0" d="M88.7,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C92.6,20.9,90.9,18.9,88.7,18.9z"/>
      <path className="st0" d="M79.3,26.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C75.5,25,77.1,26.9,79.3,26.9z"/>
      <path className="st0" d="M70.3,26.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C66.4,25,68.1,26.9,70.3,26.9z"/>
      <path className="st0" d="M60.9,26.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C57.1,25,59,26.9,60.9,26.9z"/>
      <path className="st0" d="M51.8,26.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S48,20.9,48,23.1C48,25,49.6,26.9,51.8,26.9z"/>
      <path className="st0" d="M42.5,26.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C38.6,25,40.6,26.9,42.5,26.9z"/>
      <path className="st0" d="M33.4,26.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C29.5,25,31.2,26.9,33.4,26.9z"/>
      <path className="st0" d="M28.2,23.1c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C26.2,26.9,28.2,25,28.2,23.1z"/>
      <path className="st0" d="M15,18.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C18.8,20.9,17.2,18.9,15,18.9z"/>
      <path className="st0" d="M9.7,23.1c0-2.2-1.7-3.9-3.9-3.9S2,20.9,2,23.1s1.7,3.9,3.9,3.9C7.8,26.9,9.7,25,9.7,23.1z"/>
      <path className="st0" d="M1175.3,17.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1173.1,17.6,1175.3,17.6z"/>
      <path className="st0" d="M1120,17.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C1116.2,15.9,1117.8,17.6,1120,17.6
        z"/>
      <path className="st0" d="M1111,17.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1108.8,17.6,1111,17.6z"/>
      <path className="st0" d="M1065,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1066.9,9.9,1065,9.9z"/>
      <path className="st0" d="M1055.6,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1057.9,9.9,1055.6,9.9z"/>
      <path className="st0" d="M1046.6,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1048.5,9.9,1046.6,9.9z"/>
      <path className="st0" d="M1037.2,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1039.4,9.9,1037.2,9.9z"/>
      <path className="st0" d="M1028.1,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C1032,11.5,1030.1,9.9,1028.1,9.9z"
        />
      <path className="st0" d="M1018.8,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1021,9.9,1018.8,9.9z"/>
      <path className="st0" d="M1009.7,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1011.9,9.9,1009.7,9.9z"/>
      <path className="st0" d="M1000.3,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1002.5,9.9,1000.3,9.9z"/>
      <path className="st0" d="M991.3,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S993.5,9.9,991.3,9.9z"/>
      <path className="st0" d="M981.9,17.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S979.7,17.6,981.9,17.6z"/>
      <path className="st0" d="M972.8,17.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C969,15.9,970.6,17.6,972.8,17.6z"
        />
      <path className="st0" d="M963.5,17.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S961.5,17.6,963.5,17.6z"/>
      <path className="st0" d="M954.4,17.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S952.2,17.6,954.4,17.6z"/>
      <path className="st0" d="M945,17.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S943.1,17.6,945,17.6z"/>
      <path className="st0" d="M936,17.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S933.7,17.6,936,17.6z"/>
      <path className="st0" d="M926.6,17.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S924.7,17.6,926.6,17.6z"/>
      <path className="st0" d="M917.5,17.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S915.3,17.6,917.5,17.6z"/>
      <path className="st0" d="M908.4,17.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C904.3,15.9,906.2,17.6,908.4,17.6
        z"/>
      <path className="st0" d="M825.3,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C829.5,11.5,827.5,9.9,825.3,9.9z"/>
      <path className="st0" d="M816.3,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C820.1,11.5,818.5,9.9,816.3,9.9z"/>
      <path className="st0" d="M806.9,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C811,11.5,809.1,9.9,806.9,9.9z"/>
      <path className="st0" d="M797.8,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S800,9.9,797.8,9.9z"/>
      <path className="st0" d="M788.5,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C792.6,11.5,790.7,9.9,788.5,9.9z"/>
      <path className="st0" d="M779.4,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S781.6,9.9,779.4,9.9z"/>
      <path className="st0" d="M770.3,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S772.2,9.9,770.3,9.9z"/>
      <path className="st0" d="M760.9,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S763.1,9.9,760.9,9.9z"/>
      <path className="st0" d="M751.9,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S753.8,9.9,751.9,9.9z"/>
      <path className="st0" d="M742.5,17.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S740.3,17.6,742.5,17.6z"/>
      <path className="st0" d="M733.4,17.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C729.3,15.9,731.2,17.6,733.4,17.6
        z"/>
      <path className="st0" d="M724.1,17.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S721.9,17.6,724.1,17.6z"/>
      <path className="st0" d="M715,17.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C710.9,15.9,712.8,17.6,715,17.6z"/>
      <path className="st0" d="M705.6,17.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S703.4,17.6,705.6,17.6z"/>
      <path className="st0" d="M696.6,17.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S694.4,17.6,696.6,17.6z"/>
      <path className="st0" d="M687.2,17.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S685,17.6,687.2,17.6z"/>
      <path className="st0" d="M678.1,17.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S675.9,17.6,678.1,17.6z"/>
      <path className="st0" d="M664.9,13.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S664.9,11.5,664.9,13.7z"/>
      <path className="st0" d="M659.7,17.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S657.5,17.6,659.7,17.6z"/>
      <path className="st0" d="M180.6,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C184.7,11.5,182.8,9.9,180.6,9.9z"/>
      <path className="st0" d="M175.4,13.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C173.7,17.6,175.4,15.9,175.4,13.7z
        "/>
      <path className="st0" d="M166.3,13.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C164.4,17.6,166.3,15.9,166.3,13.7z"/>
      <path className="st0" d="M153.1,17.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C149.2,15.9,150.9,17.6,153.1,17.6
        z"/>
      <path className="st0" d="M147.9,13.7c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C145.9,17.6,147.9,15.9,147.9,13.7z"/>
      <path className="st0" d="M138.5,13.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C136.9,17.6,138.5,15.9,138.5,13.7z
        "/>
      <path className="st0" d="M121.5,13.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C123.4,9.9,121.5,11.5,121.5,13.7z"
        />
      <path className="st0" d="M112.4,13.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S112.4,11.5,112.4,13.7z"/>
      <path className="st0" d="M107.1,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S109.3,9.9,107.1,9.9z"/>
      <path className="st0" d="M97.8,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C101.6,11.5,100,9.9,97.8,9.9z"/>
      <path className="st0" d="M88.7,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C92.6,11.5,90.9,9.9,88.7,9.9z"/>
      <path className="st0" d="M79.3,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C83.5,11.5,81.6,9.9,79.3,9.9z"/>
      <path className="st0" d="M66.4,13.7c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C68.1,9.9,66.4,11.5,66.4,13.7z"/>
      <path className="st0" d="M57.1,13.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C59,9.9,57.1,11.5,57.1,13.7z"/>
      <path className="st0" d="M51.8,9.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S54,9.9,51.8,9.9z"/>
      <path className="st0" d="M38.6,13.7c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C40.6,9.9,38.6,11.5,38.6,13.7z"/>
      <path className="st0" d="M33.4,17.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S31.2,17.6,33.4,17.6z"/>
      <path className="st0" d="M24,17.6c2.2,0,3.9-1.7,3.9-3.9S26.2,9.9,24,9.9c-2.2,0-3.9,1.7-3.9,3.9S22.1,17.6,24,17.6z"/>
      <path className="st0" d="M1175.3,8.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1173.1,8.5,1175.3,8.5z"/>
      <path className="st0" d="M1166.3,0.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1168.5,0.8,1166.3,0.8z"/>
      <path className="st0" d="M1111,8.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S1108.8,8.5,1111,8.5z"/>
      <path className="st0" d="M1060.9,4.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C1062.8,0.8,1060.9,2.4,1060.9,4.6z"/>
      <path className="st0" d="M1055.6,0.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1057.9,0.8,1055.6,0.8z"/>
      <path className="st0" d="M1046.6,0.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1048.5,0.8,1046.6,0.8z"/>
      <path className="st0" d="M1037.2,0.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1039.4,0.8,1037.2,0.8z"/>
      <path className="st0" d="M1028.1,0.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C1032,2.4,1030.1,0.8,1028.1,0.8z"
        />
      <path className="st0" d="M1018.8,0.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1021,0.8,1018.8,0.8z"/>
      <path className="st0" d="M1009.7,0.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S1011.9,0.8,1009.7,0.8z"/>
      <path className="st0" d="M1000.3,0.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S1002.5,0.8,1000.3,0.8z"/>
      <path className="st0" d="M991.3,0.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S993.5,0.8,991.3,0.8z"/>
      <path className="st0" d="M981.9,8.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S979.7,8.5,981.9,8.5z"/>
      <path className="st0" d="M972.8,8.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C969,6.8,970.6,8.5,972.8,8.5z"/>
      <path className="st0" d="M963.5,8.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S961.5,8.5,963.5,8.5z"/>
      <path className="st0" d="M954.4,8.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S952.2,8.5,954.4,8.5z"/>
      <path className="st0" d="M945,8.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S943.1,8.5,945,8.5z"/>
      <path className="st0" d="M936,8.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S933.7,8.5,936,8.5z"/>
      <path className="st0" d="M926.6,8.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S924.7,8.5,926.6,8.5z"/>
      <path className="st0" d="M917.5,8.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S915.3,8.5,917.5,8.5z"/>
      <path className="st0" d="M908.4,8.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C904.3,6.8,906.2,8.5,908.4,8.5z"/>
      <path className="st0" d="M899.1,0.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S901.3,0.8,899.1,0.8z"/>
      <path className="st0" d="M825.3,0.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C829.5,2.4,827.5,0.8,825.3,0.8z"/>
      <path className="st0" d="M820.1,4.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C818.5,8.5,820.1,6.8,820.1,4.6z"/>
      <path className="st0" d="M811,4.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C809.1,8.5,811,6.8,811,4.6z"/>
      <path className="st0" d="M801.7,4.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C800,8.5,801.7,6.8,801.7,4.6z"/>
      <path className="st0" d="M792.6,4.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C790.7,8.5,792.6,6.8,792.6,4.6z"/>
      <path className="st0" d="M783.2,4.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C781.6,8.5,783.2,6.8,783.2,4.6z"/>
      <path className="st0" d="M774.2,4.6c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C772.2,8.5,774.2,6.8,774.2,4.6z"/>
      <path className="st0" d="M764.8,4.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C763.1,8.5,764.8,6.8,764.8,4.6z"/>
      <path className="st0" d="M755.7,4.6c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C753.8,8.5,755.7,6.8,755.7,4.6z"/>
      <path className="st0" d="M742.5,8.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S740.3,8.5,742.5,8.5z"/>
      <path className="st0" d="M733.4,8.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C729.3,6.8,731.2,8.5,733.4,8.5z"/>
      <path className="st0" d="M724.1,8.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S721.9,8.5,724.1,8.5z"/>
      <path className="st0" d="M715,8.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C710.9,6.8,712.8,8.5,715,8.5z"/>
      <path className="st0" d="M705.6,8.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S703.4,8.5,705.6,8.5z"/>
      <path className="st0" d="M696.6,8.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S694.4,8.5,696.6,8.5z"/>
      <path className="st0" d="M687.2,8.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S685,8.5,687.2,8.5z"/>
      <path className="st0" d="M678.1,8.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S675.9,8.5,678.1,8.5z"/>
      <path className="st0" d="M190,0.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S192.2,0.8,190,0.8z"/>
      <path className="st0" d="M180.6,0.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C184.7,2.4,182.8,0.8,180.6,0.8z"/>
      <path className="st0" d="M171.5,0.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S173.7,0.8,171.5,0.8z"/>
      <path className="st0" d="M162.5,0.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S164.4,0.8,162.5,0.8z"/>
      <path className="st0" d="M153.1,0.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S155.3,0.8,153.1,0.8z"/>
      <path className="st0" d="M147.9,4.6c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C145.9,8.5,147.9,6.8,147.9,4.6z"/>
      <path className="st0" d="M134.7,0.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S136.9,0.8,134.7,0.8z"/>
      <path className="st0" d="M121.5,4.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C123.4,0.8,121.5,2.4,121.5,4.6z"/>
      <path className="st0" d="M112.4,4.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S112.4,2.4,112.4,4.6z"/>
      <path className="st0" d="M107.1,0.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S109.3,0.8,107.1,0.8z"/>
      <path className="st0" d="M97.8,0.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C101.6,2.4,100,0.8,97.8,0.8z"/>
      <path className="st0" d="M88.7,0.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C92.6,2.4,90.9,0.8,88.7,0.8z"/>
      <path className="st0" d="M79.3,0.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C83.5,2.4,81.6,0.8,79.3,0.8z"/>
      <path className="st0" d="M66.4,4.6c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C68.1,0.8,66.4,2.4,66.4,4.6z"/>
      <path className="st0" d="M57.1,4.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C59,0.8,57.1,2.4,57.1,4.6z"/>
      <path className="st0" d="M51.8,0.8c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S54,0.8,51.8,0.8z"/>
      <path className="st0" d="M42.5,8.5c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S40.6,8.5,42.5,8.5z"/>
      <path className="st0" d="M29.5,4.6c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S29.5,2.4,29.5,4.6z"/>
      <path className="st0" d="M862.2,827.4c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C866.1,829.3,864.4,827.4,862.2,827.4z"/>
      <path className="st0" d="M282.1,807.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C278,805.9,279.9,807.6,282.1,807.6z"/>
      <path className="st0" d="M374.1,753.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C378.2,755.8,376.3,753.9,374.1,753.9z"/>
      <path className="st0" d="M788.5,735.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C792.6,737.4,790.7,735.5,788.5,735.5z"/>
      <path className="st0" d="M650.3,725c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C646.5,723.4,648.4,725,650.3,725z"
        />
      <path className="st0" d="M862.2,715.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C858.4,714,860,715.9,862.2,715.9z"/>
      <path className="st0" d="M779.4,688.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C775.5,686.5,777.2,688.2,779.4,688.2z"/>
      <path className="st0" d="M825.3,660.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,659,823.1,660.6,825.3,660.6z"/>
      <path className="st0" d="M930.7,647.7c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9
        C928.8,651.6,930.7,649.9,930.7,647.7z"/>
      <path className="st0" d="M770.3,624c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C766.2,622.1,768.1,624,770.3,624z"
        />
      <path className="st0" d="M549.1,605.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S546.9,605.6,549.1,605.6z"/>
      <path className="st0" d="M742.5,596.5c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C738.7,594.9,740.3,596.5,742.5,596.5z"/>
      <path className="st0" d="M724.1,587.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C720.2,585.5,721.9,587.2,724.1,587.2z"/>
      <path className="st0" d="M471.5,574.2c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C473.4,570.1,471.5,572,471.5,574.2z"/>
      <path className="st0" d="M696.6,533.5c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C700.4,535.2,698.8,533.5,696.6,533.5z"/>
      <ellipse className="st0" cx="503.1" cy="528.3" rx="3.9" ry="3.9"/>
      <ellipse className="st0" cx="1009.7" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st0" d="M521.5,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C517.7,475.2,519.3,477.1,521.5,477.1z"
        />
      <path className="st0" d="M438.7,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C442.6,471,440.6,469.1,438.7,469.1z"/>
      <path className="st0" d="M678.1,460c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S680.3,460,678.1,460z"/>
      <path className="st0" d="M309.7,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C313.5,425.1,311.9,423.2,309.7,423.2z"/>
      <path className="st0" d="M972.8,385.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C969,383.3,970.6,385.2,972.8,385.2z"/>
      <path className="st0" d="M922.7,353.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C924.7,349.7,922.7,351.6,922.7,353.5z"
        />
      <path className="st0" d="M668.8,329.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S666.6,329.9,668.8,329.9z"/>
    </g>
    <g id="Layer_5">
      <path className="st1" d="M896.1,656.9c0,1.6,1.2,2.9,2.9,2.9s2.9-1.2,2.9-2.9c0-1.6-1.2-2.9-2.9-2.9C897.5,654,896.1,655.4,896.1,656.9
        z"/>
      <path className="st2" d="M894.5,656.9c0,2.6,1.9,4.5,4.5,4.5s4.5-1.9,4.5-4.5s-1.9-4.5-4.5-4.5C896.7,652.4,894.5,654.6,894.5,656.9z"
        />
      <path className="st1" d="M877.8,372.1c0,1.6,1.2,2.9,2.9,2.9s2.9-1.2,2.9-2.9c0-1.6-1.2-2.9-2.9-2.9
        C879.2,369.3,877.8,370.7,877.8,372.1z"/>
      <path className="st2" d="M876.2,372.1c0,2.6,1.9,4.5,4.5,4.5s4.5-1.9,4.5-4.5c0-2.6-1.9-4.5-4.5-4.5
        C878.4,367.6,876.2,369.9,876.2,372.1z"/>
      <path className="st1" d="M527.8,611c0,1.6,1.2,2.9,2.9,2.9s2.9-1.2,2.9-2.9s-1.2-2.9-2.9-2.9C529.2,608.1,527.8,609.5,527.8,611z"/>
      <path className="st2" d="M526.2,611c0,2.6,1.9,4.5,4.5,4.5s4.5-1.9,4.5-4.5s-1.9-4.5-4.5-4.5C528.4,606.5,526.2,608.7,526.2,611z"/>
      <path className="st1" d="M389.6,757.9c0,1.6,1.2,2.9,2.9,2.9s2.9-1.2,2.9-2.9s-1.2-2.9-2.9-2.9C391,755,389.6,756.5,389.6,757.9z"/>
      <path className="st2" d="M387.9,757.9c0,2.6,1.9,4.5,4.5,4.5s4.5-1.9,4.5-4.5s-1.9-4.5-4.5-4.5C390.2,753.4,387.9,755.7,387.9,757.9z"
        />
      <path className="st1" d="M693.6,307.9c0,1.6,1.2,2.9,2.9,2.9s2.9-1.2,2.9-2.9s-1.2-2.9-2.9-2.9C695,305,693.6,306.4,693.6,307.9z"/>
      <path className="st2" d="M691.9,307.9c0,2.6,1.9,4.5,4.5,4.5s4.5-1.9,4.5-4.5s-1.9-4.5-4.5-4.5C694.2,303.4,691.9,305.6,691.9,307.9z"
        />
      <path className="st1" d="M444.9,464c0,1.6,1.2,2.9,2.9,2.9s2.9-1.2,2.9-2.9c0-1.6-1.2-2.9-2.9-2.9C446.4,461.1,444.9,462.6,444.9,464z"
        />
      <path className="st2" d="M443.3,464c0,2.6,1.9,4.5,4.5,4.5s4.5-1.9,4.5-4.5c0-2.6-1.9-4.5-4.5-4.5C445.6,459.5,443.3,461.7,443.3,464z"
        />
      <path className="st1" d="M739.6,610.8c0,1.6,1.2,2.9,2.9,2.9s2.9-1.2,2.9-2.9s-1.2-2.9-2.9-2.9C741.1,607.9,739.6,609.4,739.6,610.8z"
        />
      <path className="st2" d="M738,610.8c0,2.6,1.9,4.5,4.5,4.5s4.5-1.9,4.5-4.5s-1.9-4.5-4.5-4.5C740.3,606.3,738,608.6,738,610.8z"/>
      <path className="st1" d="M647.7,537.4c0,1.6,1.2,2.9,2.9,2.9s2.9-1.2,2.9-2.9s-1.2-2.9-2.9-2.9C649.2,534.5,647.7,535.9,647.7,537.4z"
        />
      <path className="st2" d="M646.1,537.4c0,2.6,1.9,4.5,4.5,4.5s4.5-1.9,4.5-4.5s-1.9-4.5-4.5-4.5C648.4,532.9,646.1,535.1,646.1,537.4z"
        />
      <path className="st1" d="M730.6,767.1c0,1.6,1.2,2.9,2.9,2.9c1.6,0,2.9-1.2,2.9-2.9s-1.2-2.9-2.9-2.9
        C732,764.3,730.6,765.7,730.6,767.1z"/>
      <path className="st2" d="M728.9,767.1c0,2.6,1.9,4.5,4.5,4.5s4.5-1.9,4.5-4.5s-1.9-4.5-4.5-4.5C731.2,762.7,728.9,764.9,728.9,767.1z"
        />
    </g>
    <g id="Layer_4">
      <path className="st3" d="M843.8,550.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C839.9,548.9,841.6,550.6,843.8,550.6z"/>
      <ellipse className="st3" cx="834.7" cy="546.7" rx="3.9" ry="3.9"/>
      <path className="st3" d="M825.3,550.6c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,548.9,823.1,550.6,825.3,550.6z"/>
      <path className="st3" d="M847.6,537.4c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9S847.6,539.6,847.6,537.4z"/>
      <path className="st3" d="M834.7,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C830.8,539.6,832.5,541.2,834.7,541.2z"/>
      <path className="st3" d="M825.3,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,539.6,823.1,541.2,825.3,541.2z"/>
      <path className="st3" d="M816.3,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C812.4,539.6,814.1,541.2,816.3,541.2z"/>
      <path className="st3" d="M806.9,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C803,539.6,805,541.2,806.9,541.2z
        "/>
      <path className="st3" d="M797.8,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C794,539.6,795.6,541.2,797.8,541.2z"/>
      <path className="st3" d="M788.5,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C784.6,539.6,786.5,541.2,788.5,541.2z"/>
      <path className="st3" d="M779.4,541.2c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C775.5,539.6,777.2,541.2,779.4,541.2z"/>
      <path className="st3" d="M853.1,524.4c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S855.3,524.4,853.1,524.4z"/>
      <path className="st3" d="M847.6,528.3c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9C846,532.1,847.6,530.5,847.6,528.3z"/>
      <ellipse className="st3" cx="834.7" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st3" d="M825.3,532.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C821.5,530.5,823.1,532.1,825.3,532.1z"/>
      <path className="st3" d="M816.3,532.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C812.4,530.5,814.1,532.1,816.3,532.1z"/>
      <path className="st3" d="M806.9,532.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9C803,530.5,805,532.1,806.9,532.1z
        "/>
      <ellipse className="st3" cx="797.8" cy="528.3" rx="3.9" ry="3.9"/>
      <path className="st3" d="M788.5,532.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C784.6,530.5,786.5,532.1,788.5,532.1z"/>
      <path className="st3" d="M779.4,532.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C775.5,530.5,777.2,532.1,779.4,532.1z"/>
      <path className="st3" d="M862.2,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C866.1,517,864.4,515.1,862.2,515.1z"/>
      <path className="st3" d="M853.1,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C857,517,855.3,515.1,853.1,515.1z"/>
      <path className="st3" d="M843.8,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C847.6,517,846,515.1,843.8,515.1z"/>
      <path className="st3" d="M834.7,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C838.5,517,836.9,515.1,834.7,515.1z"/>
      <path className="st3" d="M821.5,518.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C823.1,515.1,821.5,517,821.5,518.9z"/>
      <path className="st3" d="M812.4,518.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C814.1,515.1,812.4,517,812.4,518.9z"/>
      <path className="st4" d="M804,518.9c0,1.6,1.2,2.9,2.9,2.9s2.9-1.2,2.9-2.9c0-1.6-1.2-2.9-2.9-2.9C805.5,516.1,804,517.5,804,518.9z"/>
      <path className="st5" d="M802.4,518.9c0,2.6,1.9,4.5,4.5,4.5s4.5-1.9,4.5-4.5s-1.9-4.5-4.5-4.5C804.7,514.4,802.4,516.7,802.4,518.9z"
        />
      <path className="st3" d="M797.8,515.1c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C801.7,517,800,515.1,797.8,515.1z"/>
      <path className="st3" d="M788.5,523.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C784.6,521.1,786.5,523.1,788.5,523.1z"/>
      <path className="st3" d="M779.4,523.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C775.5,521.1,777.2,523.1,779.4,523.1z"/>
      <path className="st3" d="M770.3,523.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C766.2,521.1,768.1,523.1,770.3,523.1z"/>
      <path className="st3" d="M760.9,523.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C757.1,521.1,758.7,523.1,760.9,523.1z"/>
      <path className="st3" d="M751.9,523.1c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C747.7,521.1,749.7,523.1,751.9,523.1z"/>
      <path className="st3" d="M862.2,506c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C866.1,507.6,864.4,506,862.2,506z"/>
      <path className="st3" d="M853.1,506c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C857,507.6,855.3,506,853.1,506z"/>
      <path className="st3" d="M843.8,506c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C847.6,507.6,846,506,843.8,506z"/>
      <ellipse className="st3" cx="834.7" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st3" d="M821.5,509.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C823.1,506,821.5,507.6,821.5,509.8z"/>
      <path className="st3" d="M812.4,509.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C814.1,506,812.4,507.6,812.4,509.8z"/>
      <path className="st3" d="M803,509.8c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C805,506,803,507.6,803,509.8z"/>
      <ellipse className="st3" cx="797.8" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st3" d="M788.5,506c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C792.3,507.6,790.7,506,788.5,506z"/>
      <path className="st3" d="M779.4,506c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C783.2,507.6,781.6,506,779.4,506z"/>
      <path className="st3" d="M770.3,513.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C766.4,512,768.1,513.7,770.3,513.7z"/>
      <ellipse className="st3" cx="760.9" cy="509.8" rx="3.9" ry="3.9"/>
      <path className="st3" d="M751.9,513.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C748,512,749.7,513.7,751.9,513.7z"/>
      <path className="st3" d="M742.5,513.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C738.7,512,740.3,513.7,742.5,513.7z"/>
      <path className="st3" d="M862.2,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C866.1,498.6,864.4,496.6,862.2,496.6z"/>
      <path className="st3" d="M853.1,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C857,498.6,855.3,496.6,853.1,496.6z"/>
      <path className="st3" d="M843.8,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C847.6,498.6,846,496.6,843.8,496.6z"/>
      <path className="st3" d="M830.8,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C832.5,496.9,830.8,498.6,830.8,500.8z"/>
      <path className="st3" d="M821.5,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C823.1,496.6,821.5,498.6,821.5,500.8z"/>
      <path className="st3" d="M812.4,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C814.1,496.6,812.4,498.6,812.4,500.8z"/>
      <path className="st3" d="M803,500.8c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C805,496.6,803,498.6,803,500.8z"/>
      <path className="st3" d="M797.8,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C801.7,498.6,800,496.6,797.8,496.6z"/>
      <path className="st3" d="M788.5,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C792.6,498.6,790.7,496.6,788.5,496.6z"/>
      <path className="st3" d="M779.4,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C783.2,498.6,781.6,496.6,779.4,496.6z"/>
      <path className="st3" d="M770.3,496.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C774.2,498.6,772.2,496.6,770.3,496.6z"
        />
      <path className="st3" d="M757.1,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C758.7,496.9,757.1,498.6,757.1,500.8z"/>
      <path className="st3" d="M747.7,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C749.7,496.6,747.7,498.6,747.7,500.8z"/>
      <path className="st3" d="M738.7,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C740.3,496.6,738.7,498.6,738.7,500.8z"/>
      <path className="st3" d="M729.3,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C731.2,496.6,729.3,498.6,729.3,500.8z"/>
      <path className="st3" d="M720.2,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C721.9,496.9,720.2,498.6,720.2,500.8z"/>
      <path className="st3" d="M710.9,500.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C712.8,496.6,710.9,498.6,710.9,500.8z"/>
      <path className="st3" d="M862.2,487.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S864.4,487.6,862.2,487.6z"/>
      <path className="st3" d="M853.1,487.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S855.3,487.6,853.1,487.6z"/>
      <path className="st3" d="M843.8,487.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C847.6,489.2,846,487.6,843.8,487.6z"/>
      <path className="st3" d="M830.8,491.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C832.5,487.6,830.8,489.2,830.8,491.4z"/>
      <path className="st3" d="M821.5,491.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C823.1,487.6,821.5,489.2,821.5,491.4z"/>
      <path className="st3" d="M812.4,491.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C814.1,487.6,812.4,489.2,812.4,491.4z"/>
      <path className="st3" d="M803,491.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C805,487.6,803,489.2,803,491.4z"/>
      <path className="st3" d="M797.8,487.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S800,487.6,797.8,487.6z"/>
      <path className="st3" d="M788.5,487.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C792.6,489.2,790.7,487.6,788.5,487.6z"/>
      <path className="st3" d="M779.4,487.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S781.6,487.6,779.4,487.6z"/>
      <path className="st3" d="M770.3,487.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S772.2,487.6,770.3,487.6z"/>
      <path className="st3" d="M757.1,491.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C758.7,487.6,757.1,489.2,757.1,491.4z"/>
      <path className="st3" d="M747.7,491.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C749.7,487.6,747.7,489.2,747.7,491.4z"/>
      <path className="st3" d="M738.7,491.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C740.3,487.6,738.7,489.2,738.7,491.4z"/>
      <path className="st3" d="M729.3,491.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C731.2,487.6,729.3,489.2,729.3,491.4z"/>
      <path className="st3" d="M720.2,491.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C721.9,487.6,720.2,489.2,720.2,491.4z"/>
      <path className="st3" d="M710.9,491.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C712.8,487.6,710.9,489.2,710.9,491.4z"/>
      <path className="st3" d="M853.1,478.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S855.3,478.5,853.1,478.5z"/>
      <path className="st3" d="M843.8,478.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S846,478.5,843.8,478.5z"/>
      <ellipse className="st3" cx="834.7" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st3" d="M821.5,482.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C823.1,478.5,821.5,480.1,821.5,482.3z"/>
      <path className="st3" d="M812.4,482.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C814.1,478.5,812.4,480.1,812.4,482.3z"/>
      <path className="st3" d="M803,482.3c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C805,478.5,803,480.1,803,482.3z"/>
      <ellipse className="st3" cx="797.8" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st3" d="M788.5,478.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S790.7,478.5,788.5,478.5z"/>
      <path className="st3" d="M779.4,478.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S781.6,478.5,779.4,478.5z"/>
      <path className="st3" d="M770.3,478.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S772.2,478.5,770.3,478.5z"/>
      <ellipse className="st3" cx="760.9" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st3" d="M747.7,482.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C749.7,478.5,747.7,480.1,747.7,482.3z"/>
      <path className="st3" d="M738.7,482.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C740.3,478.5,738.7,480.1,738.7,482.3z"/>
      <path className="st3" d="M729.3,482.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C731.2,478.5,729.3,480.1,729.3,482.3z"/>
      <ellipse className="st3" cx="724.1" cy="482.3" rx="3.9" ry="3.9"/>
      <path className="st3" d="M710.9,482.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C712.8,478.5,710.9,480.1,710.9,482.3z"/>
      <path className="st3" d="M853.1,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C857,471,855.3,469.1,853.1,469.1z
        "/>
      <path className="st3" d="M843.8,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C847.6,471,846,469.1,843.8,469.1z
        "/>
      <path className="st3" d="M830.8,473c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C832.5,469.1,830.8,471,830.8,473z"/>
      <path className="st3" d="M821.5,473c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C823.1,469.1,821.5,471,821.5,473z"/>
      <path className="st3" d="M812.4,473c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C814.1,469.1,812.4,471,812.4,473z"/>
      <path className="st3" d="M803,473c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C805,469.1,803,471,803,473z"/>
      <path className="st3" d="M797.8,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C801.7,471,800,469.1,797.8,469.1z
        "/>
      <path className="st3" d="M788.5,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C792.6,471,790.7,469.1,788.5,469.1z"/>
      <path className="st3" d="M779.4,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C783.2,471,781.6,469.1,779.4,469.1z"/>
      <path className="st3" d="M770.3,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C774.2,471,772.2,469.1,770.3,469.1z"/>
      <path className="st3" d="M760.9,469.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C764.8,471,763.1,469.1,760.9,469.1z"/>
      <path className="st3" d="M747.7,473c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C749.7,469.1,747.7,471,747.7,473z"/>
      <path className="st3" d="M738.7,473c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C740.3,469.1,738.7,471,738.7,473z"/>
      <path className="st3" d="M733.4,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C729.3,475.2,731.2,477.1,733.4,477.1z"/>
      <path className="st3" d="M724.1,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C720.2,475.2,721.9,477.1,724.1,477.1z"/>
      <path className="st3" d="M715,477.1c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9C710.9,475.2,712.8,477.1,715,477.1
        z"/>
      <path className="st3" d="M853.1,460c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S855.3,460,853.1,460z"/>
      <path className="st3" d="M839.9,463.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C841.6,460,839.9,461.7,839.9,463.9z"/>
      <ellipse className="st3" cx="834.7" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st3" d="M821.5,463.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C823.1,460,821.5,461.7,821.5,463.9z"/>
      <path className="st3" d="M812.4,463.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C814.1,460,812.4,461.7,812.4,463.9z"/>
      <path className="st3" d="M803,463.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C805,460,803,461.7,803,463.9z"/>
      <ellipse className="st3" cx="797.8" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st3" d="M788.5,460c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S790.7,460,788.5,460z"/>
      <path className="st3" d="M779.4,460c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S781.6,460,779.4,460z"/>
      <path className="st3" d="M770.3,460c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S772.2,460,770.3,460z"/>
      <ellipse className="st3" cx="760.9" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st3" d="M747.7,463.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C749.7,460,747.7,461.7,747.7,463.9z"/>
      <path className="st3" d="M738.7,463.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C740.3,460,738.7,461.7,738.7,463.9z"/>
      <path className="st3" d="M733.4,460c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S735.4,460,733.4,460z"/>
      <ellipse className="st3" cx="724.1" cy="463.9" rx="3.9" ry="3.9"/>
      <path className="st3" d="M710.9,463.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C712.8,460,710.9,461.7,710.9,463.9z"/>
      <path className="st3" d="M701.8,463.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C703.4,460,701.8,461.7,701.8,463.9z"/>
      <path className="st3" d="M853.1,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C857,452.6,855.3,450.7,853.1,450.7z"/>
      <path className="st3" d="M839.9,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C841.6,450.7,839.9,452.6,839.9,454.8z"/>
      <path className="st3" d="M830.8,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C832.5,451,830.8,452.6,830.8,454.8z"/>
      <path className="st3" d="M821.5,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C823.1,450.7,821.5,452.6,821.5,454.8z"/>
      <path className="st3" d="M812.4,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C814.1,450.7,812.4,452.6,812.4,454.8z"/>
      <path className="st3" d="M803,454.8c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C805,450.7,803,452.6,803,454.8z"/>
      <path className="st3" d="M797.8,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C801.7,452.6,800,450.7,797.8,450.7z"/>
      <path className="st3" d="M788.5,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C792.6,452.6,790.7,450.7,788.5,450.7z"/>
      <path className="st3" d="M779.4,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C783.2,452.6,781.6,450.7,779.4,450.7z"/>
      <path className="st3" d="M770.3,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C774.2,452.6,772.2,450.7,770.3,450.7z"/>
      <path className="st3" d="M760.9,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C764.8,452.6,763.1,450.7,760.9,450.7z"/>
      <path className="st3" d="M747.7,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C749.7,450.7,747.7,452.6,747.7,454.8z"/>
      <path className="st3" d="M738.7,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C740.3,450.7,738.7,452.6,738.7,454.8z"/>
      <path className="st3" d="M733.4,450.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,452.6,735.4,450.7,733.4,450.7z"/>
      <path className="st3" d="M720.2,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C721.9,451,720.2,452.6,720.2,454.8z"/>
      <path className="st3" d="M710.9,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C712.8,450.7,710.9,452.6,710.9,454.8z"/>
      <path className="st3" d="M701.8,454.8c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C703.4,450.7,701.8,452.6,701.8,454.8z"/>
      <path className="st3" d="M862.2,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S864.4,441.6,862.2,441.6z"/>
      <path className="st3" d="M853.1,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S855.3,441.6,853.1,441.6z"/>
      <path className="st3" d="M839.9,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C841.6,441.6,839.9,443.3,839.9,445.5z"/>
      <path className="st3" d="M830.8,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C832.5,441.6,830.8,443.3,830.8,445.5z"/>
      <path className="st3" d="M821.5,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C823.1,441.6,821.5,443.3,821.5,445.5z"/>
      <path className="st3" d="M812.4,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C814.1,441.6,812.4,443.3,812.4,445.5z"/>
      <path className="st3" d="M803,445.5c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C805,441.6,803,443.3,803,445.5z"/>
      <path className="st3" d="M797.8,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S800,441.6,797.8,441.6z"/>
      <path className="st3" d="M788.5,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C792.6,443.3,790.7,441.6,788.5,441.6z"/>
      <path className="st3" d="M779.4,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S781.6,441.6,779.4,441.6z"/>
      <path className="st3" d="M770.3,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S772.2,441.6,770.3,441.6z"/>
      <path className="st3" d="M760.9,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S763.1,441.6,760.9,441.6z"/>
      <path className="st3" d="M747.7,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C749.7,441.6,747.7,443.3,747.7,445.5z"/>
      <path className="st3" d="M738.7,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C740.3,441.6,738.7,443.3,738.7,445.5z"/>
      <path className="st3" d="M733.4,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S735.4,441.6,733.4,441.6z"/>
      <path className="st3" d="M724.1,441.6c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,443.3,726.3,441.6,724.1,441.6z"/>
      <path className="st3" d="M710.9,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C712.8,441.6,710.9,443.3,710.9,445.5z"/>
      <path className="st3" d="M701.8,445.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C703.4,441.6,701.8,443.3,701.8,445.5z"/>
      <path className="st3" d="M862.2,432.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S864.4,432.5,862.2,432.5z"/>
      <path className="st3" d="M853.1,432.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S855.3,432.5,853.1,432.5z"/>
      <path className="st3" d="M839.9,436.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C841.6,432.5,839.9,434.2,839.9,436.4z"/>
      <ellipse className="st3" cx="834.7" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st3" d="M821.5,436.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C823.1,432.5,821.5,434.2,821.5,436.4z"/>
      <path className="st3" d="M812.4,436.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C814.1,432.5,812.4,434.2,812.4,436.4z"/>
      <path className="st3" d="M803,436.4c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C805,432.5,803,434.2,803,436.4z"/>
      <ellipse className="st3" cx="797.8" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st3" d="M788.5,432.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S790.7,432.5,788.5,432.5z"/>
      <path className="st3" d="M779.4,432.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S781.6,432.5,779.4,432.5z"/>
      <path className="st3" d="M770.3,432.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S772.2,432.5,770.3,432.5z"/>
      <ellipse className="st3" cx="760.9" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st3" d="M747.7,436.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C749.7,432.5,747.7,434.2,747.7,436.4z"/>
      <path className="st3" d="M738.7,436.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C740.3,432.5,738.7,434.2,738.7,436.4z"/>
      <path className="st3" d="M733.4,432.5c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S735.4,432.5,733.4,432.5z"/>
      <ellipse className="st3" cx="724.1" cy="436.4" rx="3.9" ry="3.9"/>
      <path className="st3" d="M710.9,436.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C712.8,432.5,710.9,434.2,710.9,436.4z"/>
      <path className="st3" d="M701.8,436.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C703.4,432.5,701.8,434.2,701.8,436.4z"/>
      <path className="st3" d="M862.2,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C866.1,425.1,864.4,423.2,862.2,423.2z"/>
      <path className="st3" d="M853.1,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C857,425.1,855.3,423.2,853.1,423.2z"/>
      <path className="st3" d="M839.9,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C841.6,423.2,839.9,425.1,839.9,427.3z"/>
      <path className="st3" d="M830.8,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C832.5,423.4,830.8,425.1,830.8,427.3z"/>
      <path className="st3" d="M821.5,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C823.1,423.2,821.5,425.1,821.5,427.3z"/>
      <path className="st3" d="M812.4,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C814.1,423.2,812.4,425.1,812.4,427.3z"/>
      <path className="st3" d="M803,427.3c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9C805,423.2,803,425.1,803,427.3z"/>
      <path className="st3" d="M797.8,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C801.7,425.1,800,423.2,797.8,423.2z"/>
      <path className="st3" d="M788.5,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C792.6,425.1,790.7,423.2,788.5,423.2z"/>
      <path className="st3" d="M779.4,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C783.2,425.1,781.6,423.2,779.4,423.2z"/>
      <path className="st3" d="M770.3,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C774.2,425.1,772.2,423.2,770.3,423.2z"
        />
      <path className="st3" d="M760.9,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C764.8,425.1,763.1,423.2,760.9,423.2z"/>
      <path className="st3" d="M747.7,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C749.7,423.2,747.7,425.1,747.7,427.3z"/>
      <path className="st3" d="M738.7,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C740.3,423.2,738.7,425.1,738.7,427.3z"/>
      <path className="st3" d="M733.4,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C737.3,425.1,735.4,423.2,733.4,423.2z"/>
      <path className="st3" d="M724.1,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9
        C727.9,425.1,726.3,423.2,724.1,423.2z"/>
      <path className="st3" d="M715,423.2c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C718.8,425.1,717.2,423.2,715,423.2z
        "/>
      <path className="st3" d="M701.8,427.3c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
        C703.4,423.2,701.8,425.1,701.8,427.3z"/>
      <path className="st3" d="M849,417.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C850.9,414.1,849,415.7,849,417.9z"/>
      <path className="st3" d="M839.9,417.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C841.6,414.1,839.9,415.7,839.9,417.9z"/>
      <ellipse className="st3" cx="834.7" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st3" d="M821.5,417.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C823.1,414.1,821.5,415.7,821.5,417.9z"/>
      <path className="st3" d="M812.4,417.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C814.1,414.1,812.4,415.7,812.4,417.9z"/>
      <path className="st3" d="M803,417.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C805,414.1,803,415.7,803,417.9z"/>
      <ellipse className="st3" cx="797.8" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st3" d="M788.5,414.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S790.7,414.1,788.5,414.1z"/>
      <path className="st3" d="M779.4,414.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S781.6,414.1,779.4,414.1z"/>
      <path className="st3" d="M770.3,414.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S772.2,414.1,770.3,414.1z"/>
      <ellipse className="st3" cx="760.9" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st3" d="M747.7,417.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C749.7,414.1,747.7,415.7,747.7,417.9z"/>
      <path className="st3" d="M738.7,417.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C740.3,414.1,738.7,415.7,738.7,417.9z"/>
      <path className="st3" d="M733.4,414.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S735.4,414.1,733.4,414.1z"/>
      <ellipse className="st3" cx="724.1" cy="417.9" rx="3.9" ry="3.9"/>
      <path className="st3" d="M715,414.1c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S717.2,414.1,715,414.1z"/>
      <path className="st3" d="M705.6,421.8c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C701.8,420.1,703.4,421.8,705.6,421.8z"/>
      <path className="st3" d="M849,408.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9C850.9,405,849,406.7,849,408.9
        z"/>
      <path className="st3" d="M843.8,405c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C847.6,406.7,846,405,843.8,405z"/>
      <path className="st3" d="M834.7,405c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S836.9,405,834.7,405z"/>
      <path className="st3" d="M825.3,405c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C829.5,406.7,827.5,405,825.3,405z"
        />
      <path className="st3" d="M812.4,408.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C814.1,405,812.4,406.7,812.4,408.9z"/>
      <path className="st3" d="M806.9,405c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9C811,406.7,809.1,405,806.9,405z"/>
      <path className="st3" d="M797.8,405c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S800,405,797.8,405z"/>
      <path className="st3" d="M788.5,405c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9C792.6,406.7,790.7,405,788.5,405z"
        />
      <path className="st3" d="M779.4,405c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S781.6,405,779.4,405z"/>
      <path className="st3" d="M770.3,405c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S772.2,405,770.3,405z"/>
      <path className="st3" d="M760.9,405c-2.2,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9S763.1,405,760.9,405z"/>
      <path className="st3" d="M747.7,408.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C749.7,405,747.7,406.7,747.7,408.9z"/>
      <path className="st3" d="M742.5,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C738.7,411.1,740.3,412.7,742.5,412.7z"/>
      <path className="st3" d="M733.4,412.7c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9
        C729.3,411.1,731.2,412.7,733.4,412.7z"/>
      <path className="st3" d="M720.2,408.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C721.9,405,720.2,406.7,720.2,408.9z"/>
      <path className="st3" d="M710.9,408.9c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C712.8,405,710.9,406.7,710.9,408.9z"/>
      <ellipse className="st3" cx="797.8" cy="399.5" rx="3.9" ry="3.9"/>
      <path className="st3" d="M784.6,399.5c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C786.5,395.7,784.6,397.6,784.6,399.5z"/>
      <path className="st3" d="M779.4,403.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S777.2,403.6,779.4,403.6z"/>
      <path className="st3" d="M770.3,395.7c-2.2,0-3.9,1.7-3.9,3.9c0,2.2,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9
        C774.2,397.3,772.2,395.7,770.3,395.7z"/>
      <ellipse className="st3" cx="760.9" cy="399.5" rx="3.9" ry="3.9"/>
      <path className="st3" d="M751.9,403.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S749.7,403.6,751.9,403.6z"/>
      <path className="st3" d="M742.5,403.6c2.2,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9c-2.2,0-3.9,1.7-3.9,3.9S740.3,403.6,742.5,403.6z"/>
      <path className="st3" d="M770.3,394.3c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9
        C766.2,392.6,768.1,394.3,770.3,394.3z"/>
      <path className="st3" d="M757.1,390.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C758.7,386.6,757.1,388.2,757.1,390.4z"/>
      <path className="st3" d="M747.7,390.4c0,2.2,1.7,3.9,3.9,3.9c2.2,0,3.9-1.7,3.9-3.9c0-2.2-1.7-3.9-3.9-3.9
        C749.7,386.6,747.7,388.2,747.7,390.4z"/>
    </g>
    </svg>


    </>
  )
}

export default BannerMap
